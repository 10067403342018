import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: { input: any; output: any; }
  /** A field whose value conforms to the standard internet email address format as specified in HTML Spec: https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address. */
  EmailAddress: { input: any; output: any; }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any; }
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: { input: any; output: any; }
};

export type Access = {
  __typename?: 'Access';
  articles?: Maybe<ArticlesAccess>;
  canAccessAdmin: Scalars['Boolean']['output'];
  categories?: Maybe<CategoriesAccess>;
  media?: Maybe<MediaAccess>;
  payload_preferences?: Maybe<Payload_PreferencesAccess>;
  task_templates?: Maybe<Task_TemplatesAccess>;
  users?: Maybe<UsersAccess>;
};

export type Article = {
  __typename?: 'Article';
  _status?: Maybe<Article__Status>;
  author?: Maybe<User>;
  categories?: Maybe<Array<Category>>;
  content: Array<Article_Content>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  hero?: Maybe<Article_Hero>;
  id?: Maybe<Scalars['String']['output']>;
  meta?: Maybe<Article_Meta>;
  populatedAuthor?: Maybe<Article_PopulatedAuthor>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  relatedArticles?: Maybe<Array<Article>>;
  slug?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum ArticleUpdate__Status_MutationInput {
  Draft = 'draft',
  Published = 'published'
}

export type ArticleVersion = {
  __typename?: 'ArticleVersion';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  latest?: Maybe<Scalars['Boolean']['output']>;
  parent?: Maybe<Article>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  version?: Maybe<ArticleVersion_Version>;
};

export type ArticleVersion_Version = {
  __typename?: 'ArticleVersion_Version';
  _status?: Maybe<ArticleVersion_Version__Status>;
  author?: Maybe<User>;
  categories?: Maybe<Array<Category>>;
  content: Array<ArticleVersion_Version_Content>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  hero?: Maybe<ArticleVersion_Version_Hero>;
  meta?: Maybe<ArticleVersion_Version_Meta>;
  populatedAuthor?: Maybe<ArticleVersion_Version_PopulatedAuthor>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  relatedArticles?: Maybe<Array<Article>>;
  slug?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ArticleVersion_Version_Content = Content | Cta | MediaBlock;

export type ArticleVersion_Version_Hero = {
  __typename?: 'ArticleVersion_Version_Hero';
  media?: Maybe<Media>;
  richText?: Maybe<Scalars['JSON']['output']>;
};


export type ArticleVersion_Version_HeroMediaArgs = {
  where?: InputMaybe<ArticleVersion_Version_Hero_Media_Where>;
};


export type ArticleVersion_Version_HeroRichTextArgs = {
  depth?: InputMaybe<Scalars['Int']['input']>;
};

export type ArticleVersion_Version_Hero_Media_Alt_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ArticleVersion_Version_Hero_Media_Caption_Operator = {
  contains?: InputMaybe<Scalars['JSON']['input']>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<Scalars['JSON']['input']>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
};

export type ArticleVersion_Version_Hero_Media_CreatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ArticleVersion_Version_Hero_Media_Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ArticleVersion_Version_Hero_Media_Filesize_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type ArticleVersion_Version_Hero_Media_Height_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type ArticleVersion_Version_Hero_Media_Id_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ArticleVersion_Version_Hero_Media_MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ArticleVersion_Version_Hero_Media_UpdatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ArticleVersion_Version_Hero_Media_Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ArticleVersion_Version_Hero_Media_Where = {
  AND?: InputMaybe<Array<InputMaybe<ArticleVersion_Version_Hero_Media_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<ArticleVersion_Version_Hero_Media_Where_Or>>>;
  alt?: InputMaybe<ArticleVersion_Version_Hero_Media_Alt_Operator>;
  caption?: InputMaybe<ArticleVersion_Version_Hero_Media_Caption_Operator>;
  createdAt?: InputMaybe<ArticleVersion_Version_Hero_Media_CreatedAt_Operator>;
  filename?: InputMaybe<ArticleVersion_Version_Hero_Media_Filename_Operator>;
  filesize?: InputMaybe<ArticleVersion_Version_Hero_Media_Filesize_Operator>;
  height?: InputMaybe<ArticleVersion_Version_Hero_Media_Height_Operator>;
  id?: InputMaybe<ArticleVersion_Version_Hero_Media_Id_Operator>;
  mimeType?: InputMaybe<ArticleVersion_Version_Hero_Media_MimeType_Operator>;
  updatedAt?: InputMaybe<ArticleVersion_Version_Hero_Media_UpdatedAt_Operator>;
  url?: InputMaybe<ArticleVersion_Version_Hero_Media_Url_Operator>;
  width?: InputMaybe<ArticleVersion_Version_Hero_Media_Width_Operator>;
};

export type ArticleVersion_Version_Hero_Media_Where_And = {
  AND?: InputMaybe<Array<InputMaybe<ArticleVersion_Version_Hero_Media_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<ArticleVersion_Version_Hero_Media_Where_Or>>>;
  alt?: InputMaybe<ArticleVersion_Version_Hero_Media_Alt_Operator>;
  caption?: InputMaybe<ArticleVersion_Version_Hero_Media_Caption_Operator>;
  createdAt?: InputMaybe<ArticleVersion_Version_Hero_Media_CreatedAt_Operator>;
  filename?: InputMaybe<ArticleVersion_Version_Hero_Media_Filename_Operator>;
  filesize?: InputMaybe<ArticleVersion_Version_Hero_Media_Filesize_Operator>;
  height?: InputMaybe<ArticleVersion_Version_Hero_Media_Height_Operator>;
  id?: InputMaybe<ArticleVersion_Version_Hero_Media_Id_Operator>;
  mimeType?: InputMaybe<ArticleVersion_Version_Hero_Media_MimeType_Operator>;
  updatedAt?: InputMaybe<ArticleVersion_Version_Hero_Media_UpdatedAt_Operator>;
  url?: InputMaybe<ArticleVersion_Version_Hero_Media_Url_Operator>;
  width?: InputMaybe<ArticleVersion_Version_Hero_Media_Width_Operator>;
};

export type ArticleVersion_Version_Hero_Media_Where_Or = {
  AND?: InputMaybe<Array<InputMaybe<ArticleVersion_Version_Hero_Media_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<ArticleVersion_Version_Hero_Media_Where_Or>>>;
  alt?: InputMaybe<ArticleVersion_Version_Hero_Media_Alt_Operator>;
  caption?: InputMaybe<ArticleVersion_Version_Hero_Media_Caption_Operator>;
  createdAt?: InputMaybe<ArticleVersion_Version_Hero_Media_CreatedAt_Operator>;
  filename?: InputMaybe<ArticleVersion_Version_Hero_Media_Filename_Operator>;
  filesize?: InputMaybe<ArticleVersion_Version_Hero_Media_Filesize_Operator>;
  height?: InputMaybe<ArticleVersion_Version_Hero_Media_Height_Operator>;
  id?: InputMaybe<ArticleVersion_Version_Hero_Media_Id_Operator>;
  mimeType?: InputMaybe<ArticleVersion_Version_Hero_Media_MimeType_Operator>;
  updatedAt?: InputMaybe<ArticleVersion_Version_Hero_Media_UpdatedAt_Operator>;
  url?: InputMaybe<ArticleVersion_Version_Hero_Media_Url_Operator>;
  width?: InputMaybe<ArticleVersion_Version_Hero_Media_Width_Operator>;
};

export type ArticleVersion_Version_Hero_Media_Width_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type ArticleVersion_Version_Meta = {
  __typename?: 'ArticleVersion_Version_Meta';
  description?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Media>;
  title?: Maybe<Scalars['String']['output']>;
};


export type ArticleVersion_Version_MetaImageArgs = {
  where?: InputMaybe<ArticleVersion_Version_Meta_Image_Where>;
};

export type ArticleVersion_Version_Meta_Image_Alt_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ArticleVersion_Version_Meta_Image_Caption_Operator = {
  contains?: InputMaybe<Scalars['JSON']['input']>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<Scalars['JSON']['input']>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
};

export type ArticleVersion_Version_Meta_Image_CreatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ArticleVersion_Version_Meta_Image_Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ArticleVersion_Version_Meta_Image_Filesize_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type ArticleVersion_Version_Meta_Image_Height_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type ArticleVersion_Version_Meta_Image_Id_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ArticleVersion_Version_Meta_Image_MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ArticleVersion_Version_Meta_Image_UpdatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ArticleVersion_Version_Meta_Image_Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ArticleVersion_Version_Meta_Image_Where = {
  AND?: InputMaybe<Array<InputMaybe<ArticleVersion_Version_Meta_Image_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<ArticleVersion_Version_Meta_Image_Where_Or>>>;
  alt?: InputMaybe<ArticleVersion_Version_Meta_Image_Alt_Operator>;
  caption?: InputMaybe<ArticleVersion_Version_Meta_Image_Caption_Operator>;
  createdAt?: InputMaybe<ArticleVersion_Version_Meta_Image_CreatedAt_Operator>;
  filename?: InputMaybe<ArticleVersion_Version_Meta_Image_Filename_Operator>;
  filesize?: InputMaybe<ArticleVersion_Version_Meta_Image_Filesize_Operator>;
  height?: InputMaybe<ArticleVersion_Version_Meta_Image_Height_Operator>;
  id?: InputMaybe<ArticleVersion_Version_Meta_Image_Id_Operator>;
  mimeType?: InputMaybe<ArticleVersion_Version_Meta_Image_MimeType_Operator>;
  updatedAt?: InputMaybe<ArticleVersion_Version_Meta_Image_UpdatedAt_Operator>;
  url?: InputMaybe<ArticleVersion_Version_Meta_Image_Url_Operator>;
  width?: InputMaybe<ArticleVersion_Version_Meta_Image_Width_Operator>;
};

export type ArticleVersion_Version_Meta_Image_Where_And = {
  AND?: InputMaybe<Array<InputMaybe<ArticleVersion_Version_Meta_Image_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<ArticleVersion_Version_Meta_Image_Where_Or>>>;
  alt?: InputMaybe<ArticleVersion_Version_Meta_Image_Alt_Operator>;
  caption?: InputMaybe<ArticleVersion_Version_Meta_Image_Caption_Operator>;
  createdAt?: InputMaybe<ArticleVersion_Version_Meta_Image_CreatedAt_Operator>;
  filename?: InputMaybe<ArticleVersion_Version_Meta_Image_Filename_Operator>;
  filesize?: InputMaybe<ArticleVersion_Version_Meta_Image_Filesize_Operator>;
  height?: InputMaybe<ArticleVersion_Version_Meta_Image_Height_Operator>;
  id?: InputMaybe<ArticleVersion_Version_Meta_Image_Id_Operator>;
  mimeType?: InputMaybe<ArticleVersion_Version_Meta_Image_MimeType_Operator>;
  updatedAt?: InputMaybe<ArticleVersion_Version_Meta_Image_UpdatedAt_Operator>;
  url?: InputMaybe<ArticleVersion_Version_Meta_Image_Url_Operator>;
  width?: InputMaybe<ArticleVersion_Version_Meta_Image_Width_Operator>;
};

export type ArticleVersion_Version_Meta_Image_Where_Or = {
  AND?: InputMaybe<Array<InputMaybe<ArticleVersion_Version_Meta_Image_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<ArticleVersion_Version_Meta_Image_Where_Or>>>;
  alt?: InputMaybe<ArticleVersion_Version_Meta_Image_Alt_Operator>;
  caption?: InputMaybe<ArticleVersion_Version_Meta_Image_Caption_Operator>;
  createdAt?: InputMaybe<ArticleVersion_Version_Meta_Image_CreatedAt_Operator>;
  filename?: InputMaybe<ArticleVersion_Version_Meta_Image_Filename_Operator>;
  filesize?: InputMaybe<ArticleVersion_Version_Meta_Image_Filesize_Operator>;
  height?: InputMaybe<ArticleVersion_Version_Meta_Image_Height_Operator>;
  id?: InputMaybe<ArticleVersion_Version_Meta_Image_Id_Operator>;
  mimeType?: InputMaybe<ArticleVersion_Version_Meta_Image_MimeType_Operator>;
  updatedAt?: InputMaybe<ArticleVersion_Version_Meta_Image_UpdatedAt_Operator>;
  url?: InputMaybe<ArticleVersion_Version_Meta_Image_Url_Operator>;
  width?: InputMaybe<ArticleVersion_Version_Meta_Image_Width_Operator>;
};

export type ArticleVersion_Version_Meta_Image_Width_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type ArticleVersion_Version_PopulatedAuthor = {
  __typename?: 'ArticleVersion_Version_PopulatedAuthor';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export enum ArticleVersion_Version__Status {
  Draft = 'draft',
  Published = 'published'
}

export type Article_Content = Content | Cta | MediaBlock;

export type Article_Hero = {
  __typename?: 'Article_Hero';
  media?: Maybe<Media>;
  richText?: Maybe<Scalars['JSON']['output']>;
};


export type Article_HeroMediaArgs = {
  where?: InputMaybe<Article_Hero_Media_Where>;
};


export type Article_HeroRichTextArgs = {
  depth?: InputMaybe<Scalars['Int']['input']>;
};

export type Article_Hero_Media_Alt_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Article_Hero_Media_Caption_Operator = {
  contains?: InputMaybe<Scalars['JSON']['input']>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<Scalars['JSON']['input']>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
};

export type Article_Hero_Media_CreatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Article_Hero_Media_Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Article_Hero_Media_Filesize_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Article_Hero_Media_Height_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Article_Hero_Media_Id_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Article_Hero_Media_MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Article_Hero_Media_UpdatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Article_Hero_Media_Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Article_Hero_Media_Where = {
  AND?: InputMaybe<Array<InputMaybe<Article_Hero_Media_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<Article_Hero_Media_Where_Or>>>;
  alt?: InputMaybe<Article_Hero_Media_Alt_Operator>;
  caption?: InputMaybe<Article_Hero_Media_Caption_Operator>;
  createdAt?: InputMaybe<Article_Hero_Media_CreatedAt_Operator>;
  filename?: InputMaybe<Article_Hero_Media_Filename_Operator>;
  filesize?: InputMaybe<Article_Hero_Media_Filesize_Operator>;
  height?: InputMaybe<Article_Hero_Media_Height_Operator>;
  id?: InputMaybe<Article_Hero_Media_Id_Operator>;
  mimeType?: InputMaybe<Article_Hero_Media_MimeType_Operator>;
  updatedAt?: InputMaybe<Article_Hero_Media_UpdatedAt_Operator>;
  url?: InputMaybe<Article_Hero_Media_Url_Operator>;
  width?: InputMaybe<Article_Hero_Media_Width_Operator>;
};

export type Article_Hero_Media_Where_And = {
  AND?: InputMaybe<Array<InputMaybe<Article_Hero_Media_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<Article_Hero_Media_Where_Or>>>;
  alt?: InputMaybe<Article_Hero_Media_Alt_Operator>;
  caption?: InputMaybe<Article_Hero_Media_Caption_Operator>;
  createdAt?: InputMaybe<Article_Hero_Media_CreatedAt_Operator>;
  filename?: InputMaybe<Article_Hero_Media_Filename_Operator>;
  filesize?: InputMaybe<Article_Hero_Media_Filesize_Operator>;
  height?: InputMaybe<Article_Hero_Media_Height_Operator>;
  id?: InputMaybe<Article_Hero_Media_Id_Operator>;
  mimeType?: InputMaybe<Article_Hero_Media_MimeType_Operator>;
  updatedAt?: InputMaybe<Article_Hero_Media_UpdatedAt_Operator>;
  url?: InputMaybe<Article_Hero_Media_Url_Operator>;
  width?: InputMaybe<Article_Hero_Media_Width_Operator>;
};

export type Article_Hero_Media_Where_Or = {
  AND?: InputMaybe<Array<InputMaybe<Article_Hero_Media_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<Article_Hero_Media_Where_Or>>>;
  alt?: InputMaybe<Article_Hero_Media_Alt_Operator>;
  caption?: InputMaybe<Article_Hero_Media_Caption_Operator>;
  createdAt?: InputMaybe<Article_Hero_Media_CreatedAt_Operator>;
  filename?: InputMaybe<Article_Hero_Media_Filename_Operator>;
  filesize?: InputMaybe<Article_Hero_Media_Filesize_Operator>;
  height?: InputMaybe<Article_Hero_Media_Height_Operator>;
  id?: InputMaybe<Article_Hero_Media_Id_Operator>;
  mimeType?: InputMaybe<Article_Hero_Media_MimeType_Operator>;
  updatedAt?: InputMaybe<Article_Hero_Media_UpdatedAt_Operator>;
  url?: InputMaybe<Article_Hero_Media_Url_Operator>;
  width?: InputMaybe<Article_Hero_Media_Width_Operator>;
};

export type Article_Hero_Media_Width_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Article_Meta = {
  __typename?: 'Article_Meta';
  description?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Media>;
  title?: Maybe<Scalars['String']['output']>;
};


export type Article_MetaImageArgs = {
  where?: InputMaybe<Article_Meta_Image_Where>;
};

export type Article_Meta_Image_Alt_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Article_Meta_Image_Caption_Operator = {
  contains?: InputMaybe<Scalars['JSON']['input']>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<Scalars['JSON']['input']>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
};

export type Article_Meta_Image_CreatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Article_Meta_Image_Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Article_Meta_Image_Filesize_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Article_Meta_Image_Height_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Article_Meta_Image_Id_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Article_Meta_Image_MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Article_Meta_Image_UpdatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Article_Meta_Image_Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Article_Meta_Image_Where = {
  AND?: InputMaybe<Array<InputMaybe<Article_Meta_Image_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<Article_Meta_Image_Where_Or>>>;
  alt?: InputMaybe<Article_Meta_Image_Alt_Operator>;
  caption?: InputMaybe<Article_Meta_Image_Caption_Operator>;
  createdAt?: InputMaybe<Article_Meta_Image_CreatedAt_Operator>;
  filename?: InputMaybe<Article_Meta_Image_Filename_Operator>;
  filesize?: InputMaybe<Article_Meta_Image_Filesize_Operator>;
  height?: InputMaybe<Article_Meta_Image_Height_Operator>;
  id?: InputMaybe<Article_Meta_Image_Id_Operator>;
  mimeType?: InputMaybe<Article_Meta_Image_MimeType_Operator>;
  updatedAt?: InputMaybe<Article_Meta_Image_UpdatedAt_Operator>;
  url?: InputMaybe<Article_Meta_Image_Url_Operator>;
  width?: InputMaybe<Article_Meta_Image_Width_Operator>;
};

export type Article_Meta_Image_Where_And = {
  AND?: InputMaybe<Array<InputMaybe<Article_Meta_Image_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<Article_Meta_Image_Where_Or>>>;
  alt?: InputMaybe<Article_Meta_Image_Alt_Operator>;
  caption?: InputMaybe<Article_Meta_Image_Caption_Operator>;
  createdAt?: InputMaybe<Article_Meta_Image_CreatedAt_Operator>;
  filename?: InputMaybe<Article_Meta_Image_Filename_Operator>;
  filesize?: InputMaybe<Article_Meta_Image_Filesize_Operator>;
  height?: InputMaybe<Article_Meta_Image_Height_Operator>;
  id?: InputMaybe<Article_Meta_Image_Id_Operator>;
  mimeType?: InputMaybe<Article_Meta_Image_MimeType_Operator>;
  updatedAt?: InputMaybe<Article_Meta_Image_UpdatedAt_Operator>;
  url?: InputMaybe<Article_Meta_Image_Url_Operator>;
  width?: InputMaybe<Article_Meta_Image_Width_Operator>;
};

export type Article_Meta_Image_Where_Or = {
  AND?: InputMaybe<Array<InputMaybe<Article_Meta_Image_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<Article_Meta_Image_Where_Or>>>;
  alt?: InputMaybe<Article_Meta_Image_Alt_Operator>;
  caption?: InputMaybe<Article_Meta_Image_Caption_Operator>;
  createdAt?: InputMaybe<Article_Meta_Image_CreatedAt_Operator>;
  filename?: InputMaybe<Article_Meta_Image_Filename_Operator>;
  filesize?: InputMaybe<Article_Meta_Image_Filesize_Operator>;
  height?: InputMaybe<Article_Meta_Image_Height_Operator>;
  id?: InputMaybe<Article_Meta_Image_Id_Operator>;
  mimeType?: InputMaybe<Article_Meta_Image_MimeType_Operator>;
  updatedAt?: InputMaybe<Article_Meta_Image_UpdatedAt_Operator>;
  url?: InputMaybe<Article_Meta_Image_Url_Operator>;
  width?: InputMaybe<Article_Meta_Image_Width_Operator>;
};

export type Article_Meta_Image_Width_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Article_PopulatedAuthor = {
  __typename?: 'Article_PopulatedAuthor';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export enum Article__Status {
  Draft = 'draft',
  Published = 'published'
}

export enum Article__Status_Input {
  Draft = 'draft',
  Published = 'published'
}

export enum Article__Status_MutationInput {
  Draft = 'draft',
  Published = 'published'
}

export type Article__Status_Operator = {
  all?: InputMaybe<Array<InputMaybe<Article__Status_Input>>>;
  equals?: InputMaybe<Article__Status_Input>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Article__Status_Input>>>;
  not_equals?: InputMaybe<Article__Status_Input>;
  not_in?: InputMaybe<Array<InputMaybe<Article__Status_Input>>>;
};

export type Article_Author_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
};

export type Article_Categories_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
};

export type Article_CreatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Article_Hero__Media_Operator = {
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
};

export type Article_Hero__RichText_Operator = {
  contains?: InputMaybe<Scalars['JSON']['input']>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  like?: InputMaybe<Scalars['JSON']['input']>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
};

export type Article_Id_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Article_Meta__Description_Operator = {
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
};

export type Article_Meta__Image_Operator = {
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
};

export type Article_Meta__Title_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Article_PopulatedAuthor__Id_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Article_PopulatedAuthor__Name_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Article_PublishedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Article_RelatedArticles_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
};

export type Article_Slug_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Article_Title_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Article_UpdatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Article_Where = {
  AND?: InputMaybe<Array<InputMaybe<Article_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<Article_Where_Or>>>;
  _status?: InputMaybe<Article__Status_Operator>;
  author?: InputMaybe<Article_Author_Operator>;
  categories?: InputMaybe<Article_Categories_Operator>;
  createdAt?: InputMaybe<Article_CreatedAt_Operator>;
  hero__media?: InputMaybe<Article_Hero__Media_Operator>;
  hero__richText?: InputMaybe<Article_Hero__RichText_Operator>;
  id?: InputMaybe<Article_Id_Operator>;
  meta__description?: InputMaybe<Article_Meta__Description_Operator>;
  meta__image?: InputMaybe<Article_Meta__Image_Operator>;
  meta__title?: InputMaybe<Article_Meta__Title_Operator>;
  populatedAuthor__id?: InputMaybe<Article_PopulatedAuthor__Id_Operator>;
  populatedAuthor__name?: InputMaybe<Article_PopulatedAuthor__Name_Operator>;
  publishedAt?: InputMaybe<Article_PublishedAt_Operator>;
  relatedArticles?: InputMaybe<Article_RelatedArticles_Operator>;
  slug?: InputMaybe<Article_Slug_Operator>;
  title?: InputMaybe<Article_Title_Operator>;
  updatedAt?: InputMaybe<Article_UpdatedAt_Operator>;
};

export type Article_Where_And = {
  AND?: InputMaybe<Array<InputMaybe<Article_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<Article_Where_Or>>>;
  _status?: InputMaybe<Article__Status_Operator>;
  author?: InputMaybe<Article_Author_Operator>;
  categories?: InputMaybe<Article_Categories_Operator>;
  createdAt?: InputMaybe<Article_CreatedAt_Operator>;
  hero__media?: InputMaybe<Article_Hero__Media_Operator>;
  hero__richText?: InputMaybe<Article_Hero__RichText_Operator>;
  id?: InputMaybe<Article_Id_Operator>;
  meta__description?: InputMaybe<Article_Meta__Description_Operator>;
  meta__image?: InputMaybe<Article_Meta__Image_Operator>;
  meta__title?: InputMaybe<Article_Meta__Title_Operator>;
  populatedAuthor__id?: InputMaybe<Article_PopulatedAuthor__Id_Operator>;
  populatedAuthor__name?: InputMaybe<Article_PopulatedAuthor__Name_Operator>;
  publishedAt?: InputMaybe<Article_PublishedAt_Operator>;
  relatedArticles?: InputMaybe<Article_RelatedArticles_Operator>;
  slug?: InputMaybe<Article_Slug_Operator>;
  title?: InputMaybe<Article_Title_Operator>;
  updatedAt?: InputMaybe<Article_UpdatedAt_Operator>;
};

export type Article_Where_Or = {
  AND?: InputMaybe<Array<InputMaybe<Article_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<Article_Where_Or>>>;
  _status?: InputMaybe<Article__Status_Operator>;
  author?: InputMaybe<Article_Author_Operator>;
  categories?: InputMaybe<Article_Categories_Operator>;
  createdAt?: InputMaybe<Article_CreatedAt_Operator>;
  hero__media?: InputMaybe<Article_Hero__Media_Operator>;
  hero__richText?: InputMaybe<Article_Hero__RichText_Operator>;
  id?: InputMaybe<Article_Id_Operator>;
  meta__description?: InputMaybe<Article_Meta__Description_Operator>;
  meta__image?: InputMaybe<Article_Meta__Image_Operator>;
  meta__title?: InputMaybe<Article_Meta__Title_Operator>;
  populatedAuthor__id?: InputMaybe<Article_PopulatedAuthor__Id_Operator>;
  populatedAuthor__name?: InputMaybe<Article_PopulatedAuthor__Name_Operator>;
  publishedAt?: InputMaybe<Article_PublishedAt_Operator>;
  relatedArticles?: InputMaybe<Article_RelatedArticles_Operator>;
  slug?: InputMaybe<Article_Slug_Operator>;
  title?: InputMaybe<Article_Title_Operator>;
  updatedAt?: InputMaybe<Article_UpdatedAt_Operator>;
};

export type Articles = {
  __typename?: 'Articles';
  docs?: Maybe<Array<Maybe<Article>>>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  nextPage?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  pagingCounter?: Maybe<Scalars['Int']['output']>;
  prevPage?: Maybe<Scalars['Int']['output']>;
  totalDocs?: Maybe<Scalars['Int']['output']>;
  totalPages?: Maybe<Scalars['Int']['output']>;
};

export type ArticlesCreateAccess = {
  __typename?: 'ArticlesCreateAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type ArticlesCreateDocAccess = {
  __typename?: 'ArticlesCreateDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type ArticlesDeleteAccess = {
  __typename?: 'ArticlesDeleteAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type ArticlesDeleteDocAccess = {
  __typename?: 'ArticlesDeleteDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type ArticlesDocAccessFields = {
  __typename?: 'ArticlesDocAccessFields';
  _status?: Maybe<ArticlesDocAccessFields__Status>;
  author?: Maybe<ArticlesDocAccessFields_Author>;
  categories?: Maybe<ArticlesDocAccessFields_Categories>;
  content?: Maybe<ArticlesDocAccessFields_Content>;
  createdAt?: Maybe<ArticlesDocAccessFields_CreatedAt>;
  hero?: Maybe<ArticlesDocAccessFields_Hero>;
  meta?: Maybe<ArticlesDocAccessFields_Meta>;
  populatedAuthor?: Maybe<ArticlesDocAccessFields_PopulatedAuthor>;
  publishedAt?: Maybe<ArticlesDocAccessFields_PublishedAt>;
  relatedArticles?: Maybe<ArticlesDocAccessFields_RelatedArticles>;
  slug?: Maybe<ArticlesDocAccessFields_Slug>;
  title?: Maybe<ArticlesDocAccessFields_Title>;
  updatedAt?: Maybe<ArticlesDocAccessFields_UpdatedAt>;
};

export type ArticlesDocAccessFields__Status = {
  __typename?: 'ArticlesDocAccessFields__status';
  create?: Maybe<ArticlesDocAccessFields__Status_Create>;
  delete?: Maybe<ArticlesDocAccessFields__Status_Delete>;
  read?: Maybe<ArticlesDocAccessFields__Status_Read>;
  update?: Maybe<ArticlesDocAccessFields__Status_Update>;
};

export type ArticlesDocAccessFields__Status_Create = {
  __typename?: 'ArticlesDocAccessFields__status_Create';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields__Status_Delete = {
  __typename?: 'ArticlesDocAccessFields__status_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields__Status_Read = {
  __typename?: 'ArticlesDocAccessFields__status_Read';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields__Status_Update = {
  __typename?: 'ArticlesDocAccessFields__status_Update';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_Author = {
  __typename?: 'ArticlesDocAccessFields_author';
  create?: Maybe<ArticlesDocAccessFields_Author_Create>;
  delete?: Maybe<ArticlesDocAccessFields_Author_Delete>;
  read?: Maybe<ArticlesDocAccessFields_Author_Read>;
  update?: Maybe<ArticlesDocAccessFields_Author_Update>;
};

export type ArticlesDocAccessFields_Author_Create = {
  __typename?: 'ArticlesDocAccessFields_author_Create';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_Author_Delete = {
  __typename?: 'ArticlesDocAccessFields_author_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_Author_Read = {
  __typename?: 'ArticlesDocAccessFields_author_Read';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_Author_Update = {
  __typename?: 'ArticlesDocAccessFields_author_Update';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_Categories = {
  __typename?: 'ArticlesDocAccessFields_categories';
  create?: Maybe<ArticlesDocAccessFields_Categories_Create>;
  delete?: Maybe<ArticlesDocAccessFields_Categories_Delete>;
  read?: Maybe<ArticlesDocAccessFields_Categories_Read>;
  update?: Maybe<ArticlesDocAccessFields_Categories_Update>;
};

export type ArticlesDocAccessFields_Categories_Create = {
  __typename?: 'ArticlesDocAccessFields_categories_Create';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_Categories_Delete = {
  __typename?: 'ArticlesDocAccessFields_categories_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_Categories_Read = {
  __typename?: 'ArticlesDocAccessFields_categories_Read';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_Categories_Update = {
  __typename?: 'ArticlesDocAccessFields_categories_Update';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_Content = {
  __typename?: 'ArticlesDocAccessFields_content';
  create?: Maybe<ArticlesDocAccessFields_Content_Create>;
  delete?: Maybe<ArticlesDocAccessFields_Content_Delete>;
  read?: Maybe<ArticlesDocAccessFields_Content_Read>;
  update?: Maybe<ArticlesDocAccessFields_Content_Update>;
};

export type ArticlesDocAccessFields_Content_Create = {
  __typename?: 'ArticlesDocAccessFields_content_Create';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_Content_Delete = {
  __typename?: 'ArticlesDocAccessFields_content_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_Content_Read = {
  __typename?: 'ArticlesDocAccessFields_content_Read';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_Content_Update = {
  __typename?: 'ArticlesDocAccessFields_content_Update';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_CreatedAt = {
  __typename?: 'ArticlesDocAccessFields_createdAt';
  create?: Maybe<ArticlesDocAccessFields_CreatedAt_Create>;
  delete?: Maybe<ArticlesDocAccessFields_CreatedAt_Delete>;
  read?: Maybe<ArticlesDocAccessFields_CreatedAt_Read>;
  update?: Maybe<ArticlesDocAccessFields_CreatedAt_Update>;
};

export type ArticlesDocAccessFields_CreatedAt_Create = {
  __typename?: 'ArticlesDocAccessFields_createdAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_CreatedAt_Delete = {
  __typename?: 'ArticlesDocAccessFields_createdAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_CreatedAt_Read = {
  __typename?: 'ArticlesDocAccessFields_createdAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_CreatedAt_Update = {
  __typename?: 'ArticlesDocAccessFields_createdAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_Hero = {
  __typename?: 'ArticlesDocAccessFields_hero';
  create?: Maybe<ArticlesDocAccessFields_Hero_Create>;
  delete?: Maybe<ArticlesDocAccessFields_Hero_Delete>;
  fields?: Maybe<ArticlesDocAccessFields_Hero_Fields>;
  read?: Maybe<ArticlesDocAccessFields_Hero_Read>;
  update?: Maybe<ArticlesDocAccessFields_Hero_Update>;
};

export type ArticlesDocAccessFields_Hero_Create = {
  __typename?: 'ArticlesDocAccessFields_hero_Create';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_Hero_Delete = {
  __typename?: 'ArticlesDocAccessFields_hero_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_Hero_Fields = {
  __typename?: 'ArticlesDocAccessFields_hero_Fields';
  media?: Maybe<ArticlesDocAccessFields_Hero_Media>;
  richText?: Maybe<ArticlesDocAccessFields_Hero_RichText>;
};

export type ArticlesDocAccessFields_Hero_Read = {
  __typename?: 'ArticlesDocAccessFields_hero_Read';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_Hero_Update = {
  __typename?: 'ArticlesDocAccessFields_hero_Update';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_Hero_Media = {
  __typename?: 'ArticlesDocAccessFields_hero_media';
  create?: Maybe<ArticlesDocAccessFields_Hero_Media_Create>;
  delete?: Maybe<ArticlesDocAccessFields_Hero_Media_Delete>;
  read?: Maybe<ArticlesDocAccessFields_Hero_Media_Read>;
  update?: Maybe<ArticlesDocAccessFields_Hero_Media_Update>;
};

export type ArticlesDocAccessFields_Hero_Media_Create = {
  __typename?: 'ArticlesDocAccessFields_hero_media_Create';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_Hero_Media_Delete = {
  __typename?: 'ArticlesDocAccessFields_hero_media_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_Hero_Media_Read = {
  __typename?: 'ArticlesDocAccessFields_hero_media_Read';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_Hero_Media_Update = {
  __typename?: 'ArticlesDocAccessFields_hero_media_Update';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_Hero_RichText = {
  __typename?: 'ArticlesDocAccessFields_hero_richText';
  create?: Maybe<ArticlesDocAccessFields_Hero_RichText_Create>;
  delete?: Maybe<ArticlesDocAccessFields_Hero_RichText_Delete>;
  read?: Maybe<ArticlesDocAccessFields_Hero_RichText_Read>;
  update?: Maybe<ArticlesDocAccessFields_Hero_RichText_Update>;
};

export type ArticlesDocAccessFields_Hero_RichText_Create = {
  __typename?: 'ArticlesDocAccessFields_hero_richText_Create';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_Hero_RichText_Delete = {
  __typename?: 'ArticlesDocAccessFields_hero_richText_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_Hero_RichText_Read = {
  __typename?: 'ArticlesDocAccessFields_hero_richText_Read';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_Hero_RichText_Update = {
  __typename?: 'ArticlesDocAccessFields_hero_richText_Update';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_Meta = {
  __typename?: 'ArticlesDocAccessFields_meta';
  create?: Maybe<ArticlesDocAccessFields_Meta_Create>;
  delete?: Maybe<ArticlesDocAccessFields_Meta_Delete>;
  fields?: Maybe<ArticlesDocAccessFields_Meta_Fields>;
  read?: Maybe<ArticlesDocAccessFields_Meta_Read>;
  update?: Maybe<ArticlesDocAccessFields_Meta_Update>;
};

export type ArticlesDocAccessFields_Meta_Create = {
  __typename?: 'ArticlesDocAccessFields_meta_Create';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_Meta_Delete = {
  __typename?: 'ArticlesDocAccessFields_meta_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_Meta_Fields = {
  __typename?: 'ArticlesDocAccessFields_meta_Fields';
  description?: Maybe<ArticlesDocAccessFields_Meta_Description>;
  image?: Maybe<ArticlesDocAccessFields_Meta_Image>;
  title?: Maybe<ArticlesDocAccessFields_Meta_Title>;
};

export type ArticlesDocAccessFields_Meta_Read = {
  __typename?: 'ArticlesDocAccessFields_meta_Read';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_Meta_Update = {
  __typename?: 'ArticlesDocAccessFields_meta_Update';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_Meta_Description = {
  __typename?: 'ArticlesDocAccessFields_meta_description';
  create?: Maybe<ArticlesDocAccessFields_Meta_Description_Create>;
  delete?: Maybe<ArticlesDocAccessFields_Meta_Description_Delete>;
  read?: Maybe<ArticlesDocAccessFields_Meta_Description_Read>;
  update?: Maybe<ArticlesDocAccessFields_Meta_Description_Update>;
};

export type ArticlesDocAccessFields_Meta_Description_Create = {
  __typename?: 'ArticlesDocAccessFields_meta_description_Create';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_Meta_Description_Delete = {
  __typename?: 'ArticlesDocAccessFields_meta_description_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_Meta_Description_Read = {
  __typename?: 'ArticlesDocAccessFields_meta_description_Read';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_Meta_Description_Update = {
  __typename?: 'ArticlesDocAccessFields_meta_description_Update';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_Meta_Image = {
  __typename?: 'ArticlesDocAccessFields_meta_image';
  create?: Maybe<ArticlesDocAccessFields_Meta_Image_Create>;
  delete?: Maybe<ArticlesDocAccessFields_Meta_Image_Delete>;
  read?: Maybe<ArticlesDocAccessFields_Meta_Image_Read>;
  update?: Maybe<ArticlesDocAccessFields_Meta_Image_Update>;
};

export type ArticlesDocAccessFields_Meta_Image_Create = {
  __typename?: 'ArticlesDocAccessFields_meta_image_Create';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_Meta_Image_Delete = {
  __typename?: 'ArticlesDocAccessFields_meta_image_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_Meta_Image_Read = {
  __typename?: 'ArticlesDocAccessFields_meta_image_Read';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_Meta_Image_Update = {
  __typename?: 'ArticlesDocAccessFields_meta_image_Update';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_Meta_Title = {
  __typename?: 'ArticlesDocAccessFields_meta_title';
  create?: Maybe<ArticlesDocAccessFields_Meta_Title_Create>;
  delete?: Maybe<ArticlesDocAccessFields_Meta_Title_Delete>;
  read?: Maybe<ArticlesDocAccessFields_Meta_Title_Read>;
  update?: Maybe<ArticlesDocAccessFields_Meta_Title_Update>;
};

export type ArticlesDocAccessFields_Meta_Title_Create = {
  __typename?: 'ArticlesDocAccessFields_meta_title_Create';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_Meta_Title_Delete = {
  __typename?: 'ArticlesDocAccessFields_meta_title_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_Meta_Title_Read = {
  __typename?: 'ArticlesDocAccessFields_meta_title_Read';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_Meta_Title_Update = {
  __typename?: 'ArticlesDocAccessFields_meta_title_Update';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_PopulatedAuthor = {
  __typename?: 'ArticlesDocAccessFields_populatedAuthor';
  create?: Maybe<ArticlesDocAccessFields_PopulatedAuthor_Create>;
  delete?: Maybe<ArticlesDocAccessFields_PopulatedAuthor_Delete>;
  fields?: Maybe<ArticlesDocAccessFields_PopulatedAuthor_Fields>;
  read?: Maybe<ArticlesDocAccessFields_PopulatedAuthor_Read>;
  update?: Maybe<ArticlesDocAccessFields_PopulatedAuthor_Update>;
};

export type ArticlesDocAccessFields_PopulatedAuthor_Create = {
  __typename?: 'ArticlesDocAccessFields_populatedAuthor_Create';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_PopulatedAuthor_Delete = {
  __typename?: 'ArticlesDocAccessFields_populatedAuthor_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_PopulatedAuthor_Fields = {
  __typename?: 'ArticlesDocAccessFields_populatedAuthor_Fields';
  id?: Maybe<ArticlesDocAccessFields_PopulatedAuthor_Id>;
  name?: Maybe<ArticlesDocAccessFields_PopulatedAuthor_Name>;
};

export type ArticlesDocAccessFields_PopulatedAuthor_Read = {
  __typename?: 'ArticlesDocAccessFields_populatedAuthor_Read';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_PopulatedAuthor_Update = {
  __typename?: 'ArticlesDocAccessFields_populatedAuthor_Update';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_PopulatedAuthor_Id = {
  __typename?: 'ArticlesDocAccessFields_populatedAuthor_id';
  create?: Maybe<ArticlesDocAccessFields_PopulatedAuthor_Id_Create>;
  delete?: Maybe<ArticlesDocAccessFields_PopulatedAuthor_Id_Delete>;
  read?: Maybe<ArticlesDocAccessFields_PopulatedAuthor_Id_Read>;
  update?: Maybe<ArticlesDocAccessFields_PopulatedAuthor_Id_Update>;
};

export type ArticlesDocAccessFields_PopulatedAuthor_Id_Create = {
  __typename?: 'ArticlesDocAccessFields_populatedAuthor_id_Create';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_PopulatedAuthor_Id_Delete = {
  __typename?: 'ArticlesDocAccessFields_populatedAuthor_id_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_PopulatedAuthor_Id_Read = {
  __typename?: 'ArticlesDocAccessFields_populatedAuthor_id_Read';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_PopulatedAuthor_Id_Update = {
  __typename?: 'ArticlesDocAccessFields_populatedAuthor_id_Update';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_PopulatedAuthor_Name = {
  __typename?: 'ArticlesDocAccessFields_populatedAuthor_name';
  create?: Maybe<ArticlesDocAccessFields_PopulatedAuthor_Name_Create>;
  delete?: Maybe<ArticlesDocAccessFields_PopulatedAuthor_Name_Delete>;
  read?: Maybe<ArticlesDocAccessFields_PopulatedAuthor_Name_Read>;
  update?: Maybe<ArticlesDocAccessFields_PopulatedAuthor_Name_Update>;
};

export type ArticlesDocAccessFields_PopulatedAuthor_Name_Create = {
  __typename?: 'ArticlesDocAccessFields_populatedAuthor_name_Create';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_PopulatedAuthor_Name_Delete = {
  __typename?: 'ArticlesDocAccessFields_populatedAuthor_name_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_PopulatedAuthor_Name_Read = {
  __typename?: 'ArticlesDocAccessFields_populatedAuthor_name_Read';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_PopulatedAuthor_Name_Update = {
  __typename?: 'ArticlesDocAccessFields_populatedAuthor_name_Update';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_PublishedAt = {
  __typename?: 'ArticlesDocAccessFields_publishedAt';
  create?: Maybe<ArticlesDocAccessFields_PublishedAt_Create>;
  delete?: Maybe<ArticlesDocAccessFields_PublishedAt_Delete>;
  read?: Maybe<ArticlesDocAccessFields_PublishedAt_Read>;
  update?: Maybe<ArticlesDocAccessFields_PublishedAt_Update>;
};

export type ArticlesDocAccessFields_PublishedAt_Create = {
  __typename?: 'ArticlesDocAccessFields_publishedAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_PublishedAt_Delete = {
  __typename?: 'ArticlesDocAccessFields_publishedAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_PublishedAt_Read = {
  __typename?: 'ArticlesDocAccessFields_publishedAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_PublishedAt_Update = {
  __typename?: 'ArticlesDocAccessFields_publishedAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_RelatedArticles = {
  __typename?: 'ArticlesDocAccessFields_relatedArticles';
  create?: Maybe<ArticlesDocAccessFields_RelatedArticles_Create>;
  delete?: Maybe<ArticlesDocAccessFields_RelatedArticles_Delete>;
  read?: Maybe<ArticlesDocAccessFields_RelatedArticles_Read>;
  update?: Maybe<ArticlesDocAccessFields_RelatedArticles_Update>;
};

export type ArticlesDocAccessFields_RelatedArticles_Create = {
  __typename?: 'ArticlesDocAccessFields_relatedArticles_Create';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_RelatedArticles_Delete = {
  __typename?: 'ArticlesDocAccessFields_relatedArticles_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_RelatedArticles_Read = {
  __typename?: 'ArticlesDocAccessFields_relatedArticles_Read';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_RelatedArticles_Update = {
  __typename?: 'ArticlesDocAccessFields_relatedArticles_Update';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_Slug = {
  __typename?: 'ArticlesDocAccessFields_slug';
  create?: Maybe<ArticlesDocAccessFields_Slug_Create>;
  delete?: Maybe<ArticlesDocAccessFields_Slug_Delete>;
  read?: Maybe<ArticlesDocAccessFields_Slug_Read>;
  update?: Maybe<ArticlesDocAccessFields_Slug_Update>;
};

export type ArticlesDocAccessFields_Slug_Create = {
  __typename?: 'ArticlesDocAccessFields_slug_Create';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_Slug_Delete = {
  __typename?: 'ArticlesDocAccessFields_slug_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_Slug_Read = {
  __typename?: 'ArticlesDocAccessFields_slug_Read';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_Slug_Update = {
  __typename?: 'ArticlesDocAccessFields_slug_Update';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_Title = {
  __typename?: 'ArticlesDocAccessFields_title';
  create?: Maybe<ArticlesDocAccessFields_Title_Create>;
  delete?: Maybe<ArticlesDocAccessFields_Title_Delete>;
  read?: Maybe<ArticlesDocAccessFields_Title_Read>;
  update?: Maybe<ArticlesDocAccessFields_Title_Update>;
};

export type ArticlesDocAccessFields_Title_Create = {
  __typename?: 'ArticlesDocAccessFields_title_Create';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_Title_Delete = {
  __typename?: 'ArticlesDocAccessFields_title_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_Title_Read = {
  __typename?: 'ArticlesDocAccessFields_title_Read';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_Title_Update = {
  __typename?: 'ArticlesDocAccessFields_title_Update';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_UpdatedAt = {
  __typename?: 'ArticlesDocAccessFields_updatedAt';
  create?: Maybe<ArticlesDocAccessFields_UpdatedAt_Create>;
  delete?: Maybe<ArticlesDocAccessFields_UpdatedAt_Delete>;
  read?: Maybe<ArticlesDocAccessFields_UpdatedAt_Read>;
  update?: Maybe<ArticlesDocAccessFields_UpdatedAt_Update>;
};

export type ArticlesDocAccessFields_UpdatedAt_Create = {
  __typename?: 'ArticlesDocAccessFields_updatedAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_UpdatedAt_Delete = {
  __typename?: 'ArticlesDocAccessFields_updatedAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_UpdatedAt_Read = {
  __typename?: 'ArticlesDocAccessFields_updatedAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesDocAccessFields_UpdatedAt_Update = {
  __typename?: 'ArticlesDocAccessFields_updatedAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields = {
  __typename?: 'ArticlesFields';
  _status?: Maybe<ArticlesFields__Status>;
  author?: Maybe<ArticlesFields_Author>;
  categories?: Maybe<ArticlesFields_Categories>;
  content?: Maybe<ArticlesFields_Content>;
  createdAt?: Maybe<ArticlesFields_CreatedAt>;
  hero?: Maybe<ArticlesFields_Hero>;
  meta?: Maybe<ArticlesFields_Meta>;
  populatedAuthor?: Maybe<ArticlesFields_PopulatedAuthor>;
  publishedAt?: Maybe<ArticlesFields_PublishedAt>;
  relatedArticles?: Maybe<ArticlesFields_RelatedArticles>;
  slug?: Maybe<ArticlesFields_Slug>;
  title?: Maybe<ArticlesFields_Title>;
  updatedAt?: Maybe<ArticlesFields_UpdatedAt>;
};

export type ArticlesFields__Status = {
  __typename?: 'ArticlesFields__status';
  create?: Maybe<ArticlesFields__Status_Create>;
  delete?: Maybe<ArticlesFields__Status_Delete>;
  read?: Maybe<ArticlesFields__Status_Read>;
  update?: Maybe<ArticlesFields__Status_Update>;
};

export type ArticlesFields__Status_Create = {
  __typename?: 'ArticlesFields__status_Create';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields__Status_Delete = {
  __typename?: 'ArticlesFields__status_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields__Status_Read = {
  __typename?: 'ArticlesFields__status_Read';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields__Status_Update = {
  __typename?: 'ArticlesFields__status_Update';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_Author = {
  __typename?: 'ArticlesFields_author';
  create?: Maybe<ArticlesFields_Author_Create>;
  delete?: Maybe<ArticlesFields_Author_Delete>;
  read?: Maybe<ArticlesFields_Author_Read>;
  update?: Maybe<ArticlesFields_Author_Update>;
};

export type ArticlesFields_Author_Create = {
  __typename?: 'ArticlesFields_author_Create';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_Author_Delete = {
  __typename?: 'ArticlesFields_author_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_Author_Read = {
  __typename?: 'ArticlesFields_author_Read';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_Author_Update = {
  __typename?: 'ArticlesFields_author_Update';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_Categories = {
  __typename?: 'ArticlesFields_categories';
  create?: Maybe<ArticlesFields_Categories_Create>;
  delete?: Maybe<ArticlesFields_Categories_Delete>;
  read?: Maybe<ArticlesFields_Categories_Read>;
  update?: Maybe<ArticlesFields_Categories_Update>;
};

export type ArticlesFields_Categories_Create = {
  __typename?: 'ArticlesFields_categories_Create';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_Categories_Delete = {
  __typename?: 'ArticlesFields_categories_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_Categories_Read = {
  __typename?: 'ArticlesFields_categories_Read';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_Categories_Update = {
  __typename?: 'ArticlesFields_categories_Update';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_Content = {
  __typename?: 'ArticlesFields_content';
  create?: Maybe<ArticlesFields_Content_Create>;
  delete?: Maybe<ArticlesFields_Content_Delete>;
  read?: Maybe<ArticlesFields_Content_Read>;
  update?: Maybe<ArticlesFields_Content_Update>;
};

export type ArticlesFields_Content_Create = {
  __typename?: 'ArticlesFields_content_Create';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_Content_Delete = {
  __typename?: 'ArticlesFields_content_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_Content_Read = {
  __typename?: 'ArticlesFields_content_Read';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_Content_Update = {
  __typename?: 'ArticlesFields_content_Update';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_CreatedAt = {
  __typename?: 'ArticlesFields_createdAt';
  create?: Maybe<ArticlesFields_CreatedAt_Create>;
  delete?: Maybe<ArticlesFields_CreatedAt_Delete>;
  read?: Maybe<ArticlesFields_CreatedAt_Read>;
  update?: Maybe<ArticlesFields_CreatedAt_Update>;
};

export type ArticlesFields_CreatedAt_Create = {
  __typename?: 'ArticlesFields_createdAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_CreatedAt_Delete = {
  __typename?: 'ArticlesFields_createdAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_CreatedAt_Read = {
  __typename?: 'ArticlesFields_createdAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_CreatedAt_Update = {
  __typename?: 'ArticlesFields_createdAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_Hero = {
  __typename?: 'ArticlesFields_hero';
  create?: Maybe<ArticlesFields_Hero_Create>;
  delete?: Maybe<ArticlesFields_Hero_Delete>;
  fields?: Maybe<ArticlesFields_Hero_Fields>;
  read?: Maybe<ArticlesFields_Hero_Read>;
  update?: Maybe<ArticlesFields_Hero_Update>;
};

export type ArticlesFields_Hero_Create = {
  __typename?: 'ArticlesFields_hero_Create';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_Hero_Delete = {
  __typename?: 'ArticlesFields_hero_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_Hero_Fields = {
  __typename?: 'ArticlesFields_hero_Fields';
  media?: Maybe<ArticlesFields_Hero_Media>;
  richText?: Maybe<ArticlesFields_Hero_RichText>;
};

export type ArticlesFields_Hero_Read = {
  __typename?: 'ArticlesFields_hero_Read';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_Hero_Update = {
  __typename?: 'ArticlesFields_hero_Update';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_Hero_Media = {
  __typename?: 'ArticlesFields_hero_media';
  create?: Maybe<ArticlesFields_Hero_Media_Create>;
  delete?: Maybe<ArticlesFields_Hero_Media_Delete>;
  read?: Maybe<ArticlesFields_Hero_Media_Read>;
  update?: Maybe<ArticlesFields_Hero_Media_Update>;
};

export type ArticlesFields_Hero_Media_Create = {
  __typename?: 'ArticlesFields_hero_media_Create';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_Hero_Media_Delete = {
  __typename?: 'ArticlesFields_hero_media_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_Hero_Media_Read = {
  __typename?: 'ArticlesFields_hero_media_Read';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_Hero_Media_Update = {
  __typename?: 'ArticlesFields_hero_media_Update';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_Hero_RichText = {
  __typename?: 'ArticlesFields_hero_richText';
  create?: Maybe<ArticlesFields_Hero_RichText_Create>;
  delete?: Maybe<ArticlesFields_Hero_RichText_Delete>;
  read?: Maybe<ArticlesFields_Hero_RichText_Read>;
  update?: Maybe<ArticlesFields_Hero_RichText_Update>;
};

export type ArticlesFields_Hero_RichText_Create = {
  __typename?: 'ArticlesFields_hero_richText_Create';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_Hero_RichText_Delete = {
  __typename?: 'ArticlesFields_hero_richText_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_Hero_RichText_Read = {
  __typename?: 'ArticlesFields_hero_richText_Read';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_Hero_RichText_Update = {
  __typename?: 'ArticlesFields_hero_richText_Update';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_Meta = {
  __typename?: 'ArticlesFields_meta';
  create?: Maybe<ArticlesFields_Meta_Create>;
  delete?: Maybe<ArticlesFields_Meta_Delete>;
  fields?: Maybe<ArticlesFields_Meta_Fields>;
  read?: Maybe<ArticlesFields_Meta_Read>;
  update?: Maybe<ArticlesFields_Meta_Update>;
};

export type ArticlesFields_Meta_Create = {
  __typename?: 'ArticlesFields_meta_Create';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_Meta_Delete = {
  __typename?: 'ArticlesFields_meta_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_Meta_Fields = {
  __typename?: 'ArticlesFields_meta_Fields';
  description?: Maybe<ArticlesFields_Meta_Description>;
  image?: Maybe<ArticlesFields_Meta_Image>;
  title?: Maybe<ArticlesFields_Meta_Title>;
};

export type ArticlesFields_Meta_Read = {
  __typename?: 'ArticlesFields_meta_Read';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_Meta_Update = {
  __typename?: 'ArticlesFields_meta_Update';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_Meta_Description = {
  __typename?: 'ArticlesFields_meta_description';
  create?: Maybe<ArticlesFields_Meta_Description_Create>;
  delete?: Maybe<ArticlesFields_Meta_Description_Delete>;
  read?: Maybe<ArticlesFields_Meta_Description_Read>;
  update?: Maybe<ArticlesFields_Meta_Description_Update>;
};

export type ArticlesFields_Meta_Description_Create = {
  __typename?: 'ArticlesFields_meta_description_Create';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_Meta_Description_Delete = {
  __typename?: 'ArticlesFields_meta_description_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_Meta_Description_Read = {
  __typename?: 'ArticlesFields_meta_description_Read';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_Meta_Description_Update = {
  __typename?: 'ArticlesFields_meta_description_Update';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_Meta_Image = {
  __typename?: 'ArticlesFields_meta_image';
  create?: Maybe<ArticlesFields_Meta_Image_Create>;
  delete?: Maybe<ArticlesFields_Meta_Image_Delete>;
  read?: Maybe<ArticlesFields_Meta_Image_Read>;
  update?: Maybe<ArticlesFields_Meta_Image_Update>;
};

export type ArticlesFields_Meta_Image_Create = {
  __typename?: 'ArticlesFields_meta_image_Create';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_Meta_Image_Delete = {
  __typename?: 'ArticlesFields_meta_image_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_Meta_Image_Read = {
  __typename?: 'ArticlesFields_meta_image_Read';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_Meta_Image_Update = {
  __typename?: 'ArticlesFields_meta_image_Update';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_Meta_Title = {
  __typename?: 'ArticlesFields_meta_title';
  create?: Maybe<ArticlesFields_Meta_Title_Create>;
  delete?: Maybe<ArticlesFields_Meta_Title_Delete>;
  read?: Maybe<ArticlesFields_Meta_Title_Read>;
  update?: Maybe<ArticlesFields_Meta_Title_Update>;
};

export type ArticlesFields_Meta_Title_Create = {
  __typename?: 'ArticlesFields_meta_title_Create';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_Meta_Title_Delete = {
  __typename?: 'ArticlesFields_meta_title_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_Meta_Title_Read = {
  __typename?: 'ArticlesFields_meta_title_Read';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_Meta_Title_Update = {
  __typename?: 'ArticlesFields_meta_title_Update';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_PopulatedAuthor = {
  __typename?: 'ArticlesFields_populatedAuthor';
  create?: Maybe<ArticlesFields_PopulatedAuthor_Create>;
  delete?: Maybe<ArticlesFields_PopulatedAuthor_Delete>;
  fields?: Maybe<ArticlesFields_PopulatedAuthor_Fields>;
  read?: Maybe<ArticlesFields_PopulatedAuthor_Read>;
  update?: Maybe<ArticlesFields_PopulatedAuthor_Update>;
};

export type ArticlesFields_PopulatedAuthor_Create = {
  __typename?: 'ArticlesFields_populatedAuthor_Create';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_PopulatedAuthor_Delete = {
  __typename?: 'ArticlesFields_populatedAuthor_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_PopulatedAuthor_Fields = {
  __typename?: 'ArticlesFields_populatedAuthor_Fields';
  id?: Maybe<ArticlesFields_PopulatedAuthor_Id>;
  name?: Maybe<ArticlesFields_PopulatedAuthor_Name>;
};

export type ArticlesFields_PopulatedAuthor_Read = {
  __typename?: 'ArticlesFields_populatedAuthor_Read';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_PopulatedAuthor_Update = {
  __typename?: 'ArticlesFields_populatedAuthor_Update';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_PopulatedAuthor_Id = {
  __typename?: 'ArticlesFields_populatedAuthor_id';
  create?: Maybe<ArticlesFields_PopulatedAuthor_Id_Create>;
  delete?: Maybe<ArticlesFields_PopulatedAuthor_Id_Delete>;
  read?: Maybe<ArticlesFields_PopulatedAuthor_Id_Read>;
  update?: Maybe<ArticlesFields_PopulatedAuthor_Id_Update>;
};

export type ArticlesFields_PopulatedAuthor_Id_Create = {
  __typename?: 'ArticlesFields_populatedAuthor_id_Create';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_PopulatedAuthor_Id_Delete = {
  __typename?: 'ArticlesFields_populatedAuthor_id_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_PopulatedAuthor_Id_Read = {
  __typename?: 'ArticlesFields_populatedAuthor_id_Read';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_PopulatedAuthor_Id_Update = {
  __typename?: 'ArticlesFields_populatedAuthor_id_Update';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_PopulatedAuthor_Name = {
  __typename?: 'ArticlesFields_populatedAuthor_name';
  create?: Maybe<ArticlesFields_PopulatedAuthor_Name_Create>;
  delete?: Maybe<ArticlesFields_PopulatedAuthor_Name_Delete>;
  read?: Maybe<ArticlesFields_PopulatedAuthor_Name_Read>;
  update?: Maybe<ArticlesFields_PopulatedAuthor_Name_Update>;
};

export type ArticlesFields_PopulatedAuthor_Name_Create = {
  __typename?: 'ArticlesFields_populatedAuthor_name_Create';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_PopulatedAuthor_Name_Delete = {
  __typename?: 'ArticlesFields_populatedAuthor_name_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_PopulatedAuthor_Name_Read = {
  __typename?: 'ArticlesFields_populatedAuthor_name_Read';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_PopulatedAuthor_Name_Update = {
  __typename?: 'ArticlesFields_populatedAuthor_name_Update';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_PublishedAt = {
  __typename?: 'ArticlesFields_publishedAt';
  create?: Maybe<ArticlesFields_PublishedAt_Create>;
  delete?: Maybe<ArticlesFields_PublishedAt_Delete>;
  read?: Maybe<ArticlesFields_PublishedAt_Read>;
  update?: Maybe<ArticlesFields_PublishedAt_Update>;
};

export type ArticlesFields_PublishedAt_Create = {
  __typename?: 'ArticlesFields_publishedAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_PublishedAt_Delete = {
  __typename?: 'ArticlesFields_publishedAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_PublishedAt_Read = {
  __typename?: 'ArticlesFields_publishedAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_PublishedAt_Update = {
  __typename?: 'ArticlesFields_publishedAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_RelatedArticles = {
  __typename?: 'ArticlesFields_relatedArticles';
  create?: Maybe<ArticlesFields_RelatedArticles_Create>;
  delete?: Maybe<ArticlesFields_RelatedArticles_Delete>;
  read?: Maybe<ArticlesFields_RelatedArticles_Read>;
  update?: Maybe<ArticlesFields_RelatedArticles_Update>;
};

export type ArticlesFields_RelatedArticles_Create = {
  __typename?: 'ArticlesFields_relatedArticles_Create';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_RelatedArticles_Delete = {
  __typename?: 'ArticlesFields_relatedArticles_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_RelatedArticles_Read = {
  __typename?: 'ArticlesFields_relatedArticles_Read';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_RelatedArticles_Update = {
  __typename?: 'ArticlesFields_relatedArticles_Update';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_Slug = {
  __typename?: 'ArticlesFields_slug';
  create?: Maybe<ArticlesFields_Slug_Create>;
  delete?: Maybe<ArticlesFields_Slug_Delete>;
  read?: Maybe<ArticlesFields_Slug_Read>;
  update?: Maybe<ArticlesFields_Slug_Update>;
};

export type ArticlesFields_Slug_Create = {
  __typename?: 'ArticlesFields_slug_Create';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_Slug_Delete = {
  __typename?: 'ArticlesFields_slug_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_Slug_Read = {
  __typename?: 'ArticlesFields_slug_Read';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_Slug_Update = {
  __typename?: 'ArticlesFields_slug_Update';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_Title = {
  __typename?: 'ArticlesFields_title';
  create?: Maybe<ArticlesFields_Title_Create>;
  delete?: Maybe<ArticlesFields_Title_Delete>;
  read?: Maybe<ArticlesFields_Title_Read>;
  update?: Maybe<ArticlesFields_Title_Update>;
};

export type ArticlesFields_Title_Create = {
  __typename?: 'ArticlesFields_title_Create';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_Title_Delete = {
  __typename?: 'ArticlesFields_title_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_Title_Read = {
  __typename?: 'ArticlesFields_title_Read';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_Title_Update = {
  __typename?: 'ArticlesFields_title_Update';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_UpdatedAt = {
  __typename?: 'ArticlesFields_updatedAt';
  create?: Maybe<ArticlesFields_UpdatedAt_Create>;
  delete?: Maybe<ArticlesFields_UpdatedAt_Delete>;
  read?: Maybe<ArticlesFields_UpdatedAt_Read>;
  update?: Maybe<ArticlesFields_UpdatedAt_Update>;
};

export type ArticlesFields_UpdatedAt_Create = {
  __typename?: 'ArticlesFields_updatedAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_UpdatedAt_Delete = {
  __typename?: 'ArticlesFields_updatedAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_UpdatedAt_Read = {
  __typename?: 'ArticlesFields_updatedAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesFields_UpdatedAt_Update = {
  __typename?: 'ArticlesFields_updatedAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type ArticlesReadAccess = {
  __typename?: 'ArticlesReadAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type ArticlesReadDocAccess = {
  __typename?: 'ArticlesReadDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type ArticlesReadVersionsAccess = {
  __typename?: 'ArticlesReadVersionsAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type ArticlesReadVersionsDocAccess = {
  __typename?: 'ArticlesReadVersionsDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type ArticlesUpdateAccess = {
  __typename?: 'ArticlesUpdateAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type ArticlesUpdateDocAccess = {
  __typename?: 'ArticlesUpdateDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type Categories = {
  __typename?: 'Categories';
  docs?: Maybe<Array<Maybe<Category>>>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  nextPage?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  pagingCounter?: Maybe<Scalars['Int']['output']>;
  prevPage?: Maybe<Scalars['Int']['output']>;
  totalDocs?: Maybe<Scalars['Int']['output']>;
  totalPages?: Maybe<Scalars['Int']['output']>;
};

export type CategoriesCreateAccess = {
  __typename?: 'CategoriesCreateAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type CategoriesCreateDocAccess = {
  __typename?: 'CategoriesCreateDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type CategoriesDeleteAccess = {
  __typename?: 'CategoriesDeleteAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type CategoriesDeleteDocAccess = {
  __typename?: 'CategoriesDeleteDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type CategoriesDocAccessFields = {
  __typename?: 'CategoriesDocAccessFields';
  breadcrumbs?: Maybe<CategoriesDocAccessFields_Breadcrumbs>;
  createdAt?: Maybe<CategoriesDocAccessFields_CreatedAt>;
  parent?: Maybe<CategoriesDocAccessFields_Parent>;
  title?: Maybe<CategoriesDocAccessFields_Title>;
  updatedAt?: Maybe<CategoriesDocAccessFields_UpdatedAt>;
};

export type CategoriesDocAccessFields_Breadcrumbs = {
  __typename?: 'CategoriesDocAccessFields_breadcrumbs';
  create?: Maybe<CategoriesDocAccessFields_Breadcrumbs_Create>;
  delete?: Maybe<CategoriesDocAccessFields_Breadcrumbs_Delete>;
  fields?: Maybe<CategoriesDocAccessFields_Breadcrumbs_Fields>;
  read?: Maybe<CategoriesDocAccessFields_Breadcrumbs_Read>;
  update?: Maybe<CategoriesDocAccessFields_Breadcrumbs_Update>;
};

export type CategoriesDocAccessFields_Breadcrumbs_Create = {
  __typename?: 'CategoriesDocAccessFields_breadcrumbs_Create';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesDocAccessFields_Breadcrumbs_Delete = {
  __typename?: 'CategoriesDocAccessFields_breadcrumbs_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesDocAccessFields_Breadcrumbs_Fields = {
  __typename?: 'CategoriesDocAccessFields_breadcrumbs_Fields';
  doc?: Maybe<CategoriesDocAccessFields_Breadcrumbs_Doc>;
  id?: Maybe<CategoriesDocAccessFields_Breadcrumbs_Id>;
  label?: Maybe<CategoriesDocAccessFields_Breadcrumbs_Label>;
  url?: Maybe<CategoriesDocAccessFields_Breadcrumbs_Url>;
};

export type CategoriesDocAccessFields_Breadcrumbs_Read = {
  __typename?: 'CategoriesDocAccessFields_breadcrumbs_Read';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesDocAccessFields_Breadcrumbs_Update = {
  __typename?: 'CategoriesDocAccessFields_breadcrumbs_Update';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesDocAccessFields_Breadcrumbs_Doc = {
  __typename?: 'CategoriesDocAccessFields_breadcrumbs_doc';
  create?: Maybe<CategoriesDocAccessFields_Breadcrumbs_Doc_Create>;
  delete?: Maybe<CategoriesDocAccessFields_Breadcrumbs_Doc_Delete>;
  read?: Maybe<CategoriesDocAccessFields_Breadcrumbs_Doc_Read>;
  update?: Maybe<CategoriesDocAccessFields_Breadcrumbs_Doc_Update>;
};

export type CategoriesDocAccessFields_Breadcrumbs_Doc_Create = {
  __typename?: 'CategoriesDocAccessFields_breadcrumbs_doc_Create';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesDocAccessFields_Breadcrumbs_Doc_Delete = {
  __typename?: 'CategoriesDocAccessFields_breadcrumbs_doc_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesDocAccessFields_Breadcrumbs_Doc_Read = {
  __typename?: 'CategoriesDocAccessFields_breadcrumbs_doc_Read';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesDocAccessFields_Breadcrumbs_Doc_Update = {
  __typename?: 'CategoriesDocAccessFields_breadcrumbs_doc_Update';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesDocAccessFields_Breadcrumbs_Id = {
  __typename?: 'CategoriesDocAccessFields_breadcrumbs_id';
  create?: Maybe<CategoriesDocAccessFields_Breadcrumbs_Id_Create>;
  delete?: Maybe<CategoriesDocAccessFields_Breadcrumbs_Id_Delete>;
  read?: Maybe<CategoriesDocAccessFields_Breadcrumbs_Id_Read>;
  update?: Maybe<CategoriesDocAccessFields_Breadcrumbs_Id_Update>;
};

export type CategoriesDocAccessFields_Breadcrumbs_Id_Create = {
  __typename?: 'CategoriesDocAccessFields_breadcrumbs_id_Create';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesDocAccessFields_Breadcrumbs_Id_Delete = {
  __typename?: 'CategoriesDocAccessFields_breadcrumbs_id_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesDocAccessFields_Breadcrumbs_Id_Read = {
  __typename?: 'CategoriesDocAccessFields_breadcrumbs_id_Read';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesDocAccessFields_Breadcrumbs_Id_Update = {
  __typename?: 'CategoriesDocAccessFields_breadcrumbs_id_Update';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesDocAccessFields_Breadcrumbs_Label = {
  __typename?: 'CategoriesDocAccessFields_breadcrumbs_label';
  create?: Maybe<CategoriesDocAccessFields_Breadcrumbs_Label_Create>;
  delete?: Maybe<CategoriesDocAccessFields_Breadcrumbs_Label_Delete>;
  read?: Maybe<CategoriesDocAccessFields_Breadcrumbs_Label_Read>;
  update?: Maybe<CategoriesDocAccessFields_Breadcrumbs_Label_Update>;
};

export type CategoriesDocAccessFields_Breadcrumbs_Label_Create = {
  __typename?: 'CategoriesDocAccessFields_breadcrumbs_label_Create';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesDocAccessFields_Breadcrumbs_Label_Delete = {
  __typename?: 'CategoriesDocAccessFields_breadcrumbs_label_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesDocAccessFields_Breadcrumbs_Label_Read = {
  __typename?: 'CategoriesDocAccessFields_breadcrumbs_label_Read';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesDocAccessFields_Breadcrumbs_Label_Update = {
  __typename?: 'CategoriesDocAccessFields_breadcrumbs_label_Update';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesDocAccessFields_Breadcrumbs_Url = {
  __typename?: 'CategoriesDocAccessFields_breadcrumbs_url';
  create?: Maybe<CategoriesDocAccessFields_Breadcrumbs_Url_Create>;
  delete?: Maybe<CategoriesDocAccessFields_Breadcrumbs_Url_Delete>;
  read?: Maybe<CategoriesDocAccessFields_Breadcrumbs_Url_Read>;
  update?: Maybe<CategoriesDocAccessFields_Breadcrumbs_Url_Update>;
};

export type CategoriesDocAccessFields_Breadcrumbs_Url_Create = {
  __typename?: 'CategoriesDocAccessFields_breadcrumbs_url_Create';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesDocAccessFields_Breadcrumbs_Url_Delete = {
  __typename?: 'CategoriesDocAccessFields_breadcrumbs_url_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesDocAccessFields_Breadcrumbs_Url_Read = {
  __typename?: 'CategoriesDocAccessFields_breadcrumbs_url_Read';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesDocAccessFields_Breadcrumbs_Url_Update = {
  __typename?: 'CategoriesDocAccessFields_breadcrumbs_url_Update';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesDocAccessFields_CreatedAt = {
  __typename?: 'CategoriesDocAccessFields_createdAt';
  create?: Maybe<CategoriesDocAccessFields_CreatedAt_Create>;
  delete?: Maybe<CategoriesDocAccessFields_CreatedAt_Delete>;
  read?: Maybe<CategoriesDocAccessFields_CreatedAt_Read>;
  update?: Maybe<CategoriesDocAccessFields_CreatedAt_Update>;
};

export type CategoriesDocAccessFields_CreatedAt_Create = {
  __typename?: 'CategoriesDocAccessFields_createdAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesDocAccessFields_CreatedAt_Delete = {
  __typename?: 'CategoriesDocAccessFields_createdAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesDocAccessFields_CreatedAt_Read = {
  __typename?: 'CategoriesDocAccessFields_createdAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesDocAccessFields_CreatedAt_Update = {
  __typename?: 'CategoriesDocAccessFields_createdAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesDocAccessFields_Parent = {
  __typename?: 'CategoriesDocAccessFields_parent';
  create?: Maybe<CategoriesDocAccessFields_Parent_Create>;
  delete?: Maybe<CategoriesDocAccessFields_Parent_Delete>;
  read?: Maybe<CategoriesDocAccessFields_Parent_Read>;
  update?: Maybe<CategoriesDocAccessFields_Parent_Update>;
};

export type CategoriesDocAccessFields_Parent_Create = {
  __typename?: 'CategoriesDocAccessFields_parent_Create';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesDocAccessFields_Parent_Delete = {
  __typename?: 'CategoriesDocAccessFields_parent_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesDocAccessFields_Parent_Read = {
  __typename?: 'CategoriesDocAccessFields_parent_Read';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesDocAccessFields_Parent_Update = {
  __typename?: 'CategoriesDocAccessFields_parent_Update';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesDocAccessFields_Title = {
  __typename?: 'CategoriesDocAccessFields_title';
  create?: Maybe<CategoriesDocAccessFields_Title_Create>;
  delete?: Maybe<CategoriesDocAccessFields_Title_Delete>;
  read?: Maybe<CategoriesDocAccessFields_Title_Read>;
  update?: Maybe<CategoriesDocAccessFields_Title_Update>;
};

export type CategoriesDocAccessFields_Title_Create = {
  __typename?: 'CategoriesDocAccessFields_title_Create';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesDocAccessFields_Title_Delete = {
  __typename?: 'CategoriesDocAccessFields_title_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesDocAccessFields_Title_Read = {
  __typename?: 'CategoriesDocAccessFields_title_Read';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesDocAccessFields_Title_Update = {
  __typename?: 'CategoriesDocAccessFields_title_Update';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesDocAccessFields_UpdatedAt = {
  __typename?: 'CategoriesDocAccessFields_updatedAt';
  create?: Maybe<CategoriesDocAccessFields_UpdatedAt_Create>;
  delete?: Maybe<CategoriesDocAccessFields_UpdatedAt_Delete>;
  read?: Maybe<CategoriesDocAccessFields_UpdatedAt_Read>;
  update?: Maybe<CategoriesDocAccessFields_UpdatedAt_Update>;
};

export type CategoriesDocAccessFields_UpdatedAt_Create = {
  __typename?: 'CategoriesDocAccessFields_updatedAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesDocAccessFields_UpdatedAt_Delete = {
  __typename?: 'CategoriesDocAccessFields_updatedAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesDocAccessFields_UpdatedAt_Read = {
  __typename?: 'CategoriesDocAccessFields_updatedAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesDocAccessFields_UpdatedAt_Update = {
  __typename?: 'CategoriesDocAccessFields_updatedAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesFields = {
  __typename?: 'CategoriesFields';
  breadcrumbs?: Maybe<CategoriesFields_Breadcrumbs>;
  createdAt?: Maybe<CategoriesFields_CreatedAt>;
  parent?: Maybe<CategoriesFields_Parent>;
  title?: Maybe<CategoriesFields_Title>;
  updatedAt?: Maybe<CategoriesFields_UpdatedAt>;
};

export type CategoriesFields_Breadcrumbs = {
  __typename?: 'CategoriesFields_breadcrumbs';
  create?: Maybe<CategoriesFields_Breadcrumbs_Create>;
  delete?: Maybe<CategoriesFields_Breadcrumbs_Delete>;
  fields?: Maybe<CategoriesFields_Breadcrumbs_Fields>;
  read?: Maybe<CategoriesFields_Breadcrumbs_Read>;
  update?: Maybe<CategoriesFields_Breadcrumbs_Update>;
};

export type CategoriesFields_Breadcrumbs_Create = {
  __typename?: 'CategoriesFields_breadcrumbs_Create';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesFields_Breadcrumbs_Delete = {
  __typename?: 'CategoriesFields_breadcrumbs_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesFields_Breadcrumbs_Fields = {
  __typename?: 'CategoriesFields_breadcrumbs_Fields';
  doc?: Maybe<CategoriesFields_Breadcrumbs_Doc>;
  id?: Maybe<CategoriesFields_Breadcrumbs_Id>;
  label?: Maybe<CategoriesFields_Breadcrumbs_Label>;
  url?: Maybe<CategoriesFields_Breadcrumbs_Url>;
};

export type CategoriesFields_Breadcrumbs_Read = {
  __typename?: 'CategoriesFields_breadcrumbs_Read';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesFields_Breadcrumbs_Update = {
  __typename?: 'CategoriesFields_breadcrumbs_Update';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesFields_Breadcrumbs_Doc = {
  __typename?: 'CategoriesFields_breadcrumbs_doc';
  create?: Maybe<CategoriesFields_Breadcrumbs_Doc_Create>;
  delete?: Maybe<CategoriesFields_Breadcrumbs_Doc_Delete>;
  read?: Maybe<CategoriesFields_Breadcrumbs_Doc_Read>;
  update?: Maybe<CategoriesFields_Breadcrumbs_Doc_Update>;
};

export type CategoriesFields_Breadcrumbs_Doc_Create = {
  __typename?: 'CategoriesFields_breadcrumbs_doc_Create';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesFields_Breadcrumbs_Doc_Delete = {
  __typename?: 'CategoriesFields_breadcrumbs_doc_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesFields_Breadcrumbs_Doc_Read = {
  __typename?: 'CategoriesFields_breadcrumbs_doc_Read';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesFields_Breadcrumbs_Doc_Update = {
  __typename?: 'CategoriesFields_breadcrumbs_doc_Update';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesFields_Breadcrumbs_Id = {
  __typename?: 'CategoriesFields_breadcrumbs_id';
  create?: Maybe<CategoriesFields_Breadcrumbs_Id_Create>;
  delete?: Maybe<CategoriesFields_Breadcrumbs_Id_Delete>;
  read?: Maybe<CategoriesFields_Breadcrumbs_Id_Read>;
  update?: Maybe<CategoriesFields_Breadcrumbs_Id_Update>;
};

export type CategoriesFields_Breadcrumbs_Id_Create = {
  __typename?: 'CategoriesFields_breadcrumbs_id_Create';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesFields_Breadcrumbs_Id_Delete = {
  __typename?: 'CategoriesFields_breadcrumbs_id_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesFields_Breadcrumbs_Id_Read = {
  __typename?: 'CategoriesFields_breadcrumbs_id_Read';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesFields_Breadcrumbs_Id_Update = {
  __typename?: 'CategoriesFields_breadcrumbs_id_Update';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesFields_Breadcrumbs_Label = {
  __typename?: 'CategoriesFields_breadcrumbs_label';
  create?: Maybe<CategoriesFields_Breadcrumbs_Label_Create>;
  delete?: Maybe<CategoriesFields_Breadcrumbs_Label_Delete>;
  read?: Maybe<CategoriesFields_Breadcrumbs_Label_Read>;
  update?: Maybe<CategoriesFields_Breadcrumbs_Label_Update>;
};

export type CategoriesFields_Breadcrumbs_Label_Create = {
  __typename?: 'CategoriesFields_breadcrumbs_label_Create';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesFields_Breadcrumbs_Label_Delete = {
  __typename?: 'CategoriesFields_breadcrumbs_label_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesFields_Breadcrumbs_Label_Read = {
  __typename?: 'CategoriesFields_breadcrumbs_label_Read';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesFields_Breadcrumbs_Label_Update = {
  __typename?: 'CategoriesFields_breadcrumbs_label_Update';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesFields_Breadcrumbs_Url = {
  __typename?: 'CategoriesFields_breadcrumbs_url';
  create?: Maybe<CategoriesFields_Breadcrumbs_Url_Create>;
  delete?: Maybe<CategoriesFields_Breadcrumbs_Url_Delete>;
  read?: Maybe<CategoriesFields_Breadcrumbs_Url_Read>;
  update?: Maybe<CategoriesFields_Breadcrumbs_Url_Update>;
};

export type CategoriesFields_Breadcrumbs_Url_Create = {
  __typename?: 'CategoriesFields_breadcrumbs_url_Create';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesFields_Breadcrumbs_Url_Delete = {
  __typename?: 'CategoriesFields_breadcrumbs_url_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesFields_Breadcrumbs_Url_Read = {
  __typename?: 'CategoriesFields_breadcrumbs_url_Read';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesFields_Breadcrumbs_Url_Update = {
  __typename?: 'CategoriesFields_breadcrumbs_url_Update';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesFields_CreatedAt = {
  __typename?: 'CategoriesFields_createdAt';
  create?: Maybe<CategoriesFields_CreatedAt_Create>;
  delete?: Maybe<CategoriesFields_CreatedAt_Delete>;
  read?: Maybe<CategoriesFields_CreatedAt_Read>;
  update?: Maybe<CategoriesFields_CreatedAt_Update>;
};

export type CategoriesFields_CreatedAt_Create = {
  __typename?: 'CategoriesFields_createdAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesFields_CreatedAt_Delete = {
  __typename?: 'CategoriesFields_createdAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesFields_CreatedAt_Read = {
  __typename?: 'CategoriesFields_createdAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesFields_CreatedAt_Update = {
  __typename?: 'CategoriesFields_createdAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesFields_Parent = {
  __typename?: 'CategoriesFields_parent';
  create?: Maybe<CategoriesFields_Parent_Create>;
  delete?: Maybe<CategoriesFields_Parent_Delete>;
  read?: Maybe<CategoriesFields_Parent_Read>;
  update?: Maybe<CategoriesFields_Parent_Update>;
};

export type CategoriesFields_Parent_Create = {
  __typename?: 'CategoriesFields_parent_Create';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesFields_Parent_Delete = {
  __typename?: 'CategoriesFields_parent_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesFields_Parent_Read = {
  __typename?: 'CategoriesFields_parent_Read';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesFields_Parent_Update = {
  __typename?: 'CategoriesFields_parent_Update';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesFields_Title = {
  __typename?: 'CategoriesFields_title';
  create?: Maybe<CategoriesFields_Title_Create>;
  delete?: Maybe<CategoriesFields_Title_Delete>;
  read?: Maybe<CategoriesFields_Title_Read>;
  update?: Maybe<CategoriesFields_Title_Update>;
};

export type CategoriesFields_Title_Create = {
  __typename?: 'CategoriesFields_title_Create';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesFields_Title_Delete = {
  __typename?: 'CategoriesFields_title_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesFields_Title_Read = {
  __typename?: 'CategoriesFields_title_Read';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesFields_Title_Update = {
  __typename?: 'CategoriesFields_title_Update';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesFields_UpdatedAt = {
  __typename?: 'CategoriesFields_updatedAt';
  create?: Maybe<CategoriesFields_UpdatedAt_Create>;
  delete?: Maybe<CategoriesFields_UpdatedAt_Delete>;
  read?: Maybe<CategoriesFields_UpdatedAt_Read>;
  update?: Maybe<CategoriesFields_UpdatedAt_Update>;
};

export type CategoriesFields_UpdatedAt_Create = {
  __typename?: 'CategoriesFields_updatedAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesFields_UpdatedAt_Delete = {
  __typename?: 'CategoriesFields_updatedAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesFields_UpdatedAt_Read = {
  __typename?: 'CategoriesFields_updatedAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesFields_UpdatedAt_Update = {
  __typename?: 'CategoriesFields_updatedAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type CategoriesReadAccess = {
  __typename?: 'CategoriesReadAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type CategoriesReadDocAccess = {
  __typename?: 'CategoriesReadDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type CategoriesUpdateAccess = {
  __typename?: 'CategoriesUpdateAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type CategoriesUpdateDocAccess = {
  __typename?: 'CategoriesUpdateDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type Category = {
  __typename?: 'Category';
  breadcrumbs?: Maybe<Array<Category_Breadcrumbs>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  parent?: Maybe<Category>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type Category_Breadcrumbs = {
  __typename?: 'Category_Breadcrumbs';
  doc?: Maybe<Category>;
  id?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type Category_Breadcrumbs__Doc_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
};

export type Category_Breadcrumbs__Id_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Category_Breadcrumbs__Label_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Category_Breadcrumbs__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Category_CreatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Category_Id_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Category_Parent_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
};

export type Category_Title_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Category_UpdatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Category_Where = {
  AND?: InputMaybe<Array<InputMaybe<Category_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<Category_Where_Or>>>;
  breadcrumbs__doc?: InputMaybe<Category_Breadcrumbs__Doc_Operator>;
  breadcrumbs__id?: InputMaybe<Category_Breadcrumbs__Id_Operator>;
  breadcrumbs__label?: InputMaybe<Category_Breadcrumbs__Label_Operator>;
  breadcrumbs__url?: InputMaybe<Category_Breadcrumbs__Url_Operator>;
  createdAt?: InputMaybe<Category_CreatedAt_Operator>;
  id?: InputMaybe<Category_Id_Operator>;
  parent?: InputMaybe<Category_Parent_Operator>;
  title?: InputMaybe<Category_Title_Operator>;
  updatedAt?: InputMaybe<Category_UpdatedAt_Operator>;
};

export type Category_Where_And = {
  AND?: InputMaybe<Array<InputMaybe<Category_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<Category_Where_Or>>>;
  breadcrumbs__doc?: InputMaybe<Category_Breadcrumbs__Doc_Operator>;
  breadcrumbs__id?: InputMaybe<Category_Breadcrumbs__Id_Operator>;
  breadcrumbs__label?: InputMaybe<Category_Breadcrumbs__Label_Operator>;
  breadcrumbs__url?: InputMaybe<Category_Breadcrumbs__Url_Operator>;
  createdAt?: InputMaybe<Category_CreatedAt_Operator>;
  id?: InputMaybe<Category_Id_Operator>;
  parent?: InputMaybe<Category_Parent_Operator>;
  title?: InputMaybe<Category_Title_Operator>;
  updatedAt?: InputMaybe<Category_UpdatedAt_Operator>;
};

export type Category_Where_Or = {
  AND?: InputMaybe<Array<InputMaybe<Category_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<Category_Where_Or>>>;
  breadcrumbs__doc?: InputMaybe<Category_Breadcrumbs__Doc_Operator>;
  breadcrumbs__id?: InputMaybe<Category_Breadcrumbs__Id_Operator>;
  breadcrumbs__label?: InputMaybe<Category_Breadcrumbs__Label_Operator>;
  breadcrumbs__url?: InputMaybe<Category_Breadcrumbs__Url_Operator>;
  createdAt?: InputMaybe<Category_CreatedAt_Operator>;
  id?: InputMaybe<Category_Id_Operator>;
  parent?: InputMaybe<Category_Parent_Operator>;
  title?: InputMaybe<Category_Title_Operator>;
  updatedAt?: InputMaybe<Category_UpdatedAt_Operator>;
};

export type Content = {
  __typename?: 'Content';
  blockName?: Maybe<Scalars['String']['output']>;
  blockType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  richText?: Maybe<Scalars['JSON']['output']>;
};


export type ContentRichTextArgs = {
  depth?: InputMaybe<Scalars['Int']['input']>;
};

export type Cta = {
  __typename?: 'Cta';
  blockName?: Maybe<Scalars['String']['output']>;
  blockType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  links?: Maybe<Array<Cta_Links>>;
  richText?: Maybe<Scalars['JSON']['output']>;
};


export type CtaRichTextArgs = {
  depth?: InputMaybe<Scalars['Int']['input']>;
};

export type Cta_Links = {
  __typename?: 'Cta_Links';
  id?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Cta_Links_Link>;
};

export type Cta_Links_Link = {
  __typename?: 'Cta_Links_Link';
  appearance?: Maybe<Cta_Links_Link_Appearance>;
  label?: Maybe<Scalars['String']['output']>;
  newTab?: Maybe<Scalars['Boolean']['output']>;
  reference?: Maybe<Cta_Links_Link_Reference_Relationship>;
  type?: Maybe<Cta_Links_Link_Type>;
  url?: Maybe<Scalars['String']['output']>;
};

export type Cta_Links_Link_Reference = Article;

export enum Cta_Links_Link_Reference_RelationTo {
  Articles = 'articles'
}

export type Cta_Links_Link_Reference_Relationship = {
  __typename?: 'Cta_Links_Link_Reference_Relationship';
  relationTo?: Maybe<Cta_Links_Link_Reference_RelationTo>;
  value?: Maybe<Cta_Links_Link_Reference>;
};

export enum Cta_Links_Link_Appearance {
  Primary = 'primary',
  Secondary = 'secondary'
}

export enum Cta_Links_Link_Type {
  Custom = 'custom',
  Reference = 'reference'
}

export type Media = {
  __typename?: 'Media';
  alt?: Maybe<Scalars['String']['output']>;
  caption?: Maybe<Scalars['JSON']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  filename?: Maybe<Scalars['String']['output']>;
  filesize?: Maybe<Scalars['Float']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  mimeType?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};


export type MediaCaptionArgs = {
  depth?: InputMaybe<Scalars['Int']['input']>;
};

export type MediaBlock = {
  __typename?: 'MediaBlock';
  blockName?: Maybe<Scalars['String']['output']>;
  blockType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  media?: Maybe<Media>;
};


export type MediaBlockMediaArgs = {
  where?: InputMaybe<MediaBlock_Media_Where>;
};

export type MediaBlock_Media_Alt_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type MediaBlock_Media_Caption_Operator = {
  contains?: InputMaybe<Scalars['JSON']['input']>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<Scalars['JSON']['input']>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
};

export type MediaBlock_Media_CreatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type MediaBlock_Media_Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type MediaBlock_Media_Filesize_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type MediaBlock_Media_Height_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type MediaBlock_Media_Id_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type MediaBlock_Media_MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type MediaBlock_Media_UpdatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type MediaBlock_Media_Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type MediaBlock_Media_Where = {
  AND?: InputMaybe<Array<InputMaybe<MediaBlock_Media_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<MediaBlock_Media_Where_Or>>>;
  alt?: InputMaybe<MediaBlock_Media_Alt_Operator>;
  caption?: InputMaybe<MediaBlock_Media_Caption_Operator>;
  createdAt?: InputMaybe<MediaBlock_Media_CreatedAt_Operator>;
  filename?: InputMaybe<MediaBlock_Media_Filename_Operator>;
  filesize?: InputMaybe<MediaBlock_Media_Filesize_Operator>;
  height?: InputMaybe<MediaBlock_Media_Height_Operator>;
  id?: InputMaybe<MediaBlock_Media_Id_Operator>;
  mimeType?: InputMaybe<MediaBlock_Media_MimeType_Operator>;
  updatedAt?: InputMaybe<MediaBlock_Media_UpdatedAt_Operator>;
  url?: InputMaybe<MediaBlock_Media_Url_Operator>;
  width?: InputMaybe<MediaBlock_Media_Width_Operator>;
};

export type MediaBlock_Media_Where_And = {
  AND?: InputMaybe<Array<InputMaybe<MediaBlock_Media_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<MediaBlock_Media_Where_Or>>>;
  alt?: InputMaybe<MediaBlock_Media_Alt_Operator>;
  caption?: InputMaybe<MediaBlock_Media_Caption_Operator>;
  createdAt?: InputMaybe<MediaBlock_Media_CreatedAt_Operator>;
  filename?: InputMaybe<MediaBlock_Media_Filename_Operator>;
  filesize?: InputMaybe<MediaBlock_Media_Filesize_Operator>;
  height?: InputMaybe<MediaBlock_Media_Height_Operator>;
  id?: InputMaybe<MediaBlock_Media_Id_Operator>;
  mimeType?: InputMaybe<MediaBlock_Media_MimeType_Operator>;
  updatedAt?: InputMaybe<MediaBlock_Media_UpdatedAt_Operator>;
  url?: InputMaybe<MediaBlock_Media_Url_Operator>;
  width?: InputMaybe<MediaBlock_Media_Width_Operator>;
};

export type MediaBlock_Media_Where_Or = {
  AND?: InputMaybe<Array<InputMaybe<MediaBlock_Media_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<MediaBlock_Media_Where_Or>>>;
  alt?: InputMaybe<MediaBlock_Media_Alt_Operator>;
  caption?: InputMaybe<MediaBlock_Media_Caption_Operator>;
  createdAt?: InputMaybe<MediaBlock_Media_CreatedAt_Operator>;
  filename?: InputMaybe<MediaBlock_Media_Filename_Operator>;
  filesize?: InputMaybe<MediaBlock_Media_Filesize_Operator>;
  height?: InputMaybe<MediaBlock_Media_Height_Operator>;
  id?: InputMaybe<MediaBlock_Media_Id_Operator>;
  mimeType?: InputMaybe<MediaBlock_Media_MimeType_Operator>;
  updatedAt?: InputMaybe<MediaBlock_Media_UpdatedAt_Operator>;
  url?: InputMaybe<MediaBlock_Media_Url_Operator>;
  width?: InputMaybe<MediaBlock_Media_Width_Operator>;
};

export type MediaBlock_Media_Width_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type MediaCreateAccess = {
  __typename?: 'MediaCreateAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type MediaCreateDocAccess = {
  __typename?: 'MediaCreateDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type MediaDeleteAccess = {
  __typename?: 'MediaDeleteAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type MediaDeleteDocAccess = {
  __typename?: 'MediaDeleteDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type MediaDocAccessFields = {
  __typename?: 'MediaDocAccessFields';
  alt?: Maybe<MediaDocAccessFields_Alt>;
  caption?: Maybe<MediaDocAccessFields_Caption>;
  createdAt?: Maybe<MediaDocAccessFields_CreatedAt>;
  filename?: Maybe<MediaDocAccessFields_Filename>;
  filesize?: Maybe<MediaDocAccessFields_Filesize>;
  height?: Maybe<MediaDocAccessFields_Height>;
  mimeType?: Maybe<MediaDocAccessFields_MimeType>;
  updatedAt?: Maybe<MediaDocAccessFields_UpdatedAt>;
  url?: Maybe<MediaDocAccessFields_Url>;
  width?: Maybe<MediaDocAccessFields_Width>;
};

export type MediaDocAccessFields_Alt = {
  __typename?: 'MediaDocAccessFields_alt';
  create?: Maybe<MediaDocAccessFields_Alt_Create>;
  delete?: Maybe<MediaDocAccessFields_Alt_Delete>;
  read?: Maybe<MediaDocAccessFields_Alt_Read>;
  update?: Maybe<MediaDocAccessFields_Alt_Update>;
};

export type MediaDocAccessFields_Alt_Create = {
  __typename?: 'MediaDocAccessFields_alt_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Alt_Delete = {
  __typename?: 'MediaDocAccessFields_alt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Alt_Read = {
  __typename?: 'MediaDocAccessFields_alt_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Alt_Update = {
  __typename?: 'MediaDocAccessFields_alt_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Caption = {
  __typename?: 'MediaDocAccessFields_caption';
  create?: Maybe<MediaDocAccessFields_Caption_Create>;
  delete?: Maybe<MediaDocAccessFields_Caption_Delete>;
  read?: Maybe<MediaDocAccessFields_Caption_Read>;
  update?: Maybe<MediaDocAccessFields_Caption_Update>;
};

export type MediaDocAccessFields_Caption_Create = {
  __typename?: 'MediaDocAccessFields_caption_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Caption_Delete = {
  __typename?: 'MediaDocAccessFields_caption_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Caption_Read = {
  __typename?: 'MediaDocAccessFields_caption_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Caption_Update = {
  __typename?: 'MediaDocAccessFields_caption_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_CreatedAt = {
  __typename?: 'MediaDocAccessFields_createdAt';
  create?: Maybe<MediaDocAccessFields_CreatedAt_Create>;
  delete?: Maybe<MediaDocAccessFields_CreatedAt_Delete>;
  read?: Maybe<MediaDocAccessFields_CreatedAt_Read>;
  update?: Maybe<MediaDocAccessFields_CreatedAt_Update>;
};

export type MediaDocAccessFields_CreatedAt_Create = {
  __typename?: 'MediaDocAccessFields_createdAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_CreatedAt_Delete = {
  __typename?: 'MediaDocAccessFields_createdAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_CreatedAt_Read = {
  __typename?: 'MediaDocAccessFields_createdAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_CreatedAt_Update = {
  __typename?: 'MediaDocAccessFields_createdAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Filename = {
  __typename?: 'MediaDocAccessFields_filename';
  create?: Maybe<MediaDocAccessFields_Filename_Create>;
  delete?: Maybe<MediaDocAccessFields_Filename_Delete>;
  read?: Maybe<MediaDocAccessFields_Filename_Read>;
  update?: Maybe<MediaDocAccessFields_Filename_Update>;
};

export type MediaDocAccessFields_Filename_Create = {
  __typename?: 'MediaDocAccessFields_filename_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Filename_Delete = {
  __typename?: 'MediaDocAccessFields_filename_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Filename_Read = {
  __typename?: 'MediaDocAccessFields_filename_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Filename_Update = {
  __typename?: 'MediaDocAccessFields_filename_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Filesize = {
  __typename?: 'MediaDocAccessFields_filesize';
  create?: Maybe<MediaDocAccessFields_Filesize_Create>;
  delete?: Maybe<MediaDocAccessFields_Filesize_Delete>;
  read?: Maybe<MediaDocAccessFields_Filesize_Read>;
  update?: Maybe<MediaDocAccessFields_Filesize_Update>;
};

export type MediaDocAccessFields_Filesize_Create = {
  __typename?: 'MediaDocAccessFields_filesize_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Filesize_Delete = {
  __typename?: 'MediaDocAccessFields_filesize_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Filesize_Read = {
  __typename?: 'MediaDocAccessFields_filesize_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Filesize_Update = {
  __typename?: 'MediaDocAccessFields_filesize_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Height = {
  __typename?: 'MediaDocAccessFields_height';
  create?: Maybe<MediaDocAccessFields_Height_Create>;
  delete?: Maybe<MediaDocAccessFields_Height_Delete>;
  read?: Maybe<MediaDocAccessFields_Height_Read>;
  update?: Maybe<MediaDocAccessFields_Height_Update>;
};

export type MediaDocAccessFields_Height_Create = {
  __typename?: 'MediaDocAccessFields_height_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Height_Delete = {
  __typename?: 'MediaDocAccessFields_height_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Height_Read = {
  __typename?: 'MediaDocAccessFields_height_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Height_Update = {
  __typename?: 'MediaDocAccessFields_height_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_MimeType = {
  __typename?: 'MediaDocAccessFields_mimeType';
  create?: Maybe<MediaDocAccessFields_MimeType_Create>;
  delete?: Maybe<MediaDocAccessFields_MimeType_Delete>;
  read?: Maybe<MediaDocAccessFields_MimeType_Read>;
  update?: Maybe<MediaDocAccessFields_MimeType_Update>;
};

export type MediaDocAccessFields_MimeType_Create = {
  __typename?: 'MediaDocAccessFields_mimeType_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_MimeType_Delete = {
  __typename?: 'MediaDocAccessFields_mimeType_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_MimeType_Read = {
  __typename?: 'MediaDocAccessFields_mimeType_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_MimeType_Update = {
  __typename?: 'MediaDocAccessFields_mimeType_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_UpdatedAt = {
  __typename?: 'MediaDocAccessFields_updatedAt';
  create?: Maybe<MediaDocAccessFields_UpdatedAt_Create>;
  delete?: Maybe<MediaDocAccessFields_UpdatedAt_Delete>;
  read?: Maybe<MediaDocAccessFields_UpdatedAt_Read>;
  update?: Maybe<MediaDocAccessFields_UpdatedAt_Update>;
};

export type MediaDocAccessFields_UpdatedAt_Create = {
  __typename?: 'MediaDocAccessFields_updatedAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_UpdatedAt_Delete = {
  __typename?: 'MediaDocAccessFields_updatedAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_UpdatedAt_Read = {
  __typename?: 'MediaDocAccessFields_updatedAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_UpdatedAt_Update = {
  __typename?: 'MediaDocAccessFields_updatedAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Url = {
  __typename?: 'MediaDocAccessFields_url';
  create?: Maybe<MediaDocAccessFields_Url_Create>;
  delete?: Maybe<MediaDocAccessFields_Url_Delete>;
  read?: Maybe<MediaDocAccessFields_Url_Read>;
  update?: Maybe<MediaDocAccessFields_Url_Update>;
};

export type MediaDocAccessFields_Url_Create = {
  __typename?: 'MediaDocAccessFields_url_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Url_Delete = {
  __typename?: 'MediaDocAccessFields_url_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Url_Read = {
  __typename?: 'MediaDocAccessFields_url_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Url_Update = {
  __typename?: 'MediaDocAccessFields_url_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Width = {
  __typename?: 'MediaDocAccessFields_width';
  create?: Maybe<MediaDocAccessFields_Width_Create>;
  delete?: Maybe<MediaDocAccessFields_Width_Delete>;
  read?: Maybe<MediaDocAccessFields_Width_Read>;
  update?: Maybe<MediaDocAccessFields_Width_Update>;
};

export type MediaDocAccessFields_Width_Create = {
  __typename?: 'MediaDocAccessFields_width_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Width_Delete = {
  __typename?: 'MediaDocAccessFields_width_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Width_Read = {
  __typename?: 'MediaDocAccessFields_width_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaDocAccessFields_Width_Update = {
  __typename?: 'MediaDocAccessFields_width_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields = {
  __typename?: 'MediaFields';
  alt?: Maybe<MediaFields_Alt>;
  caption?: Maybe<MediaFields_Caption>;
  createdAt?: Maybe<MediaFields_CreatedAt>;
  filename?: Maybe<MediaFields_Filename>;
  filesize?: Maybe<MediaFields_Filesize>;
  height?: Maybe<MediaFields_Height>;
  mimeType?: Maybe<MediaFields_MimeType>;
  updatedAt?: Maybe<MediaFields_UpdatedAt>;
  url?: Maybe<MediaFields_Url>;
  width?: Maybe<MediaFields_Width>;
};

export type MediaFields_Alt = {
  __typename?: 'MediaFields_alt';
  create?: Maybe<MediaFields_Alt_Create>;
  delete?: Maybe<MediaFields_Alt_Delete>;
  read?: Maybe<MediaFields_Alt_Read>;
  update?: Maybe<MediaFields_Alt_Update>;
};

export type MediaFields_Alt_Create = {
  __typename?: 'MediaFields_alt_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Alt_Delete = {
  __typename?: 'MediaFields_alt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Alt_Read = {
  __typename?: 'MediaFields_alt_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Alt_Update = {
  __typename?: 'MediaFields_alt_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Caption = {
  __typename?: 'MediaFields_caption';
  create?: Maybe<MediaFields_Caption_Create>;
  delete?: Maybe<MediaFields_Caption_Delete>;
  read?: Maybe<MediaFields_Caption_Read>;
  update?: Maybe<MediaFields_Caption_Update>;
};

export type MediaFields_Caption_Create = {
  __typename?: 'MediaFields_caption_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Caption_Delete = {
  __typename?: 'MediaFields_caption_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Caption_Read = {
  __typename?: 'MediaFields_caption_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Caption_Update = {
  __typename?: 'MediaFields_caption_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_CreatedAt = {
  __typename?: 'MediaFields_createdAt';
  create?: Maybe<MediaFields_CreatedAt_Create>;
  delete?: Maybe<MediaFields_CreatedAt_Delete>;
  read?: Maybe<MediaFields_CreatedAt_Read>;
  update?: Maybe<MediaFields_CreatedAt_Update>;
};

export type MediaFields_CreatedAt_Create = {
  __typename?: 'MediaFields_createdAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_CreatedAt_Delete = {
  __typename?: 'MediaFields_createdAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_CreatedAt_Read = {
  __typename?: 'MediaFields_createdAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_CreatedAt_Update = {
  __typename?: 'MediaFields_createdAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Filename = {
  __typename?: 'MediaFields_filename';
  create?: Maybe<MediaFields_Filename_Create>;
  delete?: Maybe<MediaFields_Filename_Delete>;
  read?: Maybe<MediaFields_Filename_Read>;
  update?: Maybe<MediaFields_Filename_Update>;
};

export type MediaFields_Filename_Create = {
  __typename?: 'MediaFields_filename_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Filename_Delete = {
  __typename?: 'MediaFields_filename_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Filename_Read = {
  __typename?: 'MediaFields_filename_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Filename_Update = {
  __typename?: 'MediaFields_filename_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Filesize = {
  __typename?: 'MediaFields_filesize';
  create?: Maybe<MediaFields_Filesize_Create>;
  delete?: Maybe<MediaFields_Filesize_Delete>;
  read?: Maybe<MediaFields_Filesize_Read>;
  update?: Maybe<MediaFields_Filesize_Update>;
};

export type MediaFields_Filesize_Create = {
  __typename?: 'MediaFields_filesize_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Filesize_Delete = {
  __typename?: 'MediaFields_filesize_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Filesize_Read = {
  __typename?: 'MediaFields_filesize_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Filesize_Update = {
  __typename?: 'MediaFields_filesize_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Height = {
  __typename?: 'MediaFields_height';
  create?: Maybe<MediaFields_Height_Create>;
  delete?: Maybe<MediaFields_Height_Delete>;
  read?: Maybe<MediaFields_Height_Read>;
  update?: Maybe<MediaFields_Height_Update>;
};

export type MediaFields_Height_Create = {
  __typename?: 'MediaFields_height_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Height_Delete = {
  __typename?: 'MediaFields_height_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Height_Read = {
  __typename?: 'MediaFields_height_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Height_Update = {
  __typename?: 'MediaFields_height_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_MimeType = {
  __typename?: 'MediaFields_mimeType';
  create?: Maybe<MediaFields_MimeType_Create>;
  delete?: Maybe<MediaFields_MimeType_Delete>;
  read?: Maybe<MediaFields_MimeType_Read>;
  update?: Maybe<MediaFields_MimeType_Update>;
};

export type MediaFields_MimeType_Create = {
  __typename?: 'MediaFields_mimeType_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_MimeType_Delete = {
  __typename?: 'MediaFields_mimeType_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_MimeType_Read = {
  __typename?: 'MediaFields_mimeType_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_MimeType_Update = {
  __typename?: 'MediaFields_mimeType_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_UpdatedAt = {
  __typename?: 'MediaFields_updatedAt';
  create?: Maybe<MediaFields_UpdatedAt_Create>;
  delete?: Maybe<MediaFields_UpdatedAt_Delete>;
  read?: Maybe<MediaFields_UpdatedAt_Read>;
  update?: Maybe<MediaFields_UpdatedAt_Update>;
};

export type MediaFields_UpdatedAt_Create = {
  __typename?: 'MediaFields_updatedAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_UpdatedAt_Delete = {
  __typename?: 'MediaFields_updatedAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_UpdatedAt_Read = {
  __typename?: 'MediaFields_updatedAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_UpdatedAt_Update = {
  __typename?: 'MediaFields_updatedAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Url = {
  __typename?: 'MediaFields_url';
  create?: Maybe<MediaFields_Url_Create>;
  delete?: Maybe<MediaFields_Url_Delete>;
  read?: Maybe<MediaFields_Url_Read>;
  update?: Maybe<MediaFields_Url_Update>;
};

export type MediaFields_Url_Create = {
  __typename?: 'MediaFields_url_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Url_Delete = {
  __typename?: 'MediaFields_url_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Url_Read = {
  __typename?: 'MediaFields_url_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Url_Update = {
  __typename?: 'MediaFields_url_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Width = {
  __typename?: 'MediaFields_width';
  create?: Maybe<MediaFields_Width_Create>;
  delete?: Maybe<MediaFields_Width_Delete>;
  read?: Maybe<MediaFields_Width_Read>;
  update?: Maybe<MediaFields_Width_Update>;
};

export type MediaFields_Width_Create = {
  __typename?: 'MediaFields_width_Create';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Width_Delete = {
  __typename?: 'MediaFields_width_Delete';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Width_Read = {
  __typename?: 'MediaFields_width_Read';
  permission: Scalars['Boolean']['output'];
};

export type MediaFields_Width_Update = {
  __typename?: 'MediaFields_width_Update';
  permission: Scalars['Boolean']['output'];
};

export type MediaReadAccess = {
  __typename?: 'MediaReadAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type MediaReadDocAccess = {
  __typename?: 'MediaReadDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type MediaUpdateAccess = {
  __typename?: 'MediaUpdateAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type MediaUpdateDocAccess = {
  __typename?: 'MediaUpdateDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type Media_Alt_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Media_Caption_Operator = {
  contains?: InputMaybe<Scalars['JSON']['input']>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<Scalars['JSON']['input']>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
};

export type Media_CreatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Media_Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Media_Filesize_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Media_Height_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Media_Id_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Media_MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Media_UpdatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Media_Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Media_Where = {
  AND?: InputMaybe<Array<InputMaybe<Media_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<Media_Where_Or>>>;
  alt?: InputMaybe<Media_Alt_Operator>;
  caption?: InputMaybe<Media_Caption_Operator>;
  createdAt?: InputMaybe<Media_CreatedAt_Operator>;
  filename?: InputMaybe<Media_Filename_Operator>;
  filesize?: InputMaybe<Media_Filesize_Operator>;
  height?: InputMaybe<Media_Height_Operator>;
  id?: InputMaybe<Media_Id_Operator>;
  mimeType?: InputMaybe<Media_MimeType_Operator>;
  updatedAt?: InputMaybe<Media_UpdatedAt_Operator>;
  url?: InputMaybe<Media_Url_Operator>;
  width?: InputMaybe<Media_Width_Operator>;
};

export type Media_Where_And = {
  AND?: InputMaybe<Array<InputMaybe<Media_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<Media_Where_Or>>>;
  alt?: InputMaybe<Media_Alt_Operator>;
  caption?: InputMaybe<Media_Caption_Operator>;
  createdAt?: InputMaybe<Media_CreatedAt_Operator>;
  filename?: InputMaybe<Media_Filename_Operator>;
  filesize?: InputMaybe<Media_Filesize_Operator>;
  height?: InputMaybe<Media_Height_Operator>;
  id?: InputMaybe<Media_Id_Operator>;
  mimeType?: InputMaybe<Media_MimeType_Operator>;
  updatedAt?: InputMaybe<Media_UpdatedAt_Operator>;
  url?: InputMaybe<Media_Url_Operator>;
  width?: InputMaybe<Media_Width_Operator>;
};

export type Media_Where_Or = {
  AND?: InputMaybe<Array<InputMaybe<Media_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<Media_Where_Or>>>;
  alt?: InputMaybe<Media_Alt_Operator>;
  caption?: InputMaybe<Media_Caption_Operator>;
  createdAt?: InputMaybe<Media_CreatedAt_Operator>;
  filename?: InputMaybe<Media_Filename_Operator>;
  filesize?: InputMaybe<Media_Filesize_Operator>;
  height?: InputMaybe<Media_Height_Operator>;
  id?: InputMaybe<Media_Id_Operator>;
  mimeType?: InputMaybe<Media_MimeType_Operator>;
  updatedAt?: InputMaybe<Media_UpdatedAt_Operator>;
  url?: InputMaybe<Media_Url_Operator>;
  width?: InputMaybe<Media_Width_Operator>;
};

export type Media_Width_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createArticle?: Maybe<Article>;
  createCategory?: Maybe<Category>;
  createMedia?: Maybe<Media>;
  createPayloadPreference?: Maybe<PayloadPreference>;
  createTaskTemplate?: Maybe<TaskTemplate>;
  createUser?: Maybe<User>;
  deleteArticle?: Maybe<Article>;
  deleteCategory?: Maybe<Category>;
  deleteMedia?: Maybe<Media>;
  deletePayloadPreference?: Maybe<PayloadPreference>;
  deleteTaskTemplate?: Maybe<TaskTemplate>;
  deleteUser?: Maybe<User>;
  forgotPasswordUser: Scalars['Boolean']['output'];
  loginUser?: Maybe<UsersLoginResult>;
  logoutUser?: Maybe<Scalars['String']['output']>;
  refreshTokenUser?: Maybe<UsersRefreshedUser>;
  resetPasswordUser?: Maybe<UsersResetPassword>;
  restoreVersionArticle?: Maybe<Article>;
  restoreVersionTaskTemplate?: Maybe<TaskTemplate>;
  unlockUser: Scalars['Boolean']['output'];
  updateArticle?: Maybe<Article>;
  updateCategory?: Maybe<Category>;
  updateMedia?: Maybe<Media>;
  updatePayloadPreference?: Maybe<PayloadPreference>;
  updateTaskTemplate?: Maybe<TaskTemplate>;
  updateUser?: Maybe<User>;
  verifyEmailUser?: Maybe<Scalars['Boolean']['output']>;
};


export type MutationCreateArticleArgs = {
  data: MutationArticleInput;
  draft?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateCategoryArgs = {
  data: MutationCategoryInput;
  draft?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateMediaArgs = {
  data: MutationMediaInput;
  draft?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreatePayloadPreferenceArgs = {
  data: MutationPayloadPreferenceInput;
  draft?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateTaskTemplateArgs = {
  data: MutationTaskTemplateInput;
  draft?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateUserArgs = {
  data: MutationUserInput;
  draft?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationDeleteArticleArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteCategoryArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteMediaArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeletePayloadPreferenceArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteTaskTemplateArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['String']['input'];
};


export type MutationForgotPasswordUserArgs = {
  disableEmail?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  expiration?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationLoginUserArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRefreshTokenUserArgs = {
  token?: InputMaybe<Scalars['String']['input']>;
};


export type MutationResetPasswordUserArgs = {
  password?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRestoreVersionArticleArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRestoreVersionTaskTemplateArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUnlockUserArgs = {
  email: Scalars['String']['input'];
};


export type MutationUpdateArticleArgs = {
  autosave?: InputMaybe<Scalars['Boolean']['input']>;
  data: MutationArticleUpdateInput;
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
};


export type MutationUpdateCategoryArgs = {
  autosave?: InputMaybe<Scalars['Boolean']['input']>;
  data: MutationCategoryUpdateInput;
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
};


export type MutationUpdateMediaArgs = {
  autosave?: InputMaybe<Scalars['Boolean']['input']>;
  data: MutationMediaUpdateInput;
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
};


export type MutationUpdatePayloadPreferenceArgs = {
  autosave?: InputMaybe<Scalars['Boolean']['input']>;
  data: MutationPayloadPreferenceUpdateInput;
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
};


export type MutationUpdateTaskTemplateArgs = {
  autosave?: InputMaybe<Scalars['Boolean']['input']>;
  data: MutationTaskTemplateUpdateInput;
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
};


export type MutationUpdateUserArgs = {
  autosave?: InputMaybe<Scalars['Boolean']['input']>;
  data: MutationUserUpdateInput;
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
};


export type MutationVerifyEmailUserArgs = {
  token?: InputMaybe<Scalars['String']['input']>;
};

export type PayloadPreference = {
  __typename?: 'PayloadPreference';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  user: PayloadPreference_User_Relationship;
  value?: Maybe<Scalars['JSON']['output']>;
};

export type PayloadPreferenceUpdate_UserRelationshipInput = {
  relationTo?: InputMaybe<PayloadPreferenceUpdate_UserRelationshipInputRelationTo>;
  value?: InputMaybe<Scalars['JSON']['input']>;
};

export enum PayloadPreferenceUpdate_UserRelationshipInputRelationTo {
  Users = 'users'
}

export type PayloadPreference_User = User;

export type PayloadPreference_UserRelationshipInput = {
  relationTo?: InputMaybe<PayloadPreference_UserRelationshipInputRelationTo>;
  value?: InputMaybe<Scalars['JSON']['input']>;
};

export enum PayloadPreference_UserRelationshipInputRelationTo {
  Users = 'users'
}

export enum PayloadPreference_User_RelationTo {
  Users = 'users'
}

export type PayloadPreference_User_Relationship = {
  __typename?: 'PayloadPreference_User_Relationship';
  relationTo?: Maybe<PayloadPreference_User_RelationTo>;
  value?: Maybe<PayloadPreference_User>;
};

export type PayloadPreference_CreatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PayloadPreference_Id_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type PayloadPreference_Key_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type PayloadPreference_UpdatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PayloadPreference_User_Relation = {
  relationTo?: InputMaybe<PayloadPreference_User_Relation_RelationTo>;
  value?: InputMaybe<Scalars['JSON']['input']>;
};

export enum PayloadPreference_User_Relation_RelationTo {
  Users = 'users'
}

export type PayloadPreference_Value_Operator = {
  contains?: InputMaybe<Scalars['JSON']['input']>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  intersects?: InputMaybe<Scalars['JSON']['input']>;
  like?: InputMaybe<Scalars['JSON']['input']>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
  within?: InputMaybe<Scalars['JSON']['input']>;
};

export type PayloadPreference_Where = {
  AND?: InputMaybe<Array<InputMaybe<PayloadPreference_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<PayloadPreference_Where_Or>>>;
  createdAt?: InputMaybe<PayloadPreference_CreatedAt_Operator>;
  id?: InputMaybe<PayloadPreference_Id_Operator>;
  key?: InputMaybe<PayloadPreference_Key_Operator>;
  updatedAt?: InputMaybe<PayloadPreference_UpdatedAt_Operator>;
  user?: InputMaybe<PayloadPreference_User_Relation>;
  value?: InputMaybe<PayloadPreference_Value_Operator>;
};

export type PayloadPreference_Where_And = {
  AND?: InputMaybe<Array<InputMaybe<PayloadPreference_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<PayloadPreference_Where_Or>>>;
  createdAt?: InputMaybe<PayloadPreference_CreatedAt_Operator>;
  id?: InputMaybe<PayloadPreference_Id_Operator>;
  key?: InputMaybe<PayloadPreference_Key_Operator>;
  updatedAt?: InputMaybe<PayloadPreference_UpdatedAt_Operator>;
  user?: InputMaybe<PayloadPreference_User_Relation>;
  value?: InputMaybe<PayloadPreference_Value_Operator>;
};

export type PayloadPreference_Where_Or = {
  AND?: InputMaybe<Array<InputMaybe<PayloadPreference_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<PayloadPreference_Where_Or>>>;
  createdAt?: InputMaybe<PayloadPreference_CreatedAt_Operator>;
  id?: InputMaybe<PayloadPreference_Id_Operator>;
  key?: InputMaybe<PayloadPreference_Key_Operator>;
  updatedAt?: InputMaybe<PayloadPreference_UpdatedAt_Operator>;
  user?: InputMaybe<PayloadPreference_User_Relation>;
  value?: InputMaybe<PayloadPreference_Value_Operator>;
};

export type PayloadPreferences = {
  __typename?: 'PayloadPreferences';
  docs?: Maybe<Array<Maybe<PayloadPreference>>>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  nextPage?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  pagingCounter?: Maybe<Scalars['Int']['output']>;
  prevPage?: Maybe<Scalars['Int']['output']>;
  totalDocs?: Maybe<Scalars['Int']['output']>;
  totalPages?: Maybe<Scalars['Int']['output']>;
};

export type PayloadPreferencesCreateAccess = {
  __typename?: 'PayloadPreferencesCreateAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type PayloadPreferencesCreateDocAccess = {
  __typename?: 'PayloadPreferencesCreateDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type PayloadPreferencesDeleteAccess = {
  __typename?: 'PayloadPreferencesDeleteAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type PayloadPreferencesDeleteDocAccess = {
  __typename?: 'PayloadPreferencesDeleteDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type PayloadPreferencesDocAccessFields = {
  __typename?: 'PayloadPreferencesDocAccessFields';
  createdAt?: Maybe<PayloadPreferencesDocAccessFields_CreatedAt>;
  key?: Maybe<PayloadPreferencesDocAccessFields_Key>;
  updatedAt?: Maybe<PayloadPreferencesDocAccessFields_UpdatedAt>;
  user?: Maybe<PayloadPreferencesDocAccessFields_User>;
  value?: Maybe<PayloadPreferencesDocAccessFields_Value>;
};

export type PayloadPreferencesDocAccessFields_CreatedAt = {
  __typename?: 'PayloadPreferencesDocAccessFields_createdAt';
  create?: Maybe<PayloadPreferencesDocAccessFields_CreatedAt_Create>;
  delete?: Maybe<PayloadPreferencesDocAccessFields_CreatedAt_Delete>;
  read?: Maybe<PayloadPreferencesDocAccessFields_CreatedAt_Read>;
  update?: Maybe<PayloadPreferencesDocAccessFields_CreatedAt_Update>;
};

export type PayloadPreferencesDocAccessFields_CreatedAt_Create = {
  __typename?: 'PayloadPreferencesDocAccessFields_createdAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesDocAccessFields_CreatedAt_Delete = {
  __typename?: 'PayloadPreferencesDocAccessFields_createdAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesDocAccessFields_CreatedAt_Read = {
  __typename?: 'PayloadPreferencesDocAccessFields_createdAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesDocAccessFields_CreatedAt_Update = {
  __typename?: 'PayloadPreferencesDocAccessFields_createdAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesDocAccessFields_Key = {
  __typename?: 'PayloadPreferencesDocAccessFields_key';
  create?: Maybe<PayloadPreferencesDocAccessFields_Key_Create>;
  delete?: Maybe<PayloadPreferencesDocAccessFields_Key_Delete>;
  read?: Maybe<PayloadPreferencesDocAccessFields_Key_Read>;
  update?: Maybe<PayloadPreferencesDocAccessFields_Key_Update>;
};

export type PayloadPreferencesDocAccessFields_Key_Create = {
  __typename?: 'PayloadPreferencesDocAccessFields_key_Create';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesDocAccessFields_Key_Delete = {
  __typename?: 'PayloadPreferencesDocAccessFields_key_Delete';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesDocAccessFields_Key_Read = {
  __typename?: 'PayloadPreferencesDocAccessFields_key_Read';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesDocAccessFields_Key_Update = {
  __typename?: 'PayloadPreferencesDocAccessFields_key_Update';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesDocAccessFields_UpdatedAt = {
  __typename?: 'PayloadPreferencesDocAccessFields_updatedAt';
  create?: Maybe<PayloadPreferencesDocAccessFields_UpdatedAt_Create>;
  delete?: Maybe<PayloadPreferencesDocAccessFields_UpdatedAt_Delete>;
  read?: Maybe<PayloadPreferencesDocAccessFields_UpdatedAt_Read>;
  update?: Maybe<PayloadPreferencesDocAccessFields_UpdatedAt_Update>;
};

export type PayloadPreferencesDocAccessFields_UpdatedAt_Create = {
  __typename?: 'PayloadPreferencesDocAccessFields_updatedAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesDocAccessFields_UpdatedAt_Delete = {
  __typename?: 'PayloadPreferencesDocAccessFields_updatedAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesDocAccessFields_UpdatedAt_Read = {
  __typename?: 'PayloadPreferencesDocAccessFields_updatedAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesDocAccessFields_UpdatedAt_Update = {
  __typename?: 'PayloadPreferencesDocAccessFields_updatedAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesDocAccessFields_User = {
  __typename?: 'PayloadPreferencesDocAccessFields_user';
  create?: Maybe<PayloadPreferencesDocAccessFields_User_Create>;
  delete?: Maybe<PayloadPreferencesDocAccessFields_User_Delete>;
  read?: Maybe<PayloadPreferencesDocAccessFields_User_Read>;
  update?: Maybe<PayloadPreferencesDocAccessFields_User_Update>;
};

export type PayloadPreferencesDocAccessFields_User_Create = {
  __typename?: 'PayloadPreferencesDocAccessFields_user_Create';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesDocAccessFields_User_Delete = {
  __typename?: 'PayloadPreferencesDocAccessFields_user_Delete';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesDocAccessFields_User_Read = {
  __typename?: 'PayloadPreferencesDocAccessFields_user_Read';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesDocAccessFields_User_Update = {
  __typename?: 'PayloadPreferencesDocAccessFields_user_Update';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesDocAccessFields_Value = {
  __typename?: 'PayloadPreferencesDocAccessFields_value';
  create?: Maybe<PayloadPreferencesDocAccessFields_Value_Create>;
  delete?: Maybe<PayloadPreferencesDocAccessFields_Value_Delete>;
  read?: Maybe<PayloadPreferencesDocAccessFields_Value_Read>;
  update?: Maybe<PayloadPreferencesDocAccessFields_Value_Update>;
};

export type PayloadPreferencesDocAccessFields_Value_Create = {
  __typename?: 'PayloadPreferencesDocAccessFields_value_Create';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesDocAccessFields_Value_Delete = {
  __typename?: 'PayloadPreferencesDocAccessFields_value_Delete';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesDocAccessFields_Value_Read = {
  __typename?: 'PayloadPreferencesDocAccessFields_value_Read';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesDocAccessFields_Value_Update = {
  __typename?: 'PayloadPreferencesDocAccessFields_value_Update';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesFields = {
  __typename?: 'PayloadPreferencesFields';
  createdAt?: Maybe<PayloadPreferencesFields_CreatedAt>;
  key?: Maybe<PayloadPreferencesFields_Key>;
  updatedAt?: Maybe<PayloadPreferencesFields_UpdatedAt>;
  user?: Maybe<PayloadPreferencesFields_User>;
  value?: Maybe<PayloadPreferencesFields_Value>;
};

export type PayloadPreferencesFields_CreatedAt = {
  __typename?: 'PayloadPreferencesFields_createdAt';
  create?: Maybe<PayloadPreferencesFields_CreatedAt_Create>;
  delete?: Maybe<PayloadPreferencesFields_CreatedAt_Delete>;
  read?: Maybe<PayloadPreferencesFields_CreatedAt_Read>;
  update?: Maybe<PayloadPreferencesFields_CreatedAt_Update>;
};

export type PayloadPreferencesFields_CreatedAt_Create = {
  __typename?: 'PayloadPreferencesFields_createdAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesFields_CreatedAt_Delete = {
  __typename?: 'PayloadPreferencesFields_createdAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesFields_CreatedAt_Read = {
  __typename?: 'PayloadPreferencesFields_createdAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesFields_CreatedAt_Update = {
  __typename?: 'PayloadPreferencesFields_createdAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesFields_Key = {
  __typename?: 'PayloadPreferencesFields_key';
  create?: Maybe<PayloadPreferencesFields_Key_Create>;
  delete?: Maybe<PayloadPreferencesFields_Key_Delete>;
  read?: Maybe<PayloadPreferencesFields_Key_Read>;
  update?: Maybe<PayloadPreferencesFields_Key_Update>;
};

export type PayloadPreferencesFields_Key_Create = {
  __typename?: 'PayloadPreferencesFields_key_Create';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesFields_Key_Delete = {
  __typename?: 'PayloadPreferencesFields_key_Delete';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesFields_Key_Read = {
  __typename?: 'PayloadPreferencesFields_key_Read';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesFields_Key_Update = {
  __typename?: 'PayloadPreferencesFields_key_Update';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesFields_UpdatedAt = {
  __typename?: 'PayloadPreferencesFields_updatedAt';
  create?: Maybe<PayloadPreferencesFields_UpdatedAt_Create>;
  delete?: Maybe<PayloadPreferencesFields_UpdatedAt_Delete>;
  read?: Maybe<PayloadPreferencesFields_UpdatedAt_Read>;
  update?: Maybe<PayloadPreferencesFields_UpdatedAt_Update>;
};

export type PayloadPreferencesFields_UpdatedAt_Create = {
  __typename?: 'PayloadPreferencesFields_updatedAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesFields_UpdatedAt_Delete = {
  __typename?: 'PayloadPreferencesFields_updatedAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesFields_UpdatedAt_Read = {
  __typename?: 'PayloadPreferencesFields_updatedAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesFields_UpdatedAt_Update = {
  __typename?: 'PayloadPreferencesFields_updatedAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesFields_User = {
  __typename?: 'PayloadPreferencesFields_user';
  create?: Maybe<PayloadPreferencesFields_User_Create>;
  delete?: Maybe<PayloadPreferencesFields_User_Delete>;
  read?: Maybe<PayloadPreferencesFields_User_Read>;
  update?: Maybe<PayloadPreferencesFields_User_Update>;
};

export type PayloadPreferencesFields_User_Create = {
  __typename?: 'PayloadPreferencesFields_user_Create';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesFields_User_Delete = {
  __typename?: 'PayloadPreferencesFields_user_Delete';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesFields_User_Read = {
  __typename?: 'PayloadPreferencesFields_user_Read';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesFields_User_Update = {
  __typename?: 'PayloadPreferencesFields_user_Update';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesFields_Value = {
  __typename?: 'PayloadPreferencesFields_value';
  create?: Maybe<PayloadPreferencesFields_Value_Create>;
  delete?: Maybe<PayloadPreferencesFields_Value_Delete>;
  read?: Maybe<PayloadPreferencesFields_Value_Read>;
  update?: Maybe<PayloadPreferencesFields_Value_Update>;
};

export type PayloadPreferencesFields_Value_Create = {
  __typename?: 'PayloadPreferencesFields_value_Create';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesFields_Value_Delete = {
  __typename?: 'PayloadPreferencesFields_value_Delete';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesFields_Value_Read = {
  __typename?: 'PayloadPreferencesFields_value_Read';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesFields_Value_Update = {
  __typename?: 'PayloadPreferencesFields_value_Update';
  permission: Scalars['Boolean']['output'];
};

export type PayloadPreferencesReadAccess = {
  __typename?: 'PayloadPreferencesReadAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type PayloadPreferencesReadDocAccess = {
  __typename?: 'PayloadPreferencesReadDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type PayloadPreferencesUpdateAccess = {
  __typename?: 'PayloadPreferencesUpdateAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type PayloadPreferencesUpdateDocAccess = {
  __typename?: 'PayloadPreferencesUpdateDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type Query = {
  __typename?: 'Query';
  Access?: Maybe<Access>;
  Article?: Maybe<Article>;
  Articles?: Maybe<Articles>;
  Categories?: Maybe<Categories>;
  Category?: Maybe<Category>;
  Media?: Maybe<Media>;
  PayloadPreference?: Maybe<PayloadPreference>;
  PayloadPreferences?: Maybe<PayloadPreferences>;
  TaskTemplate?: Maybe<TaskTemplate>;
  TaskTemplates?: Maybe<TaskTemplates>;
  User?: Maybe<User>;
  Users?: Maybe<Users>;
  allMedia?: Maybe<AllMedia>;
  docAccessArticle?: Maybe<ArticlesDocAccess>;
  docAccessCategory?: Maybe<CategoriesDocAccess>;
  docAccessMedia?: Maybe<MediaDocAccess>;
  docAccessPayloadPreference?: Maybe<Payload_PreferencesDocAccess>;
  docAccessTaskTemplate?: Maybe<Task_TemplatesDocAccess>;
  docAccessUser?: Maybe<UsersDocAccess>;
  initializedUser?: Maybe<Scalars['Boolean']['output']>;
  meUser?: Maybe<UsersMe>;
  versionArticle?: Maybe<ArticleVersion>;
  versionTaskTemplate?: Maybe<TaskTemplateVersion>;
  versionsArticles?: Maybe<VersionsArticles>;
  versionsTaskTemplates?: Maybe<VersionsTaskTemplates>;
};


export type QueryArticleArgs = {
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
};


export type QueryArticlesArgs = {
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<Article_Where>;
};


export type QueryCategoriesArgs = {
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<Category_Where>;
};


export type QueryCategoryArgs = {
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
};


export type QueryMediaArgs = {
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
};


export type QueryPayloadPreferenceArgs = {
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
};


export type QueryPayloadPreferencesArgs = {
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<PayloadPreference_Where>;
};


export type QueryTaskTemplateArgs = {
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
};


export type QueryTaskTemplatesArgs = {
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<TaskTemplate_Where>;
};


export type QueryUserArgs = {
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
};


export type QueryUsersArgs = {
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<User_Where>;
};


export type QueryAllMediaArgs = {
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<Media_Where>;
};


export type QueryDocAccessArticleArgs = {
  id: Scalars['String']['input'];
};


export type QueryDocAccessCategoryArgs = {
  id: Scalars['String']['input'];
};


export type QueryDocAccessMediaArgs = {
  id: Scalars['String']['input'];
};


export type QueryDocAccessPayloadPreferenceArgs = {
  id: Scalars['String']['input'];
};


export type QueryDocAccessTaskTemplateArgs = {
  id: Scalars['String']['input'];
};


export type QueryDocAccessUserArgs = {
  id: Scalars['String']['input'];
};


export type QueryVersionArticleArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryVersionTaskTemplateArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryVersionsArticlesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<VersionsArticle_Where>;
};


export type QueryVersionsTaskTemplatesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<VersionsTaskTemplate_Where>;
};

export type TaskTemplate = {
  __typename?: 'TaskTemplate';
  _status?: Maybe<TaskTemplate__Status>;
  assignment?: Maybe<TaskTemplate_Assignment>;
  categories?: Maybe<Array<TaskTemplate_Categories>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  details?: Maybe<Scalars['JSON']['output']>;
  feature?: Maybe<TaskTemplate_Feature>;
  id?: Maybe<Scalars['String']['output']>;
  resources?: Maybe<TaskTemplate_Resources>;
  review?: Maybe<TaskTemplate_Review>;
  schedule?: Maybe<Array<TaskTemplate_Schedule>>;
  thumbnail?: Maybe<Media>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};


export type TaskTemplateDetailsArgs = {
  depth?: InputMaybe<Scalars['Int']['input']>;
};


export type TaskTemplateThumbnailArgs = {
  where?: InputMaybe<TaskTemplate_Thumbnail_Where>;
};

export enum TaskTemplateUpdate_Review_Approver_MutationInput {
  Manager = 'manager'
}

export enum TaskTemplateUpdate_Schedule_Recurrence_Freq_MutationInput {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY',
  Yearly = 'YEARLY'
}

export enum TaskTemplateUpdate_Schedule_Event_MutationInput {
  MoveIn = 'move_in',
  MoveOut = 'move_out'
}

export enum TaskTemplateUpdate__Status_MutationInput {
  Draft = 'draft',
  Published = 'published'
}

export enum TaskTemplateUpdate_Assignment_MutationInput {
  Manager = 'Manager',
  Tenants = 'Tenants'
}

export enum TaskTemplateUpdate_Categories_MutationInput {
  Administrative = 'Administrative',
  Legal = 'Legal',
  Maintenance = 'Maintenance',
  Safety = 'Safety',
  Turnover = 'Turnover'
}

export enum TaskTemplateUpdate_Feature_MutationInput {
  Basement = 'basement',
  Garage = 'garage',
  HotTub = 'hot_tub',
  HotWater = 'hot_water',
  Hvac = 'hvac',
  Kitchen = 'kitchen',
  Laundry = 'laundry',
  Lawn = 'lawn',
  Pool = 'pool',
  Structural = 'structural',
  SumpPump = 'sump_pump',
  WaterSoftener = 'water_softener'
}

export type TaskTemplateVersion = {
  __typename?: 'TaskTemplateVersion';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  latest?: Maybe<Scalars['Boolean']['output']>;
  parent?: Maybe<TaskTemplate>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  version?: Maybe<TaskTemplateVersion_Version>;
};

export type TaskTemplateVersion_Version = {
  __typename?: 'TaskTemplateVersion_Version';
  _status?: Maybe<TaskTemplateVersion_Version__Status>;
  assignment?: Maybe<TaskTemplateVersion_Version_Assignment>;
  categories?: Maybe<Array<TaskTemplateVersion_Version_Categories>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  details?: Maybe<Scalars['JSON']['output']>;
  feature?: Maybe<TaskTemplateVersion_Version_Feature>;
  resources?: Maybe<TaskTemplateVersion_Version_Resources>;
  review?: Maybe<TaskTemplateVersion_Version_Review>;
  schedule?: Maybe<Array<TaskTemplateVersion_Version_Schedule>>;
  thumbnail?: Maybe<Media>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};


export type TaskTemplateVersion_VersionDetailsArgs = {
  depth?: InputMaybe<Scalars['Int']['input']>;
};


export type TaskTemplateVersion_VersionThumbnailArgs = {
  where?: InputMaybe<TaskTemplateVersion_Version_Thumbnail_Where>;
};

export type TaskTemplateVersion_Version_Resources = {
  __typename?: 'TaskTemplateVersion_Version_Resources';
  articles?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  videos?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type TaskTemplateVersion_Version_Review = {
  __typename?: 'TaskTemplateVersion_Version_Review';
  approver?: Maybe<TaskTemplateVersion_Version_Review_Approver>;
  evidence?: Maybe<Scalars['Boolean']['output']>;
  required?: Maybe<Scalars['Boolean']['output']>;
};

export enum TaskTemplateVersion_Version_Review_Approver {
  Manager = 'manager'
}

export type TaskTemplateVersion_Version_Schedule = {
  __typename?: 'TaskTemplateVersion_Version_Schedule';
  due?: Maybe<Scalars['Float']['output']>;
  event?: Maybe<TaskTemplateVersion_Version_Schedule_Event>;
  id?: Maybe<Scalars['String']['output']>;
  recurrence?: Maybe<TaskTemplateVersion_Version_Schedule_Recurrence>;
};

export type TaskTemplateVersion_Version_Schedule_Recurrence = {
  __typename?: 'TaskTemplateVersion_Version_Schedule_Recurrence';
  freq?: Maybe<TaskTemplateVersion_Version_Schedule_Recurrence_Freq>;
  interval?: Maybe<Scalars['Float']['output']>;
};

export enum TaskTemplateVersion_Version_Schedule_Recurrence_Freq {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY',
  Yearly = 'YEARLY'
}

export enum TaskTemplateVersion_Version_Schedule_Event {
  MoveIn = 'move_in',
  MoveOut = 'move_out'
}

export type TaskTemplateVersion_Version_Thumbnail_Alt_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TaskTemplateVersion_Version_Thumbnail_Caption_Operator = {
  contains?: InputMaybe<Scalars['JSON']['input']>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<Scalars['JSON']['input']>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
};

export type TaskTemplateVersion_Version_Thumbnail_CreatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TaskTemplateVersion_Version_Thumbnail_Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TaskTemplateVersion_Version_Thumbnail_Filesize_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type TaskTemplateVersion_Version_Thumbnail_Height_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type TaskTemplateVersion_Version_Thumbnail_Id_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TaskTemplateVersion_Version_Thumbnail_MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TaskTemplateVersion_Version_Thumbnail_UpdatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TaskTemplateVersion_Version_Thumbnail_Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TaskTemplateVersion_Version_Thumbnail_Where = {
  AND?: InputMaybe<Array<InputMaybe<TaskTemplateVersion_Version_Thumbnail_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<TaskTemplateVersion_Version_Thumbnail_Where_Or>>>;
  alt?: InputMaybe<TaskTemplateVersion_Version_Thumbnail_Alt_Operator>;
  caption?: InputMaybe<TaskTemplateVersion_Version_Thumbnail_Caption_Operator>;
  createdAt?: InputMaybe<TaskTemplateVersion_Version_Thumbnail_CreatedAt_Operator>;
  filename?: InputMaybe<TaskTemplateVersion_Version_Thumbnail_Filename_Operator>;
  filesize?: InputMaybe<TaskTemplateVersion_Version_Thumbnail_Filesize_Operator>;
  height?: InputMaybe<TaskTemplateVersion_Version_Thumbnail_Height_Operator>;
  id?: InputMaybe<TaskTemplateVersion_Version_Thumbnail_Id_Operator>;
  mimeType?: InputMaybe<TaskTemplateVersion_Version_Thumbnail_MimeType_Operator>;
  updatedAt?: InputMaybe<TaskTemplateVersion_Version_Thumbnail_UpdatedAt_Operator>;
  url?: InputMaybe<TaskTemplateVersion_Version_Thumbnail_Url_Operator>;
  width?: InputMaybe<TaskTemplateVersion_Version_Thumbnail_Width_Operator>;
};

export type TaskTemplateVersion_Version_Thumbnail_Where_And = {
  AND?: InputMaybe<Array<InputMaybe<TaskTemplateVersion_Version_Thumbnail_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<TaskTemplateVersion_Version_Thumbnail_Where_Or>>>;
  alt?: InputMaybe<TaskTemplateVersion_Version_Thumbnail_Alt_Operator>;
  caption?: InputMaybe<TaskTemplateVersion_Version_Thumbnail_Caption_Operator>;
  createdAt?: InputMaybe<TaskTemplateVersion_Version_Thumbnail_CreatedAt_Operator>;
  filename?: InputMaybe<TaskTemplateVersion_Version_Thumbnail_Filename_Operator>;
  filesize?: InputMaybe<TaskTemplateVersion_Version_Thumbnail_Filesize_Operator>;
  height?: InputMaybe<TaskTemplateVersion_Version_Thumbnail_Height_Operator>;
  id?: InputMaybe<TaskTemplateVersion_Version_Thumbnail_Id_Operator>;
  mimeType?: InputMaybe<TaskTemplateVersion_Version_Thumbnail_MimeType_Operator>;
  updatedAt?: InputMaybe<TaskTemplateVersion_Version_Thumbnail_UpdatedAt_Operator>;
  url?: InputMaybe<TaskTemplateVersion_Version_Thumbnail_Url_Operator>;
  width?: InputMaybe<TaskTemplateVersion_Version_Thumbnail_Width_Operator>;
};

export type TaskTemplateVersion_Version_Thumbnail_Where_Or = {
  AND?: InputMaybe<Array<InputMaybe<TaskTemplateVersion_Version_Thumbnail_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<TaskTemplateVersion_Version_Thumbnail_Where_Or>>>;
  alt?: InputMaybe<TaskTemplateVersion_Version_Thumbnail_Alt_Operator>;
  caption?: InputMaybe<TaskTemplateVersion_Version_Thumbnail_Caption_Operator>;
  createdAt?: InputMaybe<TaskTemplateVersion_Version_Thumbnail_CreatedAt_Operator>;
  filename?: InputMaybe<TaskTemplateVersion_Version_Thumbnail_Filename_Operator>;
  filesize?: InputMaybe<TaskTemplateVersion_Version_Thumbnail_Filesize_Operator>;
  height?: InputMaybe<TaskTemplateVersion_Version_Thumbnail_Height_Operator>;
  id?: InputMaybe<TaskTemplateVersion_Version_Thumbnail_Id_Operator>;
  mimeType?: InputMaybe<TaskTemplateVersion_Version_Thumbnail_MimeType_Operator>;
  updatedAt?: InputMaybe<TaskTemplateVersion_Version_Thumbnail_UpdatedAt_Operator>;
  url?: InputMaybe<TaskTemplateVersion_Version_Thumbnail_Url_Operator>;
  width?: InputMaybe<TaskTemplateVersion_Version_Thumbnail_Width_Operator>;
};

export type TaskTemplateVersion_Version_Thumbnail_Width_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export enum TaskTemplateVersion_Version__Status {
  Draft = 'draft',
  Published = 'published'
}

export enum TaskTemplateVersion_Version_Assignment {
  Manager = 'Manager',
  Tenants = 'Tenants'
}

export enum TaskTemplateVersion_Version_Categories {
  Administrative = 'Administrative',
  Legal = 'Legal',
  Maintenance = 'Maintenance',
  Safety = 'Safety',
  Turnover = 'Turnover'
}

export enum TaskTemplateVersion_Version_Feature {
  Basement = 'basement',
  Garage = 'garage',
  HotTub = 'hot_tub',
  HotWater = 'hot_water',
  Hvac = 'hvac',
  Kitchen = 'kitchen',
  Laundry = 'laundry',
  Lawn = 'lawn',
  Pool = 'pool',
  Structural = 'structural',
  SumpPump = 'sump_pump',
  WaterSoftener = 'water_softener'
}

export type TaskTemplate_Resources = {
  __typename?: 'TaskTemplate_Resources';
  articles?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  videos?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type TaskTemplate_Review = {
  __typename?: 'TaskTemplate_Review';
  approver?: Maybe<TaskTemplate_Review_Approver>;
  evidence?: Maybe<Scalars['Boolean']['output']>;
  required?: Maybe<Scalars['Boolean']['output']>;
};

export enum TaskTemplate_Review_Approver {
  Manager = 'manager'
}

export enum TaskTemplate_Review_Approver_MutationInput {
  Manager = 'manager'
}

export type TaskTemplate_Schedule = {
  __typename?: 'TaskTemplate_Schedule';
  due?: Maybe<Scalars['Float']['output']>;
  event?: Maybe<TaskTemplate_Schedule_Event>;
  id?: Maybe<Scalars['String']['output']>;
  recurrence?: Maybe<TaskTemplate_Schedule_Recurrence>;
};

export type TaskTemplate_Schedule_Recurrence = {
  __typename?: 'TaskTemplate_Schedule_Recurrence';
  freq?: Maybe<TaskTemplate_Schedule_Recurrence_Freq>;
  interval?: Maybe<Scalars['Float']['output']>;
};

export enum TaskTemplate_Schedule_Recurrence_Freq {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY',
  Yearly = 'YEARLY'
}

export enum TaskTemplate_Schedule_Recurrence_Freq_MutationInput {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY',
  Yearly = 'YEARLY'
}

export enum TaskTemplate_Schedule_Event {
  MoveIn = 'move_in',
  MoveOut = 'move_out'
}

export enum TaskTemplate_Schedule_Event_MutationInput {
  MoveIn = 'move_in',
  MoveOut = 'move_out'
}

export type TaskTemplate_Thumbnail_Alt_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TaskTemplate_Thumbnail_Caption_Operator = {
  contains?: InputMaybe<Scalars['JSON']['input']>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<Scalars['JSON']['input']>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
};

export type TaskTemplate_Thumbnail_CreatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TaskTemplate_Thumbnail_Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TaskTemplate_Thumbnail_Filesize_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type TaskTemplate_Thumbnail_Height_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type TaskTemplate_Thumbnail_Id_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TaskTemplate_Thumbnail_MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TaskTemplate_Thumbnail_UpdatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TaskTemplate_Thumbnail_Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TaskTemplate_Thumbnail_Where = {
  AND?: InputMaybe<Array<InputMaybe<TaskTemplate_Thumbnail_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<TaskTemplate_Thumbnail_Where_Or>>>;
  alt?: InputMaybe<TaskTemplate_Thumbnail_Alt_Operator>;
  caption?: InputMaybe<TaskTemplate_Thumbnail_Caption_Operator>;
  createdAt?: InputMaybe<TaskTemplate_Thumbnail_CreatedAt_Operator>;
  filename?: InputMaybe<TaskTemplate_Thumbnail_Filename_Operator>;
  filesize?: InputMaybe<TaskTemplate_Thumbnail_Filesize_Operator>;
  height?: InputMaybe<TaskTemplate_Thumbnail_Height_Operator>;
  id?: InputMaybe<TaskTemplate_Thumbnail_Id_Operator>;
  mimeType?: InputMaybe<TaskTemplate_Thumbnail_MimeType_Operator>;
  updatedAt?: InputMaybe<TaskTemplate_Thumbnail_UpdatedAt_Operator>;
  url?: InputMaybe<TaskTemplate_Thumbnail_Url_Operator>;
  width?: InputMaybe<TaskTemplate_Thumbnail_Width_Operator>;
};

export type TaskTemplate_Thumbnail_Where_And = {
  AND?: InputMaybe<Array<InputMaybe<TaskTemplate_Thumbnail_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<TaskTemplate_Thumbnail_Where_Or>>>;
  alt?: InputMaybe<TaskTemplate_Thumbnail_Alt_Operator>;
  caption?: InputMaybe<TaskTemplate_Thumbnail_Caption_Operator>;
  createdAt?: InputMaybe<TaskTemplate_Thumbnail_CreatedAt_Operator>;
  filename?: InputMaybe<TaskTemplate_Thumbnail_Filename_Operator>;
  filesize?: InputMaybe<TaskTemplate_Thumbnail_Filesize_Operator>;
  height?: InputMaybe<TaskTemplate_Thumbnail_Height_Operator>;
  id?: InputMaybe<TaskTemplate_Thumbnail_Id_Operator>;
  mimeType?: InputMaybe<TaskTemplate_Thumbnail_MimeType_Operator>;
  updatedAt?: InputMaybe<TaskTemplate_Thumbnail_UpdatedAt_Operator>;
  url?: InputMaybe<TaskTemplate_Thumbnail_Url_Operator>;
  width?: InputMaybe<TaskTemplate_Thumbnail_Width_Operator>;
};

export type TaskTemplate_Thumbnail_Where_Or = {
  AND?: InputMaybe<Array<InputMaybe<TaskTemplate_Thumbnail_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<TaskTemplate_Thumbnail_Where_Or>>>;
  alt?: InputMaybe<TaskTemplate_Thumbnail_Alt_Operator>;
  caption?: InputMaybe<TaskTemplate_Thumbnail_Caption_Operator>;
  createdAt?: InputMaybe<TaskTemplate_Thumbnail_CreatedAt_Operator>;
  filename?: InputMaybe<TaskTemplate_Thumbnail_Filename_Operator>;
  filesize?: InputMaybe<TaskTemplate_Thumbnail_Filesize_Operator>;
  height?: InputMaybe<TaskTemplate_Thumbnail_Height_Operator>;
  id?: InputMaybe<TaskTemplate_Thumbnail_Id_Operator>;
  mimeType?: InputMaybe<TaskTemplate_Thumbnail_MimeType_Operator>;
  updatedAt?: InputMaybe<TaskTemplate_Thumbnail_UpdatedAt_Operator>;
  url?: InputMaybe<TaskTemplate_Thumbnail_Url_Operator>;
  width?: InputMaybe<TaskTemplate_Thumbnail_Width_Operator>;
};

export type TaskTemplate_Thumbnail_Width_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export enum TaskTemplate__Status {
  Draft = 'draft',
  Published = 'published'
}

export enum TaskTemplate__Status_Input {
  Draft = 'draft',
  Published = 'published'
}

export enum TaskTemplate__Status_MutationInput {
  Draft = 'draft',
  Published = 'published'
}

export type TaskTemplate__Status_Operator = {
  all?: InputMaybe<Array<InputMaybe<TaskTemplate__Status_Input>>>;
  equals?: InputMaybe<TaskTemplate__Status_Input>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<TaskTemplate__Status_Input>>>;
  not_equals?: InputMaybe<TaskTemplate__Status_Input>;
  not_in?: InputMaybe<Array<InputMaybe<TaskTemplate__Status_Input>>>;
};

export enum TaskTemplate_Assignment {
  Manager = 'Manager',
  Tenants = 'Tenants'
}

export enum TaskTemplate_Assignment_Input {
  Manager = 'Manager',
  Tenants = 'Tenants'
}

export enum TaskTemplate_Assignment_MutationInput {
  Manager = 'Manager',
  Tenants = 'Tenants'
}

export type TaskTemplate_Assignment_Operator = {
  all?: InputMaybe<Array<InputMaybe<TaskTemplate_Assignment_Input>>>;
  equals?: InputMaybe<TaskTemplate_Assignment_Input>;
  in?: InputMaybe<Array<InputMaybe<TaskTemplate_Assignment_Input>>>;
  not_equals?: InputMaybe<TaskTemplate_Assignment_Input>;
  not_in?: InputMaybe<Array<InputMaybe<TaskTemplate_Assignment_Input>>>;
};

export enum TaskTemplate_Categories {
  Administrative = 'Administrative',
  Legal = 'Legal',
  Maintenance = 'Maintenance',
  Safety = 'Safety',
  Turnover = 'Turnover'
}

export enum TaskTemplate_Categories_Input {
  Administrative = 'Administrative',
  Legal = 'Legal',
  Maintenance = 'Maintenance',
  Safety = 'Safety',
  Turnover = 'Turnover'
}

export enum TaskTemplate_Categories_MutationInput {
  Administrative = 'Administrative',
  Legal = 'Legal',
  Maintenance = 'Maintenance',
  Safety = 'Safety',
  Turnover = 'Turnover'
}

export type TaskTemplate_Categories_Operator = {
  all?: InputMaybe<Array<InputMaybe<TaskTemplate_Categories_Input>>>;
  equals?: InputMaybe<TaskTemplate_Categories_Input>;
  in?: InputMaybe<Array<InputMaybe<TaskTemplate_Categories_Input>>>;
  not_equals?: InputMaybe<TaskTemplate_Categories_Input>;
  not_in?: InputMaybe<Array<InputMaybe<TaskTemplate_Categories_Input>>>;
};

export type TaskTemplate_CreatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TaskTemplate_Description_Operator = {
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
};

export type TaskTemplate_Details_Operator = {
  contains?: InputMaybe<Scalars['JSON']['input']>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  like?: InputMaybe<Scalars['JSON']['input']>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
};

export enum TaskTemplate_Feature {
  Basement = 'basement',
  Garage = 'garage',
  HotTub = 'hot_tub',
  HotWater = 'hot_water',
  Hvac = 'hvac',
  Kitchen = 'kitchen',
  Laundry = 'laundry',
  Lawn = 'lawn',
  Pool = 'pool',
  Structural = 'structural',
  SumpPump = 'sump_pump',
  WaterSoftener = 'water_softener'
}

export enum TaskTemplate_Feature_Input {
  Basement = 'basement',
  Garage = 'garage',
  HotTub = 'hot_tub',
  HotWater = 'hot_water',
  Hvac = 'hvac',
  Kitchen = 'kitchen',
  Laundry = 'laundry',
  Lawn = 'lawn',
  Pool = 'pool',
  Structural = 'structural',
  SumpPump = 'sump_pump',
  WaterSoftener = 'water_softener'
}

export enum TaskTemplate_Feature_MutationInput {
  Basement = 'basement',
  Garage = 'garage',
  HotTub = 'hot_tub',
  HotWater = 'hot_water',
  Hvac = 'hvac',
  Kitchen = 'kitchen',
  Laundry = 'laundry',
  Lawn = 'lawn',
  Pool = 'pool',
  Structural = 'structural',
  SumpPump = 'sump_pump',
  WaterSoftener = 'water_softener'
}

export type TaskTemplate_Feature_Operator = {
  all?: InputMaybe<Array<InputMaybe<TaskTemplate_Feature_Input>>>;
  equals?: InputMaybe<TaskTemplate_Feature_Input>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<TaskTemplate_Feature_Input>>>;
  not_equals?: InputMaybe<TaskTemplate_Feature_Input>;
  not_in?: InputMaybe<Array<InputMaybe<TaskTemplate_Feature_Input>>>;
};

export type TaskTemplate_Id_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export enum TaskTemplate_Recurrence__Freq_Input {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY',
  Yearly = 'YEARLY'
}

export type TaskTemplate_Recurrence__Freq_Operator = {
  all?: InputMaybe<Array<InputMaybe<TaskTemplate_Recurrence__Freq_Input>>>;
  equals?: InputMaybe<TaskTemplate_Recurrence__Freq_Input>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<TaskTemplate_Recurrence__Freq_Input>>>;
  not_equals?: InputMaybe<TaskTemplate_Recurrence__Freq_Input>;
  not_in?: InputMaybe<Array<InputMaybe<TaskTemplate_Recurrence__Freq_Input>>>;
};

export type TaskTemplate_Recurrence__Interval_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type TaskTemplate_Resources__Articles_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TaskTemplate_Resources__Videos_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export enum TaskTemplate_Review__Approver_Input {
  Manager = 'manager'
}

export type TaskTemplate_Review__Approver_Operator = {
  all?: InputMaybe<Array<InputMaybe<TaskTemplate_Review__Approver_Input>>>;
  equals?: InputMaybe<TaskTemplate_Review__Approver_Input>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<TaskTemplate_Review__Approver_Input>>>;
  not_equals?: InputMaybe<TaskTemplate_Review__Approver_Input>;
  not_in?: InputMaybe<Array<InputMaybe<TaskTemplate_Review__Approver_Input>>>;
};

export type TaskTemplate_Review__Evidence_Operator = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  not_equals?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TaskTemplate_Review__Required_Operator = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  not_equals?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TaskTemplate_Schedule__Due_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export enum TaskTemplate_Schedule__Event_Input {
  MoveIn = 'move_in',
  MoveOut = 'move_out'
}

export type TaskTemplate_Schedule__Event_Operator = {
  all?: InputMaybe<Array<InputMaybe<TaskTemplate_Schedule__Event_Input>>>;
  equals?: InputMaybe<TaskTemplate_Schedule__Event_Input>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<TaskTemplate_Schedule__Event_Input>>>;
  not_equals?: InputMaybe<TaskTemplate_Schedule__Event_Input>;
  not_in?: InputMaybe<Array<InputMaybe<TaskTemplate_Schedule__Event_Input>>>;
};

export type TaskTemplate_Schedule__Id_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TaskTemplate_Thumbnail_Operator = {
  equals?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
};

export type TaskTemplate_Title_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TaskTemplate_UpdatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TaskTemplate_Where = {
  AND?: InputMaybe<Array<InputMaybe<TaskTemplate_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<TaskTemplate_Where_Or>>>;
  _status?: InputMaybe<TaskTemplate__Status_Operator>;
  assignment?: InputMaybe<TaskTemplate_Assignment_Operator>;
  categories?: InputMaybe<TaskTemplate_Categories_Operator>;
  createdAt?: InputMaybe<TaskTemplate_CreatedAt_Operator>;
  description?: InputMaybe<TaskTemplate_Description_Operator>;
  details?: InputMaybe<TaskTemplate_Details_Operator>;
  feature?: InputMaybe<TaskTemplate_Feature_Operator>;
  id?: InputMaybe<TaskTemplate_Id_Operator>;
  recurrence__freq?: InputMaybe<TaskTemplate_Recurrence__Freq_Operator>;
  recurrence__interval?: InputMaybe<TaskTemplate_Recurrence__Interval_Operator>;
  resources__articles?: InputMaybe<TaskTemplate_Resources__Articles_Operator>;
  resources__videos?: InputMaybe<TaskTemplate_Resources__Videos_Operator>;
  review__approver?: InputMaybe<TaskTemplate_Review__Approver_Operator>;
  review__evidence?: InputMaybe<TaskTemplate_Review__Evidence_Operator>;
  review__required?: InputMaybe<TaskTemplate_Review__Required_Operator>;
  schedule__due?: InputMaybe<TaskTemplate_Schedule__Due_Operator>;
  schedule__event?: InputMaybe<TaskTemplate_Schedule__Event_Operator>;
  schedule__id?: InputMaybe<TaskTemplate_Schedule__Id_Operator>;
  thumbnail?: InputMaybe<TaskTemplate_Thumbnail_Operator>;
  title?: InputMaybe<TaskTemplate_Title_Operator>;
  updatedAt?: InputMaybe<TaskTemplate_UpdatedAt_Operator>;
};

export type TaskTemplate_Where_And = {
  AND?: InputMaybe<Array<InputMaybe<TaskTemplate_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<TaskTemplate_Where_Or>>>;
  _status?: InputMaybe<TaskTemplate__Status_Operator>;
  assignment?: InputMaybe<TaskTemplate_Assignment_Operator>;
  categories?: InputMaybe<TaskTemplate_Categories_Operator>;
  createdAt?: InputMaybe<TaskTemplate_CreatedAt_Operator>;
  description?: InputMaybe<TaskTemplate_Description_Operator>;
  details?: InputMaybe<TaskTemplate_Details_Operator>;
  feature?: InputMaybe<TaskTemplate_Feature_Operator>;
  id?: InputMaybe<TaskTemplate_Id_Operator>;
  recurrence__freq?: InputMaybe<TaskTemplate_Recurrence__Freq_Operator>;
  recurrence__interval?: InputMaybe<TaskTemplate_Recurrence__Interval_Operator>;
  resources__articles?: InputMaybe<TaskTemplate_Resources__Articles_Operator>;
  resources__videos?: InputMaybe<TaskTemplate_Resources__Videos_Operator>;
  review__approver?: InputMaybe<TaskTemplate_Review__Approver_Operator>;
  review__evidence?: InputMaybe<TaskTemplate_Review__Evidence_Operator>;
  review__required?: InputMaybe<TaskTemplate_Review__Required_Operator>;
  schedule__due?: InputMaybe<TaskTemplate_Schedule__Due_Operator>;
  schedule__event?: InputMaybe<TaskTemplate_Schedule__Event_Operator>;
  schedule__id?: InputMaybe<TaskTemplate_Schedule__Id_Operator>;
  thumbnail?: InputMaybe<TaskTemplate_Thumbnail_Operator>;
  title?: InputMaybe<TaskTemplate_Title_Operator>;
  updatedAt?: InputMaybe<TaskTemplate_UpdatedAt_Operator>;
};

export type TaskTemplate_Where_Or = {
  AND?: InputMaybe<Array<InputMaybe<TaskTemplate_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<TaskTemplate_Where_Or>>>;
  _status?: InputMaybe<TaskTemplate__Status_Operator>;
  assignment?: InputMaybe<TaskTemplate_Assignment_Operator>;
  categories?: InputMaybe<TaskTemplate_Categories_Operator>;
  createdAt?: InputMaybe<TaskTemplate_CreatedAt_Operator>;
  description?: InputMaybe<TaskTemplate_Description_Operator>;
  details?: InputMaybe<TaskTemplate_Details_Operator>;
  feature?: InputMaybe<TaskTemplate_Feature_Operator>;
  id?: InputMaybe<TaskTemplate_Id_Operator>;
  recurrence__freq?: InputMaybe<TaskTemplate_Recurrence__Freq_Operator>;
  recurrence__interval?: InputMaybe<TaskTemplate_Recurrence__Interval_Operator>;
  resources__articles?: InputMaybe<TaskTemplate_Resources__Articles_Operator>;
  resources__videos?: InputMaybe<TaskTemplate_Resources__Videos_Operator>;
  review__approver?: InputMaybe<TaskTemplate_Review__Approver_Operator>;
  review__evidence?: InputMaybe<TaskTemplate_Review__Evidence_Operator>;
  review__required?: InputMaybe<TaskTemplate_Review__Required_Operator>;
  schedule__due?: InputMaybe<TaskTemplate_Schedule__Due_Operator>;
  schedule__event?: InputMaybe<TaskTemplate_Schedule__Event_Operator>;
  schedule__id?: InputMaybe<TaskTemplate_Schedule__Id_Operator>;
  thumbnail?: InputMaybe<TaskTemplate_Thumbnail_Operator>;
  title?: InputMaybe<TaskTemplate_Title_Operator>;
  updatedAt?: InputMaybe<TaskTemplate_UpdatedAt_Operator>;
};

export type TaskTemplates = {
  __typename?: 'TaskTemplates';
  docs?: Maybe<Array<Maybe<TaskTemplate>>>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  nextPage?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  pagingCounter?: Maybe<Scalars['Int']['output']>;
  prevPage?: Maybe<Scalars['Int']['output']>;
  totalDocs?: Maybe<Scalars['Int']['output']>;
  totalPages?: Maybe<Scalars['Int']['output']>;
};

export type TaskTemplatesCreateAccess = {
  __typename?: 'TaskTemplatesCreateAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type TaskTemplatesCreateDocAccess = {
  __typename?: 'TaskTemplatesCreateDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type TaskTemplatesDeleteAccess = {
  __typename?: 'TaskTemplatesDeleteAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type TaskTemplatesDeleteDocAccess = {
  __typename?: 'TaskTemplatesDeleteDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type TaskTemplatesDocAccessFields = {
  __typename?: 'TaskTemplatesDocAccessFields';
  _status?: Maybe<TaskTemplatesDocAccessFields__Status>;
  assignment?: Maybe<TaskTemplatesDocAccessFields_Assignment>;
  categories?: Maybe<TaskTemplatesDocAccessFields_Categories>;
  createdAt?: Maybe<TaskTemplatesDocAccessFields_CreatedAt>;
  description?: Maybe<TaskTemplatesDocAccessFields_Description>;
  details?: Maybe<TaskTemplatesDocAccessFields_Details>;
  feature?: Maybe<TaskTemplatesDocAccessFields_Feature>;
  resources?: Maybe<TaskTemplatesDocAccessFields_Resources>;
  review?: Maybe<TaskTemplatesDocAccessFields_Review>;
  schedule?: Maybe<TaskTemplatesDocAccessFields_Schedule>;
  thumbnail?: Maybe<TaskTemplatesDocAccessFields_Thumbnail>;
  title?: Maybe<TaskTemplatesDocAccessFields_Title>;
  updatedAt?: Maybe<TaskTemplatesDocAccessFields_UpdatedAt>;
};

export type TaskTemplatesDocAccessFields__Status = {
  __typename?: 'TaskTemplatesDocAccessFields__status';
  create?: Maybe<TaskTemplatesDocAccessFields__Status_Create>;
  delete?: Maybe<TaskTemplatesDocAccessFields__Status_Delete>;
  read?: Maybe<TaskTemplatesDocAccessFields__Status_Read>;
  update?: Maybe<TaskTemplatesDocAccessFields__Status_Update>;
};

export type TaskTemplatesDocAccessFields__Status_Create = {
  __typename?: 'TaskTemplatesDocAccessFields__status_Create';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields__Status_Delete = {
  __typename?: 'TaskTemplatesDocAccessFields__status_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields__Status_Read = {
  __typename?: 'TaskTemplatesDocAccessFields__status_Read';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields__Status_Update = {
  __typename?: 'TaskTemplatesDocAccessFields__status_Update';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Assignment = {
  __typename?: 'TaskTemplatesDocAccessFields_assignment';
  create?: Maybe<TaskTemplatesDocAccessFields_Assignment_Create>;
  delete?: Maybe<TaskTemplatesDocAccessFields_Assignment_Delete>;
  read?: Maybe<TaskTemplatesDocAccessFields_Assignment_Read>;
  update?: Maybe<TaskTemplatesDocAccessFields_Assignment_Update>;
};

export type TaskTemplatesDocAccessFields_Assignment_Create = {
  __typename?: 'TaskTemplatesDocAccessFields_assignment_Create';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Assignment_Delete = {
  __typename?: 'TaskTemplatesDocAccessFields_assignment_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Assignment_Read = {
  __typename?: 'TaskTemplatesDocAccessFields_assignment_Read';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Assignment_Update = {
  __typename?: 'TaskTemplatesDocAccessFields_assignment_Update';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Categories = {
  __typename?: 'TaskTemplatesDocAccessFields_categories';
  create?: Maybe<TaskTemplatesDocAccessFields_Categories_Create>;
  delete?: Maybe<TaskTemplatesDocAccessFields_Categories_Delete>;
  read?: Maybe<TaskTemplatesDocAccessFields_Categories_Read>;
  update?: Maybe<TaskTemplatesDocAccessFields_Categories_Update>;
};

export type TaskTemplatesDocAccessFields_Categories_Create = {
  __typename?: 'TaskTemplatesDocAccessFields_categories_Create';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Categories_Delete = {
  __typename?: 'TaskTemplatesDocAccessFields_categories_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Categories_Read = {
  __typename?: 'TaskTemplatesDocAccessFields_categories_Read';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Categories_Update = {
  __typename?: 'TaskTemplatesDocAccessFields_categories_Update';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_CreatedAt = {
  __typename?: 'TaskTemplatesDocAccessFields_createdAt';
  create?: Maybe<TaskTemplatesDocAccessFields_CreatedAt_Create>;
  delete?: Maybe<TaskTemplatesDocAccessFields_CreatedAt_Delete>;
  read?: Maybe<TaskTemplatesDocAccessFields_CreatedAt_Read>;
  update?: Maybe<TaskTemplatesDocAccessFields_CreatedAt_Update>;
};

export type TaskTemplatesDocAccessFields_CreatedAt_Create = {
  __typename?: 'TaskTemplatesDocAccessFields_createdAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_CreatedAt_Delete = {
  __typename?: 'TaskTemplatesDocAccessFields_createdAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_CreatedAt_Read = {
  __typename?: 'TaskTemplatesDocAccessFields_createdAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_CreatedAt_Update = {
  __typename?: 'TaskTemplatesDocAccessFields_createdAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Description = {
  __typename?: 'TaskTemplatesDocAccessFields_description';
  create?: Maybe<TaskTemplatesDocAccessFields_Description_Create>;
  delete?: Maybe<TaskTemplatesDocAccessFields_Description_Delete>;
  read?: Maybe<TaskTemplatesDocAccessFields_Description_Read>;
  update?: Maybe<TaskTemplatesDocAccessFields_Description_Update>;
};

export type TaskTemplatesDocAccessFields_Description_Create = {
  __typename?: 'TaskTemplatesDocAccessFields_description_Create';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Description_Delete = {
  __typename?: 'TaskTemplatesDocAccessFields_description_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Description_Read = {
  __typename?: 'TaskTemplatesDocAccessFields_description_Read';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Description_Update = {
  __typename?: 'TaskTemplatesDocAccessFields_description_Update';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Details = {
  __typename?: 'TaskTemplatesDocAccessFields_details';
  create?: Maybe<TaskTemplatesDocAccessFields_Details_Create>;
  delete?: Maybe<TaskTemplatesDocAccessFields_Details_Delete>;
  read?: Maybe<TaskTemplatesDocAccessFields_Details_Read>;
  update?: Maybe<TaskTemplatesDocAccessFields_Details_Update>;
};

export type TaskTemplatesDocAccessFields_Details_Create = {
  __typename?: 'TaskTemplatesDocAccessFields_details_Create';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Details_Delete = {
  __typename?: 'TaskTemplatesDocAccessFields_details_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Details_Read = {
  __typename?: 'TaskTemplatesDocAccessFields_details_Read';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Details_Update = {
  __typename?: 'TaskTemplatesDocAccessFields_details_Update';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Feature = {
  __typename?: 'TaskTemplatesDocAccessFields_feature';
  create?: Maybe<TaskTemplatesDocAccessFields_Feature_Create>;
  delete?: Maybe<TaskTemplatesDocAccessFields_Feature_Delete>;
  read?: Maybe<TaskTemplatesDocAccessFields_Feature_Read>;
  update?: Maybe<TaskTemplatesDocAccessFields_Feature_Update>;
};

export type TaskTemplatesDocAccessFields_Feature_Create = {
  __typename?: 'TaskTemplatesDocAccessFields_feature_Create';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Feature_Delete = {
  __typename?: 'TaskTemplatesDocAccessFields_feature_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Feature_Read = {
  __typename?: 'TaskTemplatesDocAccessFields_feature_Read';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Feature_Update = {
  __typename?: 'TaskTemplatesDocAccessFields_feature_Update';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Resources = {
  __typename?: 'TaskTemplatesDocAccessFields_resources';
  create?: Maybe<TaskTemplatesDocAccessFields_Resources_Create>;
  delete?: Maybe<TaskTemplatesDocAccessFields_Resources_Delete>;
  fields?: Maybe<TaskTemplatesDocAccessFields_Resources_Fields>;
  read?: Maybe<TaskTemplatesDocAccessFields_Resources_Read>;
  update?: Maybe<TaskTemplatesDocAccessFields_Resources_Update>;
};

export type TaskTemplatesDocAccessFields_Resources_Create = {
  __typename?: 'TaskTemplatesDocAccessFields_resources_Create';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Resources_Delete = {
  __typename?: 'TaskTemplatesDocAccessFields_resources_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Resources_Fields = {
  __typename?: 'TaskTemplatesDocAccessFields_resources_Fields';
  articles?: Maybe<TaskTemplatesDocAccessFields_Resources_Articles>;
  videos?: Maybe<TaskTemplatesDocAccessFields_Resources_Videos>;
};

export type TaskTemplatesDocAccessFields_Resources_Read = {
  __typename?: 'TaskTemplatesDocAccessFields_resources_Read';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Resources_Update = {
  __typename?: 'TaskTemplatesDocAccessFields_resources_Update';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Resources_Articles = {
  __typename?: 'TaskTemplatesDocAccessFields_resources_articles';
  create?: Maybe<TaskTemplatesDocAccessFields_Resources_Articles_Create>;
  delete?: Maybe<TaskTemplatesDocAccessFields_Resources_Articles_Delete>;
  read?: Maybe<TaskTemplatesDocAccessFields_Resources_Articles_Read>;
  update?: Maybe<TaskTemplatesDocAccessFields_Resources_Articles_Update>;
};

export type TaskTemplatesDocAccessFields_Resources_Articles_Create = {
  __typename?: 'TaskTemplatesDocAccessFields_resources_articles_Create';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Resources_Articles_Delete = {
  __typename?: 'TaskTemplatesDocAccessFields_resources_articles_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Resources_Articles_Read = {
  __typename?: 'TaskTemplatesDocAccessFields_resources_articles_Read';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Resources_Articles_Update = {
  __typename?: 'TaskTemplatesDocAccessFields_resources_articles_Update';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Resources_Videos = {
  __typename?: 'TaskTemplatesDocAccessFields_resources_videos';
  create?: Maybe<TaskTemplatesDocAccessFields_Resources_Videos_Create>;
  delete?: Maybe<TaskTemplatesDocAccessFields_Resources_Videos_Delete>;
  read?: Maybe<TaskTemplatesDocAccessFields_Resources_Videos_Read>;
  update?: Maybe<TaskTemplatesDocAccessFields_Resources_Videos_Update>;
};

export type TaskTemplatesDocAccessFields_Resources_Videos_Create = {
  __typename?: 'TaskTemplatesDocAccessFields_resources_videos_Create';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Resources_Videos_Delete = {
  __typename?: 'TaskTemplatesDocAccessFields_resources_videos_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Resources_Videos_Read = {
  __typename?: 'TaskTemplatesDocAccessFields_resources_videos_Read';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Resources_Videos_Update = {
  __typename?: 'TaskTemplatesDocAccessFields_resources_videos_Update';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Review = {
  __typename?: 'TaskTemplatesDocAccessFields_review';
  create?: Maybe<TaskTemplatesDocAccessFields_Review_Create>;
  delete?: Maybe<TaskTemplatesDocAccessFields_Review_Delete>;
  fields?: Maybe<TaskTemplatesDocAccessFields_Review_Fields>;
  read?: Maybe<TaskTemplatesDocAccessFields_Review_Read>;
  update?: Maybe<TaskTemplatesDocAccessFields_Review_Update>;
};

export type TaskTemplatesDocAccessFields_Review_Create = {
  __typename?: 'TaskTemplatesDocAccessFields_review_Create';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Review_Delete = {
  __typename?: 'TaskTemplatesDocAccessFields_review_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Review_Fields = {
  __typename?: 'TaskTemplatesDocAccessFields_review_Fields';
  approver?: Maybe<TaskTemplatesDocAccessFields_Review_Approver>;
  evidence?: Maybe<TaskTemplatesDocAccessFields_Review_Evidence>;
  required?: Maybe<TaskTemplatesDocAccessFields_Review_Required>;
};

export type TaskTemplatesDocAccessFields_Review_Read = {
  __typename?: 'TaskTemplatesDocAccessFields_review_Read';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Review_Update = {
  __typename?: 'TaskTemplatesDocAccessFields_review_Update';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Review_Approver = {
  __typename?: 'TaskTemplatesDocAccessFields_review_approver';
  create?: Maybe<TaskTemplatesDocAccessFields_Review_Approver_Create>;
  delete?: Maybe<TaskTemplatesDocAccessFields_Review_Approver_Delete>;
  read?: Maybe<TaskTemplatesDocAccessFields_Review_Approver_Read>;
  update?: Maybe<TaskTemplatesDocAccessFields_Review_Approver_Update>;
};

export type TaskTemplatesDocAccessFields_Review_Approver_Create = {
  __typename?: 'TaskTemplatesDocAccessFields_review_approver_Create';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Review_Approver_Delete = {
  __typename?: 'TaskTemplatesDocAccessFields_review_approver_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Review_Approver_Read = {
  __typename?: 'TaskTemplatesDocAccessFields_review_approver_Read';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Review_Approver_Update = {
  __typename?: 'TaskTemplatesDocAccessFields_review_approver_Update';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Review_Evidence = {
  __typename?: 'TaskTemplatesDocAccessFields_review_evidence';
  create?: Maybe<TaskTemplatesDocAccessFields_Review_Evidence_Create>;
  delete?: Maybe<TaskTemplatesDocAccessFields_Review_Evidence_Delete>;
  read?: Maybe<TaskTemplatesDocAccessFields_Review_Evidence_Read>;
  update?: Maybe<TaskTemplatesDocAccessFields_Review_Evidence_Update>;
};

export type TaskTemplatesDocAccessFields_Review_Evidence_Create = {
  __typename?: 'TaskTemplatesDocAccessFields_review_evidence_Create';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Review_Evidence_Delete = {
  __typename?: 'TaskTemplatesDocAccessFields_review_evidence_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Review_Evidence_Read = {
  __typename?: 'TaskTemplatesDocAccessFields_review_evidence_Read';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Review_Evidence_Update = {
  __typename?: 'TaskTemplatesDocAccessFields_review_evidence_Update';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Review_Required = {
  __typename?: 'TaskTemplatesDocAccessFields_review_required';
  create?: Maybe<TaskTemplatesDocAccessFields_Review_Required_Create>;
  delete?: Maybe<TaskTemplatesDocAccessFields_Review_Required_Delete>;
  read?: Maybe<TaskTemplatesDocAccessFields_Review_Required_Read>;
  update?: Maybe<TaskTemplatesDocAccessFields_Review_Required_Update>;
};

export type TaskTemplatesDocAccessFields_Review_Required_Create = {
  __typename?: 'TaskTemplatesDocAccessFields_review_required_Create';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Review_Required_Delete = {
  __typename?: 'TaskTemplatesDocAccessFields_review_required_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Review_Required_Read = {
  __typename?: 'TaskTemplatesDocAccessFields_review_required_Read';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Review_Required_Update = {
  __typename?: 'TaskTemplatesDocAccessFields_review_required_Update';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Schedule = {
  __typename?: 'TaskTemplatesDocAccessFields_schedule';
  create?: Maybe<TaskTemplatesDocAccessFields_Schedule_Create>;
  delete?: Maybe<TaskTemplatesDocAccessFields_Schedule_Delete>;
  fields?: Maybe<TaskTemplatesDocAccessFields_Schedule_Fields>;
  read?: Maybe<TaskTemplatesDocAccessFields_Schedule_Read>;
  update?: Maybe<TaskTemplatesDocAccessFields_Schedule_Update>;
};

export type TaskTemplatesDocAccessFields_Schedule_Create = {
  __typename?: 'TaskTemplatesDocAccessFields_schedule_Create';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Schedule_Delete = {
  __typename?: 'TaskTemplatesDocAccessFields_schedule_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Schedule_Fields = {
  __typename?: 'TaskTemplatesDocAccessFields_schedule_Fields';
  due?: Maybe<TaskTemplatesDocAccessFields_Schedule_Due>;
  event?: Maybe<TaskTemplatesDocAccessFields_Schedule_Event>;
  freq?: Maybe<TaskTemplatesDocAccessFields_Schedule_Freq>;
  id?: Maybe<TaskTemplatesDocAccessFields_Schedule_Id>;
  interval?: Maybe<TaskTemplatesDocAccessFields_Schedule_Interval>;
};

export type TaskTemplatesDocAccessFields_Schedule_Read = {
  __typename?: 'TaskTemplatesDocAccessFields_schedule_Read';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Schedule_Update = {
  __typename?: 'TaskTemplatesDocAccessFields_schedule_Update';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Schedule_Due = {
  __typename?: 'TaskTemplatesDocAccessFields_schedule_due';
  create?: Maybe<TaskTemplatesDocAccessFields_Schedule_Due_Create>;
  delete?: Maybe<TaskTemplatesDocAccessFields_Schedule_Due_Delete>;
  read?: Maybe<TaskTemplatesDocAccessFields_Schedule_Due_Read>;
  update?: Maybe<TaskTemplatesDocAccessFields_Schedule_Due_Update>;
};

export type TaskTemplatesDocAccessFields_Schedule_Due_Create = {
  __typename?: 'TaskTemplatesDocAccessFields_schedule_due_Create';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Schedule_Due_Delete = {
  __typename?: 'TaskTemplatesDocAccessFields_schedule_due_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Schedule_Due_Read = {
  __typename?: 'TaskTemplatesDocAccessFields_schedule_due_Read';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Schedule_Due_Update = {
  __typename?: 'TaskTemplatesDocAccessFields_schedule_due_Update';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Schedule_Event = {
  __typename?: 'TaskTemplatesDocAccessFields_schedule_event';
  create?: Maybe<TaskTemplatesDocAccessFields_Schedule_Event_Create>;
  delete?: Maybe<TaskTemplatesDocAccessFields_Schedule_Event_Delete>;
  read?: Maybe<TaskTemplatesDocAccessFields_Schedule_Event_Read>;
  update?: Maybe<TaskTemplatesDocAccessFields_Schedule_Event_Update>;
};

export type TaskTemplatesDocAccessFields_Schedule_Event_Create = {
  __typename?: 'TaskTemplatesDocAccessFields_schedule_event_Create';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Schedule_Event_Delete = {
  __typename?: 'TaskTemplatesDocAccessFields_schedule_event_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Schedule_Event_Read = {
  __typename?: 'TaskTemplatesDocAccessFields_schedule_event_Read';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Schedule_Event_Update = {
  __typename?: 'TaskTemplatesDocAccessFields_schedule_event_Update';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Schedule_Freq = {
  __typename?: 'TaskTemplatesDocAccessFields_schedule_freq';
  create?: Maybe<TaskTemplatesDocAccessFields_Schedule_Freq_Create>;
  delete?: Maybe<TaskTemplatesDocAccessFields_Schedule_Freq_Delete>;
  read?: Maybe<TaskTemplatesDocAccessFields_Schedule_Freq_Read>;
  update?: Maybe<TaskTemplatesDocAccessFields_Schedule_Freq_Update>;
};

export type TaskTemplatesDocAccessFields_Schedule_Freq_Create = {
  __typename?: 'TaskTemplatesDocAccessFields_schedule_freq_Create';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Schedule_Freq_Delete = {
  __typename?: 'TaskTemplatesDocAccessFields_schedule_freq_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Schedule_Freq_Read = {
  __typename?: 'TaskTemplatesDocAccessFields_schedule_freq_Read';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Schedule_Freq_Update = {
  __typename?: 'TaskTemplatesDocAccessFields_schedule_freq_Update';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Schedule_Id = {
  __typename?: 'TaskTemplatesDocAccessFields_schedule_id';
  create?: Maybe<TaskTemplatesDocAccessFields_Schedule_Id_Create>;
  delete?: Maybe<TaskTemplatesDocAccessFields_Schedule_Id_Delete>;
  read?: Maybe<TaskTemplatesDocAccessFields_Schedule_Id_Read>;
  update?: Maybe<TaskTemplatesDocAccessFields_Schedule_Id_Update>;
};

export type TaskTemplatesDocAccessFields_Schedule_Id_Create = {
  __typename?: 'TaskTemplatesDocAccessFields_schedule_id_Create';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Schedule_Id_Delete = {
  __typename?: 'TaskTemplatesDocAccessFields_schedule_id_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Schedule_Id_Read = {
  __typename?: 'TaskTemplatesDocAccessFields_schedule_id_Read';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Schedule_Id_Update = {
  __typename?: 'TaskTemplatesDocAccessFields_schedule_id_Update';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Schedule_Interval = {
  __typename?: 'TaskTemplatesDocAccessFields_schedule_interval';
  create?: Maybe<TaskTemplatesDocAccessFields_Schedule_Interval_Create>;
  delete?: Maybe<TaskTemplatesDocAccessFields_Schedule_Interval_Delete>;
  read?: Maybe<TaskTemplatesDocAccessFields_Schedule_Interval_Read>;
  update?: Maybe<TaskTemplatesDocAccessFields_Schedule_Interval_Update>;
};

export type TaskTemplatesDocAccessFields_Schedule_Interval_Create = {
  __typename?: 'TaskTemplatesDocAccessFields_schedule_interval_Create';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Schedule_Interval_Delete = {
  __typename?: 'TaskTemplatesDocAccessFields_schedule_interval_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Schedule_Interval_Read = {
  __typename?: 'TaskTemplatesDocAccessFields_schedule_interval_Read';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Schedule_Interval_Update = {
  __typename?: 'TaskTemplatesDocAccessFields_schedule_interval_Update';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Thumbnail = {
  __typename?: 'TaskTemplatesDocAccessFields_thumbnail';
  create?: Maybe<TaskTemplatesDocAccessFields_Thumbnail_Create>;
  delete?: Maybe<TaskTemplatesDocAccessFields_Thumbnail_Delete>;
  read?: Maybe<TaskTemplatesDocAccessFields_Thumbnail_Read>;
  update?: Maybe<TaskTemplatesDocAccessFields_Thumbnail_Update>;
};

export type TaskTemplatesDocAccessFields_Thumbnail_Create = {
  __typename?: 'TaskTemplatesDocAccessFields_thumbnail_Create';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Thumbnail_Delete = {
  __typename?: 'TaskTemplatesDocAccessFields_thumbnail_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Thumbnail_Read = {
  __typename?: 'TaskTemplatesDocAccessFields_thumbnail_Read';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Thumbnail_Update = {
  __typename?: 'TaskTemplatesDocAccessFields_thumbnail_Update';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Title = {
  __typename?: 'TaskTemplatesDocAccessFields_title';
  create?: Maybe<TaskTemplatesDocAccessFields_Title_Create>;
  delete?: Maybe<TaskTemplatesDocAccessFields_Title_Delete>;
  read?: Maybe<TaskTemplatesDocAccessFields_Title_Read>;
  update?: Maybe<TaskTemplatesDocAccessFields_Title_Update>;
};

export type TaskTemplatesDocAccessFields_Title_Create = {
  __typename?: 'TaskTemplatesDocAccessFields_title_Create';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Title_Delete = {
  __typename?: 'TaskTemplatesDocAccessFields_title_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Title_Read = {
  __typename?: 'TaskTemplatesDocAccessFields_title_Read';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_Title_Update = {
  __typename?: 'TaskTemplatesDocAccessFields_title_Update';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_UpdatedAt = {
  __typename?: 'TaskTemplatesDocAccessFields_updatedAt';
  create?: Maybe<TaskTemplatesDocAccessFields_UpdatedAt_Create>;
  delete?: Maybe<TaskTemplatesDocAccessFields_UpdatedAt_Delete>;
  read?: Maybe<TaskTemplatesDocAccessFields_UpdatedAt_Read>;
  update?: Maybe<TaskTemplatesDocAccessFields_UpdatedAt_Update>;
};

export type TaskTemplatesDocAccessFields_UpdatedAt_Create = {
  __typename?: 'TaskTemplatesDocAccessFields_updatedAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_UpdatedAt_Delete = {
  __typename?: 'TaskTemplatesDocAccessFields_updatedAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_UpdatedAt_Read = {
  __typename?: 'TaskTemplatesDocAccessFields_updatedAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesDocAccessFields_UpdatedAt_Update = {
  __typename?: 'TaskTemplatesDocAccessFields_updatedAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields = {
  __typename?: 'TaskTemplatesFields';
  _status?: Maybe<TaskTemplatesFields__Status>;
  assignment?: Maybe<TaskTemplatesFields_Assignment>;
  categories?: Maybe<TaskTemplatesFields_Categories>;
  createdAt?: Maybe<TaskTemplatesFields_CreatedAt>;
  description?: Maybe<TaskTemplatesFields_Description>;
  details?: Maybe<TaskTemplatesFields_Details>;
  feature?: Maybe<TaskTemplatesFields_Feature>;
  resources?: Maybe<TaskTemplatesFields_Resources>;
  review?: Maybe<TaskTemplatesFields_Review>;
  schedule?: Maybe<TaskTemplatesFields_Schedule>;
  thumbnail?: Maybe<TaskTemplatesFields_Thumbnail>;
  title?: Maybe<TaskTemplatesFields_Title>;
  updatedAt?: Maybe<TaskTemplatesFields_UpdatedAt>;
};

export type TaskTemplatesFields__Status = {
  __typename?: 'TaskTemplatesFields__status';
  create?: Maybe<TaskTemplatesFields__Status_Create>;
  delete?: Maybe<TaskTemplatesFields__Status_Delete>;
  read?: Maybe<TaskTemplatesFields__Status_Read>;
  update?: Maybe<TaskTemplatesFields__Status_Update>;
};

export type TaskTemplatesFields__Status_Create = {
  __typename?: 'TaskTemplatesFields__status_Create';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields__Status_Delete = {
  __typename?: 'TaskTemplatesFields__status_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields__Status_Read = {
  __typename?: 'TaskTemplatesFields__status_Read';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields__Status_Update = {
  __typename?: 'TaskTemplatesFields__status_Update';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Assignment = {
  __typename?: 'TaskTemplatesFields_assignment';
  create?: Maybe<TaskTemplatesFields_Assignment_Create>;
  delete?: Maybe<TaskTemplatesFields_Assignment_Delete>;
  read?: Maybe<TaskTemplatesFields_Assignment_Read>;
  update?: Maybe<TaskTemplatesFields_Assignment_Update>;
};

export type TaskTemplatesFields_Assignment_Create = {
  __typename?: 'TaskTemplatesFields_assignment_Create';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Assignment_Delete = {
  __typename?: 'TaskTemplatesFields_assignment_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Assignment_Read = {
  __typename?: 'TaskTemplatesFields_assignment_Read';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Assignment_Update = {
  __typename?: 'TaskTemplatesFields_assignment_Update';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Categories = {
  __typename?: 'TaskTemplatesFields_categories';
  create?: Maybe<TaskTemplatesFields_Categories_Create>;
  delete?: Maybe<TaskTemplatesFields_Categories_Delete>;
  read?: Maybe<TaskTemplatesFields_Categories_Read>;
  update?: Maybe<TaskTemplatesFields_Categories_Update>;
};

export type TaskTemplatesFields_Categories_Create = {
  __typename?: 'TaskTemplatesFields_categories_Create';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Categories_Delete = {
  __typename?: 'TaskTemplatesFields_categories_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Categories_Read = {
  __typename?: 'TaskTemplatesFields_categories_Read';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Categories_Update = {
  __typename?: 'TaskTemplatesFields_categories_Update';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_CreatedAt = {
  __typename?: 'TaskTemplatesFields_createdAt';
  create?: Maybe<TaskTemplatesFields_CreatedAt_Create>;
  delete?: Maybe<TaskTemplatesFields_CreatedAt_Delete>;
  read?: Maybe<TaskTemplatesFields_CreatedAt_Read>;
  update?: Maybe<TaskTemplatesFields_CreatedAt_Update>;
};

export type TaskTemplatesFields_CreatedAt_Create = {
  __typename?: 'TaskTemplatesFields_createdAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_CreatedAt_Delete = {
  __typename?: 'TaskTemplatesFields_createdAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_CreatedAt_Read = {
  __typename?: 'TaskTemplatesFields_createdAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_CreatedAt_Update = {
  __typename?: 'TaskTemplatesFields_createdAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Description = {
  __typename?: 'TaskTemplatesFields_description';
  create?: Maybe<TaskTemplatesFields_Description_Create>;
  delete?: Maybe<TaskTemplatesFields_Description_Delete>;
  read?: Maybe<TaskTemplatesFields_Description_Read>;
  update?: Maybe<TaskTemplatesFields_Description_Update>;
};

export type TaskTemplatesFields_Description_Create = {
  __typename?: 'TaskTemplatesFields_description_Create';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Description_Delete = {
  __typename?: 'TaskTemplatesFields_description_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Description_Read = {
  __typename?: 'TaskTemplatesFields_description_Read';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Description_Update = {
  __typename?: 'TaskTemplatesFields_description_Update';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Details = {
  __typename?: 'TaskTemplatesFields_details';
  create?: Maybe<TaskTemplatesFields_Details_Create>;
  delete?: Maybe<TaskTemplatesFields_Details_Delete>;
  read?: Maybe<TaskTemplatesFields_Details_Read>;
  update?: Maybe<TaskTemplatesFields_Details_Update>;
};

export type TaskTemplatesFields_Details_Create = {
  __typename?: 'TaskTemplatesFields_details_Create';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Details_Delete = {
  __typename?: 'TaskTemplatesFields_details_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Details_Read = {
  __typename?: 'TaskTemplatesFields_details_Read';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Details_Update = {
  __typename?: 'TaskTemplatesFields_details_Update';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Feature = {
  __typename?: 'TaskTemplatesFields_feature';
  create?: Maybe<TaskTemplatesFields_Feature_Create>;
  delete?: Maybe<TaskTemplatesFields_Feature_Delete>;
  read?: Maybe<TaskTemplatesFields_Feature_Read>;
  update?: Maybe<TaskTemplatesFields_Feature_Update>;
};

export type TaskTemplatesFields_Feature_Create = {
  __typename?: 'TaskTemplatesFields_feature_Create';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Feature_Delete = {
  __typename?: 'TaskTemplatesFields_feature_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Feature_Read = {
  __typename?: 'TaskTemplatesFields_feature_Read';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Feature_Update = {
  __typename?: 'TaskTemplatesFields_feature_Update';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Resources = {
  __typename?: 'TaskTemplatesFields_resources';
  create?: Maybe<TaskTemplatesFields_Resources_Create>;
  delete?: Maybe<TaskTemplatesFields_Resources_Delete>;
  fields?: Maybe<TaskTemplatesFields_Resources_Fields>;
  read?: Maybe<TaskTemplatesFields_Resources_Read>;
  update?: Maybe<TaskTemplatesFields_Resources_Update>;
};

export type TaskTemplatesFields_Resources_Create = {
  __typename?: 'TaskTemplatesFields_resources_Create';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Resources_Delete = {
  __typename?: 'TaskTemplatesFields_resources_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Resources_Fields = {
  __typename?: 'TaskTemplatesFields_resources_Fields';
  articles?: Maybe<TaskTemplatesFields_Resources_Articles>;
  videos?: Maybe<TaskTemplatesFields_Resources_Videos>;
};

export type TaskTemplatesFields_Resources_Read = {
  __typename?: 'TaskTemplatesFields_resources_Read';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Resources_Update = {
  __typename?: 'TaskTemplatesFields_resources_Update';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Resources_Articles = {
  __typename?: 'TaskTemplatesFields_resources_articles';
  create?: Maybe<TaskTemplatesFields_Resources_Articles_Create>;
  delete?: Maybe<TaskTemplatesFields_Resources_Articles_Delete>;
  read?: Maybe<TaskTemplatesFields_Resources_Articles_Read>;
  update?: Maybe<TaskTemplatesFields_Resources_Articles_Update>;
};

export type TaskTemplatesFields_Resources_Articles_Create = {
  __typename?: 'TaskTemplatesFields_resources_articles_Create';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Resources_Articles_Delete = {
  __typename?: 'TaskTemplatesFields_resources_articles_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Resources_Articles_Read = {
  __typename?: 'TaskTemplatesFields_resources_articles_Read';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Resources_Articles_Update = {
  __typename?: 'TaskTemplatesFields_resources_articles_Update';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Resources_Videos = {
  __typename?: 'TaskTemplatesFields_resources_videos';
  create?: Maybe<TaskTemplatesFields_Resources_Videos_Create>;
  delete?: Maybe<TaskTemplatesFields_Resources_Videos_Delete>;
  read?: Maybe<TaskTemplatesFields_Resources_Videos_Read>;
  update?: Maybe<TaskTemplatesFields_Resources_Videos_Update>;
};

export type TaskTemplatesFields_Resources_Videos_Create = {
  __typename?: 'TaskTemplatesFields_resources_videos_Create';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Resources_Videos_Delete = {
  __typename?: 'TaskTemplatesFields_resources_videos_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Resources_Videos_Read = {
  __typename?: 'TaskTemplatesFields_resources_videos_Read';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Resources_Videos_Update = {
  __typename?: 'TaskTemplatesFields_resources_videos_Update';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Review = {
  __typename?: 'TaskTemplatesFields_review';
  create?: Maybe<TaskTemplatesFields_Review_Create>;
  delete?: Maybe<TaskTemplatesFields_Review_Delete>;
  fields?: Maybe<TaskTemplatesFields_Review_Fields>;
  read?: Maybe<TaskTemplatesFields_Review_Read>;
  update?: Maybe<TaskTemplatesFields_Review_Update>;
};

export type TaskTemplatesFields_Review_Create = {
  __typename?: 'TaskTemplatesFields_review_Create';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Review_Delete = {
  __typename?: 'TaskTemplatesFields_review_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Review_Fields = {
  __typename?: 'TaskTemplatesFields_review_Fields';
  approver?: Maybe<TaskTemplatesFields_Review_Approver>;
  evidence?: Maybe<TaskTemplatesFields_Review_Evidence>;
  required?: Maybe<TaskTemplatesFields_Review_Required>;
};

export type TaskTemplatesFields_Review_Read = {
  __typename?: 'TaskTemplatesFields_review_Read';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Review_Update = {
  __typename?: 'TaskTemplatesFields_review_Update';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Review_Approver = {
  __typename?: 'TaskTemplatesFields_review_approver';
  create?: Maybe<TaskTemplatesFields_Review_Approver_Create>;
  delete?: Maybe<TaskTemplatesFields_Review_Approver_Delete>;
  read?: Maybe<TaskTemplatesFields_Review_Approver_Read>;
  update?: Maybe<TaskTemplatesFields_Review_Approver_Update>;
};

export type TaskTemplatesFields_Review_Approver_Create = {
  __typename?: 'TaskTemplatesFields_review_approver_Create';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Review_Approver_Delete = {
  __typename?: 'TaskTemplatesFields_review_approver_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Review_Approver_Read = {
  __typename?: 'TaskTemplatesFields_review_approver_Read';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Review_Approver_Update = {
  __typename?: 'TaskTemplatesFields_review_approver_Update';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Review_Evidence = {
  __typename?: 'TaskTemplatesFields_review_evidence';
  create?: Maybe<TaskTemplatesFields_Review_Evidence_Create>;
  delete?: Maybe<TaskTemplatesFields_Review_Evidence_Delete>;
  read?: Maybe<TaskTemplatesFields_Review_Evidence_Read>;
  update?: Maybe<TaskTemplatesFields_Review_Evidence_Update>;
};

export type TaskTemplatesFields_Review_Evidence_Create = {
  __typename?: 'TaskTemplatesFields_review_evidence_Create';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Review_Evidence_Delete = {
  __typename?: 'TaskTemplatesFields_review_evidence_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Review_Evidence_Read = {
  __typename?: 'TaskTemplatesFields_review_evidence_Read';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Review_Evidence_Update = {
  __typename?: 'TaskTemplatesFields_review_evidence_Update';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Review_Required = {
  __typename?: 'TaskTemplatesFields_review_required';
  create?: Maybe<TaskTemplatesFields_Review_Required_Create>;
  delete?: Maybe<TaskTemplatesFields_Review_Required_Delete>;
  read?: Maybe<TaskTemplatesFields_Review_Required_Read>;
  update?: Maybe<TaskTemplatesFields_Review_Required_Update>;
};

export type TaskTemplatesFields_Review_Required_Create = {
  __typename?: 'TaskTemplatesFields_review_required_Create';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Review_Required_Delete = {
  __typename?: 'TaskTemplatesFields_review_required_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Review_Required_Read = {
  __typename?: 'TaskTemplatesFields_review_required_Read';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Review_Required_Update = {
  __typename?: 'TaskTemplatesFields_review_required_Update';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Schedule = {
  __typename?: 'TaskTemplatesFields_schedule';
  create?: Maybe<TaskTemplatesFields_Schedule_Create>;
  delete?: Maybe<TaskTemplatesFields_Schedule_Delete>;
  fields?: Maybe<TaskTemplatesFields_Schedule_Fields>;
  read?: Maybe<TaskTemplatesFields_Schedule_Read>;
  update?: Maybe<TaskTemplatesFields_Schedule_Update>;
};

export type TaskTemplatesFields_Schedule_Create = {
  __typename?: 'TaskTemplatesFields_schedule_Create';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Schedule_Delete = {
  __typename?: 'TaskTemplatesFields_schedule_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Schedule_Fields = {
  __typename?: 'TaskTemplatesFields_schedule_Fields';
  due?: Maybe<TaskTemplatesFields_Schedule_Due>;
  event?: Maybe<TaskTemplatesFields_Schedule_Event>;
  freq?: Maybe<TaskTemplatesFields_Schedule_Freq>;
  id?: Maybe<TaskTemplatesFields_Schedule_Id>;
  interval?: Maybe<TaskTemplatesFields_Schedule_Interval>;
};

export type TaskTemplatesFields_Schedule_Read = {
  __typename?: 'TaskTemplatesFields_schedule_Read';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Schedule_Update = {
  __typename?: 'TaskTemplatesFields_schedule_Update';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Schedule_Due = {
  __typename?: 'TaskTemplatesFields_schedule_due';
  create?: Maybe<TaskTemplatesFields_Schedule_Due_Create>;
  delete?: Maybe<TaskTemplatesFields_Schedule_Due_Delete>;
  read?: Maybe<TaskTemplatesFields_Schedule_Due_Read>;
  update?: Maybe<TaskTemplatesFields_Schedule_Due_Update>;
};

export type TaskTemplatesFields_Schedule_Due_Create = {
  __typename?: 'TaskTemplatesFields_schedule_due_Create';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Schedule_Due_Delete = {
  __typename?: 'TaskTemplatesFields_schedule_due_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Schedule_Due_Read = {
  __typename?: 'TaskTemplatesFields_schedule_due_Read';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Schedule_Due_Update = {
  __typename?: 'TaskTemplatesFields_schedule_due_Update';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Schedule_Event = {
  __typename?: 'TaskTemplatesFields_schedule_event';
  create?: Maybe<TaskTemplatesFields_Schedule_Event_Create>;
  delete?: Maybe<TaskTemplatesFields_Schedule_Event_Delete>;
  read?: Maybe<TaskTemplatesFields_Schedule_Event_Read>;
  update?: Maybe<TaskTemplatesFields_Schedule_Event_Update>;
};

export type TaskTemplatesFields_Schedule_Event_Create = {
  __typename?: 'TaskTemplatesFields_schedule_event_Create';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Schedule_Event_Delete = {
  __typename?: 'TaskTemplatesFields_schedule_event_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Schedule_Event_Read = {
  __typename?: 'TaskTemplatesFields_schedule_event_Read';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Schedule_Event_Update = {
  __typename?: 'TaskTemplatesFields_schedule_event_Update';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Schedule_Freq = {
  __typename?: 'TaskTemplatesFields_schedule_freq';
  create?: Maybe<TaskTemplatesFields_Schedule_Freq_Create>;
  delete?: Maybe<TaskTemplatesFields_Schedule_Freq_Delete>;
  read?: Maybe<TaskTemplatesFields_Schedule_Freq_Read>;
  update?: Maybe<TaskTemplatesFields_Schedule_Freq_Update>;
};

export type TaskTemplatesFields_Schedule_Freq_Create = {
  __typename?: 'TaskTemplatesFields_schedule_freq_Create';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Schedule_Freq_Delete = {
  __typename?: 'TaskTemplatesFields_schedule_freq_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Schedule_Freq_Read = {
  __typename?: 'TaskTemplatesFields_schedule_freq_Read';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Schedule_Freq_Update = {
  __typename?: 'TaskTemplatesFields_schedule_freq_Update';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Schedule_Id = {
  __typename?: 'TaskTemplatesFields_schedule_id';
  create?: Maybe<TaskTemplatesFields_Schedule_Id_Create>;
  delete?: Maybe<TaskTemplatesFields_Schedule_Id_Delete>;
  read?: Maybe<TaskTemplatesFields_Schedule_Id_Read>;
  update?: Maybe<TaskTemplatesFields_Schedule_Id_Update>;
};

export type TaskTemplatesFields_Schedule_Id_Create = {
  __typename?: 'TaskTemplatesFields_schedule_id_Create';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Schedule_Id_Delete = {
  __typename?: 'TaskTemplatesFields_schedule_id_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Schedule_Id_Read = {
  __typename?: 'TaskTemplatesFields_schedule_id_Read';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Schedule_Id_Update = {
  __typename?: 'TaskTemplatesFields_schedule_id_Update';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Schedule_Interval = {
  __typename?: 'TaskTemplatesFields_schedule_interval';
  create?: Maybe<TaskTemplatesFields_Schedule_Interval_Create>;
  delete?: Maybe<TaskTemplatesFields_Schedule_Interval_Delete>;
  read?: Maybe<TaskTemplatesFields_Schedule_Interval_Read>;
  update?: Maybe<TaskTemplatesFields_Schedule_Interval_Update>;
};

export type TaskTemplatesFields_Schedule_Interval_Create = {
  __typename?: 'TaskTemplatesFields_schedule_interval_Create';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Schedule_Interval_Delete = {
  __typename?: 'TaskTemplatesFields_schedule_interval_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Schedule_Interval_Read = {
  __typename?: 'TaskTemplatesFields_schedule_interval_Read';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Schedule_Interval_Update = {
  __typename?: 'TaskTemplatesFields_schedule_interval_Update';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Thumbnail = {
  __typename?: 'TaskTemplatesFields_thumbnail';
  create?: Maybe<TaskTemplatesFields_Thumbnail_Create>;
  delete?: Maybe<TaskTemplatesFields_Thumbnail_Delete>;
  read?: Maybe<TaskTemplatesFields_Thumbnail_Read>;
  update?: Maybe<TaskTemplatesFields_Thumbnail_Update>;
};

export type TaskTemplatesFields_Thumbnail_Create = {
  __typename?: 'TaskTemplatesFields_thumbnail_Create';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Thumbnail_Delete = {
  __typename?: 'TaskTemplatesFields_thumbnail_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Thumbnail_Read = {
  __typename?: 'TaskTemplatesFields_thumbnail_Read';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Thumbnail_Update = {
  __typename?: 'TaskTemplatesFields_thumbnail_Update';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Title = {
  __typename?: 'TaskTemplatesFields_title';
  create?: Maybe<TaskTemplatesFields_Title_Create>;
  delete?: Maybe<TaskTemplatesFields_Title_Delete>;
  read?: Maybe<TaskTemplatesFields_Title_Read>;
  update?: Maybe<TaskTemplatesFields_Title_Update>;
};

export type TaskTemplatesFields_Title_Create = {
  __typename?: 'TaskTemplatesFields_title_Create';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Title_Delete = {
  __typename?: 'TaskTemplatesFields_title_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Title_Read = {
  __typename?: 'TaskTemplatesFields_title_Read';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_Title_Update = {
  __typename?: 'TaskTemplatesFields_title_Update';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_UpdatedAt = {
  __typename?: 'TaskTemplatesFields_updatedAt';
  create?: Maybe<TaskTemplatesFields_UpdatedAt_Create>;
  delete?: Maybe<TaskTemplatesFields_UpdatedAt_Delete>;
  read?: Maybe<TaskTemplatesFields_UpdatedAt_Read>;
  update?: Maybe<TaskTemplatesFields_UpdatedAt_Update>;
};

export type TaskTemplatesFields_UpdatedAt_Create = {
  __typename?: 'TaskTemplatesFields_updatedAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_UpdatedAt_Delete = {
  __typename?: 'TaskTemplatesFields_updatedAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_UpdatedAt_Read = {
  __typename?: 'TaskTemplatesFields_updatedAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesFields_UpdatedAt_Update = {
  __typename?: 'TaskTemplatesFields_updatedAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type TaskTemplatesReadAccess = {
  __typename?: 'TaskTemplatesReadAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type TaskTemplatesReadDocAccess = {
  __typename?: 'TaskTemplatesReadDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type TaskTemplatesReadVersionsAccess = {
  __typename?: 'TaskTemplatesReadVersionsAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type TaskTemplatesReadVersionsDocAccess = {
  __typename?: 'TaskTemplatesReadVersionsDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type TaskTemplatesUpdateAccess = {
  __typename?: 'TaskTemplatesUpdateAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type TaskTemplatesUpdateDocAccess = {
  __typename?: 'TaskTemplatesUpdateDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type User = {
  __typename?: 'User';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['EmailAddress']['output'];
  hash?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lockUntil?: Maybe<Scalars['DateTime']['output']>;
  loginAttempts?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  password: Scalars['String']['output'];
  resetPasswordExpiration?: Maybe<Scalars['DateTime']['output']>;
  resetPasswordToken?: Maybe<Scalars['String']['output']>;
  roles?: Maybe<Array<User_Roles>>;
  salt?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum UserUpdate_Roles_MutationInput {
  Admin = 'admin',
  User = 'user'
}

export type User_CreatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type User_Email_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['EmailAddress']['input']>>>;
  contains?: InputMaybe<Scalars['EmailAddress']['input']>;
  equals?: InputMaybe<Scalars['EmailAddress']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['EmailAddress']['input']>>>;
  like?: InputMaybe<Scalars['EmailAddress']['input']>;
  not_equals?: InputMaybe<Scalars['EmailAddress']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['EmailAddress']['input']>>>;
};

export type User_Id_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type User_Name_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export enum User_Roles {
  Admin = 'admin',
  User = 'user'
}

export enum User_Roles_Input {
  Admin = 'admin',
  User = 'user'
}

export enum User_Roles_MutationInput {
  Admin = 'admin',
  User = 'user'
}

export type User_Roles_Operator = {
  all?: InputMaybe<Array<InputMaybe<User_Roles_Input>>>;
  equals?: InputMaybe<User_Roles_Input>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<User_Roles_Input>>>;
  not_equals?: InputMaybe<User_Roles_Input>;
  not_in?: InputMaybe<Array<InputMaybe<User_Roles_Input>>>;
};

export type User_UpdatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type User_Where = {
  AND?: InputMaybe<Array<InputMaybe<User_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<User_Where_Or>>>;
  createdAt?: InputMaybe<User_CreatedAt_Operator>;
  email?: InputMaybe<User_Email_Operator>;
  id?: InputMaybe<User_Id_Operator>;
  name?: InputMaybe<User_Name_Operator>;
  roles?: InputMaybe<User_Roles_Operator>;
  updatedAt?: InputMaybe<User_UpdatedAt_Operator>;
};

export type User_Where_And = {
  AND?: InputMaybe<Array<InputMaybe<User_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<User_Where_Or>>>;
  createdAt?: InputMaybe<User_CreatedAt_Operator>;
  email?: InputMaybe<User_Email_Operator>;
  id?: InputMaybe<User_Id_Operator>;
  name?: InputMaybe<User_Name_Operator>;
  roles?: InputMaybe<User_Roles_Operator>;
  updatedAt?: InputMaybe<User_UpdatedAt_Operator>;
};

export type User_Where_Or = {
  AND?: InputMaybe<Array<InputMaybe<User_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<User_Where_Or>>>;
  createdAt?: InputMaybe<User_CreatedAt_Operator>;
  email?: InputMaybe<User_Email_Operator>;
  id?: InputMaybe<User_Id_Operator>;
  name?: InputMaybe<User_Name_Operator>;
  roles?: InputMaybe<User_Roles_Operator>;
  updatedAt?: InputMaybe<User_UpdatedAt_Operator>;
};

export type Users = {
  __typename?: 'Users';
  docs?: Maybe<Array<Maybe<User>>>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  nextPage?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  pagingCounter?: Maybe<Scalars['Int']['output']>;
  prevPage?: Maybe<Scalars['Int']['output']>;
  totalDocs?: Maybe<Scalars['Int']['output']>;
  totalPages?: Maybe<Scalars['Int']['output']>;
};

export type UsersCreateAccess = {
  __typename?: 'UsersCreateAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type UsersCreateDocAccess = {
  __typename?: 'UsersCreateDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type UsersDeleteAccess = {
  __typename?: 'UsersDeleteAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type UsersDeleteDocAccess = {
  __typename?: 'UsersDeleteDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type UsersDocAccessFields = {
  __typename?: 'UsersDocAccessFields';
  createdAt?: Maybe<UsersDocAccessFields_CreatedAt>;
  email?: Maybe<UsersDocAccessFields_Email>;
  name?: Maybe<UsersDocAccessFields_Name>;
  password?: Maybe<UsersDocAccessFields_Password>;
  roles?: Maybe<UsersDocAccessFields_Roles>;
  updatedAt?: Maybe<UsersDocAccessFields_UpdatedAt>;
};

export type UsersDocAccessFields_CreatedAt = {
  __typename?: 'UsersDocAccessFields_createdAt';
  create?: Maybe<UsersDocAccessFields_CreatedAt_Create>;
  delete?: Maybe<UsersDocAccessFields_CreatedAt_Delete>;
  read?: Maybe<UsersDocAccessFields_CreatedAt_Read>;
  update?: Maybe<UsersDocAccessFields_CreatedAt_Update>;
};

export type UsersDocAccessFields_CreatedAt_Create = {
  __typename?: 'UsersDocAccessFields_createdAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type UsersDocAccessFields_CreatedAt_Delete = {
  __typename?: 'UsersDocAccessFields_createdAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type UsersDocAccessFields_CreatedAt_Read = {
  __typename?: 'UsersDocAccessFields_createdAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type UsersDocAccessFields_CreatedAt_Update = {
  __typename?: 'UsersDocAccessFields_createdAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type UsersDocAccessFields_Email = {
  __typename?: 'UsersDocAccessFields_email';
  create?: Maybe<UsersDocAccessFields_Email_Create>;
  delete?: Maybe<UsersDocAccessFields_Email_Delete>;
  read?: Maybe<UsersDocAccessFields_Email_Read>;
  update?: Maybe<UsersDocAccessFields_Email_Update>;
};

export type UsersDocAccessFields_Email_Create = {
  __typename?: 'UsersDocAccessFields_email_Create';
  permission: Scalars['Boolean']['output'];
};

export type UsersDocAccessFields_Email_Delete = {
  __typename?: 'UsersDocAccessFields_email_Delete';
  permission: Scalars['Boolean']['output'];
};

export type UsersDocAccessFields_Email_Read = {
  __typename?: 'UsersDocAccessFields_email_Read';
  permission: Scalars['Boolean']['output'];
};

export type UsersDocAccessFields_Email_Update = {
  __typename?: 'UsersDocAccessFields_email_Update';
  permission: Scalars['Boolean']['output'];
};

export type UsersDocAccessFields_Name = {
  __typename?: 'UsersDocAccessFields_name';
  create?: Maybe<UsersDocAccessFields_Name_Create>;
  delete?: Maybe<UsersDocAccessFields_Name_Delete>;
  read?: Maybe<UsersDocAccessFields_Name_Read>;
  update?: Maybe<UsersDocAccessFields_Name_Update>;
};

export type UsersDocAccessFields_Name_Create = {
  __typename?: 'UsersDocAccessFields_name_Create';
  permission: Scalars['Boolean']['output'];
};

export type UsersDocAccessFields_Name_Delete = {
  __typename?: 'UsersDocAccessFields_name_Delete';
  permission: Scalars['Boolean']['output'];
};

export type UsersDocAccessFields_Name_Read = {
  __typename?: 'UsersDocAccessFields_name_Read';
  permission: Scalars['Boolean']['output'];
};

export type UsersDocAccessFields_Name_Update = {
  __typename?: 'UsersDocAccessFields_name_Update';
  permission: Scalars['Boolean']['output'];
};

export type UsersDocAccessFields_Password = {
  __typename?: 'UsersDocAccessFields_password';
  create?: Maybe<UsersDocAccessFields_Password_Create>;
  delete?: Maybe<UsersDocAccessFields_Password_Delete>;
  read?: Maybe<UsersDocAccessFields_Password_Read>;
  update?: Maybe<UsersDocAccessFields_Password_Update>;
};

export type UsersDocAccessFields_Password_Create = {
  __typename?: 'UsersDocAccessFields_password_Create';
  permission: Scalars['Boolean']['output'];
};

export type UsersDocAccessFields_Password_Delete = {
  __typename?: 'UsersDocAccessFields_password_Delete';
  permission: Scalars['Boolean']['output'];
};

export type UsersDocAccessFields_Password_Read = {
  __typename?: 'UsersDocAccessFields_password_Read';
  permission: Scalars['Boolean']['output'];
};

export type UsersDocAccessFields_Password_Update = {
  __typename?: 'UsersDocAccessFields_password_Update';
  permission: Scalars['Boolean']['output'];
};

export type UsersDocAccessFields_Roles = {
  __typename?: 'UsersDocAccessFields_roles';
  create?: Maybe<UsersDocAccessFields_Roles_Create>;
  delete?: Maybe<UsersDocAccessFields_Roles_Delete>;
  read?: Maybe<UsersDocAccessFields_Roles_Read>;
  update?: Maybe<UsersDocAccessFields_Roles_Update>;
};

export type UsersDocAccessFields_Roles_Create = {
  __typename?: 'UsersDocAccessFields_roles_Create';
  permission: Scalars['Boolean']['output'];
};

export type UsersDocAccessFields_Roles_Delete = {
  __typename?: 'UsersDocAccessFields_roles_Delete';
  permission: Scalars['Boolean']['output'];
};

export type UsersDocAccessFields_Roles_Read = {
  __typename?: 'UsersDocAccessFields_roles_Read';
  permission: Scalars['Boolean']['output'];
};

export type UsersDocAccessFields_Roles_Update = {
  __typename?: 'UsersDocAccessFields_roles_Update';
  permission: Scalars['Boolean']['output'];
};

export type UsersDocAccessFields_UpdatedAt = {
  __typename?: 'UsersDocAccessFields_updatedAt';
  create?: Maybe<UsersDocAccessFields_UpdatedAt_Create>;
  delete?: Maybe<UsersDocAccessFields_UpdatedAt_Delete>;
  read?: Maybe<UsersDocAccessFields_UpdatedAt_Read>;
  update?: Maybe<UsersDocAccessFields_UpdatedAt_Update>;
};

export type UsersDocAccessFields_UpdatedAt_Create = {
  __typename?: 'UsersDocAccessFields_updatedAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type UsersDocAccessFields_UpdatedAt_Delete = {
  __typename?: 'UsersDocAccessFields_updatedAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type UsersDocAccessFields_UpdatedAt_Read = {
  __typename?: 'UsersDocAccessFields_updatedAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type UsersDocAccessFields_UpdatedAt_Update = {
  __typename?: 'UsersDocAccessFields_updatedAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type UsersFields = {
  __typename?: 'UsersFields';
  createdAt?: Maybe<UsersFields_CreatedAt>;
  email?: Maybe<UsersFields_Email>;
  name?: Maybe<UsersFields_Name>;
  password?: Maybe<UsersFields_Password>;
  roles?: Maybe<UsersFields_Roles>;
  updatedAt?: Maybe<UsersFields_UpdatedAt>;
};

export type UsersFields_CreatedAt = {
  __typename?: 'UsersFields_createdAt';
  create?: Maybe<UsersFields_CreatedAt_Create>;
  delete?: Maybe<UsersFields_CreatedAt_Delete>;
  read?: Maybe<UsersFields_CreatedAt_Read>;
  update?: Maybe<UsersFields_CreatedAt_Update>;
};

export type UsersFields_CreatedAt_Create = {
  __typename?: 'UsersFields_createdAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type UsersFields_CreatedAt_Delete = {
  __typename?: 'UsersFields_createdAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type UsersFields_CreatedAt_Read = {
  __typename?: 'UsersFields_createdAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type UsersFields_CreatedAt_Update = {
  __typename?: 'UsersFields_createdAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type UsersFields_Email = {
  __typename?: 'UsersFields_email';
  create?: Maybe<UsersFields_Email_Create>;
  delete?: Maybe<UsersFields_Email_Delete>;
  read?: Maybe<UsersFields_Email_Read>;
  update?: Maybe<UsersFields_Email_Update>;
};

export type UsersFields_Email_Create = {
  __typename?: 'UsersFields_email_Create';
  permission: Scalars['Boolean']['output'];
};

export type UsersFields_Email_Delete = {
  __typename?: 'UsersFields_email_Delete';
  permission: Scalars['Boolean']['output'];
};

export type UsersFields_Email_Read = {
  __typename?: 'UsersFields_email_Read';
  permission: Scalars['Boolean']['output'];
};

export type UsersFields_Email_Update = {
  __typename?: 'UsersFields_email_Update';
  permission: Scalars['Boolean']['output'];
};

export type UsersFields_Name = {
  __typename?: 'UsersFields_name';
  create?: Maybe<UsersFields_Name_Create>;
  delete?: Maybe<UsersFields_Name_Delete>;
  read?: Maybe<UsersFields_Name_Read>;
  update?: Maybe<UsersFields_Name_Update>;
};

export type UsersFields_Name_Create = {
  __typename?: 'UsersFields_name_Create';
  permission: Scalars['Boolean']['output'];
};

export type UsersFields_Name_Delete = {
  __typename?: 'UsersFields_name_Delete';
  permission: Scalars['Boolean']['output'];
};

export type UsersFields_Name_Read = {
  __typename?: 'UsersFields_name_Read';
  permission: Scalars['Boolean']['output'];
};

export type UsersFields_Name_Update = {
  __typename?: 'UsersFields_name_Update';
  permission: Scalars['Boolean']['output'];
};

export type UsersFields_Password = {
  __typename?: 'UsersFields_password';
  create?: Maybe<UsersFields_Password_Create>;
  delete?: Maybe<UsersFields_Password_Delete>;
  read?: Maybe<UsersFields_Password_Read>;
  update?: Maybe<UsersFields_Password_Update>;
};

export type UsersFields_Password_Create = {
  __typename?: 'UsersFields_password_Create';
  permission: Scalars['Boolean']['output'];
};

export type UsersFields_Password_Delete = {
  __typename?: 'UsersFields_password_Delete';
  permission: Scalars['Boolean']['output'];
};

export type UsersFields_Password_Read = {
  __typename?: 'UsersFields_password_Read';
  permission: Scalars['Boolean']['output'];
};

export type UsersFields_Password_Update = {
  __typename?: 'UsersFields_password_Update';
  permission: Scalars['Boolean']['output'];
};

export type UsersFields_Roles = {
  __typename?: 'UsersFields_roles';
  create?: Maybe<UsersFields_Roles_Create>;
  delete?: Maybe<UsersFields_Roles_Delete>;
  read?: Maybe<UsersFields_Roles_Read>;
  update?: Maybe<UsersFields_Roles_Update>;
};

export type UsersFields_Roles_Create = {
  __typename?: 'UsersFields_roles_Create';
  permission: Scalars['Boolean']['output'];
};

export type UsersFields_Roles_Delete = {
  __typename?: 'UsersFields_roles_Delete';
  permission: Scalars['Boolean']['output'];
};

export type UsersFields_Roles_Read = {
  __typename?: 'UsersFields_roles_Read';
  permission: Scalars['Boolean']['output'];
};

export type UsersFields_Roles_Update = {
  __typename?: 'UsersFields_roles_Update';
  permission: Scalars['Boolean']['output'];
};

export type UsersFields_UpdatedAt = {
  __typename?: 'UsersFields_updatedAt';
  create?: Maybe<UsersFields_UpdatedAt_Create>;
  delete?: Maybe<UsersFields_UpdatedAt_Delete>;
  read?: Maybe<UsersFields_UpdatedAt_Read>;
  update?: Maybe<UsersFields_UpdatedAt_Update>;
};

export type UsersFields_UpdatedAt_Create = {
  __typename?: 'UsersFields_updatedAt_Create';
  permission: Scalars['Boolean']['output'];
};

export type UsersFields_UpdatedAt_Delete = {
  __typename?: 'UsersFields_updatedAt_Delete';
  permission: Scalars['Boolean']['output'];
};

export type UsersFields_UpdatedAt_Read = {
  __typename?: 'UsersFields_updatedAt_Read';
  permission: Scalars['Boolean']['output'];
};

export type UsersFields_UpdatedAt_Update = {
  __typename?: 'UsersFields_updatedAt_Update';
  permission: Scalars['Boolean']['output'];
};

export type UsersReadAccess = {
  __typename?: 'UsersReadAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type UsersReadDocAccess = {
  __typename?: 'UsersReadDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type UsersUnlockAccess = {
  __typename?: 'UsersUnlockAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type UsersUnlockDocAccess = {
  __typename?: 'UsersUnlockDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type UsersUpdateAccess = {
  __typename?: 'UsersUpdateAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type UsersUpdateDocAccess = {
  __typename?: 'UsersUpdateDocAccess';
  permission: Scalars['Boolean']['output'];
  where?: Maybe<Scalars['JSONObject']['output']>;
};

export type AllMedia = {
  __typename?: 'allMedia';
  docs?: Maybe<Array<Maybe<Media>>>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  nextPage?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  pagingCounter?: Maybe<Scalars['Int']['output']>;
  prevPage?: Maybe<Scalars['Int']['output']>;
  totalDocs?: Maybe<Scalars['Int']['output']>;
  totalPages?: Maybe<Scalars['Int']['output']>;
};

export type ArticlesAccess = {
  __typename?: 'articlesAccess';
  create?: Maybe<ArticlesCreateAccess>;
  delete?: Maybe<ArticlesDeleteAccess>;
  fields?: Maybe<ArticlesFields>;
  read?: Maybe<ArticlesReadAccess>;
  readVersions?: Maybe<ArticlesReadVersionsAccess>;
  update?: Maybe<ArticlesUpdateAccess>;
};

export type ArticlesDocAccess = {
  __typename?: 'articlesDocAccess';
  create?: Maybe<ArticlesCreateDocAccess>;
  delete?: Maybe<ArticlesDeleteDocAccess>;
  fields?: Maybe<ArticlesDocAccessFields>;
  read?: Maybe<ArticlesReadDocAccess>;
  readVersions?: Maybe<ArticlesReadVersionsDocAccess>;
  update?: Maybe<ArticlesUpdateDocAccess>;
};

export type CategoriesAccess = {
  __typename?: 'categoriesAccess';
  create?: Maybe<CategoriesCreateAccess>;
  delete?: Maybe<CategoriesDeleteAccess>;
  fields?: Maybe<CategoriesFields>;
  read?: Maybe<CategoriesReadAccess>;
  update?: Maybe<CategoriesUpdateAccess>;
};

export type CategoriesDocAccess = {
  __typename?: 'categoriesDocAccess';
  create?: Maybe<CategoriesCreateDocAccess>;
  delete?: Maybe<CategoriesDeleteDocAccess>;
  fields?: Maybe<CategoriesDocAccessFields>;
  read?: Maybe<CategoriesReadDocAccess>;
  update?: Maybe<CategoriesUpdateDocAccess>;
};

export type MediaAccess = {
  __typename?: 'mediaAccess';
  create?: Maybe<MediaCreateAccess>;
  delete?: Maybe<MediaDeleteAccess>;
  fields?: Maybe<MediaFields>;
  read?: Maybe<MediaReadAccess>;
  update?: Maybe<MediaUpdateAccess>;
};

export type MediaDocAccess = {
  __typename?: 'mediaDocAccess';
  create?: Maybe<MediaCreateDocAccess>;
  delete?: Maybe<MediaDeleteDocAccess>;
  fields?: Maybe<MediaDocAccessFields>;
  read?: Maybe<MediaReadDocAccess>;
  update?: Maybe<MediaUpdateDocAccess>;
};

export type MutationArticleInput = {
  _status?: InputMaybe<Article__Status_MutationInput>;
  author?: InputMaybe<Scalars['String']['input']>;
  categories?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  content?: InputMaybe<Scalars['JSON']['input']>;
  createdAt?: InputMaybe<Scalars['String']['input']>;
  hero: MutationArticle_HeroInput;
  meta?: InputMaybe<MutationArticle_MetaInput>;
  populatedAuthor?: InputMaybe<MutationArticle_PopulatedAuthorInput>;
  publishedAt?: InputMaybe<Scalars['String']['input']>;
  relatedArticles?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type MutationArticleUpdateInput = {
  _status?: InputMaybe<ArticleUpdate__Status_MutationInput>;
  author?: InputMaybe<Scalars['String']['input']>;
  categories?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  content?: InputMaybe<Scalars['JSON']['input']>;
  createdAt?: InputMaybe<Scalars['String']['input']>;
  hero: MutationArticleUpdate_HeroInput;
  meta?: InputMaybe<MutationArticleUpdate_MetaInput>;
  populatedAuthor?: InputMaybe<MutationArticleUpdate_PopulatedAuthorInput>;
  publishedAt?: InputMaybe<Scalars['String']['input']>;
  relatedArticles?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type MutationArticleUpdate_HeroInput = {
  media?: InputMaybe<Scalars['String']['input']>;
  richText: Scalars['JSON']['input'];
};

export type MutationArticleUpdate_MetaInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type MutationArticleUpdate_PopulatedAuthorInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type MutationArticle_HeroInput = {
  media?: InputMaybe<Scalars['String']['input']>;
  richText: Scalars['JSON']['input'];
};

export type MutationArticle_MetaInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type MutationArticle_PopulatedAuthorInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCategoryInput = {
  breadcrumbs?: InputMaybe<Array<InputMaybe<MutationCategory_BreadcrumbsInput>>>;
  createdAt?: InputMaybe<Scalars['String']['input']>;
  parent?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCategoryUpdateInput = {
  breadcrumbs?: InputMaybe<Array<InputMaybe<MutationCategoryUpdate_BreadcrumbsInput>>>;
  createdAt?: InputMaybe<Scalars['String']['input']>;
  parent?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCategoryUpdate_BreadcrumbsInput = {
  doc?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCategory_BreadcrumbsInput = {
  doc?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type MutationMediaInput = {
  alt?: InputMaybe<Scalars['String']['input']>;
  caption?: InputMaybe<Scalars['JSON']['input']>;
  createdAt?: InputMaybe<Scalars['String']['input']>;
  filename?: InputMaybe<Scalars['String']['input']>;
  filesize?: InputMaybe<Scalars['Float']['input']>;
  height?: InputMaybe<Scalars['Float']['input']>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['Float']['input']>;
};

export type MutationMediaUpdateInput = {
  alt?: InputMaybe<Scalars['String']['input']>;
  caption?: InputMaybe<Scalars['JSON']['input']>;
  createdAt?: InputMaybe<Scalars['String']['input']>;
  filename?: InputMaybe<Scalars['String']['input']>;
  filesize?: InputMaybe<Scalars['Float']['input']>;
  height?: InputMaybe<Scalars['Float']['input']>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['Float']['input']>;
};

export type MutationPayloadPreferenceInput = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<PayloadPreference_UserRelationshipInput>;
  value?: InputMaybe<Scalars['JSON']['input']>;
};

export type MutationPayloadPreferenceUpdateInput = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<PayloadPreferenceUpdate_UserRelationshipInput>;
  value?: InputMaybe<Scalars['JSON']['input']>;
};

export type MutationTaskTemplateInput = {
  _status?: InputMaybe<TaskTemplate__Status_MutationInput>;
  assignment: TaskTemplate_Assignment_MutationInput;
  categories: Array<InputMaybe<TaskTemplate_Categories_MutationInput>>;
  createdAt?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  details: Scalars['JSON']['input'];
  feature?: InputMaybe<TaskTemplate_Feature_MutationInput>;
  resources?: InputMaybe<MutationTaskTemplate_ResourcesInput>;
  review?: InputMaybe<MutationTaskTemplate_ReviewInput>;
  schedule?: InputMaybe<Array<InputMaybe<MutationTaskTemplate_ScheduleInput>>>;
  thumbnail: Scalars['String']['input'];
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type MutationTaskTemplateUpdateInput = {
  _status?: InputMaybe<TaskTemplateUpdate__Status_MutationInput>;
  assignment?: InputMaybe<TaskTemplateUpdate_Assignment_MutationInput>;
  categories?: InputMaybe<Array<InputMaybe<TaskTemplateUpdate_Categories_MutationInput>>>;
  createdAt?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  details?: InputMaybe<Scalars['JSON']['input']>;
  feature?: InputMaybe<TaskTemplateUpdate_Feature_MutationInput>;
  resources?: InputMaybe<MutationTaskTemplateUpdate_ResourcesInput>;
  review?: InputMaybe<MutationTaskTemplateUpdate_ReviewInput>;
  schedule?: InputMaybe<Array<InputMaybe<MutationTaskTemplateUpdate_ScheduleInput>>>;
  thumbnail?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type MutationTaskTemplateUpdate_ResourcesInput = {
  articles?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  videos?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type MutationTaskTemplateUpdate_ReviewInput = {
  approver?: InputMaybe<TaskTemplateUpdate_Review_Approver_MutationInput>;
  evidence?: InputMaybe<Scalars['Boolean']['input']>;
  required?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationTaskTemplateUpdate_ScheduleInput = {
  due?: InputMaybe<Scalars['Float']['input']>;
  event?: InputMaybe<TaskTemplateUpdate_Schedule_Event_MutationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  recurrence?: InputMaybe<MutationTaskTemplateUpdate_Schedule_RecurrenceInput>;
};

export type MutationTaskTemplateUpdate_Schedule_RecurrenceInput = {
  freq?: InputMaybe<TaskTemplateUpdate_Schedule_Recurrence_Freq_MutationInput>;
  interval?: InputMaybe<Scalars['Float']['input']>;
};

export type MutationTaskTemplate_ResourcesInput = {
  articles?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  videos?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type MutationTaskTemplate_ReviewInput = {
  approver?: InputMaybe<TaskTemplate_Review_Approver_MutationInput>;
  evidence?: InputMaybe<Scalars['Boolean']['input']>;
  required?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationTaskTemplate_ScheduleInput = {
  due?: InputMaybe<Scalars['Float']['input']>;
  event?: InputMaybe<TaskTemplate_Schedule_Event_MutationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  recurrence?: InputMaybe<MutationTaskTemplate_Schedule_RecurrenceInput>;
};

export type MutationTaskTemplate_Schedule_RecurrenceInput = {
  freq?: InputMaybe<TaskTemplate_Schedule_Recurrence_Freq_MutationInput>;
  interval?: InputMaybe<Scalars['Float']['input']>;
};

export type MutationUserInput = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  hash?: InputMaybe<Scalars['String']['input']>;
  lockUntil?: InputMaybe<Scalars['String']['input']>;
  loginAttempts?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  resetPasswordExpiration?: InputMaybe<Scalars['String']['input']>;
  resetPasswordToken?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<InputMaybe<User_Roles_MutationInput>>>;
  salt?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUserUpdateInput = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  hash?: InputMaybe<Scalars['String']['input']>;
  lockUntil?: InputMaybe<Scalars['String']['input']>;
  loginAttempts?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  resetPasswordExpiration?: InputMaybe<Scalars['String']['input']>;
  resetPasswordToken?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<InputMaybe<UserUpdate_Roles_MutationInput>>>;
  salt?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type Payload_PreferencesAccess = {
  __typename?: 'payload_preferencesAccess';
  create?: Maybe<PayloadPreferencesCreateAccess>;
  delete?: Maybe<PayloadPreferencesDeleteAccess>;
  fields?: Maybe<PayloadPreferencesFields>;
  read?: Maybe<PayloadPreferencesReadAccess>;
  update?: Maybe<PayloadPreferencesUpdateAccess>;
};

export type Payload_PreferencesDocAccess = {
  __typename?: 'payload_preferencesDocAccess';
  create?: Maybe<PayloadPreferencesCreateDocAccess>;
  delete?: Maybe<PayloadPreferencesDeleteDocAccess>;
  fields?: Maybe<PayloadPreferencesDocAccessFields>;
  read?: Maybe<PayloadPreferencesReadDocAccess>;
  update?: Maybe<PayloadPreferencesUpdateDocAccess>;
};

export type Task_TemplatesAccess = {
  __typename?: 'task_templatesAccess';
  create?: Maybe<TaskTemplatesCreateAccess>;
  delete?: Maybe<TaskTemplatesDeleteAccess>;
  fields?: Maybe<TaskTemplatesFields>;
  read?: Maybe<TaskTemplatesReadAccess>;
  readVersions?: Maybe<TaskTemplatesReadVersionsAccess>;
  update?: Maybe<TaskTemplatesUpdateAccess>;
};

export type Task_TemplatesDocAccess = {
  __typename?: 'task_templatesDocAccess';
  create?: Maybe<TaskTemplatesCreateDocAccess>;
  delete?: Maybe<TaskTemplatesDeleteDocAccess>;
  fields?: Maybe<TaskTemplatesDocAccessFields>;
  read?: Maybe<TaskTemplatesReadDocAccess>;
  readVersions?: Maybe<TaskTemplatesReadVersionsDocAccess>;
  update?: Maybe<TaskTemplatesUpdateDocAccess>;
};

export type UsersAccess = {
  __typename?: 'usersAccess';
  create?: Maybe<UsersCreateAccess>;
  delete?: Maybe<UsersDeleteAccess>;
  fields?: Maybe<UsersFields>;
  read?: Maybe<UsersReadAccess>;
  unlock?: Maybe<UsersUnlockAccess>;
  update?: Maybe<UsersUpdateAccess>;
};

export type UsersDocAccess = {
  __typename?: 'usersDocAccess';
  create?: Maybe<UsersCreateDocAccess>;
  delete?: Maybe<UsersDeleteDocAccess>;
  fields?: Maybe<UsersDocAccessFields>;
  read?: Maybe<UsersReadDocAccess>;
  unlock?: Maybe<UsersUnlockDocAccess>;
  update?: Maybe<UsersUpdateDocAccess>;
};

export type UsersJwt = {
  __typename?: 'usersJWT';
  collection: Scalars['String']['output'];
  email: Scalars['EmailAddress']['output'];
};

export type UsersLoginResult = {
  __typename?: 'usersLoginResult';
  exp?: Maybe<Scalars['Int']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type UsersMe = {
  __typename?: 'usersMe';
  collection?: Maybe<Scalars['String']['output']>;
  exp?: Maybe<Scalars['Int']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type UsersRefreshedUser = {
  __typename?: 'usersRefreshedUser';
  exp?: Maybe<Scalars['Int']['output']>;
  refreshedToken?: Maybe<Scalars['String']['output']>;
  user?: Maybe<UsersJwt>;
};

export type UsersResetPassword = {
  __typename?: 'usersResetPassword';
  token?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type VersionsArticle_CreatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type VersionsArticle_Id_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type VersionsArticle_Latest_Operator = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  not_equals?: InputMaybe<Scalars['Boolean']['input']>;
};

export type VersionsArticle_Parent_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
};

export type VersionsArticle_UpdatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export enum VersionsArticle_Version___Status_Input {
  Draft = 'draft',
  Published = 'published'
}

export type VersionsArticle_Version___Status_Operator = {
  all?: InputMaybe<Array<InputMaybe<VersionsArticle_Version___Status_Input>>>;
  equals?: InputMaybe<VersionsArticle_Version___Status_Input>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<VersionsArticle_Version___Status_Input>>>;
  not_equals?: InputMaybe<VersionsArticle_Version___Status_Input>;
  not_in?: InputMaybe<Array<InputMaybe<VersionsArticle_Version___Status_Input>>>;
};

export type VersionsArticle_Version__Author_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
};

export type VersionsArticle_Version__Categories_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
};

export type VersionsArticle_Version__CreatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type VersionsArticle_Version__Hero__Media_Operator = {
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
};

export type VersionsArticle_Version__Hero__RichText_Operator = {
  contains?: InputMaybe<Scalars['JSON']['input']>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  like?: InputMaybe<Scalars['JSON']['input']>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
};

export type VersionsArticle_Version__Meta__Description_Operator = {
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
};

export type VersionsArticle_Version__Meta__Image_Operator = {
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
};

export type VersionsArticle_Version__Meta__Title_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type VersionsArticle_Version__PopulatedAuthor__Id_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type VersionsArticle_Version__PopulatedAuthor__Name_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type VersionsArticle_Version__PublishedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type VersionsArticle_Version__RelatedArticles_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
};

export type VersionsArticle_Version__Slug_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type VersionsArticle_Version__Title_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type VersionsArticle_Version__UpdatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type VersionsArticle_Where = {
  AND?: InputMaybe<Array<InputMaybe<VersionsArticle_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<VersionsArticle_Where_Or>>>;
  createdAt?: InputMaybe<VersionsArticle_CreatedAt_Operator>;
  id?: InputMaybe<VersionsArticle_Id_Operator>;
  latest?: InputMaybe<VersionsArticle_Latest_Operator>;
  parent?: InputMaybe<VersionsArticle_Parent_Operator>;
  updatedAt?: InputMaybe<VersionsArticle_UpdatedAt_Operator>;
  version___status?: InputMaybe<VersionsArticle_Version___Status_Operator>;
  version__author?: InputMaybe<VersionsArticle_Version__Author_Operator>;
  version__categories?: InputMaybe<VersionsArticle_Version__Categories_Operator>;
  version__createdAt?: InputMaybe<VersionsArticle_Version__CreatedAt_Operator>;
  version__hero__media?: InputMaybe<VersionsArticle_Version__Hero__Media_Operator>;
  version__hero__richText?: InputMaybe<VersionsArticle_Version__Hero__RichText_Operator>;
  version__meta__description?: InputMaybe<VersionsArticle_Version__Meta__Description_Operator>;
  version__meta__image?: InputMaybe<VersionsArticle_Version__Meta__Image_Operator>;
  version__meta__title?: InputMaybe<VersionsArticle_Version__Meta__Title_Operator>;
  version__populatedAuthor__id?: InputMaybe<VersionsArticle_Version__PopulatedAuthor__Id_Operator>;
  version__populatedAuthor__name?: InputMaybe<VersionsArticle_Version__PopulatedAuthor__Name_Operator>;
  version__publishedAt?: InputMaybe<VersionsArticle_Version__PublishedAt_Operator>;
  version__relatedArticles?: InputMaybe<VersionsArticle_Version__RelatedArticles_Operator>;
  version__slug?: InputMaybe<VersionsArticle_Version__Slug_Operator>;
  version__title?: InputMaybe<VersionsArticle_Version__Title_Operator>;
  version__updatedAt?: InputMaybe<VersionsArticle_Version__UpdatedAt_Operator>;
};

export type VersionsArticle_Where_And = {
  AND?: InputMaybe<Array<InputMaybe<VersionsArticle_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<VersionsArticle_Where_Or>>>;
  createdAt?: InputMaybe<VersionsArticle_CreatedAt_Operator>;
  id?: InputMaybe<VersionsArticle_Id_Operator>;
  latest?: InputMaybe<VersionsArticle_Latest_Operator>;
  parent?: InputMaybe<VersionsArticle_Parent_Operator>;
  updatedAt?: InputMaybe<VersionsArticle_UpdatedAt_Operator>;
  version___status?: InputMaybe<VersionsArticle_Version___Status_Operator>;
  version__author?: InputMaybe<VersionsArticle_Version__Author_Operator>;
  version__categories?: InputMaybe<VersionsArticle_Version__Categories_Operator>;
  version__createdAt?: InputMaybe<VersionsArticle_Version__CreatedAt_Operator>;
  version__hero__media?: InputMaybe<VersionsArticle_Version__Hero__Media_Operator>;
  version__hero__richText?: InputMaybe<VersionsArticle_Version__Hero__RichText_Operator>;
  version__meta__description?: InputMaybe<VersionsArticle_Version__Meta__Description_Operator>;
  version__meta__image?: InputMaybe<VersionsArticle_Version__Meta__Image_Operator>;
  version__meta__title?: InputMaybe<VersionsArticle_Version__Meta__Title_Operator>;
  version__populatedAuthor__id?: InputMaybe<VersionsArticle_Version__PopulatedAuthor__Id_Operator>;
  version__populatedAuthor__name?: InputMaybe<VersionsArticle_Version__PopulatedAuthor__Name_Operator>;
  version__publishedAt?: InputMaybe<VersionsArticle_Version__PublishedAt_Operator>;
  version__relatedArticles?: InputMaybe<VersionsArticle_Version__RelatedArticles_Operator>;
  version__slug?: InputMaybe<VersionsArticle_Version__Slug_Operator>;
  version__title?: InputMaybe<VersionsArticle_Version__Title_Operator>;
  version__updatedAt?: InputMaybe<VersionsArticle_Version__UpdatedAt_Operator>;
};

export type VersionsArticle_Where_Or = {
  AND?: InputMaybe<Array<InputMaybe<VersionsArticle_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<VersionsArticle_Where_Or>>>;
  createdAt?: InputMaybe<VersionsArticle_CreatedAt_Operator>;
  id?: InputMaybe<VersionsArticle_Id_Operator>;
  latest?: InputMaybe<VersionsArticle_Latest_Operator>;
  parent?: InputMaybe<VersionsArticle_Parent_Operator>;
  updatedAt?: InputMaybe<VersionsArticle_UpdatedAt_Operator>;
  version___status?: InputMaybe<VersionsArticle_Version___Status_Operator>;
  version__author?: InputMaybe<VersionsArticle_Version__Author_Operator>;
  version__categories?: InputMaybe<VersionsArticle_Version__Categories_Operator>;
  version__createdAt?: InputMaybe<VersionsArticle_Version__CreatedAt_Operator>;
  version__hero__media?: InputMaybe<VersionsArticle_Version__Hero__Media_Operator>;
  version__hero__richText?: InputMaybe<VersionsArticle_Version__Hero__RichText_Operator>;
  version__meta__description?: InputMaybe<VersionsArticle_Version__Meta__Description_Operator>;
  version__meta__image?: InputMaybe<VersionsArticle_Version__Meta__Image_Operator>;
  version__meta__title?: InputMaybe<VersionsArticle_Version__Meta__Title_Operator>;
  version__populatedAuthor__id?: InputMaybe<VersionsArticle_Version__PopulatedAuthor__Id_Operator>;
  version__populatedAuthor__name?: InputMaybe<VersionsArticle_Version__PopulatedAuthor__Name_Operator>;
  version__publishedAt?: InputMaybe<VersionsArticle_Version__PublishedAt_Operator>;
  version__relatedArticles?: InputMaybe<VersionsArticle_Version__RelatedArticles_Operator>;
  version__slug?: InputMaybe<VersionsArticle_Version__Slug_Operator>;
  version__title?: InputMaybe<VersionsArticle_Version__Title_Operator>;
  version__updatedAt?: InputMaybe<VersionsArticle_Version__UpdatedAt_Operator>;
};

export type VersionsArticles = {
  __typename?: 'versionsArticles';
  docs?: Maybe<Array<Maybe<ArticleVersion>>>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  nextPage?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  pagingCounter?: Maybe<Scalars['Int']['output']>;
  prevPage?: Maybe<Scalars['Int']['output']>;
  totalDocs?: Maybe<Scalars['Int']['output']>;
  totalPages?: Maybe<Scalars['Int']['output']>;
};

export type VersionsTaskTemplate_CreatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type VersionsTaskTemplate_Id_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type VersionsTaskTemplate_Latest_Operator = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  not_equals?: InputMaybe<Scalars['Boolean']['input']>;
};

export type VersionsTaskTemplate_Parent_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
};

export enum VersionsTaskTemplate_Recurrence__Freq_Input {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY',
  Yearly = 'YEARLY'
}

export type VersionsTaskTemplate_Recurrence__Freq_Operator = {
  all?: InputMaybe<Array<InputMaybe<VersionsTaskTemplate_Recurrence__Freq_Input>>>;
  equals?: InputMaybe<VersionsTaskTemplate_Recurrence__Freq_Input>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<VersionsTaskTemplate_Recurrence__Freq_Input>>>;
  not_equals?: InputMaybe<VersionsTaskTemplate_Recurrence__Freq_Input>;
  not_in?: InputMaybe<Array<InputMaybe<VersionsTaskTemplate_Recurrence__Freq_Input>>>;
};

export type VersionsTaskTemplate_Recurrence__Interval_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export type VersionsTaskTemplate_UpdatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export enum VersionsTaskTemplate_Version___Status_Input {
  Draft = 'draft',
  Published = 'published'
}

export type VersionsTaskTemplate_Version___Status_Operator = {
  all?: InputMaybe<Array<InputMaybe<VersionsTaskTemplate_Version___Status_Input>>>;
  equals?: InputMaybe<VersionsTaskTemplate_Version___Status_Input>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<VersionsTaskTemplate_Version___Status_Input>>>;
  not_equals?: InputMaybe<VersionsTaskTemplate_Version___Status_Input>;
  not_in?: InputMaybe<Array<InputMaybe<VersionsTaskTemplate_Version___Status_Input>>>;
};

export enum VersionsTaskTemplate_Version__Assignment_Input {
  Manager = 'Manager',
  Tenants = 'Tenants'
}

export type VersionsTaskTemplate_Version__Assignment_Operator = {
  all?: InputMaybe<Array<InputMaybe<VersionsTaskTemplate_Version__Assignment_Input>>>;
  equals?: InputMaybe<VersionsTaskTemplate_Version__Assignment_Input>;
  in?: InputMaybe<Array<InputMaybe<VersionsTaskTemplate_Version__Assignment_Input>>>;
  not_equals?: InputMaybe<VersionsTaskTemplate_Version__Assignment_Input>;
  not_in?: InputMaybe<Array<InputMaybe<VersionsTaskTemplate_Version__Assignment_Input>>>;
};

export enum VersionsTaskTemplate_Version__Categories_Input {
  Administrative = 'Administrative',
  Legal = 'Legal',
  Maintenance = 'Maintenance',
  Safety = 'Safety',
  Turnover = 'Turnover'
}

export type VersionsTaskTemplate_Version__Categories_Operator = {
  all?: InputMaybe<Array<InputMaybe<VersionsTaskTemplate_Version__Categories_Input>>>;
  equals?: InputMaybe<VersionsTaskTemplate_Version__Categories_Input>;
  in?: InputMaybe<Array<InputMaybe<VersionsTaskTemplate_Version__Categories_Input>>>;
  not_equals?: InputMaybe<VersionsTaskTemplate_Version__Categories_Input>;
  not_in?: InputMaybe<Array<InputMaybe<VersionsTaskTemplate_Version__Categories_Input>>>;
};

export type VersionsTaskTemplate_Version__CreatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type VersionsTaskTemplate_Version__Description_Operator = {
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
};

export type VersionsTaskTemplate_Version__Details_Operator = {
  contains?: InputMaybe<Scalars['JSON']['input']>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  like?: InputMaybe<Scalars['JSON']['input']>;
  not_equals?: InputMaybe<Scalars['JSON']['input']>;
};

export enum VersionsTaskTemplate_Version__Feature_Input {
  Basement = 'basement',
  Garage = 'garage',
  HotTub = 'hot_tub',
  HotWater = 'hot_water',
  Hvac = 'hvac',
  Kitchen = 'kitchen',
  Laundry = 'laundry',
  Lawn = 'lawn',
  Pool = 'pool',
  Structural = 'structural',
  SumpPump = 'sump_pump',
  WaterSoftener = 'water_softener'
}

export type VersionsTaskTemplate_Version__Feature_Operator = {
  all?: InputMaybe<Array<InputMaybe<VersionsTaskTemplate_Version__Feature_Input>>>;
  equals?: InputMaybe<VersionsTaskTemplate_Version__Feature_Input>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<VersionsTaskTemplate_Version__Feature_Input>>>;
  not_equals?: InputMaybe<VersionsTaskTemplate_Version__Feature_Input>;
  not_in?: InputMaybe<Array<InputMaybe<VersionsTaskTemplate_Version__Feature_Input>>>;
};

export type VersionsTaskTemplate_Version__Resources__Articles_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type VersionsTaskTemplate_Version__Resources__Videos_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export enum VersionsTaskTemplate_Version__Review__Approver_Input {
  Manager = 'manager'
}

export type VersionsTaskTemplate_Version__Review__Approver_Operator = {
  all?: InputMaybe<Array<InputMaybe<VersionsTaskTemplate_Version__Review__Approver_Input>>>;
  equals?: InputMaybe<VersionsTaskTemplate_Version__Review__Approver_Input>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<VersionsTaskTemplate_Version__Review__Approver_Input>>>;
  not_equals?: InputMaybe<VersionsTaskTemplate_Version__Review__Approver_Input>;
  not_in?: InputMaybe<Array<InputMaybe<VersionsTaskTemplate_Version__Review__Approver_Input>>>;
};

export type VersionsTaskTemplate_Version__Review__Evidence_Operator = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  not_equals?: InputMaybe<Scalars['Boolean']['input']>;
};

export type VersionsTaskTemplate_Version__Review__Required_Operator = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  not_equals?: InputMaybe<Scalars['Boolean']['input']>;
};

export type VersionsTaskTemplate_Version__Schedule__Due_Operator = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['Float']['input']>;
  greater_than_equal?: InputMaybe<Scalars['Float']['input']>;
  less_than?: InputMaybe<Scalars['Float']['input']>;
  less_than_equal?: InputMaybe<Scalars['Float']['input']>;
  not_equals?: InputMaybe<Scalars['Float']['input']>;
};

export enum VersionsTaskTemplate_Version__Schedule__Event_Input {
  MoveIn = 'move_in',
  MoveOut = 'move_out'
}

export type VersionsTaskTemplate_Version__Schedule__Event_Operator = {
  all?: InputMaybe<Array<InputMaybe<VersionsTaskTemplate_Version__Schedule__Event_Input>>>;
  equals?: InputMaybe<VersionsTaskTemplate_Version__Schedule__Event_Input>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<VersionsTaskTemplate_Version__Schedule__Event_Input>>>;
  not_equals?: InputMaybe<VersionsTaskTemplate_Version__Schedule__Event_Input>;
  not_in?: InputMaybe<Array<InputMaybe<VersionsTaskTemplate_Version__Schedule__Event_Input>>>;
};

export type VersionsTaskTemplate_Version__Schedule__Id_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type VersionsTaskTemplate_Version__Thumbnail_Operator = {
  equals?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
};

export type VersionsTaskTemplate_Version__Title_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not_equals?: InputMaybe<Scalars['String']['input']>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type VersionsTaskTemplate_Version__UpdatedAt_Operator = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  greater_than?: InputMaybe<Scalars['DateTime']['input']>;
  greater_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  less_than?: InputMaybe<Scalars['DateTime']['input']>;
  less_than_equal?: InputMaybe<Scalars['DateTime']['input']>;
  like?: InputMaybe<Scalars['DateTime']['input']>;
  not_equals?: InputMaybe<Scalars['DateTime']['input']>;
};

export type VersionsTaskTemplate_Where = {
  AND?: InputMaybe<Array<InputMaybe<VersionsTaskTemplate_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<VersionsTaskTemplate_Where_Or>>>;
  createdAt?: InputMaybe<VersionsTaskTemplate_CreatedAt_Operator>;
  id?: InputMaybe<VersionsTaskTemplate_Id_Operator>;
  latest?: InputMaybe<VersionsTaskTemplate_Latest_Operator>;
  parent?: InputMaybe<VersionsTaskTemplate_Parent_Operator>;
  recurrence__freq?: InputMaybe<VersionsTaskTemplate_Recurrence__Freq_Operator>;
  recurrence__interval?: InputMaybe<VersionsTaskTemplate_Recurrence__Interval_Operator>;
  updatedAt?: InputMaybe<VersionsTaskTemplate_UpdatedAt_Operator>;
  version___status?: InputMaybe<VersionsTaskTemplate_Version___Status_Operator>;
  version__assignment?: InputMaybe<VersionsTaskTemplate_Version__Assignment_Operator>;
  version__categories?: InputMaybe<VersionsTaskTemplate_Version__Categories_Operator>;
  version__createdAt?: InputMaybe<VersionsTaskTemplate_Version__CreatedAt_Operator>;
  version__description?: InputMaybe<VersionsTaskTemplate_Version__Description_Operator>;
  version__details?: InputMaybe<VersionsTaskTemplate_Version__Details_Operator>;
  version__feature?: InputMaybe<VersionsTaskTemplate_Version__Feature_Operator>;
  version__resources__articles?: InputMaybe<VersionsTaskTemplate_Version__Resources__Articles_Operator>;
  version__resources__videos?: InputMaybe<VersionsTaskTemplate_Version__Resources__Videos_Operator>;
  version__review__approver?: InputMaybe<VersionsTaskTemplate_Version__Review__Approver_Operator>;
  version__review__evidence?: InputMaybe<VersionsTaskTemplate_Version__Review__Evidence_Operator>;
  version__review__required?: InputMaybe<VersionsTaskTemplate_Version__Review__Required_Operator>;
  version__schedule__due?: InputMaybe<VersionsTaskTemplate_Version__Schedule__Due_Operator>;
  version__schedule__event?: InputMaybe<VersionsTaskTemplate_Version__Schedule__Event_Operator>;
  version__schedule__id?: InputMaybe<VersionsTaskTemplate_Version__Schedule__Id_Operator>;
  version__thumbnail?: InputMaybe<VersionsTaskTemplate_Version__Thumbnail_Operator>;
  version__title?: InputMaybe<VersionsTaskTemplate_Version__Title_Operator>;
  version__updatedAt?: InputMaybe<VersionsTaskTemplate_Version__UpdatedAt_Operator>;
};

export type VersionsTaskTemplate_Where_And = {
  AND?: InputMaybe<Array<InputMaybe<VersionsTaskTemplate_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<VersionsTaskTemplate_Where_Or>>>;
  createdAt?: InputMaybe<VersionsTaskTemplate_CreatedAt_Operator>;
  id?: InputMaybe<VersionsTaskTemplate_Id_Operator>;
  latest?: InputMaybe<VersionsTaskTemplate_Latest_Operator>;
  parent?: InputMaybe<VersionsTaskTemplate_Parent_Operator>;
  recurrence__freq?: InputMaybe<VersionsTaskTemplate_Recurrence__Freq_Operator>;
  recurrence__interval?: InputMaybe<VersionsTaskTemplate_Recurrence__Interval_Operator>;
  updatedAt?: InputMaybe<VersionsTaskTemplate_UpdatedAt_Operator>;
  version___status?: InputMaybe<VersionsTaskTemplate_Version___Status_Operator>;
  version__assignment?: InputMaybe<VersionsTaskTemplate_Version__Assignment_Operator>;
  version__categories?: InputMaybe<VersionsTaskTemplate_Version__Categories_Operator>;
  version__createdAt?: InputMaybe<VersionsTaskTemplate_Version__CreatedAt_Operator>;
  version__description?: InputMaybe<VersionsTaskTemplate_Version__Description_Operator>;
  version__details?: InputMaybe<VersionsTaskTemplate_Version__Details_Operator>;
  version__feature?: InputMaybe<VersionsTaskTemplate_Version__Feature_Operator>;
  version__resources__articles?: InputMaybe<VersionsTaskTemplate_Version__Resources__Articles_Operator>;
  version__resources__videos?: InputMaybe<VersionsTaskTemplate_Version__Resources__Videos_Operator>;
  version__review__approver?: InputMaybe<VersionsTaskTemplate_Version__Review__Approver_Operator>;
  version__review__evidence?: InputMaybe<VersionsTaskTemplate_Version__Review__Evidence_Operator>;
  version__review__required?: InputMaybe<VersionsTaskTemplate_Version__Review__Required_Operator>;
  version__schedule__due?: InputMaybe<VersionsTaskTemplate_Version__Schedule__Due_Operator>;
  version__schedule__event?: InputMaybe<VersionsTaskTemplate_Version__Schedule__Event_Operator>;
  version__schedule__id?: InputMaybe<VersionsTaskTemplate_Version__Schedule__Id_Operator>;
  version__thumbnail?: InputMaybe<VersionsTaskTemplate_Version__Thumbnail_Operator>;
  version__title?: InputMaybe<VersionsTaskTemplate_Version__Title_Operator>;
  version__updatedAt?: InputMaybe<VersionsTaskTemplate_Version__UpdatedAt_Operator>;
};

export type VersionsTaskTemplate_Where_Or = {
  AND?: InputMaybe<Array<InputMaybe<VersionsTaskTemplate_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<VersionsTaskTemplate_Where_Or>>>;
  createdAt?: InputMaybe<VersionsTaskTemplate_CreatedAt_Operator>;
  id?: InputMaybe<VersionsTaskTemplate_Id_Operator>;
  latest?: InputMaybe<VersionsTaskTemplate_Latest_Operator>;
  parent?: InputMaybe<VersionsTaskTemplate_Parent_Operator>;
  recurrence__freq?: InputMaybe<VersionsTaskTemplate_Recurrence__Freq_Operator>;
  recurrence__interval?: InputMaybe<VersionsTaskTemplate_Recurrence__Interval_Operator>;
  updatedAt?: InputMaybe<VersionsTaskTemplate_UpdatedAt_Operator>;
  version___status?: InputMaybe<VersionsTaskTemplate_Version___Status_Operator>;
  version__assignment?: InputMaybe<VersionsTaskTemplate_Version__Assignment_Operator>;
  version__categories?: InputMaybe<VersionsTaskTemplate_Version__Categories_Operator>;
  version__createdAt?: InputMaybe<VersionsTaskTemplate_Version__CreatedAt_Operator>;
  version__description?: InputMaybe<VersionsTaskTemplate_Version__Description_Operator>;
  version__details?: InputMaybe<VersionsTaskTemplate_Version__Details_Operator>;
  version__feature?: InputMaybe<VersionsTaskTemplate_Version__Feature_Operator>;
  version__resources__articles?: InputMaybe<VersionsTaskTemplate_Version__Resources__Articles_Operator>;
  version__resources__videos?: InputMaybe<VersionsTaskTemplate_Version__Resources__Videos_Operator>;
  version__review__approver?: InputMaybe<VersionsTaskTemplate_Version__Review__Approver_Operator>;
  version__review__evidence?: InputMaybe<VersionsTaskTemplate_Version__Review__Evidence_Operator>;
  version__review__required?: InputMaybe<VersionsTaskTemplate_Version__Review__Required_Operator>;
  version__schedule__due?: InputMaybe<VersionsTaskTemplate_Version__Schedule__Due_Operator>;
  version__schedule__event?: InputMaybe<VersionsTaskTemplate_Version__Schedule__Event_Operator>;
  version__schedule__id?: InputMaybe<VersionsTaskTemplate_Version__Schedule__Id_Operator>;
  version__thumbnail?: InputMaybe<VersionsTaskTemplate_Version__Thumbnail_Operator>;
  version__title?: InputMaybe<VersionsTaskTemplate_Version__Title_Operator>;
  version__updatedAt?: InputMaybe<VersionsTaskTemplate_Version__UpdatedAt_Operator>;
};

export type VersionsTaskTemplates = {
  __typename?: 'versionsTaskTemplates';
  docs?: Maybe<Array<Maybe<TaskTemplateVersion>>>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  nextPage?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  pagingCounter?: Maybe<Scalars['Int']['output']>;
  prevPage?: Maybe<Scalars['Int']['output']>;
  totalDocs?: Maybe<Scalars['Int']['output']>;
  totalPages?: Maybe<Scalars['Int']['output']>;
};



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping of union types */
export type ResolversUnionTypes<RefType extends Record<string, unknown>> = {
  ArticleVersion_Version_Content: ( Content ) | ( Cta ) | ( MediaBlock );
  Article_Content: ( Content ) | ( Cta ) | ( MediaBlock );
  Cta_Links_Link_Reference: ( Omit<Article, 'content'> & { content: Array<RefType['Article_Content']> } );
  PayloadPreference_User: ( User );
};


/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  Access: ResolverTypeWrapper<Access>;
  Article: ResolverTypeWrapper<Omit<Article, 'content'> & { content: Array<ResolversTypes['Article_Content']> }>;
  ArticleUpdate__status_MutationInput: ArticleUpdate__Status_MutationInput;
  ArticleVersion: ResolverTypeWrapper<ArticleVersion>;
  ArticleVersion_Version: ResolverTypeWrapper<Omit<ArticleVersion_Version, 'content'> & { content: Array<ResolversTypes['ArticleVersion_Version_Content']> }>;
  ArticleVersion_Version_Content: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['ArticleVersion_Version_Content']>;
  ArticleVersion_Version_Hero: ResolverTypeWrapper<ArticleVersion_Version_Hero>;
  ArticleVersion_Version_Hero_Media_alt_operator: ArticleVersion_Version_Hero_Media_Alt_Operator;
  ArticleVersion_Version_Hero_Media_caption_operator: ArticleVersion_Version_Hero_Media_Caption_Operator;
  ArticleVersion_Version_Hero_Media_createdAt_operator: ArticleVersion_Version_Hero_Media_CreatedAt_Operator;
  ArticleVersion_Version_Hero_Media_filename_operator: ArticleVersion_Version_Hero_Media_Filename_Operator;
  ArticleVersion_Version_Hero_Media_filesize_operator: ArticleVersion_Version_Hero_Media_Filesize_Operator;
  ArticleVersion_Version_Hero_Media_height_operator: ArticleVersion_Version_Hero_Media_Height_Operator;
  ArticleVersion_Version_Hero_Media_id_operator: ArticleVersion_Version_Hero_Media_Id_Operator;
  ArticleVersion_Version_Hero_Media_mimeType_operator: ArticleVersion_Version_Hero_Media_MimeType_Operator;
  ArticleVersion_Version_Hero_Media_updatedAt_operator: ArticleVersion_Version_Hero_Media_UpdatedAt_Operator;
  ArticleVersion_Version_Hero_Media_url_operator: ArticleVersion_Version_Hero_Media_Url_Operator;
  ArticleVersion_Version_Hero_Media_where: ArticleVersion_Version_Hero_Media_Where;
  ArticleVersion_Version_Hero_Media_where_and: ArticleVersion_Version_Hero_Media_Where_And;
  ArticleVersion_Version_Hero_Media_where_or: ArticleVersion_Version_Hero_Media_Where_Or;
  ArticleVersion_Version_Hero_Media_width_operator: ArticleVersion_Version_Hero_Media_Width_Operator;
  ArticleVersion_Version_Meta: ResolverTypeWrapper<ArticleVersion_Version_Meta>;
  ArticleVersion_Version_Meta_Image_alt_operator: ArticleVersion_Version_Meta_Image_Alt_Operator;
  ArticleVersion_Version_Meta_Image_caption_operator: ArticleVersion_Version_Meta_Image_Caption_Operator;
  ArticleVersion_Version_Meta_Image_createdAt_operator: ArticleVersion_Version_Meta_Image_CreatedAt_Operator;
  ArticleVersion_Version_Meta_Image_filename_operator: ArticleVersion_Version_Meta_Image_Filename_Operator;
  ArticleVersion_Version_Meta_Image_filesize_operator: ArticleVersion_Version_Meta_Image_Filesize_Operator;
  ArticleVersion_Version_Meta_Image_height_operator: ArticleVersion_Version_Meta_Image_Height_Operator;
  ArticleVersion_Version_Meta_Image_id_operator: ArticleVersion_Version_Meta_Image_Id_Operator;
  ArticleVersion_Version_Meta_Image_mimeType_operator: ArticleVersion_Version_Meta_Image_MimeType_Operator;
  ArticleVersion_Version_Meta_Image_updatedAt_operator: ArticleVersion_Version_Meta_Image_UpdatedAt_Operator;
  ArticleVersion_Version_Meta_Image_url_operator: ArticleVersion_Version_Meta_Image_Url_Operator;
  ArticleVersion_Version_Meta_Image_where: ArticleVersion_Version_Meta_Image_Where;
  ArticleVersion_Version_Meta_Image_where_and: ArticleVersion_Version_Meta_Image_Where_And;
  ArticleVersion_Version_Meta_Image_where_or: ArticleVersion_Version_Meta_Image_Where_Or;
  ArticleVersion_Version_Meta_Image_width_operator: ArticleVersion_Version_Meta_Image_Width_Operator;
  ArticleVersion_Version_PopulatedAuthor: ResolverTypeWrapper<ArticleVersion_Version_PopulatedAuthor>;
  ArticleVersion_Version__status: ArticleVersion_Version__Status;
  Article_Content: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['Article_Content']>;
  Article_Hero: ResolverTypeWrapper<Article_Hero>;
  Article_Hero_Media_alt_operator: Article_Hero_Media_Alt_Operator;
  Article_Hero_Media_caption_operator: Article_Hero_Media_Caption_Operator;
  Article_Hero_Media_createdAt_operator: Article_Hero_Media_CreatedAt_Operator;
  Article_Hero_Media_filename_operator: Article_Hero_Media_Filename_Operator;
  Article_Hero_Media_filesize_operator: Article_Hero_Media_Filesize_Operator;
  Article_Hero_Media_height_operator: Article_Hero_Media_Height_Operator;
  Article_Hero_Media_id_operator: Article_Hero_Media_Id_Operator;
  Article_Hero_Media_mimeType_operator: Article_Hero_Media_MimeType_Operator;
  Article_Hero_Media_updatedAt_operator: Article_Hero_Media_UpdatedAt_Operator;
  Article_Hero_Media_url_operator: Article_Hero_Media_Url_Operator;
  Article_Hero_Media_where: Article_Hero_Media_Where;
  Article_Hero_Media_where_and: Article_Hero_Media_Where_And;
  Article_Hero_Media_where_or: Article_Hero_Media_Where_Or;
  Article_Hero_Media_width_operator: Article_Hero_Media_Width_Operator;
  Article_Meta: ResolverTypeWrapper<Article_Meta>;
  Article_Meta_Image_alt_operator: Article_Meta_Image_Alt_Operator;
  Article_Meta_Image_caption_operator: Article_Meta_Image_Caption_Operator;
  Article_Meta_Image_createdAt_operator: Article_Meta_Image_CreatedAt_Operator;
  Article_Meta_Image_filename_operator: Article_Meta_Image_Filename_Operator;
  Article_Meta_Image_filesize_operator: Article_Meta_Image_Filesize_Operator;
  Article_Meta_Image_height_operator: Article_Meta_Image_Height_Operator;
  Article_Meta_Image_id_operator: Article_Meta_Image_Id_Operator;
  Article_Meta_Image_mimeType_operator: Article_Meta_Image_MimeType_Operator;
  Article_Meta_Image_updatedAt_operator: Article_Meta_Image_UpdatedAt_Operator;
  Article_Meta_Image_url_operator: Article_Meta_Image_Url_Operator;
  Article_Meta_Image_where: Article_Meta_Image_Where;
  Article_Meta_Image_where_and: Article_Meta_Image_Where_And;
  Article_Meta_Image_where_or: Article_Meta_Image_Where_Or;
  Article_Meta_Image_width_operator: Article_Meta_Image_Width_Operator;
  Article_PopulatedAuthor: ResolverTypeWrapper<Article_PopulatedAuthor>;
  Article__status: Article__Status;
  Article__status_Input: Article__Status_Input;
  Article__status_MutationInput: Article__Status_MutationInput;
  Article__status_operator: Article__Status_Operator;
  Article_author_operator: Article_Author_Operator;
  Article_categories_operator: Article_Categories_Operator;
  Article_createdAt_operator: Article_CreatedAt_Operator;
  Article_hero__media_operator: Article_Hero__Media_Operator;
  Article_hero__richText_operator: Article_Hero__RichText_Operator;
  Article_id_operator: Article_Id_Operator;
  Article_meta__description_operator: Article_Meta__Description_Operator;
  Article_meta__image_operator: Article_Meta__Image_Operator;
  Article_meta__title_operator: Article_Meta__Title_Operator;
  Article_populatedAuthor__id_operator: Article_PopulatedAuthor__Id_Operator;
  Article_populatedAuthor__name_operator: Article_PopulatedAuthor__Name_Operator;
  Article_publishedAt_operator: Article_PublishedAt_Operator;
  Article_relatedArticles_operator: Article_RelatedArticles_Operator;
  Article_slug_operator: Article_Slug_Operator;
  Article_title_operator: Article_Title_Operator;
  Article_updatedAt_operator: Article_UpdatedAt_Operator;
  Article_where: Article_Where;
  Article_where_and: Article_Where_And;
  Article_where_or: Article_Where_Or;
  Articles: ResolverTypeWrapper<Articles>;
  ArticlesCreateAccess: ResolverTypeWrapper<ArticlesCreateAccess>;
  ArticlesCreateDocAccess: ResolverTypeWrapper<ArticlesCreateDocAccess>;
  ArticlesDeleteAccess: ResolverTypeWrapper<ArticlesDeleteAccess>;
  ArticlesDeleteDocAccess: ResolverTypeWrapper<ArticlesDeleteDocAccess>;
  ArticlesDocAccessFields: ResolverTypeWrapper<ArticlesDocAccessFields>;
  ArticlesDocAccessFields__status: ResolverTypeWrapper<ArticlesDocAccessFields__Status>;
  ArticlesDocAccessFields__status_Create: ResolverTypeWrapper<ArticlesDocAccessFields__Status_Create>;
  ArticlesDocAccessFields__status_Delete: ResolverTypeWrapper<ArticlesDocAccessFields__Status_Delete>;
  ArticlesDocAccessFields__status_Read: ResolverTypeWrapper<ArticlesDocAccessFields__Status_Read>;
  ArticlesDocAccessFields__status_Update: ResolverTypeWrapper<ArticlesDocAccessFields__Status_Update>;
  ArticlesDocAccessFields_author: ResolverTypeWrapper<ArticlesDocAccessFields_Author>;
  ArticlesDocAccessFields_author_Create: ResolverTypeWrapper<ArticlesDocAccessFields_Author_Create>;
  ArticlesDocAccessFields_author_Delete: ResolverTypeWrapper<ArticlesDocAccessFields_Author_Delete>;
  ArticlesDocAccessFields_author_Read: ResolverTypeWrapper<ArticlesDocAccessFields_Author_Read>;
  ArticlesDocAccessFields_author_Update: ResolverTypeWrapper<ArticlesDocAccessFields_Author_Update>;
  ArticlesDocAccessFields_categories: ResolverTypeWrapper<ArticlesDocAccessFields_Categories>;
  ArticlesDocAccessFields_categories_Create: ResolverTypeWrapper<ArticlesDocAccessFields_Categories_Create>;
  ArticlesDocAccessFields_categories_Delete: ResolverTypeWrapper<ArticlesDocAccessFields_Categories_Delete>;
  ArticlesDocAccessFields_categories_Read: ResolverTypeWrapper<ArticlesDocAccessFields_Categories_Read>;
  ArticlesDocAccessFields_categories_Update: ResolverTypeWrapper<ArticlesDocAccessFields_Categories_Update>;
  ArticlesDocAccessFields_content: ResolverTypeWrapper<ArticlesDocAccessFields_Content>;
  ArticlesDocAccessFields_content_Create: ResolverTypeWrapper<ArticlesDocAccessFields_Content_Create>;
  ArticlesDocAccessFields_content_Delete: ResolverTypeWrapper<ArticlesDocAccessFields_Content_Delete>;
  ArticlesDocAccessFields_content_Read: ResolverTypeWrapper<ArticlesDocAccessFields_Content_Read>;
  ArticlesDocAccessFields_content_Update: ResolverTypeWrapper<ArticlesDocAccessFields_Content_Update>;
  ArticlesDocAccessFields_createdAt: ResolverTypeWrapper<ArticlesDocAccessFields_CreatedAt>;
  ArticlesDocAccessFields_createdAt_Create: ResolverTypeWrapper<ArticlesDocAccessFields_CreatedAt_Create>;
  ArticlesDocAccessFields_createdAt_Delete: ResolverTypeWrapper<ArticlesDocAccessFields_CreatedAt_Delete>;
  ArticlesDocAccessFields_createdAt_Read: ResolverTypeWrapper<ArticlesDocAccessFields_CreatedAt_Read>;
  ArticlesDocAccessFields_createdAt_Update: ResolverTypeWrapper<ArticlesDocAccessFields_CreatedAt_Update>;
  ArticlesDocAccessFields_hero: ResolverTypeWrapper<ArticlesDocAccessFields_Hero>;
  ArticlesDocAccessFields_hero_Create: ResolverTypeWrapper<ArticlesDocAccessFields_Hero_Create>;
  ArticlesDocAccessFields_hero_Delete: ResolverTypeWrapper<ArticlesDocAccessFields_Hero_Delete>;
  ArticlesDocAccessFields_hero_Fields: ResolverTypeWrapper<ArticlesDocAccessFields_Hero_Fields>;
  ArticlesDocAccessFields_hero_Read: ResolverTypeWrapper<ArticlesDocAccessFields_Hero_Read>;
  ArticlesDocAccessFields_hero_Update: ResolverTypeWrapper<ArticlesDocAccessFields_Hero_Update>;
  ArticlesDocAccessFields_hero_media: ResolverTypeWrapper<ArticlesDocAccessFields_Hero_Media>;
  ArticlesDocAccessFields_hero_media_Create: ResolverTypeWrapper<ArticlesDocAccessFields_Hero_Media_Create>;
  ArticlesDocAccessFields_hero_media_Delete: ResolverTypeWrapper<ArticlesDocAccessFields_Hero_Media_Delete>;
  ArticlesDocAccessFields_hero_media_Read: ResolverTypeWrapper<ArticlesDocAccessFields_Hero_Media_Read>;
  ArticlesDocAccessFields_hero_media_Update: ResolverTypeWrapper<ArticlesDocAccessFields_Hero_Media_Update>;
  ArticlesDocAccessFields_hero_richText: ResolverTypeWrapper<ArticlesDocAccessFields_Hero_RichText>;
  ArticlesDocAccessFields_hero_richText_Create: ResolverTypeWrapper<ArticlesDocAccessFields_Hero_RichText_Create>;
  ArticlesDocAccessFields_hero_richText_Delete: ResolverTypeWrapper<ArticlesDocAccessFields_Hero_RichText_Delete>;
  ArticlesDocAccessFields_hero_richText_Read: ResolverTypeWrapper<ArticlesDocAccessFields_Hero_RichText_Read>;
  ArticlesDocAccessFields_hero_richText_Update: ResolverTypeWrapper<ArticlesDocAccessFields_Hero_RichText_Update>;
  ArticlesDocAccessFields_meta: ResolverTypeWrapper<ArticlesDocAccessFields_Meta>;
  ArticlesDocAccessFields_meta_Create: ResolverTypeWrapper<ArticlesDocAccessFields_Meta_Create>;
  ArticlesDocAccessFields_meta_Delete: ResolverTypeWrapper<ArticlesDocAccessFields_Meta_Delete>;
  ArticlesDocAccessFields_meta_Fields: ResolverTypeWrapper<ArticlesDocAccessFields_Meta_Fields>;
  ArticlesDocAccessFields_meta_Read: ResolverTypeWrapper<ArticlesDocAccessFields_Meta_Read>;
  ArticlesDocAccessFields_meta_Update: ResolverTypeWrapper<ArticlesDocAccessFields_Meta_Update>;
  ArticlesDocAccessFields_meta_description: ResolverTypeWrapper<ArticlesDocAccessFields_Meta_Description>;
  ArticlesDocAccessFields_meta_description_Create: ResolverTypeWrapper<ArticlesDocAccessFields_Meta_Description_Create>;
  ArticlesDocAccessFields_meta_description_Delete: ResolverTypeWrapper<ArticlesDocAccessFields_Meta_Description_Delete>;
  ArticlesDocAccessFields_meta_description_Read: ResolverTypeWrapper<ArticlesDocAccessFields_Meta_Description_Read>;
  ArticlesDocAccessFields_meta_description_Update: ResolverTypeWrapper<ArticlesDocAccessFields_Meta_Description_Update>;
  ArticlesDocAccessFields_meta_image: ResolverTypeWrapper<ArticlesDocAccessFields_Meta_Image>;
  ArticlesDocAccessFields_meta_image_Create: ResolverTypeWrapper<ArticlesDocAccessFields_Meta_Image_Create>;
  ArticlesDocAccessFields_meta_image_Delete: ResolverTypeWrapper<ArticlesDocAccessFields_Meta_Image_Delete>;
  ArticlesDocAccessFields_meta_image_Read: ResolverTypeWrapper<ArticlesDocAccessFields_Meta_Image_Read>;
  ArticlesDocAccessFields_meta_image_Update: ResolverTypeWrapper<ArticlesDocAccessFields_Meta_Image_Update>;
  ArticlesDocAccessFields_meta_title: ResolverTypeWrapper<ArticlesDocAccessFields_Meta_Title>;
  ArticlesDocAccessFields_meta_title_Create: ResolverTypeWrapper<ArticlesDocAccessFields_Meta_Title_Create>;
  ArticlesDocAccessFields_meta_title_Delete: ResolverTypeWrapper<ArticlesDocAccessFields_Meta_Title_Delete>;
  ArticlesDocAccessFields_meta_title_Read: ResolverTypeWrapper<ArticlesDocAccessFields_Meta_Title_Read>;
  ArticlesDocAccessFields_meta_title_Update: ResolverTypeWrapper<ArticlesDocAccessFields_Meta_Title_Update>;
  ArticlesDocAccessFields_populatedAuthor: ResolverTypeWrapper<ArticlesDocAccessFields_PopulatedAuthor>;
  ArticlesDocAccessFields_populatedAuthor_Create: ResolverTypeWrapper<ArticlesDocAccessFields_PopulatedAuthor_Create>;
  ArticlesDocAccessFields_populatedAuthor_Delete: ResolverTypeWrapper<ArticlesDocAccessFields_PopulatedAuthor_Delete>;
  ArticlesDocAccessFields_populatedAuthor_Fields: ResolverTypeWrapper<ArticlesDocAccessFields_PopulatedAuthor_Fields>;
  ArticlesDocAccessFields_populatedAuthor_Read: ResolverTypeWrapper<ArticlesDocAccessFields_PopulatedAuthor_Read>;
  ArticlesDocAccessFields_populatedAuthor_Update: ResolverTypeWrapper<ArticlesDocAccessFields_PopulatedAuthor_Update>;
  ArticlesDocAccessFields_populatedAuthor_id: ResolverTypeWrapper<ArticlesDocAccessFields_PopulatedAuthor_Id>;
  ArticlesDocAccessFields_populatedAuthor_id_Create: ResolverTypeWrapper<ArticlesDocAccessFields_PopulatedAuthor_Id_Create>;
  ArticlesDocAccessFields_populatedAuthor_id_Delete: ResolverTypeWrapper<ArticlesDocAccessFields_PopulatedAuthor_Id_Delete>;
  ArticlesDocAccessFields_populatedAuthor_id_Read: ResolverTypeWrapper<ArticlesDocAccessFields_PopulatedAuthor_Id_Read>;
  ArticlesDocAccessFields_populatedAuthor_id_Update: ResolverTypeWrapper<ArticlesDocAccessFields_PopulatedAuthor_Id_Update>;
  ArticlesDocAccessFields_populatedAuthor_name: ResolverTypeWrapper<ArticlesDocAccessFields_PopulatedAuthor_Name>;
  ArticlesDocAccessFields_populatedAuthor_name_Create: ResolverTypeWrapper<ArticlesDocAccessFields_PopulatedAuthor_Name_Create>;
  ArticlesDocAccessFields_populatedAuthor_name_Delete: ResolverTypeWrapper<ArticlesDocAccessFields_PopulatedAuthor_Name_Delete>;
  ArticlesDocAccessFields_populatedAuthor_name_Read: ResolverTypeWrapper<ArticlesDocAccessFields_PopulatedAuthor_Name_Read>;
  ArticlesDocAccessFields_populatedAuthor_name_Update: ResolverTypeWrapper<ArticlesDocAccessFields_PopulatedAuthor_Name_Update>;
  ArticlesDocAccessFields_publishedAt: ResolverTypeWrapper<ArticlesDocAccessFields_PublishedAt>;
  ArticlesDocAccessFields_publishedAt_Create: ResolverTypeWrapper<ArticlesDocAccessFields_PublishedAt_Create>;
  ArticlesDocAccessFields_publishedAt_Delete: ResolverTypeWrapper<ArticlesDocAccessFields_PublishedAt_Delete>;
  ArticlesDocAccessFields_publishedAt_Read: ResolverTypeWrapper<ArticlesDocAccessFields_PublishedAt_Read>;
  ArticlesDocAccessFields_publishedAt_Update: ResolverTypeWrapper<ArticlesDocAccessFields_PublishedAt_Update>;
  ArticlesDocAccessFields_relatedArticles: ResolverTypeWrapper<ArticlesDocAccessFields_RelatedArticles>;
  ArticlesDocAccessFields_relatedArticles_Create: ResolverTypeWrapper<ArticlesDocAccessFields_RelatedArticles_Create>;
  ArticlesDocAccessFields_relatedArticles_Delete: ResolverTypeWrapper<ArticlesDocAccessFields_RelatedArticles_Delete>;
  ArticlesDocAccessFields_relatedArticles_Read: ResolverTypeWrapper<ArticlesDocAccessFields_RelatedArticles_Read>;
  ArticlesDocAccessFields_relatedArticles_Update: ResolverTypeWrapper<ArticlesDocAccessFields_RelatedArticles_Update>;
  ArticlesDocAccessFields_slug: ResolverTypeWrapper<ArticlesDocAccessFields_Slug>;
  ArticlesDocAccessFields_slug_Create: ResolverTypeWrapper<ArticlesDocAccessFields_Slug_Create>;
  ArticlesDocAccessFields_slug_Delete: ResolverTypeWrapper<ArticlesDocAccessFields_Slug_Delete>;
  ArticlesDocAccessFields_slug_Read: ResolverTypeWrapper<ArticlesDocAccessFields_Slug_Read>;
  ArticlesDocAccessFields_slug_Update: ResolverTypeWrapper<ArticlesDocAccessFields_Slug_Update>;
  ArticlesDocAccessFields_title: ResolverTypeWrapper<ArticlesDocAccessFields_Title>;
  ArticlesDocAccessFields_title_Create: ResolverTypeWrapper<ArticlesDocAccessFields_Title_Create>;
  ArticlesDocAccessFields_title_Delete: ResolverTypeWrapper<ArticlesDocAccessFields_Title_Delete>;
  ArticlesDocAccessFields_title_Read: ResolverTypeWrapper<ArticlesDocAccessFields_Title_Read>;
  ArticlesDocAccessFields_title_Update: ResolverTypeWrapper<ArticlesDocAccessFields_Title_Update>;
  ArticlesDocAccessFields_updatedAt: ResolverTypeWrapper<ArticlesDocAccessFields_UpdatedAt>;
  ArticlesDocAccessFields_updatedAt_Create: ResolverTypeWrapper<ArticlesDocAccessFields_UpdatedAt_Create>;
  ArticlesDocAccessFields_updatedAt_Delete: ResolverTypeWrapper<ArticlesDocAccessFields_UpdatedAt_Delete>;
  ArticlesDocAccessFields_updatedAt_Read: ResolverTypeWrapper<ArticlesDocAccessFields_UpdatedAt_Read>;
  ArticlesDocAccessFields_updatedAt_Update: ResolverTypeWrapper<ArticlesDocAccessFields_UpdatedAt_Update>;
  ArticlesFields: ResolverTypeWrapper<ArticlesFields>;
  ArticlesFields__status: ResolverTypeWrapper<ArticlesFields__Status>;
  ArticlesFields__status_Create: ResolverTypeWrapper<ArticlesFields__Status_Create>;
  ArticlesFields__status_Delete: ResolverTypeWrapper<ArticlesFields__Status_Delete>;
  ArticlesFields__status_Read: ResolverTypeWrapper<ArticlesFields__Status_Read>;
  ArticlesFields__status_Update: ResolverTypeWrapper<ArticlesFields__Status_Update>;
  ArticlesFields_author: ResolverTypeWrapper<ArticlesFields_Author>;
  ArticlesFields_author_Create: ResolverTypeWrapper<ArticlesFields_Author_Create>;
  ArticlesFields_author_Delete: ResolverTypeWrapper<ArticlesFields_Author_Delete>;
  ArticlesFields_author_Read: ResolverTypeWrapper<ArticlesFields_Author_Read>;
  ArticlesFields_author_Update: ResolverTypeWrapper<ArticlesFields_Author_Update>;
  ArticlesFields_categories: ResolverTypeWrapper<ArticlesFields_Categories>;
  ArticlesFields_categories_Create: ResolverTypeWrapper<ArticlesFields_Categories_Create>;
  ArticlesFields_categories_Delete: ResolverTypeWrapper<ArticlesFields_Categories_Delete>;
  ArticlesFields_categories_Read: ResolverTypeWrapper<ArticlesFields_Categories_Read>;
  ArticlesFields_categories_Update: ResolverTypeWrapper<ArticlesFields_Categories_Update>;
  ArticlesFields_content: ResolverTypeWrapper<ArticlesFields_Content>;
  ArticlesFields_content_Create: ResolverTypeWrapper<ArticlesFields_Content_Create>;
  ArticlesFields_content_Delete: ResolverTypeWrapper<ArticlesFields_Content_Delete>;
  ArticlesFields_content_Read: ResolverTypeWrapper<ArticlesFields_Content_Read>;
  ArticlesFields_content_Update: ResolverTypeWrapper<ArticlesFields_Content_Update>;
  ArticlesFields_createdAt: ResolverTypeWrapper<ArticlesFields_CreatedAt>;
  ArticlesFields_createdAt_Create: ResolverTypeWrapper<ArticlesFields_CreatedAt_Create>;
  ArticlesFields_createdAt_Delete: ResolverTypeWrapper<ArticlesFields_CreatedAt_Delete>;
  ArticlesFields_createdAt_Read: ResolverTypeWrapper<ArticlesFields_CreatedAt_Read>;
  ArticlesFields_createdAt_Update: ResolverTypeWrapper<ArticlesFields_CreatedAt_Update>;
  ArticlesFields_hero: ResolverTypeWrapper<ArticlesFields_Hero>;
  ArticlesFields_hero_Create: ResolverTypeWrapper<ArticlesFields_Hero_Create>;
  ArticlesFields_hero_Delete: ResolverTypeWrapper<ArticlesFields_Hero_Delete>;
  ArticlesFields_hero_Fields: ResolverTypeWrapper<ArticlesFields_Hero_Fields>;
  ArticlesFields_hero_Read: ResolverTypeWrapper<ArticlesFields_Hero_Read>;
  ArticlesFields_hero_Update: ResolverTypeWrapper<ArticlesFields_Hero_Update>;
  ArticlesFields_hero_media: ResolverTypeWrapper<ArticlesFields_Hero_Media>;
  ArticlesFields_hero_media_Create: ResolverTypeWrapper<ArticlesFields_Hero_Media_Create>;
  ArticlesFields_hero_media_Delete: ResolverTypeWrapper<ArticlesFields_Hero_Media_Delete>;
  ArticlesFields_hero_media_Read: ResolverTypeWrapper<ArticlesFields_Hero_Media_Read>;
  ArticlesFields_hero_media_Update: ResolverTypeWrapper<ArticlesFields_Hero_Media_Update>;
  ArticlesFields_hero_richText: ResolverTypeWrapper<ArticlesFields_Hero_RichText>;
  ArticlesFields_hero_richText_Create: ResolverTypeWrapper<ArticlesFields_Hero_RichText_Create>;
  ArticlesFields_hero_richText_Delete: ResolverTypeWrapper<ArticlesFields_Hero_RichText_Delete>;
  ArticlesFields_hero_richText_Read: ResolverTypeWrapper<ArticlesFields_Hero_RichText_Read>;
  ArticlesFields_hero_richText_Update: ResolverTypeWrapper<ArticlesFields_Hero_RichText_Update>;
  ArticlesFields_meta: ResolverTypeWrapper<ArticlesFields_Meta>;
  ArticlesFields_meta_Create: ResolverTypeWrapper<ArticlesFields_Meta_Create>;
  ArticlesFields_meta_Delete: ResolverTypeWrapper<ArticlesFields_Meta_Delete>;
  ArticlesFields_meta_Fields: ResolverTypeWrapper<ArticlesFields_Meta_Fields>;
  ArticlesFields_meta_Read: ResolverTypeWrapper<ArticlesFields_Meta_Read>;
  ArticlesFields_meta_Update: ResolverTypeWrapper<ArticlesFields_Meta_Update>;
  ArticlesFields_meta_description: ResolverTypeWrapper<ArticlesFields_Meta_Description>;
  ArticlesFields_meta_description_Create: ResolverTypeWrapper<ArticlesFields_Meta_Description_Create>;
  ArticlesFields_meta_description_Delete: ResolverTypeWrapper<ArticlesFields_Meta_Description_Delete>;
  ArticlesFields_meta_description_Read: ResolverTypeWrapper<ArticlesFields_Meta_Description_Read>;
  ArticlesFields_meta_description_Update: ResolverTypeWrapper<ArticlesFields_Meta_Description_Update>;
  ArticlesFields_meta_image: ResolverTypeWrapper<ArticlesFields_Meta_Image>;
  ArticlesFields_meta_image_Create: ResolverTypeWrapper<ArticlesFields_Meta_Image_Create>;
  ArticlesFields_meta_image_Delete: ResolverTypeWrapper<ArticlesFields_Meta_Image_Delete>;
  ArticlesFields_meta_image_Read: ResolverTypeWrapper<ArticlesFields_Meta_Image_Read>;
  ArticlesFields_meta_image_Update: ResolverTypeWrapper<ArticlesFields_Meta_Image_Update>;
  ArticlesFields_meta_title: ResolverTypeWrapper<ArticlesFields_Meta_Title>;
  ArticlesFields_meta_title_Create: ResolverTypeWrapper<ArticlesFields_Meta_Title_Create>;
  ArticlesFields_meta_title_Delete: ResolverTypeWrapper<ArticlesFields_Meta_Title_Delete>;
  ArticlesFields_meta_title_Read: ResolverTypeWrapper<ArticlesFields_Meta_Title_Read>;
  ArticlesFields_meta_title_Update: ResolverTypeWrapper<ArticlesFields_Meta_Title_Update>;
  ArticlesFields_populatedAuthor: ResolverTypeWrapper<ArticlesFields_PopulatedAuthor>;
  ArticlesFields_populatedAuthor_Create: ResolverTypeWrapper<ArticlesFields_PopulatedAuthor_Create>;
  ArticlesFields_populatedAuthor_Delete: ResolverTypeWrapper<ArticlesFields_PopulatedAuthor_Delete>;
  ArticlesFields_populatedAuthor_Fields: ResolverTypeWrapper<ArticlesFields_PopulatedAuthor_Fields>;
  ArticlesFields_populatedAuthor_Read: ResolverTypeWrapper<ArticlesFields_PopulatedAuthor_Read>;
  ArticlesFields_populatedAuthor_Update: ResolverTypeWrapper<ArticlesFields_PopulatedAuthor_Update>;
  ArticlesFields_populatedAuthor_id: ResolverTypeWrapper<ArticlesFields_PopulatedAuthor_Id>;
  ArticlesFields_populatedAuthor_id_Create: ResolverTypeWrapper<ArticlesFields_PopulatedAuthor_Id_Create>;
  ArticlesFields_populatedAuthor_id_Delete: ResolverTypeWrapper<ArticlesFields_PopulatedAuthor_Id_Delete>;
  ArticlesFields_populatedAuthor_id_Read: ResolverTypeWrapper<ArticlesFields_PopulatedAuthor_Id_Read>;
  ArticlesFields_populatedAuthor_id_Update: ResolverTypeWrapper<ArticlesFields_PopulatedAuthor_Id_Update>;
  ArticlesFields_populatedAuthor_name: ResolverTypeWrapper<ArticlesFields_PopulatedAuthor_Name>;
  ArticlesFields_populatedAuthor_name_Create: ResolverTypeWrapper<ArticlesFields_PopulatedAuthor_Name_Create>;
  ArticlesFields_populatedAuthor_name_Delete: ResolverTypeWrapper<ArticlesFields_PopulatedAuthor_Name_Delete>;
  ArticlesFields_populatedAuthor_name_Read: ResolverTypeWrapper<ArticlesFields_PopulatedAuthor_Name_Read>;
  ArticlesFields_populatedAuthor_name_Update: ResolverTypeWrapper<ArticlesFields_PopulatedAuthor_Name_Update>;
  ArticlesFields_publishedAt: ResolverTypeWrapper<ArticlesFields_PublishedAt>;
  ArticlesFields_publishedAt_Create: ResolverTypeWrapper<ArticlesFields_PublishedAt_Create>;
  ArticlesFields_publishedAt_Delete: ResolverTypeWrapper<ArticlesFields_PublishedAt_Delete>;
  ArticlesFields_publishedAt_Read: ResolverTypeWrapper<ArticlesFields_PublishedAt_Read>;
  ArticlesFields_publishedAt_Update: ResolverTypeWrapper<ArticlesFields_PublishedAt_Update>;
  ArticlesFields_relatedArticles: ResolverTypeWrapper<ArticlesFields_RelatedArticles>;
  ArticlesFields_relatedArticles_Create: ResolverTypeWrapper<ArticlesFields_RelatedArticles_Create>;
  ArticlesFields_relatedArticles_Delete: ResolverTypeWrapper<ArticlesFields_RelatedArticles_Delete>;
  ArticlesFields_relatedArticles_Read: ResolverTypeWrapper<ArticlesFields_RelatedArticles_Read>;
  ArticlesFields_relatedArticles_Update: ResolverTypeWrapper<ArticlesFields_RelatedArticles_Update>;
  ArticlesFields_slug: ResolverTypeWrapper<ArticlesFields_Slug>;
  ArticlesFields_slug_Create: ResolverTypeWrapper<ArticlesFields_Slug_Create>;
  ArticlesFields_slug_Delete: ResolverTypeWrapper<ArticlesFields_Slug_Delete>;
  ArticlesFields_slug_Read: ResolverTypeWrapper<ArticlesFields_Slug_Read>;
  ArticlesFields_slug_Update: ResolverTypeWrapper<ArticlesFields_Slug_Update>;
  ArticlesFields_title: ResolverTypeWrapper<ArticlesFields_Title>;
  ArticlesFields_title_Create: ResolverTypeWrapper<ArticlesFields_Title_Create>;
  ArticlesFields_title_Delete: ResolverTypeWrapper<ArticlesFields_Title_Delete>;
  ArticlesFields_title_Read: ResolverTypeWrapper<ArticlesFields_Title_Read>;
  ArticlesFields_title_Update: ResolverTypeWrapper<ArticlesFields_Title_Update>;
  ArticlesFields_updatedAt: ResolverTypeWrapper<ArticlesFields_UpdatedAt>;
  ArticlesFields_updatedAt_Create: ResolverTypeWrapper<ArticlesFields_UpdatedAt_Create>;
  ArticlesFields_updatedAt_Delete: ResolverTypeWrapper<ArticlesFields_UpdatedAt_Delete>;
  ArticlesFields_updatedAt_Read: ResolverTypeWrapper<ArticlesFields_UpdatedAt_Read>;
  ArticlesFields_updatedAt_Update: ResolverTypeWrapper<ArticlesFields_UpdatedAt_Update>;
  ArticlesReadAccess: ResolverTypeWrapper<ArticlesReadAccess>;
  ArticlesReadDocAccess: ResolverTypeWrapper<ArticlesReadDocAccess>;
  ArticlesReadVersionsAccess: ResolverTypeWrapper<ArticlesReadVersionsAccess>;
  ArticlesReadVersionsDocAccess: ResolverTypeWrapper<ArticlesReadVersionsDocAccess>;
  ArticlesUpdateAccess: ResolverTypeWrapper<ArticlesUpdateAccess>;
  ArticlesUpdateDocAccess: ResolverTypeWrapper<ArticlesUpdateDocAccess>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']['output']>;
  Categories: ResolverTypeWrapper<Categories>;
  CategoriesCreateAccess: ResolverTypeWrapper<CategoriesCreateAccess>;
  CategoriesCreateDocAccess: ResolverTypeWrapper<CategoriesCreateDocAccess>;
  CategoriesDeleteAccess: ResolverTypeWrapper<CategoriesDeleteAccess>;
  CategoriesDeleteDocAccess: ResolverTypeWrapper<CategoriesDeleteDocAccess>;
  CategoriesDocAccessFields: ResolverTypeWrapper<CategoriesDocAccessFields>;
  CategoriesDocAccessFields_breadcrumbs: ResolverTypeWrapper<CategoriesDocAccessFields_Breadcrumbs>;
  CategoriesDocAccessFields_breadcrumbs_Create: ResolverTypeWrapper<CategoriesDocAccessFields_Breadcrumbs_Create>;
  CategoriesDocAccessFields_breadcrumbs_Delete: ResolverTypeWrapper<CategoriesDocAccessFields_Breadcrumbs_Delete>;
  CategoriesDocAccessFields_breadcrumbs_Fields: ResolverTypeWrapper<CategoriesDocAccessFields_Breadcrumbs_Fields>;
  CategoriesDocAccessFields_breadcrumbs_Read: ResolverTypeWrapper<CategoriesDocAccessFields_Breadcrumbs_Read>;
  CategoriesDocAccessFields_breadcrumbs_Update: ResolverTypeWrapper<CategoriesDocAccessFields_Breadcrumbs_Update>;
  CategoriesDocAccessFields_breadcrumbs_doc: ResolverTypeWrapper<CategoriesDocAccessFields_Breadcrumbs_Doc>;
  CategoriesDocAccessFields_breadcrumbs_doc_Create: ResolverTypeWrapper<CategoriesDocAccessFields_Breadcrumbs_Doc_Create>;
  CategoriesDocAccessFields_breadcrumbs_doc_Delete: ResolverTypeWrapper<CategoriesDocAccessFields_Breadcrumbs_Doc_Delete>;
  CategoriesDocAccessFields_breadcrumbs_doc_Read: ResolverTypeWrapper<CategoriesDocAccessFields_Breadcrumbs_Doc_Read>;
  CategoriesDocAccessFields_breadcrumbs_doc_Update: ResolverTypeWrapper<CategoriesDocAccessFields_Breadcrumbs_Doc_Update>;
  CategoriesDocAccessFields_breadcrumbs_id: ResolverTypeWrapper<CategoriesDocAccessFields_Breadcrumbs_Id>;
  CategoriesDocAccessFields_breadcrumbs_id_Create: ResolverTypeWrapper<CategoriesDocAccessFields_Breadcrumbs_Id_Create>;
  CategoriesDocAccessFields_breadcrumbs_id_Delete: ResolverTypeWrapper<CategoriesDocAccessFields_Breadcrumbs_Id_Delete>;
  CategoriesDocAccessFields_breadcrumbs_id_Read: ResolverTypeWrapper<CategoriesDocAccessFields_Breadcrumbs_Id_Read>;
  CategoriesDocAccessFields_breadcrumbs_id_Update: ResolverTypeWrapper<CategoriesDocAccessFields_Breadcrumbs_Id_Update>;
  CategoriesDocAccessFields_breadcrumbs_label: ResolverTypeWrapper<CategoriesDocAccessFields_Breadcrumbs_Label>;
  CategoriesDocAccessFields_breadcrumbs_label_Create: ResolverTypeWrapper<CategoriesDocAccessFields_Breadcrumbs_Label_Create>;
  CategoriesDocAccessFields_breadcrumbs_label_Delete: ResolverTypeWrapper<CategoriesDocAccessFields_Breadcrumbs_Label_Delete>;
  CategoriesDocAccessFields_breadcrumbs_label_Read: ResolverTypeWrapper<CategoriesDocAccessFields_Breadcrumbs_Label_Read>;
  CategoriesDocAccessFields_breadcrumbs_label_Update: ResolverTypeWrapper<CategoriesDocAccessFields_Breadcrumbs_Label_Update>;
  CategoriesDocAccessFields_breadcrumbs_url: ResolverTypeWrapper<CategoriesDocAccessFields_Breadcrumbs_Url>;
  CategoriesDocAccessFields_breadcrumbs_url_Create: ResolverTypeWrapper<CategoriesDocAccessFields_Breadcrumbs_Url_Create>;
  CategoriesDocAccessFields_breadcrumbs_url_Delete: ResolverTypeWrapper<CategoriesDocAccessFields_Breadcrumbs_Url_Delete>;
  CategoriesDocAccessFields_breadcrumbs_url_Read: ResolverTypeWrapper<CategoriesDocAccessFields_Breadcrumbs_Url_Read>;
  CategoriesDocAccessFields_breadcrumbs_url_Update: ResolverTypeWrapper<CategoriesDocAccessFields_Breadcrumbs_Url_Update>;
  CategoriesDocAccessFields_createdAt: ResolverTypeWrapper<CategoriesDocAccessFields_CreatedAt>;
  CategoriesDocAccessFields_createdAt_Create: ResolverTypeWrapper<CategoriesDocAccessFields_CreatedAt_Create>;
  CategoriesDocAccessFields_createdAt_Delete: ResolverTypeWrapper<CategoriesDocAccessFields_CreatedAt_Delete>;
  CategoriesDocAccessFields_createdAt_Read: ResolverTypeWrapper<CategoriesDocAccessFields_CreatedAt_Read>;
  CategoriesDocAccessFields_createdAt_Update: ResolverTypeWrapper<CategoriesDocAccessFields_CreatedAt_Update>;
  CategoriesDocAccessFields_parent: ResolverTypeWrapper<CategoriesDocAccessFields_Parent>;
  CategoriesDocAccessFields_parent_Create: ResolverTypeWrapper<CategoriesDocAccessFields_Parent_Create>;
  CategoriesDocAccessFields_parent_Delete: ResolverTypeWrapper<CategoriesDocAccessFields_Parent_Delete>;
  CategoriesDocAccessFields_parent_Read: ResolverTypeWrapper<CategoriesDocAccessFields_Parent_Read>;
  CategoriesDocAccessFields_parent_Update: ResolverTypeWrapper<CategoriesDocAccessFields_Parent_Update>;
  CategoriesDocAccessFields_title: ResolverTypeWrapper<CategoriesDocAccessFields_Title>;
  CategoriesDocAccessFields_title_Create: ResolverTypeWrapper<CategoriesDocAccessFields_Title_Create>;
  CategoriesDocAccessFields_title_Delete: ResolverTypeWrapper<CategoriesDocAccessFields_Title_Delete>;
  CategoriesDocAccessFields_title_Read: ResolverTypeWrapper<CategoriesDocAccessFields_Title_Read>;
  CategoriesDocAccessFields_title_Update: ResolverTypeWrapper<CategoriesDocAccessFields_Title_Update>;
  CategoriesDocAccessFields_updatedAt: ResolverTypeWrapper<CategoriesDocAccessFields_UpdatedAt>;
  CategoriesDocAccessFields_updatedAt_Create: ResolverTypeWrapper<CategoriesDocAccessFields_UpdatedAt_Create>;
  CategoriesDocAccessFields_updatedAt_Delete: ResolverTypeWrapper<CategoriesDocAccessFields_UpdatedAt_Delete>;
  CategoriesDocAccessFields_updatedAt_Read: ResolverTypeWrapper<CategoriesDocAccessFields_UpdatedAt_Read>;
  CategoriesDocAccessFields_updatedAt_Update: ResolverTypeWrapper<CategoriesDocAccessFields_UpdatedAt_Update>;
  CategoriesFields: ResolverTypeWrapper<CategoriesFields>;
  CategoriesFields_breadcrumbs: ResolverTypeWrapper<CategoriesFields_Breadcrumbs>;
  CategoriesFields_breadcrumbs_Create: ResolverTypeWrapper<CategoriesFields_Breadcrumbs_Create>;
  CategoriesFields_breadcrumbs_Delete: ResolverTypeWrapper<CategoriesFields_Breadcrumbs_Delete>;
  CategoriesFields_breadcrumbs_Fields: ResolverTypeWrapper<CategoriesFields_Breadcrumbs_Fields>;
  CategoriesFields_breadcrumbs_Read: ResolverTypeWrapper<CategoriesFields_Breadcrumbs_Read>;
  CategoriesFields_breadcrumbs_Update: ResolverTypeWrapper<CategoriesFields_Breadcrumbs_Update>;
  CategoriesFields_breadcrumbs_doc: ResolverTypeWrapper<CategoriesFields_Breadcrumbs_Doc>;
  CategoriesFields_breadcrumbs_doc_Create: ResolverTypeWrapper<CategoriesFields_Breadcrumbs_Doc_Create>;
  CategoriesFields_breadcrumbs_doc_Delete: ResolverTypeWrapper<CategoriesFields_Breadcrumbs_Doc_Delete>;
  CategoriesFields_breadcrumbs_doc_Read: ResolverTypeWrapper<CategoriesFields_Breadcrumbs_Doc_Read>;
  CategoriesFields_breadcrumbs_doc_Update: ResolverTypeWrapper<CategoriesFields_Breadcrumbs_Doc_Update>;
  CategoriesFields_breadcrumbs_id: ResolverTypeWrapper<CategoriesFields_Breadcrumbs_Id>;
  CategoriesFields_breadcrumbs_id_Create: ResolverTypeWrapper<CategoriesFields_Breadcrumbs_Id_Create>;
  CategoriesFields_breadcrumbs_id_Delete: ResolverTypeWrapper<CategoriesFields_Breadcrumbs_Id_Delete>;
  CategoriesFields_breadcrumbs_id_Read: ResolverTypeWrapper<CategoriesFields_Breadcrumbs_Id_Read>;
  CategoriesFields_breadcrumbs_id_Update: ResolverTypeWrapper<CategoriesFields_Breadcrumbs_Id_Update>;
  CategoriesFields_breadcrumbs_label: ResolverTypeWrapper<CategoriesFields_Breadcrumbs_Label>;
  CategoriesFields_breadcrumbs_label_Create: ResolverTypeWrapper<CategoriesFields_Breadcrumbs_Label_Create>;
  CategoriesFields_breadcrumbs_label_Delete: ResolverTypeWrapper<CategoriesFields_Breadcrumbs_Label_Delete>;
  CategoriesFields_breadcrumbs_label_Read: ResolverTypeWrapper<CategoriesFields_Breadcrumbs_Label_Read>;
  CategoriesFields_breadcrumbs_label_Update: ResolverTypeWrapper<CategoriesFields_Breadcrumbs_Label_Update>;
  CategoriesFields_breadcrumbs_url: ResolverTypeWrapper<CategoriesFields_Breadcrumbs_Url>;
  CategoriesFields_breadcrumbs_url_Create: ResolverTypeWrapper<CategoriesFields_Breadcrumbs_Url_Create>;
  CategoriesFields_breadcrumbs_url_Delete: ResolverTypeWrapper<CategoriesFields_Breadcrumbs_Url_Delete>;
  CategoriesFields_breadcrumbs_url_Read: ResolverTypeWrapper<CategoriesFields_Breadcrumbs_Url_Read>;
  CategoriesFields_breadcrumbs_url_Update: ResolverTypeWrapper<CategoriesFields_Breadcrumbs_Url_Update>;
  CategoriesFields_createdAt: ResolverTypeWrapper<CategoriesFields_CreatedAt>;
  CategoriesFields_createdAt_Create: ResolverTypeWrapper<CategoriesFields_CreatedAt_Create>;
  CategoriesFields_createdAt_Delete: ResolverTypeWrapper<CategoriesFields_CreatedAt_Delete>;
  CategoriesFields_createdAt_Read: ResolverTypeWrapper<CategoriesFields_CreatedAt_Read>;
  CategoriesFields_createdAt_Update: ResolverTypeWrapper<CategoriesFields_CreatedAt_Update>;
  CategoriesFields_parent: ResolverTypeWrapper<CategoriesFields_Parent>;
  CategoriesFields_parent_Create: ResolverTypeWrapper<CategoriesFields_Parent_Create>;
  CategoriesFields_parent_Delete: ResolverTypeWrapper<CategoriesFields_Parent_Delete>;
  CategoriesFields_parent_Read: ResolverTypeWrapper<CategoriesFields_Parent_Read>;
  CategoriesFields_parent_Update: ResolverTypeWrapper<CategoriesFields_Parent_Update>;
  CategoriesFields_title: ResolverTypeWrapper<CategoriesFields_Title>;
  CategoriesFields_title_Create: ResolverTypeWrapper<CategoriesFields_Title_Create>;
  CategoriesFields_title_Delete: ResolverTypeWrapper<CategoriesFields_Title_Delete>;
  CategoriesFields_title_Read: ResolverTypeWrapper<CategoriesFields_Title_Read>;
  CategoriesFields_title_Update: ResolverTypeWrapper<CategoriesFields_Title_Update>;
  CategoriesFields_updatedAt: ResolverTypeWrapper<CategoriesFields_UpdatedAt>;
  CategoriesFields_updatedAt_Create: ResolverTypeWrapper<CategoriesFields_UpdatedAt_Create>;
  CategoriesFields_updatedAt_Delete: ResolverTypeWrapper<CategoriesFields_UpdatedAt_Delete>;
  CategoriesFields_updatedAt_Read: ResolverTypeWrapper<CategoriesFields_UpdatedAt_Read>;
  CategoriesFields_updatedAt_Update: ResolverTypeWrapper<CategoriesFields_UpdatedAt_Update>;
  CategoriesReadAccess: ResolverTypeWrapper<CategoriesReadAccess>;
  CategoriesReadDocAccess: ResolverTypeWrapper<CategoriesReadDocAccess>;
  CategoriesUpdateAccess: ResolverTypeWrapper<CategoriesUpdateAccess>;
  CategoriesUpdateDocAccess: ResolverTypeWrapper<CategoriesUpdateDocAccess>;
  Category: ResolverTypeWrapper<Category>;
  Category_Breadcrumbs: ResolverTypeWrapper<Category_Breadcrumbs>;
  Category_breadcrumbs__doc_operator: Category_Breadcrumbs__Doc_Operator;
  Category_breadcrumbs__id_operator: Category_Breadcrumbs__Id_Operator;
  Category_breadcrumbs__label_operator: Category_Breadcrumbs__Label_Operator;
  Category_breadcrumbs__url_operator: Category_Breadcrumbs__Url_Operator;
  Category_createdAt_operator: Category_CreatedAt_Operator;
  Category_id_operator: Category_Id_Operator;
  Category_parent_operator: Category_Parent_Operator;
  Category_title_operator: Category_Title_Operator;
  Category_updatedAt_operator: Category_UpdatedAt_Operator;
  Category_where: Category_Where;
  Category_where_and: Category_Where_And;
  Category_where_or: Category_Where_Or;
  Content: ResolverTypeWrapper<Content>;
  Cta: ResolverTypeWrapper<Cta>;
  Cta_Links: ResolverTypeWrapper<Cta_Links>;
  Cta_Links_Link: ResolverTypeWrapper<Cta_Links_Link>;
  Cta_Links_Link_Reference: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['Cta_Links_Link_Reference']>;
  Cta_Links_Link_Reference_RelationTo: Cta_Links_Link_Reference_RelationTo;
  Cta_Links_Link_Reference_Relationship: ResolverTypeWrapper<Omit<Cta_Links_Link_Reference_Relationship, 'value'> & { value?: Maybe<ResolversTypes['Cta_Links_Link_Reference']> }>;
  Cta_Links_Link_appearance: Cta_Links_Link_Appearance;
  Cta_Links_Link_type: Cta_Links_Link_Type;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']['output']>;
  EmailAddress: ResolverTypeWrapper<Scalars['EmailAddress']['output']>;
  Float: ResolverTypeWrapper<Scalars['Float']['output']>;
  Int: ResolverTypeWrapper<Scalars['Int']['output']>;
  JSON: ResolverTypeWrapper<Scalars['JSON']['output']>;
  JSONObject: ResolverTypeWrapper<Scalars['JSONObject']['output']>;
  Media: ResolverTypeWrapper<Media>;
  MediaBlock: ResolverTypeWrapper<MediaBlock>;
  MediaBlock_Media_alt_operator: MediaBlock_Media_Alt_Operator;
  MediaBlock_Media_caption_operator: MediaBlock_Media_Caption_Operator;
  MediaBlock_Media_createdAt_operator: MediaBlock_Media_CreatedAt_Operator;
  MediaBlock_Media_filename_operator: MediaBlock_Media_Filename_Operator;
  MediaBlock_Media_filesize_operator: MediaBlock_Media_Filesize_Operator;
  MediaBlock_Media_height_operator: MediaBlock_Media_Height_Operator;
  MediaBlock_Media_id_operator: MediaBlock_Media_Id_Operator;
  MediaBlock_Media_mimeType_operator: MediaBlock_Media_MimeType_Operator;
  MediaBlock_Media_updatedAt_operator: MediaBlock_Media_UpdatedAt_Operator;
  MediaBlock_Media_url_operator: MediaBlock_Media_Url_Operator;
  MediaBlock_Media_where: MediaBlock_Media_Where;
  MediaBlock_Media_where_and: MediaBlock_Media_Where_And;
  MediaBlock_Media_where_or: MediaBlock_Media_Where_Or;
  MediaBlock_Media_width_operator: MediaBlock_Media_Width_Operator;
  MediaCreateAccess: ResolverTypeWrapper<MediaCreateAccess>;
  MediaCreateDocAccess: ResolverTypeWrapper<MediaCreateDocAccess>;
  MediaDeleteAccess: ResolverTypeWrapper<MediaDeleteAccess>;
  MediaDeleteDocAccess: ResolverTypeWrapper<MediaDeleteDocAccess>;
  MediaDocAccessFields: ResolverTypeWrapper<MediaDocAccessFields>;
  MediaDocAccessFields_alt: ResolverTypeWrapper<MediaDocAccessFields_Alt>;
  MediaDocAccessFields_alt_Create: ResolverTypeWrapper<MediaDocAccessFields_Alt_Create>;
  MediaDocAccessFields_alt_Delete: ResolverTypeWrapper<MediaDocAccessFields_Alt_Delete>;
  MediaDocAccessFields_alt_Read: ResolverTypeWrapper<MediaDocAccessFields_Alt_Read>;
  MediaDocAccessFields_alt_Update: ResolverTypeWrapper<MediaDocAccessFields_Alt_Update>;
  MediaDocAccessFields_caption: ResolverTypeWrapper<MediaDocAccessFields_Caption>;
  MediaDocAccessFields_caption_Create: ResolverTypeWrapper<MediaDocAccessFields_Caption_Create>;
  MediaDocAccessFields_caption_Delete: ResolverTypeWrapper<MediaDocAccessFields_Caption_Delete>;
  MediaDocAccessFields_caption_Read: ResolverTypeWrapper<MediaDocAccessFields_Caption_Read>;
  MediaDocAccessFields_caption_Update: ResolverTypeWrapper<MediaDocAccessFields_Caption_Update>;
  MediaDocAccessFields_createdAt: ResolverTypeWrapper<MediaDocAccessFields_CreatedAt>;
  MediaDocAccessFields_createdAt_Create: ResolverTypeWrapper<MediaDocAccessFields_CreatedAt_Create>;
  MediaDocAccessFields_createdAt_Delete: ResolverTypeWrapper<MediaDocAccessFields_CreatedAt_Delete>;
  MediaDocAccessFields_createdAt_Read: ResolverTypeWrapper<MediaDocAccessFields_CreatedAt_Read>;
  MediaDocAccessFields_createdAt_Update: ResolverTypeWrapper<MediaDocAccessFields_CreatedAt_Update>;
  MediaDocAccessFields_filename: ResolverTypeWrapper<MediaDocAccessFields_Filename>;
  MediaDocAccessFields_filename_Create: ResolverTypeWrapper<MediaDocAccessFields_Filename_Create>;
  MediaDocAccessFields_filename_Delete: ResolverTypeWrapper<MediaDocAccessFields_Filename_Delete>;
  MediaDocAccessFields_filename_Read: ResolverTypeWrapper<MediaDocAccessFields_Filename_Read>;
  MediaDocAccessFields_filename_Update: ResolverTypeWrapper<MediaDocAccessFields_Filename_Update>;
  MediaDocAccessFields_filesize: ResolverTypeWrapper<MediaDocAccessFields_Filesize>;
  MediaDocAccessFields_filesize_Create: ResolverTypeWrapper<MediaDocAccessFields_Filesize_Create>;
  MediaDocAccessFields_filesize_Delete: ResolverTypeWrapper<MediaDocAccessFields_Filesize_Delete>;
  MediaDocAccessFields_filesize_Read: ResolverTypeWrapper<MediaDocAccessFields_Filesize_Read>;
  MediaDocAccessFields_filesize_Update: ResolverTypeWrapper<MediaDocAccessFields_Filesize_Update>;
  MediaDocAccessFields_height: ResolverTypeWrapper<MediaDocAccessFields_Height>;
  MediaDocAccessFields_height_Create: ResolverTypeWrapper<MediaDocAccessFields_Height_Create>;
  MediaDocAccessFields_height_Delete: ResolverTypeWrapper<MediaDocAccessFields_Height_Delete>;
  MediaDocAccessFields_height_Read: ResolverTypeWrapper<MediaDocAccessFields_Height_Read>;
  MediaDocAccessFields_height_Update: ResolverTypeWrapper<MediaDocAccessFields_Height_Update>;
  MediaDocAccessFields_mimeType: ResolverTypeWrapper<MediaDocAccessFields_MimeType>;
  MediaDocAccessFields_mimeType_Create: ResolverTypeWrapper<MediaDocAccessFields_MimeType_Create>;
  MediaDocAccessFields_mimeType_Delete: ResolverTypeWrapper<MediaDocAccessFields_MimeType_Delete>;
  MediaDocAccessFields_mimeType_Read: ResolverTypeWrapper<MediaDocAccessFields_MimeType_Read>;
  MediaDocAccessFields_mimeType_Update: ResolverTypeWrapper<MediaDocAccessFields_MimeType_Update>;
  MediaDocAccessFields_updatedAt: ResolverTypeWrapper<MediaDocAccessFields_UpdatedAt>;
  MediaDocAccessFields_updatedAt_Create: ResolverTypeWrapper<MediaDocAccessFields_UpdatedAt_Create>;
  MediaDocAccessFields_updatedAt_Delete: ResolverTypeWrapper<MediaDocAccessFields_UpdatedAt_Delete>;
  MediaDocAccessFields_updatedAt_Read: ResolverTypeWrapper<MediaDocAccessFields_UpdatedAt_Read>;
  MediaDocAccessFields_updatedAt_Update: ResolverTypeWrapper<MediaDocAccessFields_UpdatedAt_Update>;
  MediaDocAccessFields_url: ResolverTypeWrapper<MediaDocAccessFields_Url>;
  MediaDocAccessFields_url_Create: ResolverTypeWrapper<MediaDocAccessFields_Url_Create>;
  MediaDocAccessFields_url_Delete: ResolverTypeWrapper<MediaDocAccessFields_Url_Delete>;
  MediaDocAccessFields_url_Read: ResolverTypeWrapper<MediaDocAccessFields_Url_Read>;
  MediaDocAccessFields_url_Update: ResolverTypeWrapper<MediaDocAccessFields_Url_Update>;
  MediaDocAccessFields_width: ResolverTypeWrapper<MediaDocAccessFields_Width>;
  MediaDocAccessFields_width_Create: ResolverTypeWrapper<MediaDocAccessFields_Width_Create>;
  MediaDocAccessFields_width_Delete: ResolverTypeWrapper<MediaDocAccessFields_Width_Delete>;
  MediaDocAccessFields_width_Read: ResolverTypeWrapper<MediaDocAccessFields_Width_Read>;
  MediaDocAccessFields_width_Update: ResolverTypeWrapper<MediaDocAccessFields_Width_Update>;
  MediaFields: ResolverTypeWrapper<MediaFields>;
  MediaFields_alt: ResolverTypeWrapper<MediaFields_Alt>;
  MediaFields_alt_Create: ResolverTypeWrapper<MediaFields_Alt_Create>;
  MediaFields_alt_Delete: ResolverTypeWrapper<MediaFields_Alt_Delete>;
  MediaFields_alt_Read: ResolverTypeWrapper<MediaFields_Alt_Read>;
  MediaFields_alt_Update: ResolverTypeWrapper<MediaFields_Alt_Update>;
  MediaFields_caption: ResolverTypeWrapper<MediaFields_Caption>;
  MediaFields_caption_Create: ResolverTypeWrapper<MediaFields_Caption_Create>;
  MediaFields_caption_Delete: ResolverTypeWrapper<MediaFields_Caption_Delete>;
  MediaFields_caption_Read: ResolverTypeWrapper<MediaFields_Caption_Read>;
  MediaFields_caption_Update: ResolverTypeWrapper<MediaFields_Caption_Update>;
  MediaFields_createdAt: ResolverTypeWrapper<MediaFields_CreatedAt>;
  MediaFields_createdAt_Create: ResolverTypeWrapper<MediaFields_CreatedAt_Create>;
  MediaFields_createdAt_Delete: ResolverTypeWrapper<MediaFields_CreatedAt_Delete>;
  MediaFields_createdAt_Read: ResolverTypeWrapper<MediaFields_CreatedAt_Read>;
  MediaFields_createdAt_Update: ResolverTypeWrapper<MediaFields_CreatedAt_Update>;
  MediaFields_filename: ResolverTypeWrapper<MediaFields_Filename>;
  MediaFields_filename_Create: ResolverTypeWrapper<MediaFields_Filename_Create>;
  MediaFields_filename_Delete: ResolverTypeWrapper<MediaFields_Filename_Delete>;
  MediaFields_filename_Read: ResolverTypeWrapper<MediaFields_Filename_Read>;
  MediaFields_filename_Update: ResolverTypeWrapper<MediaFields_Filename_Update>;
  MediaFields_filesize: ResolverTypeWrapper<MediaFields_Filesize>;
  MediaFields_filesize_Create: ResolverTypeWrapper<MediaFields_Filesize_Create>;
  MediaFields_filesize_Delete: ResolverTypeWrapper<MediaFields_Filesize_Delete>;
  MediaFields_filesize_Read: ResolverTypeWrapper<MediaFields_Filesize_Read>;
  MediaFields_filesize_Update: ResolverTypeWrapper<MediaFields_Filesize_Update>;
  MediaFields_height: ResolverTypeWrapper<MediaFields_Height>;
  MediaFields_height_Create: ResolverTypeWrapper<MediaFields_Height_Create>;
  MediaFields_height_Delete: ResolverTypeWrapper<MediaFields_Height_Delete>;
  MediaFields_height_Read: ResolverTypeWrapper<MediaFields_Height_Read>;
  MediaFields_height_Update: ResolverTypeWrapper<MediaFields_Height_Update>;
  MediaFields_mimeType: ResolverTypeWrapper<MediaFields_MimeType>;
  MediaFields_mimeType_Create: ResolverTypeWrapper<MediaFields_MimeType_Create>;
  MediaFields_mimeType_Delete: ResolverTypeWrapper<MediaFields_MimeType_Delete>;
  MediaFields_mimeType_Read: ResolverTypeWrapper<MediaFields_MimeType_Read>;
  MediaFields_mimeType_Update: ResolverTypeWrapper<MediaFields_MimeType_Update>;
  MediaFields_updatedAt: ResolverTypeWrapper<MediaFields_UpdatedAt>;
  MediaFields_updatedAt_Create: ResolverTypeWrapper<MediaFields_UpdatedAt_Create>;
  MediaFields_updatedAt_Delete: ResolverTypeWrapper<MediaFields_UpdatedAt_Delete>;
  MediaFields_updatedAt_Read: ResolverTypeWrapper<MediaFields_UpdatedAt_Read>;
  MediaFields_updatedAt_Update: ResolverTypeWrapper<MediaFields_UpdatedAt_Update>;
  MediaFields_url: ResolverTypeWrapper<MediaFields_Url>;
  MediaFields_url_Create: ResolverTypeWrapper<MediaFields_Url_Create>;
  MediaFields_url_Delete: ResolverTypeWrapper<MediaFields_Url_Delete>;
  MediaFields_url_Read: ResolverTypeWrapper<MediaFields_Url_Read>;
  MediaFields_url_Update: ResolverTypeWrapper<MediaFields_Url_Update>;
  MediaFields_width: ResolverTypeWrapper<MediaFields_Width>;
  MediaFields_width_Create: ResolverTypeWrapper<MediaFields_Width_Create>;
  MediaFields_width_Delete: ResolverTypeWrapper<MediaFields_Width_Delete>;
  MediaFields_width_Read: ResolverTypeWrapper<MediaFields_Width_Read>;
  MediaFields_width_Update: ResolverTypeWrapper<MediaFields_Width_Update>;
  MediaReadAccess: ResolverTypeWrapper<MediaReadAccess>;
  MediaReadDocAccess: ResolverTypeWrapper<MediaReadDocAccess>;
  MediaUpdateAccess: ResolverTypeWrapper<MediaUpdateAccess>;
  MediaUpdateDocAccess: ResolverTypeWrapper<MediaUpdateDocAccess>;
  Media_alt_operator: Media_Alt_Operator;
  Media_caption_operator: Media_Caption_Operator;
  Media_createdAt_operator: Media_CreatedAt_Operator;
  Media_filename_operator: Media_Filename_Operator;
  Media_filesize_operator: Media_Filesize_Operator;
  Media_height_operator: Media_Height_Operator;
  Media_id_operator: Media_Id_Operator;
  Media_mimeType_operator: Media_MimeType_Operator;
  Media_updatedAt_operator: Media_UpdatedAt_Operator;
  Media_url_operator: Media_Url_Operator;
  Media_where: Media_Where;
  Media_where_and: Media_Where_And;
  Media_where_or: Media_Where_Or;
  Media_width_operator: Media_Width_Operator;
  Mutation: ResolverTypeWrapper<{}>;
  PayloadPreference: ResolverTypeWrapper<PayloadPreference>;
  PayloadPreferenceUpdate_UserRelationshipInput: PayloadPreferenceUpdate_UserRelationshipInput;
  PayloadPreferenceUpdate_UserRelationshipInputRelationTo: PayloadPreferenceUpdate_UserRelationshipInputRelationTo;
  PayloadPreference_User: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['PayloadPreference_User']>;
  PayloadPreference_UserRelationshipInput: PayloadPreference_UserRelationshipInput;
  PayloadPreference_UserRelationshipInputRelationTo: PayloadPreference_UserRelationshipInputRelationTo;
  PayloadPreference_User_RelationTo: PayloadPreference_User_RelationTo;
  PayloadPreference_User_Relationship: ResolverTypeWrapper<Omit<PayloadPreference_User_Relationship, 'value'> & { value?: Maybe<ResolversTypes['PayloadPreference_User']> }>;
  PayloadPreference_createdAt_operator: PayloadPreference_CreatedAt_Operator;
  PayloadPreference_id_operator: PayloadPreference_Id_Operator;
  PayloadPreference_key_operator: PayloadPreference_Key_Operator;
  PayloadPreference_updatedAt_operator: PayloadPreference_UpdatedAt_Operator;
  PayloadPreference_user_Relation: PayloadPreference_User_Relation;
  PayloadPreference_user_Relation_RelationTo: PayloadPreference_User_Relation_RelationTo;
  PayloadPreference_value_operator: PayloadPreference_Value_Operator;
  PayloadPreference_where: PayloadPreference_Where;
  PayloadPreference_where_and: PayloadPreference_Where_And;
  PayloadPreference_where_or: PayloadPreference_Where_Or;
  PayloadPreferences: ResolverTypeWrapper<PayloadPreferences>;
  PayloadPreferencesCreateAccess: ResolverTypeWrapper<PayloadPreferencesCreateAccess>;
  PayloadPreferencesCreateDocAccess: ResolverTypeWrapper<PayloadPreferencesCreateDocAccess>;
  PayloadPreferencesDeleteAccess: ResolverTypeWrapper<PayloadPreferencesDeleteAccess>;
  PayloadPreferencesDeleteDocAccess: ResolverTypeWrapper<PayloadPreferencesDeleteDocAccess>;
  PayloadPreferencesDocAccessFields: ResolverTypeWrapper<PayloadPreferencesDocAccessFields>;
  PayloadPreferencesDocAccessFields_createdAt: ResolverTypeWrapper<PayloadPreferencesDocAccessFields_CreatedAt>;
  PayloadPreferencesDocAccessFields_createdAt_Create: ResolverTypeWrapper<PayloadPreferencesDocAccessFields_CreatedAt_Create>;
  PayloadPreferencesDocAccessFields_createdAt_Delete: ResolverTypeWrapper<PayloadPreferencesDocAccessFields_CreatedAt_Delete>;
  PayloadPreferencesDocAccessFields_createdAt_Read: ResolverTypeWrapper<PayloadPreferencesDocAccessFields_CreatedAt_Read>;
  PayloadPreferencesDocAccessFields_createdAt_Update: ResolverTypeWrapper<PayloadPreferencesDocAccessFields_CreatedAt_Update>;
  PayloadPreferencesDocAccessFields_key: ResolverTypeWrapper<PayloadPreferencesDocAccessFields_Key>;
  PayloadPreferencesDocAccessFields_key_Create: ResolverTypeWrapper<PayloadPreferencesDocAccessFields_Key_Create>;
  PayloadPreferencesDocAccessFields_key_Delete: ResolverTypeWrapper<PayloadPreferencesDocAccessFields_Key_Delete>;
  PayloadPreferencesDocAccessFields_key_Read: ResolverTypeWrapper<PayloadPreferencesDocAccessFields_Key_Read>;
  PayloadPreferencesDocAccessFields_key_Update: ResolverTypeWrapper<PayloadPreferencesDocAccessFields_Key_Update>;
  PayloadPreferencesDocAccessFields_updatedAt: ResolverTypeWrapper<PayloadPreferencesDocAccessFields_UpdatedAt>;
  PayloadPreferencesDocAccessFields_updatedAt_Create: ResolverTypeWrapper<PayloadPreferencesDocAccessFields_UpdatedAt_Create>;
  PayloadPreferencesDocAccessFields_updatedAt_Delete: ResolverTypeWrapper<PayloadPreferencesDocAccessFields_UpdatedAt_Delete>;
  PayloadPreferencesDocAccessFields_updatedAt_Read: ResolverTypeWrapper<PayloadPreferencesDocAccessFields_UpdatedAt_Read>;
  PayloadPreferencesDocAccessFields_updatedAt_Update: ResolverTypeWrapper<PayloadPreferencesDocAccessFields_UpdatedAt_Update>;
  PayloadPreferencesDocAccessFields_user: ResolverTypeWrapper<PayloadPreferencesDocAccessFields_User>;
  PayloadPreferencesDocAccessFields_user_Create: ResolverTypeWrapper<PayloadPreferencesDocAccessFields_User_Create>;
  PayloadPreferencesDocAccessFields_user_Delete: ResolverTypeWrapper<PayloadPreferencesDocAccessFields_User_Delete>;
  PayloadPreferencesDocAccessFields_user_Read: ResolverTypeWrapper<PayloadPreferencesDocAccessFields_User_Read>;
  PayloadPreferencesDocAccessFields_user_Update: ResolverTypeWrapper<PayloadPreferencesDocAccessFields_User_Update>;
  PayloadPreferencesDocAccessFields_value: ResolverTypeWrapper<PayloadPreferencesDocAccessFields_Value>;
  PayloadPreferencesDocAccessFields_value_Create: ResolverTypeWrapper<PayloadPreferencesDocAccessFields_Value_Create>;
  PayloadPreferencesDocAccessFields_value_Delete: ResolverTypeWrapper<PayloadPreferencesDocAccessFields_Value_Delete>;
  PayloadPreferencesDocAccessFields_value_Read: ResolverTypeWrapper<PayloadPreferencesDocAccessFields_Value_Read>;
  PayloadPreferencesDocAccessFields_value_Update: ResolverTypeWrapper<PayloadPreferencesDocAccessFields_Value_Update>;
  PayloadPreferencesFields: ResolverTypeWrapper<PayloadPreferencesFields>;
  PayloadPreferencesFields_createdAt: ResolverTypeWrapper<PayloadPreferencesFields_CreatedAt>;
  PayloadPreferencesFields_createdAt_Create: ResolverTypeWrapper<PayloadPreferencesFields_CreatedAt_Create>;
  PayloadPreferencesFields_createdAt_Delete: ResolverTypeWrapper<PayloadPreferencesFields_CreatedAt_Delete>;
  PayloadPreferencesFields_createdAt_Read: ResolverTypeWrapper<PayloadPreferencesFields_CreatedAt_Read>;
  PayloadPreferencesFields_createdAt_Update: ResolverTypeWrapper<PayloadPreferencesFields_CreatedAt_Update>;
  PayloadPreferencesFields_key: ResolverTypeWrapper<PayloadPreferencesFields_Key>;
  PayloadPreferencesFields_key_Create: ResolverTypeWrapper<PayloadPreferencesFields_Key_Create>;
  PayloadPreferencesFields_key_Delete: ResolverTypeWrapper<PayloadPreferencesFields_Key_Delete>;
  PayloadPreferencesFields_key_Read: ResolverTypeWrapper<PayloadPreferencesFields_Key_Read>;
  PayloadPreferencesFields_key_Update: ResolverTypeWrapper<PayloadPreferencesFields_Key_Update>;
  PayloadPreferencesFields_updatedAt: ResolverTypeWrapper<PayloadPreferencesFields_UpdatedAt>;
  PayloadPreferencesFields_updatedAt_Create: ResolverTypeWrapper<PayloadPreferencesFields_UpdatedAt_Create>;
  PayloadPreferencesFields_updatedAt_Delete: ResolverTypeWrapper<PayloadPreferencesFields_UpdatedAt_Delete>;
  PayloadPreferencesFields_updatedAt_Read: ResolverTypeWrapper<PayloadPreferencesFields_UpdatedAt_Read>;
  PayloadPreferencesFields_updatedAt_Update: ResolverTypeWrapper<PayloadPreferencesFields_UpdatedAt_Update>;
  PayloadPreferencesFields_user: ResolverTypeWrapper<PayloadPreferencesFields_User>;
  PayloadPreferencesFields_user_Create: ResolverTypeWrapper<PayloadPreferencesFields_User_Create>;
  PayloadPreferencesFields_user_Delete: ResolverTypeWrapper<PayloadPreferencesFields_User_Delete>;
  PayloadPreferencesFields_user_Read: ResolverTypeWrapper<PayloadPreferencesFields_User_Read>;
  PayloadPreferencesFields_user_Update: ResolverTypeWrapper<PayloadPreferencesFields_User_Update>;
  PayloadPreferencesFields_value: ResolverTypeWrapper<PayloadPreferencesFields_Value>;
  PayloadPreferencesFields_value_Create: ResolverTypeWrapper<PayloadPreferencesFields_Value_Create>;
  PayloadPreferencesFields_value_Delete: ResolverTypeWrapper<PayloadPreferencesFields_Value_Delete>;
  PayloadPreferencesFields_value_Read: ResolverTypeWrapper<PayloadPreferencesFields_Value_Read>;
  PayloadPreferencesFields_value_Update: ResolverTypeWrapper<PayloadPreferencesFields_Value_Update>;
  PayloadPreferencesReadAccess: ResolverTypeWrapper<PayloadPreferencesReadAccess>;
  PayloadPreferencesReadDocAccess: ResolverTypeWrapper<PayloadPreferencesReadDocAccess>;
  PayloadPreferencesUpdateAccess: ResolverTypeWrapper<PayloadPreferencesUpdateAccess>;
  PayloadPreferencesUpdateDocAccess: ResolverTypeWrapper<PayloadPreferencesUpdateDocAccess>;
  Query: ResolverTypeWrapper<{}>;
  String: ResolverTypeWrapper<Scalars['String']['output']>;
  TaskTemplate: ResolverTypeWrapper<TaskTemplate>;
  TaskTemplateUpdate_Review_approver_MutationInput: TaskTemplateUpdate_Review_Approver_MutationInput;
  TaskTemplateUpdate_Schedule_Recurrence_freq_MutationInput: TaskTemplateUpdate_Schedule_Recurrence_Freq_MutationInput;
  TaskTemplateUpdate_Schedule_event_MutationInput: TaskTemplateUpdate_Schedule_Event_MutationInput;
  TaskTemplateUpdate__status_MutationInput: TaskTemplateUpdate__Status_MutationInput;
  TaskTemplateUpdate_assignment_MutationInput: TaskTemplateUpdate_Assignment_MutationInput;
  TaskTemplateUpdate_categories_MutationInput: TaskTemplateUpdate_Categories_MutationInput;
  TaskTemplateUpdate_feature_MutationInput: TaskTemplateUpdate_Feature_MutationInput;
  TaskTemplateVersion: ResolverTypeWrapper<TaskTemplateVersion>;
  TaskTemplateVersion_Version: ResolverTypeWrapper<TaskTemplateVersion_Version>;
  TaskTemplateVersion_Version_Resources: ResolverTypeWrapper<TaskTemplateVersion_Version_Resources>;
  TaskTemplateVersion_Version_Review: ResolverTypeWrapper<TaskTemplateVersion_Version_Review>;
  TaskTemplateVersion_Version_Review_approver: TaskTemplateVersion_Version_Review_Approver;
  TaskTemplateVersion_Version_Schedule: ResolverTypeWrapper<TaskTemplateVersion_Version_Schedule>;
  TaskTemplateVersion_Version_Schedule_Recurrence: ResolverTypeWrapper<TaskTemplateVersion_Version_Schedule_Recurrence>;
  TaskTemplateVersion_Version_Schedule_Recurrence_freq: TaskTemplateVersion_Version_Schedule_Recurrence_Freq;
  TaskTemplateVersion_Version_Schedule_event: TaskTemplateVersion_Version_Schedule_Event;
  TaskTemplateVersion_Version_Thumbnail_alt_operator: TaskTemplateVersion_Version_Thumbnail_Alt_Operator;
  TaskTemplateVersion_Version_Thumbnail_caption_operator: TaskTemplateVersion_Version_Thumbnail_Caption_Operator;
  TaskTemplateVersion_Version_Thumbnail_createdAt_operator: TaskTemplateVersion_Version_Thumbnail_CreatedAt_Operator;
  TaskTemplateVersion_Version_Thumbnail_filename_operator: TaskTemplateVersion_Version_Thumbnail_Filename_Operator;
  TaskTemplateVersion_Version_Thumbnail_filesize_operator: TaskTemplateVersion_Version_Thumbnail_Filesize_Operator;
  TaskTemplateVersion_Version_Thumbnail_height_operator: TaskTemplateVersion_Version_Thumbnail_Height_Operator;
  TaskTemplateVersion_Version_Thumbnail_id_operator: TaskTemplateVersion_Version_Thumbnail_Id_Operator;
  TaskTemplateVersion_Version_Thumbnail_mimeType_operator: TaskTemplateVersion_Version_Thumbnail_MimeType_Operator;
  TaskTemplateVersion_Version_Thumbnail_updatedAt_operator: TaskTemplateVersion_Version_Thumbnail_UpdatedAt_Operator;
  TaskTemplateVersion_Version_Thumbnail_url_operator: TaskTemplateVersion_Version_Thumbnail_Url_Operator;
  TaskTemplateVersion_Version_Thumbnail_where: TaskTemplateVersion_Version_Thumbnail_Where;
  TaskTemplateVersion_Version_Thumbnail_where_and: TaskTemplateVersion_Version_Thumbnail_Where_And;
  TaskTemplateVersion_Version_Thumbnail_where_or: TaskTemplateVersion_Version_Thumbnail_Where_Or;
  TaskTemplateVersion_Version_Thumbnail_width_operator: TaskTemplateVersion_Version_Thumbnail_Width_Operator;
  TaskTemplateVersion_Version__status: TaskTemplateVersion_Version__Status;
  TaskTemplateVersion_Version_assignment: TaskTemplateVersion_Version_Assignment;
  TaskTemplateVersion_Version_categories: TaskTemplateVersion_Version_Categories;
  TaskTemplateVersion_Version_feature: TaskTemplateVersion_Version_Feature;
  TaskTemplate_Resources: ResolverTypeWrapper<TaskTemplate_Resources>;
  TaskTemplate_Review: ResolverTypeWrapper<TaskTemplate_Review>;
  TaskTemplate_Review_approver: TaskTemplate_Review_Approver;
  TaskTemplate_Review_approver_MutationInput: TaskTemplate_Review_Approver_MutationInput;
  TaskTemplate_Schedule: ResolverTypeWrapper<TaskTemplate_Schedule>;
  TaskTemplate_Schedule_Recurrence: ResolverTypeWrapper<TaskTemplate_Schedule_Recurrence>;
  TaskTemplate_Schedule_Recurrence_freq: TaskTemplate_Schedule_Recurrence_Freq;
  TaskTemplate_Schedule_Recurrence_freq_MutationInput: TaskTemplate_Schedule_Recurrence_Freq_MutationInput;
  TaskTemplate_Schedule_event: TaskTemplate_Schedule_Event;
  TaskTemplate_Schedule_event_MutationInput: TaskTemplate_Schedule_Event_MutationInput;
  TaskTemplate_Thumbnail_alt_operator: TaskTemplate_Thumbnail_Alt_Operator;
  TaskTemplate_Thumbnail_caption_operator: TaskTemplate_Thumbnail_Caption_Operator;
  TaskTemplate_Thumbnail_createdAt_operator: TaskTemplate_Thumbnail_CreatedAt_Operator;
  TaskTemplate_Thumbnail_filename_operator: TaskTemplate_Thumbnail_Filename_Operator;
  TaskTemplate_Thumbnail_filesize_operator: TaskTemplate_Thumbnail_Filesize_Operator;
  TaskTemplate_Thumbnail_height_operator: TaskTemplate_Thumbnail_Height_Operator;
  TaskTemplate_Thumbnail_id_operator: TaskTemplate_Thumbnail_Id_Operator;
  TaskTemplate_Thumbnail_mimeType_operator: TaskTemplate_Thumbnail_MimeType_Operator;
  TaskTemplate_Thumbnail_updatedAt_operator: TaskTemplate_Thumbnail_UpdatedAt_Operator;
  TaskTemplate_Thumbnail_url_operator: TaskTemplate_Thumbnail_Url_Operator;
  TaskTemplate_Thumbnail_where: TaskTemplate_Thumbnail_Where;
  TaskTemplate_Thumbnail_where_and: TaskTemplate_Thumbnail_Where_And;
  TaskTemplate_Thumbnail_where_or: TaskTemplate_Thumbnail_Where_Or;
  TaskTemplate_Thumbnail_width_operator: TaskTemplate_Thumbnail_Width_Operator;
  TaskTemplate__status: TaskTemplate__Status;
  TaskTemplate__status_Input: TaskTemplate__Status_Input;
  TaskTemplate__status_MutationInput: TaskTemplate__Status_MutationInput;
  TaskTemplate__status_operator: TaskTemplate__Status_Operator;
  TaskTemplate_assignment: TaskTemplate_Assignment;
  TaskTemplate_assignment_Input: TaskTemplate_Assignment_Input;
  TaskTemplate_assignment_MutationInput: TaskTemplate_Assignment_MutationInput;
  TaskTemplate_assignment_operator: TaskTemplate_Assignment_Operator;
  TaskTemplate_categories: TaskTemplate_Categories;
  TaskTemplate_categories_Input: TaskTemplate_Categories_Input;
  TaskTemplate_categories_MutationInput: TaskTemplate_Categories_MutationInput;
  TaskTemplate_categories_operator: TaskTemplate_Categories_Operator;
  TaskTemplate_createdAt_operator: TaskTemplate_CreatedAt_Operator;
  TaskTemplate_description_operator: TaskTemplate_Description_Operator;
  TaskTemplate_details_operator: TaskTemplate_Details_Operator;
  TaskTemplate_feature: TaskTemplate_Feature;
  TaskTemplate_feature_Input: TaskTemplate_Feature_Input;
  TaskTemplate_feature_MutationInput: TaskTemplate_Feature_MutationInput;
  TaskTemplate_feature_operator: TaskTemplate_Feature_Operator;
  TaskTemplate_id_operator: TaskTemplate_Id_Operator;
  TaskTemplate_recurrence__freq_Input: TaskTemplate_Recurrence__Freq_Input;
  TaskTemplate_recurrence__freq_operator: TaskTemplate_Recurrence__Freq_Operator;
  TaskTemplate_recurrence__interval_operator: TaskTemplate_Recurrence__Interval_Operator;
  TaskTemplate_resources__articles_operator: TaskTemplate_Resources__Articles_Operator;
  TaskTemplate_resources__videos_operator: TaskTemplate_Resources__Videos_Operator;
  TaskTemplate_review__approver_Input: TaskTemplate_Review__Approver_Input;
  TaskTemplate_review__approver_operator: TaskTemplate_Review__Approver_Operator;
  TaskTemplate_review__evidence_operator: TaskTemplate_Review__Evidence_Operator;
  TaskTemplate_review__required_operator: TaskTemplate_Review__Required_Operator;
  TaskTemplate_schedule__due_operator: TaskTemplate_Schedule__Due_Operator;
  TaskTemplate_schedule__event_Input: TaskTemplate_Schedule__Event_Input;
  TaskTemplate_schedule__event_operator: TaskTemplate_Schedule__Event_Operator;
  TaskTemplate_schedule__id_operator: TaskTemplate_Schedule__Id_Operator;
  TaskTemplate_thumbnail_operator: TaskTemplate_Thumbnail_Operator;
  TaskTemplate_title_operator: TaskTemplate_Title_Operator;
  TaskTemplate_updatedAt_operator: TaskTemplate_UpdatedAt_Operator;
  TaskTemplate_where: TaskTemplate_Where;
  TaskTemplate_where_and: TaskTemplate_Where_And;
  TaskTemplate_where_or: TaskTemplate_Where_Or;
  TaskTemplates: ResolverTypeWrapper<TaskTemplates>;
  TaskTemplatesCreateAccess: ResolverTypeWrapper<TaskTemplatesCreateAccess>;
  TaskTemplatesCreateDocAccess: ResolverTypeWrapper<TaskTemplatesCreateDocAccess>;
  TaskTemplatesDeleteAccess: ResolverTypeWrapper<TaskTemplatesDeleteAccess>;
  TaskTemplatesDeleteDocAccess: ResolverTypeWrapper<TaskTemplatesDeleteDocAccess>;
  TaskTemplatesDocAccessFields: ResolverTypeWrapper<TaskTemplatesDocAccessFields>;
  TaskTemplatesDocAccessFields__status: ResolverTypeWrapper<TaskTemplatesDocAccessFields__Status>;
  TaskTemplatesDocAccessFields__status_Create: ResolverTypeWrapper<TaskTemplatesDocAccessFields__Status_Create>;
  TaskTemplatesDocAccessFields__status_Delete: ResolverTypeWrapper<TaskTemplatesDocAccessFields__Status_Delete>;
  TaskTemplatesDocAccessFields__status_Read: ResolverTypeWrapper<TaskTemplatesDocAccessFields__Status_Read>;
  TaskTemplatesDocAccessFields__status_Update: ResolverTypeWrapper<TaskTemplatesDocAccessFields__Status_Update>;
  TaskTemplatesDocAccessFields_assignment: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Assignment>;
  TaskTemplatesDocAccessFields_assignment_Create: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Assignment_Create>;
  TaskTemplatesDocAccessFields_assignment_Delete: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Assignment_Delete>;
  TaskTemplatesDocAccessFields_assignment_Read: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Assignment_Read>;
  TaskTemplatesDocAccessFields_assignment_Update: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Assignment_Update>;
  TaskTemplatesDocAccessFields_categories: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Categories>;
  TaskTemplatesDocAccessFields_categories_Create: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Categories_Create>;
  TaskTemplatesDocAccessFields_categories_Delete: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Categories_Delete>;
  TaskTemplatesDocAccessFields_categories_Read: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Categories_Read>;
  TaskTemplatesDocAccessFields_categories_Update: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Categories_Update>;
  TaskTemplatesDocAccessFields_createdAt: ResolverTypeWrapper<TaskTemplatesDocAccessFields_CreatedAt>;
  TaskTemplatesDocAccessFields_createdAt_Create: ResolverTypeWrapper<TaskTemplatesDocAccessFields_CreatedAt_Create>;
  TaskTemplatesDocAccessFields_createdAt_Delete: ResolverTypeWrapper<TaskTemplatesDocAccessFields_CreatedAt_Delete>;
  TaskTemplatesDocAccessFields_createdAt_Read: ResolverTypeWrapper<TaskTemplatesDocAccessFields_CreatedAt_Read>;
  TaskTemplatesDocAccessFields_createdAt_Update: ResolverTypeWrapper<TaskTemplatesDocAccessFields_CreatedAt_Update>;
  TaskTemplatesDocAccessFields_description: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Description>;
  TaskTemplatesDocAccessFields_description_Create: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Description_Create>;
  TaskTemplatesDocAccessFields_description_Delete: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Description_Delete>;
  TaskTemplatesDocAccessFields_description_Read: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Description_Read>;
  TaskTemplatesDocAccessFields_description_Update: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Description_Update>;
  TaskTemplatesDocAccessFields_details: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Details>;
  TaskTemplatesDocAccessFields_details_Create: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Details_Create>;
  TaskTemplatesDocAccessFields_details_Delete: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Details_Delete>;
  TaskTemplatesDocAccessFields_details_Read: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Details_Read>;
  TaskTemplatesDocAccessFields_details_Update: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Details_Update>;
  TaskTemplatesDocAccessFields_feature: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Feature>;
  TaskTemplatesDocAccessFields_feature_Create: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Feature_Create>;
  TaskTemplatesDocAccessFields_feature_Delete: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Feature_Delete>;
  TaskTemplatesDocAccessFields_feature_Read: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Feature_Read>;
  TaskTemplatesDocAccessFields_feature_Update: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Feature_Update>;
  TaskTemplatesDocAccessFields_resources: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Resources>;
  TaskTemplatesDocAccessFields_resources_Create: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Resources_Create>;
  TaskTemplatesDocAccessFields_resources_Delete: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Resources_Delete>;
  TaskTemplatesDocAccessFields_resources_Fields: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Resources_Fields>;
  TaskTemplatesDocAccessFields_resources_Read: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Resources_Read>;
  TaskTemplatesDocAccessFields_resources_Update: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Resources_Update>;
  TaskTemplatesDocAccessFields_resources_articles: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Resources_Articles>;
  TaskTemplatesDocAccessFields_resources_articles_Create: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Resources_Articles_Create>;
  TaskTemplatesDocAccessFields_resources_articles_Delete: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Resources_Articles_Delete>;
  TaskTemplatesDocAccessFields_resources_articles_Read: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Resources_Articles_Read>;
  TaskTemplatesDocAccessFields_resources_articles_Update: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Resources_Articles_Update>;
  TaskTemplatesDocAccessFields_resources_videos: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Resources_Videos>;
  TaskTemplatesDocAccessFields_resources_videos_Create: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Resources_Videos_Create>;
  TaskTemplatesDocAccessFields_resources_videos_Delete: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Resources_Videos_Delete>;
  TaskTemplatesDocAccessFields_resources_videos_Read: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Resources_Videos_Read>;
  TaskTemplatesDocAccessFields_resources_videos_Update: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Resources_Videos_Update>;
  TaskTemplatesDocAccessFields_review: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Review>;
  TaskTemplatesDocAccessFields_review_Create: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Review_Create>;
  TaskTemplatesDocAccessFields_review_Delete: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Review_Delete>;
  TaskTemplatesDocAccessFields_review_Fields: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Review_Fields>;
  TaskTemplatesDocAccessFields_review_Read: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Review_Read>;
  TaskTemplatesDocAccessFields_review_Update: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Review_Update>;
  TaskTemplatesDocAccessFields_review_approver: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Review_Approver>;
  TaskTemplatesDocAccessFields_review_approver_Create: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Review_Approver_Create>;
  TaskTemplatesDocAccessFields_review_approver_Delete: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Review_Approver_Delete>;
  TaskTemplatesDocAccessFields_review_approver_Read: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Review_Approver_Read>;
  TaskTemplatesDocAccessFields_review_approver_Update: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Review_Approver_Update>;
  TaskTemplatesDocAccessFields_review_evidence: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Review_Evidence>;
  TaskTemplatesDocAccessFields_review_evidence_Create: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Review_Evidence_Create>;
  TaskTemplatesDocAccessFields_review_evidence_Delete: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Review_Evidence_Delete>;
  TaskTemplatesDocAccessFields_review_evidence_Read: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Review_Evidence_Read>;
  TaskTemplatesDocAccessFields_review_evidence_Update: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Review_Evidence_Update>;
  TaskTemplatesDocAccessFields_review_required: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Review_Required>;
  TaskTemplatesDocAccessFields_review_required_Create: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Review_Required_Create>;
  TaskTemplatesDocAccessFields_review_required_Delete: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Review_Required_Delete>;
  TaskTemplatesDocAccessFields_review_required_Read: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Review_Required_Read>;
  TaskTemplatesDocAccessFields_review_required_Update: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Review_Required_Update>;
  TaskTemplatesDocAccessFields_schedule: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Schedule>;
  TaskTemplatesDocAccessFields_schedule_Create: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Schedule_Create>;
  TaskTemplatesDocAccessFields_schedule_Delete: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Schedule_Delete>;
  TaskTemplatesDocAccessFields_schedule_Fields: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Schedule_Fields>;
  TaskTemplatesDocAccessFields_schedule_Read: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Schedule_Read>;
  TaskTemplatesDocAccessFields_schedule_Update: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Schedule_Update>;
  TaskTemplatesDocAccessFields_schedule_due: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Schedule_Due>;
  TaskTemplatesDocAccessFields_schedule_due_Create: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Schedule_Due_Create>;
  TaskTemplatesDocAccessFields_schedule_due_Delete: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Schedule_Due_Delete>;
  TaskTemplatesDocAccessFields_schedule_due_Read: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Schedule_Due_Read>;
  TaskTemplatesDocAccessFields_schedule_due_Update: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Schedule_Due_Update>;
  TaskTemplatesDocAccessFields_schedule_event: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Schedule_Event>;
  TaskTemplatesDocAccessFields_schedule_event_Create: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Schedule_Event_Create>;
  TaskTemplatesDocAccessFields_schedule_event_Delete: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Schedule_Event_Delete>;
  TaskTemplatesDocAccessFields_schedule_event_Read: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Schedule_Event_Read>;
  TaskTemplatesDocAccessFields_schedule_event_Update: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Schedule_Event_Update>;
  TaskTemplatesDocAccessFields_schedule_freq: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Schedule_Freq>;
  TaskTemplatesDocAccessFields_schedule_freq_Create: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Schedule_Freq_Create>;
  TaskTemplatesDocAccessFields_schedule_freq_Delete: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Schedule_Freq_Delete>;
  TaskTemplatesDocAccessFields_schedule_freq_Read: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Schedule_Freq_Read>;
  TaskTemplatesDocAccessFields_schedule_freq_Update: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Schedule_Freq_Update>;
  TaskTemplatesDocAccessFields_schedule_id: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Schedule_Id>;
  TaskTemplatesDocAccessFields_schedule_id_Create: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Schedule_Id_Create>;
  TaskTemplatesDocAccessFields_schedule_id_Delete: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Schedule_Id_Delete>;
  TaskTemplatesDocAccessFields_schedule_id_Read: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Schedule_Id_Read>;
  TaskTemplatesDocAccessFields_schedule_id_Update: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Schedule_Id_Update>;
  TaskTemplatesDocAccessFields_schedule_interval: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Schedule_Interval>;
  TaskTemplatesDocAccessFields_schedule_interval_Create: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Schedule_Interval_Create>;
  TaskTemplatesDocAccessFields_schedule_interval_Delete: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Schedule_Interval_Delete>;
  TaskTemplatesDocAccessFields_schedule_interval_Read: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Schedule_Interval_Read>;
  TaskTemplatesDocAccessFields_schedule_interval_Update: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Schedule_Interval_Update>;
  TaskTemplatesDocAccessFields_thumbnail: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Thumbnail>;
  TaskTemplatesDocAccessFields_thumbnail_Create: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Thumbnail_Create>;
  TaskTemplatesDocAccessFields_thumbnail_Delete: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Thumbnail_Delete>;
  TaskTemplatesDocAccessFields_thumbnail_Read: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Thumbnail_Read>;
  TaskTemplatesDocAccessFields_thumbnail_Update: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Thumbnail_Update>;
  TaskTemplatesDocAccessFields_title: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Title>;
  TaskTemplatesDocAccessFields_title_Create: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Title_Create>;
  TaskTemplatesDocAccessFields_title_Delete: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Title_Delete>;
  TaskTemplatesDocAccessFields_title_Read: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Title_Read>;
  TaskTemplatesDocAccessFields_title_Update: ResolverTypeWrapper<TaskTemplatesDocAccessFields_Title_Update>;
  TaskTemplatesDocAccessFields_updatedAt: ResolverTypeWrapper<TaskTemplatesDocAccessFields_UpdatedAt>;
  TaskTemplatesDocAccessFields_updatedAt_Create: ResolverTypeWrapper<TaskTemplatesDocAccessFields_UpdatedAt_Create>;
  TaskTemplatesDocAccessFields_updatedAt_Delete: ResolverTypeWrapper<TaskTemplatesDocAccessFields_UpdatedAt_Delete>;
  TaskTemplatesDocAccessFields_updatedAt_Read: ResolverTypeWrapper<TaskTemplatesDocAccessFields_UpdatedAt_Read>;
  TaskTemplatesDocAccessFields_updatedAt_Update: ResolverTypeWrapper<TaskTemplatesDocAccessFields_UpdatedAt_Update>;
  TaskTemplatesFields: ResolverTypeWrapper<TaskTemplatesFields>;
  TaskTemplatesFields__status: ResolverTypeWrapper<TaskTemplatesFields__Status>;
  TaskTemplatesFields__status_Create: ResolverTypeWrapper<TaskTemplatesFields__Status_Create>;
  TaskTemplatesFields__status_Delete: ResolverTypeWrapper<TaskTemplatesFields__Status_Delete>;
  TaskTemplatesFields__status_Read: ResolverTypeWrapper<TaskTemplatesFields__Status_Read>;
  TaskTemplatesFields__status_Update: ResolverTypeWrapper<TaskTemplatesFields__Status_Update>;
  TaskTemplatesFields_assignment: ResolverTypeWrapper<TaskTemplatesFields_Assignment>;
  TaskTemplatesFields_assignment_Create: ResolverTypeWrapper<TaskTemplatesFields_Assignment_Create>;
  TaskTemplatesFields_assignment_Delete: ResolverTypeWrapper<TaskTemplatesFields_Assignment_Delete>;
  TaskTemplatesFields_assignment_Read: ResolverTypeWrapper<TaskTemplatesFields_Assignment_Read>;
  TaskTemplatesFields_assignment_Update: ResolverTypeWrapper<TaskTemplatesFields_Assignment_Update>;
  TaskTemplatesFields_categories: ResolverTypeWrapper<TaskTemplatesFields_Categories>;
  TaskTemplatesFields_categories_Create: ResolverTypeWrapper<TaskTemplatesFields_Categories_Create>;
  TaskTemplatesFields_categories_Delete: ResolverTypeWrapper<TaskTemplatesFields_Categories_Delete>;
  TaskTemplatesFields_categories_Read: ResolverTypeWrapper<TaskTemplatesFields_Categories_Read>;
  TaskTemplatesFields_categories_Update: ResolverTypeWrapper<TaskTemplatesFields_Categories_Update>;
  TaskTemplatesFields_createdAt: ResolverTypeWrapper<TaskTemplatesFields_CreatedAt>;
  TaskTemplatesFields_createdAt_Create: ResolverTypeWrapper<TaskTemplatesFields_CreatedAt_Create>;
  TaskTemplatesFields_createdAt_Delete: ResolverTypeWrapper<TaskTemplatesFields_CreatedAt_Delete>;
  TaskTemplatesFields_createdAt_Read: ResolverTypeWrapper<TaskTemplatesFields_CreatedAt_Read>;
  TaskTemplatesFields_createdAt_Update: ResolverTypeWrapper<TaskTemplatesFields_CreatedAt_Update>;
  TaskTemplatesFields_description: ResolverTypeWrapper<TaskTemplatesFields_Description>;
  TaskTemplatesFields_description_Create: ResolverTypeWrapper<TaskTemplatesFields_Description_Create>;
  TaskTemplatesFields_description_Delete: ResolverTypeWrapper<TaskTemplatesFields_Description_Delete>;
  TaskTemplatesFields_description_Read: ResolverTypeWrapper<TaskTemplatesFields_Description_Read>;
  TaskTemplatesFields_description_Update: ResolverTypeWrapper<TaskTemplatesFields_Description_Update>;
  TaskTemplatesFields_details: ResolverTypeWrapper<TaskTemplatesFields_Details>;
  TaskTemplatesFields_details_Create: ResolverTypeWrapper<TaskTemplatesFields_Details_Create>;
  TaskTemplatesFields_details_Delete: ResolverTypeWrapper<TaskTemplatesFields_Details_Delete>;
  TaskTemplatesFields_details_Read: ResolverTypeWrapper<TaskTemplatesFields_Details_Read>;
  TaskTemplatesFields_details_Update: ResolverTypeWrapper<TaskTemplatesFields_Details_Update>;
  TaskTemplatesFields_feature: ResolverTypeWrapper<TaskTemplatesFields_Feature>;
  TaskTemplatesFields_feature_Create: ResolverTypeWrapper<TaskTemplatesFields_Feature_Create>;
  TaskTemplatesFields_feature_Delete: ResolverTypeWrapper<TaskTemplatesFields_Feature_Delete>;
  TaskTemplatesFields_feature_Read: ResolverTypeWrapper<TaskTemplatesFields_Feature_Read>;
  TaskTemplatesFields_feature_Update: ResolverTypeWrapper<TaskTemplatesFields_Feature_Update>;
  TaskTemplatesFields_resources: ResolverTypeWrapper<TaskTemplatesFields_Resources>;
  TaskTemplatesFields_resources_Create: ResolverTypeWrapper<TaskTemplatesFields_Resources_Create>;
  TaskTemplatesFields_resources_Delete: ResolverTypeWrapper<TaskTemplatesFields_Resources_Delete>;
  TaskTemplatesFields_resources_Fields: ResolverTypeWrapper<TaskTemplatesFields_Resources_Fields>;
  TaskTemplatesFields_resources_Read: ResolverTypeWrapper<TaskTemplatesFields_Resources_Read>;
  TaskTemplatesFields_resources_Update: ResolverTypeWrapper<TaskTemplatesFields_Resources_Update>;
  TaskTemplatesFields_resources_articles: ResolverTypeWrapper<TaskTemplatesFields_Resources_Articles>;
  TaskTemplatesFields_resources_articles_Create: ResolverTypeWrapper<TaskTemplatesFields_Resources_Articles_Create>;
  TaskTemplatesFields_resources_articles_Delete: ResolverTypeWrapper<TaskTemplatesFields_Resources_Articles_Delete>;
  TaskTemplatesFields_resources_articles_Read: ResolverTypeWrapper<TaskTemplatesFields_Resources_Articles_Read>;
  TaskTemplatesFields_resources_articles_Update: ResolverTypeWrapper<TaskTemplatesFields_Resources_Articles_Update>;
  TaskTemplatesFields_resources_videos: ResolverTypeWrapper<TaskTemplatesFields_Resources_Videos>;
  TaskTemplatesFields_resources_videos_Create: ResolverTypeWrapper<TaskTemplatesFields_Resources_Videos_Create>;
  TaskTemplatesFields_resources_videos_Delete: ResolverTypeWrapper<TaskTemplatesFields_Resources_Videos_Delete>;
  TaskTemplatesFields_resources_videos_Read: ResolverTypeWrapper<TaskTemplatesFields_Resources_Videos_Read>;
  TaskTemplatesFields_resources_videos_Update: ResolverTypeWrapper<TaskTemplatesFields_Resources_Videos_Update>;
  TaskTemplatesFields_review: ResolverTypeWrapper<TaskTemplatesFields_Review>;
  TaskTemplatesFields_review_Create: ResolverTypeWrapper<TaskTemplatesFields_Review_Create>;
  TaskTemplatesFields_review_Delete: ResolverTypeWrapper<TaskTemplatesFields_Review_Delete>;
  TaskTemplatesFields_review_Fields: ResolverTypeWrapper<TaskTemplatesFields_Review_Fields>;
  TaskTemplatesFields_review_Read: ResolverTypeWrapper<TaskTemplatesFields_Review_Read>;
  TaskTemplatesFields_review_Update: ResolverTypeWrapper<TaskTemplatesFields_Review_Update>;
  TaskTemplatesFields_review_approver: ResolverTypeWrapper<TaskTemplatesFields_Review_Approver>;
  TaskTemplatesFields_review_approver_Create: ResolverTypeWrapper<TaskTemplatesFields_Review_Approver_Create>;
  TaskTemplatesFields_review_approver_Delete: ResolverTypeWrapper<TaskTemplatesFields_Review_Approver_Delete>;
  TaskTemplatesFields_review_approver_Read: ResolverTypeWrapper<TaskTemplatesFields_Review_Approver_Read>;
  TaskTemplatesFields_review_approver_Update: ResolverTypeWrapper<TaskTemplatesFields_Review_Approver_Update>;
  TaskTemplatesFields_review_evidence: ResolverTypeWrapper<TaskTemplatesFields_Review_Evidence>;
  TaskTemplatesFields_review_evidence_Create: ResolverTypeWrapper<TaskTemplatesFields_Review_Evidence_Create>;
  TaskTemplatesFields_review_evidence_Delete: ResolverTypeWrapper<TaskTemplatesFields_Review_Evidence_Delete>;
  TaskTemplatesFields_review_evidence_Read: ResolverTypeWrapper<TaskTemplatesFields_Review_Evidence_Read>;
  TaskTemplatesFields_review_evidence_Update: ResolverTypeWrapper<TaskTemplatesFields_Review_Evidence_Update>;
  TaskTemplatesFields_review_required: ResolverTypeWrapper<TaskTemplatesFields_Review_Required>;
  TaskTemplatesFields_review_required_Create: ResolverTypeWrapper<TaskTemplatesFields_Review_Required_Create>;
  TaskTemplatesFields_review_required_Delete: ResolverTypeWrapper<TaskTemplatesFields_Review_Required_Delete>;
  TaskTemplatesFields_review_required_Read: ResolverTypeWrapper<TaskTemplatesFields_Review_Required_Read>;
  TaskTemplatesFields_review_required_Update: ResolverTypeWrapper<TaskTemplatesFields_Review_Required_Update>;
  TaskTemplatesFields_schedule: ResolverTypeWrapper<TaskTemplatesFields_Schedule>;
  TaskTemplatesFields_schedule_Create: ResolverTypeWrapper<TaskTemplatesFields_Schedule_Create>;
  TaskTemplatesFields_schedule_Delete: ResolverTypeWrapper<TaskTemplatesFields_Schedule_Delete>;
  TaskTemplatesFields_schedule_Fields: ResolverTypeWrapper<TaskTemplatesFields_Schedule_Fields>;
  TaskTemplatesFields_schedule_Read: ResolverTypeWrapper<TaskTemplatesFields_Schedule_Read>;
  TaskTemplatesFields_schedule_Update: ResolverTypeWrapper<TaskTemplatesFields_Schedule_Update>;
  TaskTemplatesFields_schedule_due: ResolverTypeWrapper<TaskTemplatesFields_Schedule_Due>;
  TaskTemplatesFields_schedule_due_Create: ResolverTypeWrapper<TaskTemplatesFields_Schedule_Due_Create>;
  TaskTemplatesFields_schedule_due_Delete: ResolverTypeWrapper<TaskTemplatesFields_Schedule_Due_Delete>;
  TaskTemplatesFields_schedule_due_Read: ResolverTypeWrapper<TaskTemplatesFields_Schedule_Due_Read>;
  TaskTemplatesFields_schedule_due_Update: ResolverTypeWrapper<TaskTemplatesFields_Schedule_Due_Update>;
  TaskTemplatesFields_schedule_event: ResolverTypeWrapper<TaskTemplatesFields_Schedule_Event>;
  TaskTemplatesFields_schedule_event_Create: ResolverTypeWrapper<TaskTemplatesFields_Schedule_Event_Create>;
  TaskTemplatesFields_schedule_event_Delete: ResolverTypeWrapper<TaskTemplatesFields_Schedule_Event_Delete>;
  TaskTemplatesFields_schedule_event_Read: ResolverTypeWrapper<TaskTemplatesFields_Schedule_Event_Read>;
  TaskTemplatesFields_schedule_event_Update: ResolverTypeWrapper<TaskTemplatesFields_Schedule_Event_Update>;
  TaskTemplatesFields_schedule_freq: ResolverTypeWrapper<TaskTemplatesFields_Schedule_Freq>;
  TaskTemplatesFields_schedule_freq_Create: ResolverTypeWrapper<TaskTemplatesFields_Schedule_Freq_Create>;
  TaskTemplatesFields_schedule_freq_Delete: ResolverTypeWrapper<TaskTemplatesFields_Schedule_Freq_Delete>;
  TaskTemplatesFields_schedule_freq_Read: ResolverTypeWrapper<TaskTemplatesFields_Schedule_Freq_Read>;
  TaskTemplatesFields_schedule_freq_Update: ResolverTypeWrapper<TaskTemplatesFields_Schedule_Freq_Update>;
  TaskTemplatesFields_schedule_id: ResolverTypeWrapper<TaskTemplatesFields_Schedule_Id>;
  TaskTemplatesFields_schedule_id_Create: ResolverTypeWrapper<TaskTemplatesFields_Schedule_Id_Create>;
  TaskTemplatesFields_schedule_id_Delete: ResolverTypeWrapper<TaskTemplatesFields_Schedule_Id_Delete>;
  TaskTemplatesFields_schedule_id_Read: ResolverTypeWrapper<TaskTemplatesFields_Schedule_Id_Read>;
  TaskTemplatesFields_schedule_id_Update: ResolverTypeWrapper<TaskTemplatesFields_Schedule_Id_Update>;
  TaskTemplatesFields_schedule_interval: ResolverTypeWrapper<TaskTemplatesFields_Schedule_Interval>;
  TaskTemplatesFields_schedule_interval_Create: ResolverTypeWrapper<TaskTemplatesFields_Schedule_Interval_Create>;
  TaskTemplatesFields_schedule_interval_Delete: ResolverTypeWrapper<TaskTemplatesFields_Schedule_Interval_Delete>;
  TaskTemplatesFields_schedule_interval_Read: ResolverTypeWrapper<TaskTemplatesFields_Schedule_Interval_Read>;
  TaskTemplatesFields_schedule_interval_Update: ResolverTypeWrapper<TaskTemplatesFields_Schedule_Interval_Update>;
  TaskTemplatesFields_thumbnail: ResolverTypeWrapper<TaskTemplatesFields_Thumbnail>;
  TaskTemplatesFields_thumbnail_Create: ResolverTypeWrapper<TaskTemplatesFields_Thumbnail_Create>;
  TaskTemplatesFields_thumbnail_Delete: ResolverTypeWrapper<TaskTemplatesFields_Thumbnail_Delete>;
  TaskTemplatesFields_thumbnail_Read: ResolverTypeWrapper<TaskTemplatesFields_Thumbnail_Read>;
  TaskTemplatesFields_thumbnail_Update: ResolverTypeWrapper<TaskTemplatesFields_Thumbnail_Update>;
  TaskTemplatesFields_title: ResolverTypeWrapper<TaskTemplatesFields_Title>;
  TaskTemplatesFields_title_Create: ResolverTypeWrapper<TaskTemplatesFields_Title_Create>;
  TaskTemplatesFields_title_Delete: ResolverTypeWrapper<TaskTemplatesFields_Title_Delete>;
  TaskTemplatesFields_title_Read: ResolverTypeWrapper<TaskTemplatesFields_Title_Read>;
  TaskTemplatesFields_title_Update: ResolverTypeWrapper<TaskTemplatesFields_Title_Update>;
  TaskTemplatesFields_updatedAt: ResolverTypeWrapper<TaskTemplatesFields_UpdatedAt>;
  TaskTemplatesFields_updatedAt_Create: ResolverTypeWrapper<TaskTemplatesFields_UpdatedAt_Create>;
  TaskTemplatesFields_updatedAt_Delete: ResolverTypeWrapper<TaskTemplatesFields_UpdatedAt_Delete>;
  TaskTemplatesFields_updatedAt_Read: ResolverTypeWrapper<TaskTemplatesFields_UpdatedAt_Read>;
  TaskTemplatesFields_updatedAt_Update: ResolverTypeWrapper<TaskTemplatesFields_UpdatedAt_Update>;
  TaskTemplatesReadAccess: ResolverTypeWrapper<TaskTemplatesReadAccess>;
  TaskTemplatesReadDocAccess: ResolverTypeWrapper<TaskTemplatesReadDocAccess>;
  TaskTemplatesReadVersionsAccess: ResolverTypeWrapper<TaskTemplatesReadVersionsAccess>;
  TaskTemplatesReadVersionsDocAccess: ResolverTypeWrapper<TaskTemplatesReadVersionsDocAccess>;
  TaskTemplatesUpdateAccess: ResolverTypeWrapper<TaskTemplatesUpdateAccess>;
  TaskTemplatesUpdateDocAccess: ResolverTypeWrapper<TaskTemplatesUpdateDocAccess>;
  User: ResolverTypeWrapper<User>;
  UserUpdate_roles_MutationInput: UserUpdate_Roles_MutationInput;
  User_createdAt_operator: User_CreatedAt_Operator;
  User_email_operator: User_Email_Operator;
  User_id_operator: User_Id_Operator;
  User_name_operator: User_Name_Operator;
  User_roles: User_Roles;
  User_roles_Input: User_Roles_Input;
  User_roles_MutationInput: User_Roles_MutationInput;
  User_roles_operator: User_Roles_Operator;
  User_updatedAt_operator: User_UpdatedAt_Operator;
  User_where: User_Where;
  User_where_and: User_Where_And;
  User_where_or: User_Where_Or;
  Users: ResolverTypeWrapper<Users>;
  UsersCreateAccess: ResolverTypeWrapper<UsersCreateAccess>;
  UsersCreateDocAccess: ResolverTypeWrapper<UsersCreateDocAccess>;
  UsersDeleteAccess: ResolverTypeWrapper<UsersDeleteAccess>;
  UsersDeleteDocAccess: ResolverTypeWrapper<UsersDeleteDocAccess>;
  UsersDocAccessFields: ResolverTypeWrapper<UsersDocAccessFields>;
  UsersDocAccessFields_createdAt: ResolverTypeWrapper<UsersDocAccessFields_CreatedAt>;
  UsersDocAccessFields_createdAt_Create: ResolverTypeWrapper<UsersDocAccessFields_CreatedAt_Create>;
  UsersDocAccessFields_createdAt_Delete: ResolverTypeWrapper<UsersDocAccessFields_CreatedAt_Delete>;
  UsersDocAccessFields_createdAt_Read: ResolverTypeWrapper<UsersDocAccessFields_CreatedAt_Read>;
  UsersDocAccessFields_createdAt_Update: ResolverTypeWrapper<UsersDocAccessFields_CreatedAt_Update>;
  UsersDocAccessFields_email: ResolverTypeWrapper<UsersDocAccessFields_Email>;
  UsersDocAccessFields_email_Create: ResolverTypeWrapper<UsersDocAccessFields_Email_Create>;
  UsersDocAccessFields_email_Delete: ResolverTypeWrapper<UsersDocAccessFields_Email_Delete>;
  UsersDocAccessFields_email_Read: ResolverTypeWrapper<UsersDocAccessFields_Email_Read>;
  UsersDocAccessFields_email_Update: ResolverTypeWrapper<UsersDocAccessFields_Email_Update>;
  UsersDocAccessFields_name: ResolverTypeWrapper<UsersDocAccessFields_Name>;
  UsersDocAccessFields_name_Create: ResolverTypeWrapper<UsersDocAccessFields_Name_Create>;
  UsersDocAccessFields_name_Delete: ResolverTypeWrapper<UsersDocAccessFields_Name_Delete>;
  UsersDocAccessFields_name_Read: ResolverTypeWrapper<UsersDocAccessFields_Name_Read>;
  UsersDocAccessFields_name_Update: ResolverTypeWrapper<UsersDocAccessFields_Name_Update>;
  UsersDocAccessFields_password: ResolverTypeWrapper<UsersDocAccessFields_Password>;
  UsersDocAccessFields_password_Create: ResolverTypeWrapper<UsersDocAccessFields_Password_Create>;
  UsersDocAccessFields_password_Delete: ResolverTypeWrapper<UsersDocAccessFields_Password_Delete>;
  UsersDocAccessFields_password_Read: ResolverTypeWrapper<UsersDocAccessFields_Password_Read>;
  UsersDocAccessFields_password_Update: ResolverTypeWrapper<UsersDocAccessFields_Password_Update>;
  UsersDocAccessFields_roles: ResolverTypeWrapper<UsersDocAccessFields_Roles>;
  UsersDocAccessFields_roles_Create: ResolverTypeWrapper<UsersDocAccessFields_Roles_Create>;
  UsersDocAccessFields_roles_Delete: ResolverTypeWrapper<UsersDocAccessFields_Roles_Delete>;
  UsersDocAccessFields_roles_Read: ResolverTypeWrapper<UsersDocAccessFields_Roles_Read>;
  UsersDocAccessFields_roles_Update: ResolverTypeWrapper<UsersDocAccessFields_Roles_Update>;
  UsersDocAccessFields_updatedAt: ResolverTypeWrapper<UsersDocAccessFields_UpdatedAt>;
  UsersDocAccessFields_updatedAt_Create: ResolverTypeWrapper<UsersDocAccessFields_UpdatedAt_Create>;
  UsersDocAccessFields_updatedAt_Delete: ResolverTypeWrapper<UsersDocAccessFields_UpdatedAt_Delete>;
  UsersDocAccessFields_updatedAt_Read: ResolverTypeWrapper<UsersDocAccessFields_UpdatedAt_Read>;
  UsersDocAccessFields_updatedAt_Update: ResolverTypeWrapper<UsersDocAccessFields_UpdatedAt_Update>;
  UsersFields: ResolverTypeWrapper<UsersFields>;
  UsersFields_createdAt: ResolverTypeWrapper<UsersFields_CreatedAt>;
  UsersFields_createdAt_Create: ResolverTypeWrapper<UsersFields_CreatedAt_Create>;
  UsersFields_createdAt_Delete: ResolverTypeWrapper<UsersFields_CreatedAt_Delete>;
  UsersFields_createdAt_Read: ResolverTypeWrapper<UsersFields_CreatedAt_Read>;
  UsersFields_createdAt_Update: ResolverTypeWrapper<UsersFields_CreatedAt_Update>;
  UsersFields_email: ResolverTypeWrapper<UsersFields_Email>;
  UsersFields_email_Create: ResolverTypeWrapper<UsersFields_Email_Create>;
  UsersFields_email_Delete: ResolverTypeWrapper<UsersFields_Email_Delete>;
  UsersFields_email_Read: ResolverTypeWrapper<UsersFields_Email_Read>;
  UsersFields_email_Update: ResolverTypeWrapper<UsersFields_Email_Update>;
  UsersFields_name: ResolverTypeWrapper<UsersFields_Name>;
  UsersFields_name_Create: ResolverTypeWrapper<UsersFields_Name_Create>;
  UsersFields_name_Delete: ResolverTypeWrapper<UsersFields_Name_Delete>;
  UsersFields_name_Read: ResolverTypeWrapper<UsersFields_Name_Read>;
  UsersFields_name_Update: ResolverTypeWrapper<UsersFields_Name_Update>;
  UsersFields_password: ResolverTypeWrapper<UsersFields_Password>;
  UsersFields_password_Create: ResolverTypeWrapper<UsersFields_Password_Create>;
  UsersFields_password_Delete: ResolverTypeWrapper<UsersFields_Password_Delete>;
  UsersFields_password_Read: ResolverTypeWrapper<UsersFields_Password_Read>;
  UsersFields_password_Update: ResolverTypeWrapper<UsersFields_Password_Update>;
  UsersFields_roles: ResolverTypeWrapper<UsersFields_Roles>;
  UsersFields_roles_Create: ResolverTypeWrapper<UsersFields_Roles_Create>;
  UsersFields_roles_Delete: ResolverTypeWrapper<UsersFields_Roles_Delete>;
  UsersFields_roles_Read: ResolverTypeWrapper<UsersFields_Roles_Read>;
  UsersFields_roles_Update: ResolverTypeWrapper<UsersFields_Roles_Update>;
  UsersFields_updatedAt: ResolverTypeWrapper<UsersFields_UpdatedAt>;
  UsersFields_updatedAt_Create: ResolverTypeWrapper<UsersFields_UpdatedAt_Create>;
  UsersFields_updatedAt_Delete: ResolverTypeWrapper<UsersFields_UpdatedAt_Delete>;
  UsersFields_updatedAt_Read: ResolverTypeWrapper<UsersFields_UpdatedAt_Read>;
  UsersFields_updatedAt_Update: ResolverTypeWrapper<UsersFields_UpdatedAt_Update>;
  UsersReadAccess: ResolverTypeWrapper<UsersReadAccess>;
  UsersReadDocAccess: ResolverTypeWrapper<UsersReadDocAccess>;
  UsersUnlockAccess: ResolverTypeWrapper<UsersUnlockAccess>;
  UsersUnlockDocAccess: ResolverTypeWrapper<UsersUnlockDocAccess>;
  UsersUpdateAccess: ResolverTypeWrapper<UsersUpdateAccess>;
  UsersUpdateDocAccess: ResolverTypeWrapper<UsersUpdateDocAccess>;
  allMedia: ResolverTypeWrapper<AllMedia>;
  articlesAccess: ResolverTypeWrapper<ArticlesAccess>;
  articlesDocAccess: ResolverTypeWrapper<ArticlesDocAccess>;
  categoriesAccess: ResolverTypeWrapper<CategoriesAccess>;
  categoriesDocAccess: ResolverTypeWrapper<CategoriesDocAccess>;
  mediaAccess: ResolverTypeWrapper<MediaAccess>;
  mediaDocAccess: ResolverTypeWrapper<MediaDocAccess>;
  mutationArticleInput: MutationArticleInput;
  mutationArticleUpdateInput: MutationArticleUpdateInput;
  mutationArticleUpdate_HeroInput: MutationArticleUpdate_HeroInput;
  mutationArticleUpdate_MetaInput: MutationArticleUpdate_MetaInput;
  mutationArticleUpdate_PopulatedAuthorInput: MutationArticleUpdate_PopulatedAuthorInput;
  mutationArticle_HeroInput: MutationArticle_HeroInput;
  mutationArticle_MetaInput: MutationArticle_MetaInput;
  mutationArticle_PopulatedAuthorInput: MutationArticle_PopulatedAuthorInput;
  mutationCategoryInput: MutationCategoryInput;
  mutationCategoryUpdateInput: MutationCategoryUpdateInput;
  mutationCategoryUpdate_BreadcrumbsInput: MutationCategoryUpdate_BreadcrumbsInput;
  mutationCategory_BreadcrumbsInput: MutationCategory_BreadcrumbsInput;
  mutationMediaInput: MutationMediaInput;
  mutationMediaUpdateInput: MutationMediaUpdateInput;
  mutationPayloadPreferenceInput: MutationPayloadPreferenceInput;
  mutationPayloadPreferenceUpdateInput: MutationPayloadPreferenceUpdateInput;
  mutationTaskTemplateInput: MutationTaskTemplateInput;
  mutationTaskTemplateUpdateInput: MutationTaskTemplateUpdateInput;
  mutationTaskTemplateUpdate_ResourcesInput: MutationTaskTemplateUpdate_ResourcesInput;
  mutationTaskTemplateUpdate_ReviewInput: MutationTaskTemplateUpdate_ReviewInput;
  mutationTaskTemplateUpdate_ScheduleInput: MutationTaskTemplateUpdate_ScheduleInput;
  mutationTaskTemplateUpdate_Schedule_RecurrenceInput: MutationTaskTemplateUpdate_Schedule_RecurrenceInput;
  mutationTaskTemplate_ResourcesInput: MutationTaskTemplate_ResourcesInput;
  mutationTaskTemplate_ReviewInput: MutationTaskTemplate_ReviewInput;
  mutationTaskTemplate_ScheduleInput: MutationTaskTemplate_ScheduleInput;
  mutationTaskTemplate_Schedule_RecurrenceInput: MutationTaskTemplate_Schedule_RecurrenceInput;
  mutationUserInput: MutationUserInput;
  mutationUserUpdateInput: MutationUserUpdateInput;
  payload_preferencesAccess: ResolverTypeWrapper<Payload_PreferencesAccess>;
  payload_preferencesDocAccess: ResolverTypeWrapper<Payload_PreferencesDocAccess>;
  task_templatesAccess: ResolverTypeWrapper<Task_TemplatesAccess>;
  task_templatesDocAccess: ResolverTypeWrapper<Task_TemplatesDocAccess>;
  usersAccess: ResolverTypeWrapper<UsersAccess>;
  usersDocAccess: ResolverTypeWrapper<UsersDocAccess>;
  usersJWT: ResolverTypeWrapper<UsersJwt>;
  usersLoginResult: ResolverTypeWrapper<UsersLoginResult>;
  usersMe: ResolverTypeWrapper<UsersMe>;
  usersRefreshedUser: ResolverTypeWrapper<UsersRefreshedUser>;
  usersResetPassword: ResolverTypeWrapper<UsersResetPassword>;
  versionsArticle_createdAt_operator: VersionsArticle_CreatedAt_Operator;
  versionsArticle_id_operator: VersionsArticle_Id_Operator;
  versionsArticle_latest_operator: VersionsArticle_Latest_Operator;
  versionsArticle_parent_operator: VersionsArticle_Parent_Operator;
  versionsArticle_updatedAt_operator: VersionsArticle_UpdatedAt_Operator;
  versionsArticle_version___status_Input: VersionsArticle_Version___Status_Input;
  versionsArticle_version___status_operator: VersionsArticle_Version___Status_Operator;
  versionsArticle_version__author_operator: VersionsArticle_Version__Author_Operator;
  versionsArticle_version__categories_operator: VersionsArticle_Version__Categories_Operator;
  versionsArticle_version__createdAt_operator: VersionsArticle_Version__CreatedAt_Operator;
  versionsArticle_version__hero__media_operator: VersionsArticle_Version__Hero__Media_Operator;
  versionsArticle_version__hero__richText_operator: VersionsArticle_Version__Hero__RichText_Operator;
  versionsArticle_version__meta__description_operator: VersionsArticle_Version__Meta__Description_Operator;
  versionsArticle_version__meta__image_operator: VersionsArticle_Version__Meta__Image_Operator;
  versionsArticle_version__meta__title_operator: VersionsArticle_Version__Meta__Title_Operator;
  versionsArticle_version__populatedAuthor__id_operator: VersionsArticle_Version__PopulatedAuthor__Id_Operator;
  versionsArticle_version__populatedAuthor__name_operator: VersionsArticle_Version__PopulatedAuthor__Name_Operator;
  versionsArticle_version__publishedAt_operator: VersionsArticle_Version__PublishedAt_Operator;
  versionsArticle_version__relatedArticles_operator: VersionsArticle_Version__RelatedArticles_Operator;
  versionsArticle_version__slug_operator: VersionsArticle_Version__Slug_Operator;
  versionsArticle_version__title_operator: VersionsArticle_Version__Title_Operator;
  versionsArticle_version__updatedAt_operator: VersionsArticle_Version__UpdatedAt_Operator;
  versionsArticle_where: VersionsArticle_Where;
  versionsArticle_where_and: VersionsArticle_Where_And;
  versionsArticle_where_or: VersionsArticle_Where_Or;
  versionsArticles: ResolverTypeWrapper<VersionsArticles>;
  versionsTaskTemplate_createdAt_operator: VersionsTaskTemplate_CreatedAt_Operator;
  versionsTaskTemplate_id_operator: VersionsTaskTemplate_Id_Operator;
  versionsTaskTemplate_latest_operator: VersionsTaskTemplate_Latest_Operator;
  versionsTaskTemplate_parent_operator: VersionsTaskTemplate_Parent_Operator;
  versionsTaskTemplate_recurrence__freq_Input: VersionsTaskTemplate_Recurrence__Freq_Input;
  versionsTaskTemplate_recurrence__freq_operator: VersionsTaskTemplate_Recurrence__Freq_Operator;
  versionsTaskTemplate_recurrence__interval_operator: VersionsTaskTemplate_Recurrence__Interval_Operator;
  versionsTaskTemplate_updatedAt_operator: VersionsTaskTemplate_UpdatedAt_Operator;
  versionsTaskTemplate_version___status_Input: VersionsTaskTemplate_Version___Status_Input;
  versionsTaskTemplate_version___status_operator: VersionsTaskTemplate_Version___Status_Operator;
  versionsTaskTemplate_version__assignment_Input: VersionsTaskTemplate_Version__Assignment_Input;
  versionsTaskTemplate_version__assignment_operator: VersionsTaskTemplate_Version__Assignment_Operator;
  versionsTaskTemplate_version__categories_Input: VersionsTaskTemplate_Version__Categories_Input;
  versionsTaskTemplate_version__categories_operator: VersionsTaskTemplate_Version__Categories_Operator;
  versionsTaskTemplate_version__createdAt_operator: VersionsTaskTemplate_Version__CreatedAt_Operator;
  versionsTaskTemplate_version__description_operator: VersionsTaskTemplate_Version__Description_Operator;
  versionsTaskTemplate_version__details_operator: VersionsTaskTemplate_Version__Details_Operator;
  versionsTaskTemplate_version__feature_Input: VersionsTaskTemplate_Version__Feature_Input;
  versionsTaskTemplate_version__feature_operator: VersionsTaskTemplate_Version__Feature_Operator;
  versionsTaskTemplate_version__resources__articles_operator: VersionsTaskTemplate_Version__Resources__Articles_Operator;
  versionsTaskTemplate_version__resources__videos_operator: VersionsTaskTemplate_Version__Resources__Videos_Operator;
  versionsTaskTemplate_version__review__approver_Input: VersionsTaskTemplate_Version__Review__Approver_Input;
  versionsTaskTemplate_version__review__approver_operator: VersionsTaskTemplate_Version__Review__Approver_Operator;
  versionsTaskTemplate_version__review__evidence_operator: VersionsTaskTemplate_Version__Review__Evidence_Operator;
  versionsTaskTemplate_version__review__required_operator: VersionsTaskTemplate_Version__Review__Required_Operator;
  versionsTaskTemplate_version__schedule__due_operator: VersionsTaskTemplate_Version__Schedule__Due_Operator;
  versionsTaskTemplate_version__schedule__event_Input: VersionsTaskTemplate_Version__Schedule__Event_Input;
  versionsTaskTemplate_version__schedule__event_operator: VersionsTaskTemplate_Version__Schedule__Event_Operator;
  versionsTaskTemplate_version__schedule__id_operator: VersionsTaskTemplate_Version__Schedule__Id_Operator;
  versionsTaskTemplate_version__thumbnail_operator: VersionsTaskTemplate_Version__Thumbnail_Operator;
  versionsTaskTemplate_version__title_operator: VersionsTaskTemplate_Version__Title_Operator;
  versionsTaskTemplate_version__updatedAt_operator: VersionsTaskTemplate_Version__UpdatedAt_Operator;
  versionsTaskTemplate_where: VersionsTaskTemplate_Where;
  versionsTaskTemplate_where_and: VersionsTaskTemplate_Where_And;
  versionsTaskTemplate_where_or: VersionsTaskTemplate_Where_Or;
  versionsTaskTemplates: ResolverTypeWrapper<VersionsTaskTemplates>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  Access: Access;
  Article: Omit<Article, 'content'> & { content: Array<ResolversParentTypes['Article_Content']> };
  ArticleVersion: ArticleVersion;
  ArticleVersion_Version: Omit<ArticleVersion_Version, 'content'> & { content: Array<ResolversParentTypes['ArticleVersion_Version_Content']> };
  ArticleVersion_Version_Content: ResolversUnionTypes<ResolversParentTypes>['ArticleVersion_Version_Content'];
  ArticleVersion_Version_Hero: ArticleVersion_Version_Hero;
  ArticleVersion_Version_Hero_Media_alt_operator: ArticleVersion_Version_Hero_Media_Alt_Operator;
  ArticleVersion_Version_Hero_Media_caption_operator: ArticleVersion_Version_Hero_Media_Caption_Operator;
  ArticleVersion_Version_Hero_Media_createdAt_operator: ArticleVersion_Version_Hero_Media_CreatedAt_Operator;
  ArticleVersion_Version_Hero_Media_filename_operator: ArticleVersion_Version_Hero_Media_Filename_Operator;
  ArticleVersion_Version_Hero_Media_filesize_operator: ArticleVersion_Version_Hero_Media_Filesize_Operator;
  ArticleVersion_Version_Hero_Media_height_operator: ArticleVersion_Version_Hero_Media_Height_Operator;
  ArticleVersion_Version_Hero_Media_id_operator: ArticleVersion_Version_Hero_Media_Id_Operator;
  ArticleVersion_Version_Hero_Media_mimeType_operator: ArticleVersion_Version_Hero_Media_MimeType_Operator;
  ArticleVersion_Version_Hero_Media_updatedAt_operator: ArticleVersion_Version_Hero_Media_UpdatedAt_Operator;
  ArticleVersion_Version_Hero_Media_url_operator: ArticleVersion_Version_Hero_Media_Url_Operator;
  ArticleVersion_Version_Hero_Media_where: ArticleVersion_Version_Hero_Media_Where;
  ArticleVersion_Version_Hero_Media_where_and: ArticleVersion_Version_Hero_Media_Where_And;
  ArticleVersion_Version_Hero_Media_where_or: ArticleVersion_Version_Hero_Media_Where_Or;
  ArticleVersion_Version_Hero_Media_width_operator: ArticleVersion_Version_Hero_Media_Width_Operator;
  ArticleVersion_Version_Meta: ArticleVersion_Version_Meta;
  ArticleVersion_Version_Meta_Image_alt_operator: ArticleVersion_Version_Meta_Image_Alt_Operator;
  ArticleVersion_Version_Meta_Image_caption_operator: ArticleVersion_Version_Meta_Image_Caption_Operator;
  ArticleVersion_Version_Meta_Image_createdAt_operator: ArticleVersion_Version_Meta_Image_CreatedAt_Operator;
  ArticleVersion_Version_Meta_Image_filename_operator: ArticleVersion_Version_Meta_Image_Filename_Operator;
  ArticleVersion_Version_Meta_Image_filesize_operator: ArticleVersion_Version_Meta_Image_Filesize_Operator;
  ArticleVersion_Version_Meta_Image_height_operator: ArticleVersion_Version_Meta_Image_Height_Operator;
  ArticleVersion_Version_Meta_Image_id_operator: ArticleVersion_Version_Meta_Image_Id_Operator;
  ArticleVersion_Version_Meta_Image_mimeType_operator: ArticleVersion_Version_Meta_Image_MimeType_Operator;
  ArticleVersion_Version_Meta_Image_updatedAt_operator: ArticleVersion_Version_Meta_Image_UpdatedAt_Operator;
  ArticleVersion_Version_Meta_Image_url_operator: ArticleVersion_Version_Meta_Image_Url_Operator;
  ArticleVersion_Version_Meta_Image_where: ArticleVersion_Version_Meta_Image_Where;
  ArticleVersion_Version_Meta_Image_where_and: ArticleVersion_Version_Meta_Image_Where_And;
  ArticleVersion_Version_Meta_Image_where_or: ArticleVersion_Version_Meta_Image_Where_Or;
  ArticleVersion_Version_Meta_Image_width_operator: ArticleVersion_Version_Meta_Image_Width_Operator;
  ArticleVersion_Version_PopulatedAuthor: ArticleVersion_Version_PopulatedAuthor;
  Article_Content: ResolversUnionTypes<ResolversParentTypes>['Article_Content'];
  Article_Hero: Article_Hero;
  Article_Hero_Media_alt_operator: Article_Hero_Media_Alt_Operator;
  Article_Hero_Media_caption_operator: Article_Hero_Media_Caption_Operator;
  Article_Hero_Media_createdAt_operator: Article_Hero_Media_CreatedAt_Operator;
  Article_Hero_Media_filename_operator: Article_Hero_Media_Filename_Operator;
  Article_Hero_Media_filesize_operator: Article_Hero_Media_Filesize_Operator;
  Article_Hero_Media_height_operator: Article_Hero_Media_Height_Operator;
  Article_Hero_Media_id_operator: Article_Hero_Media_Id_Operator;
  Article_Hero_Media_mimeType_operator: Article_Hero_Media_MimeType_Operator;
  Article_Hero_Media_updatedAt_operator: Article_Hero_Media_UpdatedAt_Operator;
  Article_Hero_Media_url_operator: Article_Hero_Media_Url_Operator;
  Article_Hero_Media_where: Article_Hero_Media_Where;
  Article_Hero_Media_where_and: Article_Hero_Media_Where_And;
  Article_Hero_Media_where_or: Article_Hero_Media_Where_Or;
  Article_Hero_Media_width_operator: Article_Hero_Media_Width_Operator;
  Article_Meta: Article_Meta;
  Article_Meta_Image_alt_operator: Article_Meta_Image_Alt_Operator;
  Article_Meta_Image_caption_operator: Article_Meta_Image_Caption_Operator;
  Article_Meta_Image_createdAt_operator: Article_Meta_Image_CreatedAt_Operator;
  Article_Meta_Image_filename_operator: Article_Meta_Image_Filename_Operator;
  Article_Meta_Image_filesize_operator: Article_Meta_Image_Filesize_Operator;
  Article_Meta_Image_height_operator: Article_Meta_Image_Height_Operator;
  Article_Meta_Image_id_operator: Article_Meta_Image_Id_Operator;
  Article_Meta_Image_mimeType_operator: Article_Meta_Image_MimeType_Operator;
  Article_Meta_Image_updatedAt_operator: Article_Meta_Image_UpdatedAt_Operator;
  Article_Meta_Image_url_operator: Article_Meta_Image_Url_Operator;
  Article_Meta_Image_where: Article_Meta_Image_Where;
  Article_Meta_Image_where_and: Article_Meta_Image_Where_And;
  Article_Meta_Image_where_or: Article_Meta_Image_Where_Or;
  Article_Meta_Image_width_operator: Article_Meta_Image_Width_Operator;
  Article_PopulatedAuthor: Article_PopulatedAuthor;
  Article__status_operator: Article__Status_Operator;
  Article_author_operator: Article_Author_Operator;
  Article_categories_operator: Article_Categories_Operator;
  Article_createdAt_operator: Article_CreatedAt_Operator;
  Article_hero__media_operator: Article_Hero__Media_Operator;
  Article_hero__richText_operator: Article_Hero__RichText_Operator;
  Article_id_operator: Article_Id_Operator;
  Article_meta__description_operator: Article_Meta__Description_Operator;
  Article_meta__image_operator: Article_Meta__Image_Operator;
  Article_meta__title_operator: Article_Meta__Title_Operator;
  Article_populatedAuthor__id_operator: Article_PopulatedAuthor__Id_Operator;
  Article_populatedAuthor__name_operator: Article_PopulatedAuthor__Name_Operator;
  Article_publishedAt_operator: Article_PublishedAt_Operator;
  Article_relatedArticles_operator: Article_RelatedArticles_Operator;
  Article_slug_operator: Article_Slug_Operator;
  Article_title_operator: Article_Title_Operator;
  Article_updatedAt_operator: Article_UpdatedAt_Operator;
  Article_where: Article_Where;
  Article_where_and: Article_Where_And;
  Article_where_or: Article_Where_Or;
  Articles: Articles;
  ArticlesCreateAccess: ArticlesCreateAccess;
  ArticlesCreateDocAccess: ArticlesCreateDocAccess;
  ArticlesDeleteAccess: ArticlesDeleteAccess;
  ArticlesDeleteDocAccess: ArticlesDeleteDocAccess;
  ArticlesDocAccessFields: ArticlesDocAccessFields;
  ArticlesDocAccessFields__status: ArticlesDocAccessFields__Status;
  ArticlesDocAccessFields__status_Create: ArticlesDocAccessFields__Status_Create;
  ArticlesDocAccessFields__status_Delete: ArticlesDocAccessFields__Status_Delete;
  ArticlesDocAccessFields__status_Read: ArticlesDocAccessFields__Status_Read;
  ArticlesDocAccessFields__status_Update: ArticlesDocAccessFields__Status_Update;
  ArticlesDocAccessFields_author: ArticlesDocAccessFields_Author;
  ArticlesDocAccessFields_author_Create: ArticlesDocAccessFields_Author_Create;
  ArticlesDocAccessFields_author_Delete: ArticlesDocAccessFields_Author_Delete;
  ArticlesDocAccessFields_author_Read: ArticlesDocAccessFields_Author_Read;
  ArticlesDocAccessFields_author_Update: ArticlesDocAccessFields_Author_Update;
  ArticlesDocAccessFields_categories: ArticlesDocAccessFields_Categories;
  ArticlesDocAccessFields_categories_Create: ArticlesDocAccessFields_Categories_Create;
  ArticlesDocAccessFields_categories_Delete: ArticlesDocAccessFields_Categories_Delete;
  ArticlesDocAccessFields_categories_Read: ArticlesDocAccessFields_Categories_Read;
  ArticlesDocAccessFields_categories_Update: ArticlesDocAccessFields_Categories_Update;
  ArticlesDocAccessFields_content: ArticlesDocAccessFields_Content;
  ArticlesDocAccessFields_content_Create: ArticlesDocAccessFields_Content_Create;
  ArticlesDocAccessFields_content_Delete: ArticlesDocAccessFields_Content_Delete;
  ArticlesDocAccessFields_content_Read: ArticlesDocAccessFields_Content_Read;
  ArticlesDocAccessFields_content_Update: ArticlesDocAccessFields_Content_Update;
  ArticlesDocAccessFields_createdAt: ArticlesDocAccessFields_CreatedAt;
  ArticlesDocAccessFields_createdAt_Create: ArticlesDocAccessFields_CreatedAt_Create;
  ArticlesDocAccessFields_createdAt_Delete: ArticlesDocAccessFields_CreatedAt_Delete;
  ArticlesDocAccessFields_createdAt_Read: ArticlesDocAccessFields_CreatedAt_Read;
  ArticlesDocAccessFields_createdAt_Update: ArticlesDocAccessFields_CreatedAt_Update;
  ArticlesDocAccessFields_hero: ArticlesDocAccessFields_Hero;
  ArticlesDocAccessFields_hero_Create: ArticlesDocAccessFields_Hero_Create;
  ArticlesDocAccessFields_hero_Delete: ArticlesDocAccessFields_Hero_Delete;
  ArticlesDocAccessFields_hero_Fields: ArticlesDocAccessFields_Hero_Fields;
  ArticlesDocAccessFields_hero_Read: ArticlesDocAccessFields_Hero_Read;
  ArticlesDocAccessFields_hero_Update: ArticlesDocAccessFields_Hero_Update;
  ArticlesDocAccessFields_hero_media: ArticlesDocAccessFields_Hero_Media;
  ArticlesDocAccessFields_hero_media_Create: ArticlesDocAccessFields_Hero_Media_Create;
  ArticlesDocAccessFields_hero_media_Delete: ArticlesDocAccessFields_Hero_Media_Delete;
  ArticlesDocAccessFields_hero_media_Read: ArticlesDocAccessFields_Hero_Media_Read;
  ArticlesDocAccessFields_hero_media_Update: ArticlesDocAccessFields_Hero_Media_Update;
  ArticlesDocAccessFields_hero_richText: ArticlesDocAccessFields_Hero_RichText;
  ArticlesDocAccessFields_hero_richText_Create: ArticlesDocAccessFields_Hero_RichText_Create;
  ArticlesDocAccessFields_hero_richText_Delete: ArticlesDocAccessFields_Hero_RichText_Delete;
  ArticlesDocAccessFields_hero_richText_Read: ArticlesDocAccessFields_Hero_RichText_Read;
  ArticlesDocAccessFields_hero_richText_Update: ArticlesDocAccessFields_Hero_RichText_Update;
  ArticlesDocAccessFields_meta: ArticlesDocAccessFields_Meta;
  ArticlesDocAccessFields_meta_Create: ArticlesDocAccessFields_Meta_Create;
  ArticlesDocAccessFields_meta_Delete: ArticlesDocAccessFields_Meta_Delete;
  ArticlesDocAccessFields_meta_Fields: ArticlesDocAccessFields_Meta_Fields;
  ArticlesDocAccessFields_meta_Read: ArticlesDocAccessFields_Meta_Read;
  ArticlesDocAccessFields_meta_Update: ArticlesDocAccessFields_Meta_Update;
  ArticlesDocAccessFields_meta_description: ArticlesDocAccessFields_Meta_Description;
  ArticlesDocAccessFields_meta_description_Create: ArticlesDocAccessFields_Meta_Description_Create;
  ArticlesDocAccessFields_meta_description_Delete: ArticlesDocAccessFields_Meta_Description_Delete;
  ArticlesDocAccessFields_meta_description_Read: ArticlesDocAccessFields_Meta_Description_Read;
  ArticlesDocAccessFields_meta_description_Update: ArticlesDocAccessFields_Meta_Description_Update;
  ArticlesDocAccessFields_meta_image: ArticlesDocAccessFields_Meta_Image;
  ArticlesDocAccessFields_meta_image_Create: ArticlesDocAccessFields_Meta_Image_Create;
  ArticlesDocAccessFields_meta_image_Delete: ArticlesDocAccessFields_Meta_Image_Delete;
  ArticlesDocAccessFields_meta_image_Read: ArticlesDocAccessFields_Meta_Image_Read;
  ArticlesDocAccessFields_meta_image_Update: ArticlesDocAccessFields_Meta_Image_Update;
  ArticlesDocAccessFields_meta_title: ArticlesDocAccessFields_Meta_Title;
  ArticlesDocAccessFields_meta_title_Create: ArticlesDocAccessFields_Meta_Title_Create;
  ArticlesDocAccessFields_meta_title_Delete: ArticlesDocAccessFields_Meta_Title_Delete;
  ArticlesDocAccessFields_meta_title_Read: ArticlesDocAccessFields_Meta_Title_Read;
  ArticlesDocAccessFields_meta_title_Update: ArticlesDocAccessFields_Meta_Title_Update;
  ArticlesDocAccessFields_populatedAuthor: ArticlesDocAccessFields_PopulatedAuthor;
  ArticlesDocAccessFields_populatedAuthor_Create: ArticlesDocAccessFields_PopulatedAuthor_Create;
  ArticlesDocAccessFields_populatedAuthor_Delete: ArticlesDocAccessFields_PopulatedAuthor_Delete;
  ArticlesDocAccessFields_populatedAuthor_Fields: ArticlesDocAccessFields_PopulatedAuthor_Fields;
  ArticlesDocAccessFields_populatedAuthor_Read: ArticlesDocAccessFields_PopulatedAuthor_Read;
  ArticlesDocAccessFields_populatedAuthor_Update: ArticlesDocAccessFields_PopulatedAuthor_Update;
  ArticlesDocAccessFields_populatedAuthor_id: ArticlesDocAccessFields_PopulatedAuthor_Id;
  ArticlesDocAccessFields_populatedAuthor_id_Create: ArticlesDocAccessFields_PopulatedAuthor_Id_Create;
  ArticlesDocAccessFields_populatedAuthor_id_Delete: ArticlesDocAccessFields_PopulatedAuthor_Id_Delete;
  ArticlesDocAccessFields_populatedAuthor_id_Read: ArticlesDocAccessFields_PopulatedAuthor_Id_Read;
  ArticlesDocAccessFields_populatedAuthor_id_Update: ArticlesDocAccessFields_PopulatedAuthor_Id_Update;
  ArticlesDocAccessFields_populatedAuthor_name: ArticlesDocAccessFields_PopulatedAuthor_Name;
  ArticlesDocAccessFields_populatedAuthor_name_Create: ArticlesDocAccessFields_PopulatedAuthor_Name_Create;
  ArticlesDocAccessFields_populatedAuthor_name_Delete: ArticlesDocAccessFields_PopulatedAuthor_Name_Delete;
  ArticlesDocAccessFields_populatedAuthor_name_Read: ArticlesDocAccessFields_PopulatedAuthor_Name_Read;
  ArticlesDocAccessFields_populatedAuthor_name_Update: ArticlesDocAccessFields_PopulatedAuthor_Name_Update;
  ArticlesDocAccessFields_publishedAt: ArticlesDocAccessFields_PublishedAt;
  ArticlesDocAccessFields_publishedAt_Create: ArticlesDocAccessFields_PublishedAt_Create;
  ArticlesDocAccessFields_publishedAt_Delete: ArticlesDocAccessFields_PublishedAt_Delete;
  ArticlesDocAccessFields_publishedAt_Read: ArticlesDocAccessFields_PublishedAt_Read;
  ArticlesDocAccessFields_publishedAt_Update: ArticlesDocAccessFields_PublishedAt_Update;
  ArticlesDocAccessFields_relatedArticles: ArticlesDocAccessFields_RelatedArticles;
  ArticlesDocAccessFields_relatedArticles_Create: ArticlesDocAccessFields_RelatedArticles_Create;
  ArticlesDocAccessFields_relatedArticles_Delete: ArticlesDocAccessFields_RelatedArticles_Delete;
  ArticlesDocAccessFields_relatedArticles_Read: ArticlesDocAccessFields_RelatedArticles_Read;
  ArticlesDocAccessFields_relatedArticles_Update: ArticlesDocAccessFields_RelatedArticles_Update;
  ArticlesDocAccessFields_slug: ArticlesDocAccessFields_Slug;
  ArticlesDocAccessFields_slug_Create: ArticlesDocAccessFields_Slug_Create;
  ArticlesDocAccessFields_slug_Delete: ArticlesDocAccessFields_Slug_Delete;
  ArticlesDocAccessFields_slug_Read: ArticlesDocAccessFields_Slug_Read;
  ArticlesDocAccessFields_slug_Update: ArticlesDocAccessFields_Slug_Update;
  ArticlesDocAccessFields_title: ArticlesDocAccessFields_Title;
  ArticlesDocAccessFields_title_Create: ArticlesDocAccessFields_Title_Create;
  ArticlesDocAccessFields_title_Delete: ArticlesDocAccessFields_Title_Delete;
  ArticlesDocAccessFields_title_Read: ArticlesDocAccessFields_Title_Read;
  ArticlesDocAccessFields_title_Update: ArticlesDocAccessFields_Title_Update;
  ArticlesDocAccessFields_updatedAt: ArticlesDocAccessFields_UpdatedAt;
  ArticlesDocAccessFields_updatedAt_Create: ArticlesDocAccessFields_UpdatedAt_Create;
  ArticlesDocAccessFields_updatedAt_Delete: ArticlesDocAccessFields_UpdatedAt_Delete;
  ArticlesDocAccessFields_updatedAt_Read: ArticlesDocAccessFields_UpdatedAt_Read;
  ArticlesDocAccessFields_updatedAt_Update: ArticlesDocAccessFields_UpdatedAt_Update;
  ArticlesFields: ArticlesFields;
  ArticlesFields__status: ArticlesFields__Status;
  ArticlesFields__status_Create: ArticlesFields__Status_Create;
  ArticlesFields__status_Delete: ArticlesFields__Status_Delete;
  ArticlesFields__status_Read: ArticlesFields__Status_Read;
  ArticlesFields__status_Update: ArticlesFields__Status_Update;
  ArticlesFields_author: ArticlesFields_Author;
  ArticlesFields_author_Create: ArticlesFields_Author_Create;
  ArticlesFields_author_Delete: ArticlesFields_Author_Delete;
  ArticlesFields_author_Read: ArticlesFields_Author_Read;
  ArticlesFields_author_Update: ArticlesFields_Author_Update;
  ArticlesFields_categories: ArticlesFields_Categories;
  ArticlesFields_categories_Create: ArticlesFields_Categories_Create;
  ArticlesFields_categories_Delete: ArticlesFields_Categories_Delete;
  ArticlesFields_categories_Read: ArticlesFields_Categories_Read;
  ArticlesFields_categories_Update: ArticlesFields_Categories_Update;
  ArticlesFields_content: ArticlesFields_Content;
  ArticlesFields_content_Create: ArticlesFields_Content_Create;
  ArticlesFields_content_Delete: ArticlesFields_Content_Delete;
  ArticlesFields_content_Read: ArticlesFields_Content_Read;
  ArticlesFields_content_Update: ArticlesFields_Content_Update;
  ArticlesFields_createdAt: ArticlesFields_CreatedAt;
  ArticlesFields_createdAt_Create: ArticlesFields_CreatedAt_Create;
  ArticlesFields_createdAt_Delete: ArticlesFields_CreatedAt_Delete;
  ArticlesFields_createdAt_Read: ArticlesFields_CreatedAt_Read;
  ArticlesFields_createdAt_Update: ArticlesFields_CreatedAt_Update;
  ArticlesFields_hero: ArticlesFields_Hero;
  ArticlesFields_hero_Create: ArticlesFields_Hero_Create;
  ArticlesFields_hero_Delete: ArticlesFields_Hero_Delete;
  ArticlesFields_hero_Fields: ArticlesFields_Hero_Fields;
  ArticlesFields_hero_Read: ArticlesFields_Hero_Read;
  ArticlesFields_hero_Update: ArticlesFields_Hero_Update;
  ArticlesFields_hero_media: ArticlesFields_Hero_Media;
  ArticlesFields_hero_media_Create: ArticlesFields_Hero_Media_Create;
  ArticlesFields_hero_media_Delete: ArticlesFields_Hero_Media_Delete;
  ArticlesFields_hero_media_Read: ArticlesFields_Hero_Media_Read;
  ArticlesFields_hero_media_Update: ArticlesFields_Hero_Media_Update;
  ArticlesFields_hero_richText: ArticlesFields_Hero_RichText;
  ArticlesFields_hero_richText_Create: ArticlesFields_Hero_RichText_Create;
  ArticlesFields_hero_richText_Delete: ArticlesFields_Hero_RichText_Delete;
  ArticlesFields_hero_richText_Read: ArticlesFields_Hero_RichText_Read;
  ArticlesFields_hero_richText_Update: ArticlesFields_Hero_RichText_Update;
  ArticlesFields_meta: ArticlesFields_Meta;
  ArticlesFields_meta_Create: ArticlesFields_Meta_Create;
  ArticlesFields_meta_Delete: ArticlesFields_Meta_Delete;
  ArticlesFields_meta_Fields: ArticlesFields_Meta_Fields;
  ArticlesFields_meta_Read: ArticlesFields_Meta_Read;
  ArticlesFields_meta_Update: ArticlesFields_Meta_Update;
  ArticlesFields_meta_description: ArticlesFields_Meta_Description;
  ArticlesFields_meta_description_Create: ArticlesFields_Meta_Description_Create;
  ArticlesFields_meta_description_Delete: ArticlesFields_Meta_Description_Delete;
  ArticlesFields_meta_description_Read: ArticlesFields_Meta_Description_Read;
  ArticlesFields_meta_description_Update: ArticlesFields_Meta_Description_Update;
  ArticlesFields_meta_image: ArticlesFields_Meta_Image;
  ArticlesFields_meta_image_Create: ArticlesFields_Meta_Image_Create;
  ArticlesFields_meta_image_Delete: ArticlesFields_Meta_Image_Delete;
  ArticlesFields_meta_image_Read: ArticlesFields_Meta_Image_Read;
  ArticlesFields_meta_image_Update: ArticlesFields_Meta_Image_Update;
  ArticlesFields_meta_title: ArticlesFields_Meta_Title;
  ArticlesFields_meta_title_Create: ArticlesFields_Meta_Title_Create;
  ArticlesFields_meta_title_Delete: ArticlesFields_Meta_Title_Delete;
  ArticlesFields_meta_title_Read: ArticlesFields_Meta_Title_Read;
  ArticlesFields_meta_title_Update: ArticlesFields_Meta_Title_Update;
  ArticlesFields_populatedAuthor: ArticlesFields_PopulatedAuthor;
  ArticlesFields_populatedAuthor_Create: ArticlesFields_PopulatedAuthor_Create;
  ArticlesFields_populatedAuthor_Delete: ArticlesFields_PopulatedAuthor_Delete;
  ArticlesFields_populatedAuthor_Fields: ArticlesFields_PopulatedAuthor_Fields;
  ArticlesFields_populatedAuthor_Read: ArticlesFields_PopulatedAuthor_Read;
  ArticlesFields_populatedAuthor_Update: ArticlesFields_PopulatedAuthor_Update;
  ArticlesFields_populatedAuthor_id: ArticlesFields_PopulatedAuthor_Id;
  ArticlesFields_populatedAuthor_id_Create: ArticlesFields_PopulatedAuthor_Id_Create;
  ArticlesFields_populatedAuthor_id_Delete: ArticlesFields_PopulatedAuthor_Id_Delete;
  ArticlesFields_populatedAuthor_id_Read: ArticlesFields_PopulatedAuthor_Id_Read;
  ArticlesFields_populatedAuthor_id_Update: ArticlesFields_PopulatedAuthor_Id_Update;
  ArticlesFields_populatedAuthor_name: ArticlesFields_PopulatedAuthor_Name;
  ArticlesFields_populatedAuthor_name_Create: ArticlesFields_PopulatedAuthor_Name_Create;
  ArticlesFields_populatedAuthor_name_Delete: ArticlesFields_PopulatedAuthor_Name_Delete;
  ArticlesFields_populatedAuthor_name_Read: ArticlesFields_PopulatedAuthor_Name_Read;
  ArticlesFields_populatedAuthor_name_Update: ArticlesFields_PopulatedAuthor_Name_Update;
  ArticlesFields_publishedAt: ArticlesFields_PublishedAt;
  ArticlesFields_publishedAt_Create: ArticlesFields_PublishedAt_Create;
  ArticlesFields_publishedAt_Delete: ArticlesFields_PublishedAt_Delete;
  ArticlesFields_publishedAt_Read: ArticlesFields_PublishedAt_Read;
  ArticlesFields_publishedAt_Update: ArticlesFields_PublishedAt_Update;
  ArticlesFields_relatedArticles: ArticlesFields_RelatedArticles;
  ArticlesFields_relatedArticles_Create: ArticlesFields_RelatedArticles_Create;
  ArticlesFields_relatedArticles_Delete: ArticlesFields_RelatedArticles_Delete;
  ArticlesFields_relatedArticles_Read: ArticlesFields_RelatedArticles_Read;
  ArticlesFields_relatedArticles_Update: ArticlesFields_RelatedArticles_Update;
  ArticlesFields_slug: ArticlesFields_Slug;
  ArticlesFields_slug_Create: ArticlesFields_Slug_Create;
  ArticlesFields_slug_Delete: ArticlesFields_Slug_Delete;
  ArticlesFields_slug_Read: ArticlesFields_Slug_Read;
  ArticlesFields_slug_Update: ArticlesFields_Slug_Update;
  ArticlesFields_title: ArticlesFields_Title;
  ArticlesFields_title_Create: ArticlesFields_Title_Create;
  ArticlesFields_title_Delete: ArticlesFields_Title_Delete;
  ArticlesFields_title_Read: ArticlesFields_Title_Read;
  ArticlesFields_title_Update: ArticlesFields_Title_Update;
  ArticlesFields_updatedAt: ArticlesFields_UpdatedAt;
  ArticlesFields_updatedAt_Create: ArticlesFields_UpdatedAt_Create;
  ArticlesFields_updatedAt_Delete: ArticlesFields_UpdatedAt_Delete;
  ArticlesFields_updatedAt_Read: ArticlesFields_UpdatedAt_Read;
  ArticlesFields_updatedAt_Update: ArticlesFields_UpdatedAt_Update;
  ArticlesReadAccess: ArticlesReadAccess;
  ArticlesReadDocAccess: ArticlesReadDocAccess;
  ArticlesReadVersionsAccess: ArticlesReadVersionsAccess;
  ArticlesReadVersionsDocAccess: ArticlesReadVersionsDocAccess;
  ArticlesUpdateAccess: ArticlesUpdateAccess;
  ArticlesUpdateDocAccess: ArticlesUpdateDocAccess;
  Boolean: Scalars['Boolean']['output'];
  Categories: Categories;
  CategoriesCreateAccess: CategoriesCreateAccess;
  CategoriesCreateDocAccess: CategoriesCreateDocAccess;
  CategoriesDeleteAccess: CategoriesDeleteAccess;
  CategoriesDeleteDocAccess: CategoriesDeleteDocAccess;
  CategoriesDocAccessFields: CategoriesDocAccessFields;
  CategoriesDocAccessFields_breadcrumbs: CategoriesDocAccessFields_Breadcrumbs;
  CategoriesDocAccessFields_breadcrumbs_Create: CategoriesDocAccessFields_Breadcrumbs_Create;
  CategoriesDocAccessFields_breadcrumbs_Delete: CategoriesDocAccessFields_Breadcrumbs_Delete;
  CategoriesDocAccessFields_breadcrumbs_Fields: CategoriesDocAccessFields_Breadcrumbs_Fields;
  CategoriesDocAccessFields_breadcrumbs_Read: CategoriesDocAccessFields_Breadcrumbs_Read;
  CategoriesDocAccessFields_breadcrumbs_Update: CategoriesDocAccessFields_Breadcrumbs_Update;
  CategoriesDocAccessFields_breadcrumbs_doc: CategoriesDocAccessFields_Breadcrumbs_Doc;
  CategoriesDocAccessFields_breadcrumbs_doc_Create: CategoriesDocAccessFields_Breadcrumbs_Doc_Create;
  CategoriesDocAccessFields_breadcrumbs_doc_Delete: CategoriesDocAccessFields_Breadcrumbs_Doc_Delete;
  CategoriesDocAccessFields_breadcrumbs_doc_Read: CategoriesDocAccessFields_Breadcrumbs_Doc_Read;
  CategoriesDocAccessFields_breadcrumbs_doc_Update: CategoriesDocAccessFields_Breadcrumbs_Doc_Update;
  CategoriesDocAccessFields_breadcrumbs_id: CategoriesDocAccessFields_Breadcrumbs_Id;
  CategoriesDocAccessFields_breadcrumbs_id_Create: CategoriesDocAccessFields_Breadcrumbs_Id_Create;
  CategoriesDocAccessFields_breadcrumbs_id_Delete: CategoriesDocAccessFields_Breadcrumbs_Id_Delete;
  CategoriesDocAccessFields_breadcrumbs_id_Read: CategoriesDocAccessFields_Breadcrumbs_Id_Read;
  CategoriesDocAccessFields_breadcrumbs_id_Update: CategoriesDocAccessFields_Breadcrumbs_Id_Update;
  CategoriesDocAccessFields_breadcrumbs_label: CategoriesDocAccessFields_Breadcrumbs_Label;
  CategoriesDocAccessFields_breadcrumbs_label_Create: CategoriesDocAccessFields_Breadcrumbs_Label_Create;
  CategoriesDocAccessFields_breadcrumbs_label_Delete: CategoriesDocAccessFields_Breadcrumbs_Label_Delete;
  CategoriesDocAccessFields_breadcrumbs_label_Read: CategoriesDocAccessFields_Breadcrumbs_Label_Read;
  CategoriesDocAccessFields_breadcrumbs_label_Update: CategoriesDocAccessFields_Breadcrumbs_Label_Update;
  CategoriesDocAccessFields_breadcrumbs_url: CategoriesDocAccessFields_Breadcrumbs_Url;
  CategoriesDocAccessFields_breadcrumbs_url_Create: CategoriesDocAccessFields_Breadcrumbs_Url_Create;
  CategoriesDocAccessFields_breadcrumbs_url_Delete: CategoriesDocAccessFields_Breadcrumbs_Url_Delete;
  CategoriesDocAccessFields_breadcrumbs_url_Read: CategoriesDocAccessFields_Breadcrumbs_Url_Read;
  CategoriesDocAccessFields_breadcrumbs_url_Update: CategoriesDocAccessFields_Breadcrumbs_Url_Update;
  CategoriesDocAccessFields_createdAt: CategoriesDocAccessFields_CreatedAt;
  CategoriesDocAccessFields_createdAt_Create: CategoriesDocAccessFields_CreatedAt_Create;
  CategoriesDocAccessFields_createdAt_Delete: CategoriesDocAccessFields_CreatedAt_Delete;
  CategoriesDocAccessFields_createdAt_Read: CategoriesDocAccessFields_CreatedAt_Read;
  CategoriesDocAccessFields_createdAt_Update: CategoriesDocAccessFields_CreatedAt_Update;
  CategoriesDocAccessFields_parent: CategoriesDocAccessFields_Parent;
  CategoriesDocAccessFields_parent_Create: CategoriesDocAccessFields_Parent_Create;
  CategoriesDocAccessFields_parent_Delete: CategoriesDocAccessFields_Parent_Delete;
  CategoriesDocAccessFields_parent_Read: CategoriesDocAccessFields_Parent_Read;
  CategoriesDocAccessFields_parent_Update: CategoriesDocAccessFields_Parent_Update;
  CategoriesDocAccessFields_title: CategoriesDocAccessFields_Title;
  CategoriesDocAccessFields_title_Create: CategoriesDocAccessFields_Title_Create;
  CategoriesDocAccessFields_title_Delete: CategoriesDocAccessFields_Title_Delete;
  CategoriesDocAccessFields_title_Read: CategoriesDocAccessFields_Title_Read;
  CategoriesDocAccessFields_title_Update: CategoriesDocAccessFields_Title_Update;
  CategoriesDocAccessFields_updatedAt: CategoriesDocAccessFields_UpdatedAt;
  CategoriesDocAccessFields_updatedAt_Create: CategoriesDocAccessFields_UpdatedAt_Create;
  CategoriesDocAccessFields_updatedAt_Delete: CategoriesDocAccessFields_UpdatedAt_Delete;
  CategoriesDocAccessFields_updatedAt_Read: CategoriesDocAccessFields_UpdatedAt_Read;
  CategoriesDocAccessFields_updatedAt_Update: CategoriesDocAccessFields_UpdatedAt_Update;
  CategoriesFields: CategoriesFields;
  CategoriesFields_breadcrumbs: CategoriesFields_Breadcrumbs;
  CategoriesFields_breadcrumbs_Create: CategoriesFields_Breadcrumbs_Create;
  CategoriesFields_breadcrumbs_Delete: CategoriesFields_Breadcrumbs_Delete;
  CategoriesFields_breadcrumbs_Fields: CategoriesFields_Breadcrumbs_Fields;
  CategoriesFields_breadcrumbs_Read: CategoriesFields_Breadcrumbs_Read;
  CategoriesFields_breadcrumbs_Update: CategoriesFields_Breadcrumbs_Update;
  CategoriesFields_breadcrumbs_doc: CategoriesFields_Breadcrumbs_Doc;
  CategoriesFields_breadcrumbs_doc_Create: CategoriesFields_Breadcrumbs_Doc_Create;
  CategoriesFields_breadcrumbs_doc_Delete: CategoriesFields_Breadcrumbs_Doc_Delete;
  CategoriesFields_breadcrumbs_doc_Read: CategoriesFields_Breadcrumbs_Doc_Read;
  CategoriesFields_breadcrumbs_doc_Update: CategoriesFields_Breadcrumbs_Doc_Update;
  CategoriesFields_breadcrumbs_id: CategoriesFields_Breadcrumbs_Id;
  CategoriesFields_breadcrumbs_id_Create: CategoriesFields_Breadcrumbs_Id_Create;
  CategoriesFields_breadcrumbs_id_Delete: CategoriesFields_Breadcrumbs_Id_Delete;
  CategoriesFields_breadcrumbs_id_Read: CategoriesFields_Breadcrumbs_Id_Read;
  CategoriesFields_breadcrumbs_id_Update: CategoriesFields_Breadcrumbs_Id_Update;
  CategoriesFields_breadcrumbs_label: CategoriesFields_Breadcrumbs_Label;
  CategoriesFields_breadcrumbs_label_Create: CategoriesFields_Breadcrumbs_Label_Create;
  CategoriesFields_breadcrumbs_label_Delete: CategoriesFields_Breadcrumbs_Label_Delete;
  CategoriesFields_breadcrumbs_label_Read: CategoriesFields_Breadcrumbs_Label_Read;
  CategoriesFields_breadcrumbs_label_Update: CategoriesFields_Breadcrumbs_Label_Update;
  CategoriesFields_breadcrumbs_url: CategoriesFields_Breadcrumbs_Url;
  CategoriesFields_breadcrumbs_url_Create: CategoriesFields_Breadcrumbs_Url_Create;
  CategoriesFields_breadcrumbs_url_Delete: CategoriesFields_Breadcrumbs_Url_Delete;
  CategoriesFields_breadcrumbs_url_Read: CategoriesFields_Breadcrumbs_Url_Read;
  CategoriesFields_breadcrumbs_url_Update: CategoriesFields_Breadcrumbs_Url_Update;
  CategoriesFields_createdAt: CategoriesFields_CreatedAt;
  CategoriesFields_createdAt_Create: CategoriesFields_CreatedAt_Create;
  CategoriesFields_createdAt_Delete: CategoriesFields_CreatedAt_Delete;
  CategoriesFields_createdAt_Read: CategoriesFields_CreatedAt_Read;
  CategoriesFields_createdAt_Update: CategoriesFields_CreatedAt_Update;
  CategoriesFields_parent: CategoriesFields_Parent;
  CategoriesFields_parent_Create: CategoriesFields_Parent_Create;
  CategoriesFields_parent_Delete: CategoriesFields_Parent_Delete;
  CategoriesFields_parent_Read: CategoriesFields_Parent_Read;
  CategoriesFields_parent_Update: CategoriesFields_Parent_Update;
  CategoriesFields_title: CategoriesFields_Title;
  CategoriesFields_title_Create: CategoriesFields_Title_Create;
  CategoriesFields_title_Delete: CategoriesFields_Title_Delete;
  CategoriesFields_title_Read: CategoriesFields_Title_Read;
  CategoriesFields_title_Update: CategoriesFields_Title_Update;
  CategoriesFields_updatedAt: CategoriesFields_UpdatedAt;
  CategoriesFields_updatedAt_Create: CategoriesFields_UpdatedAt_Create;
  CategoriesFields_updatedAt_Delete: CategoriesFields_UpdatedAt_Delete;
  CategoriesFields_updatedAt_Read: CategoriesFields_UpdatedAt_Read;
  CategoriesFields_updatedAt_Update: CategoriesFields_UpdatedAt_Update;
  CategoriesReadAccess: CategoriesReadAccess;
  CategoriesReadDocAccess: CategoriesReadDocAccess;
  CategoriesUpdateAccess: CategoriesUpdateAccess;
  CategoriesUpdateDocAccess: CategoriesUpdateDocAccess;
  Category: Category;
  Category_Breadcrumbs: Category_Breadcrumbs;
  Category_breadcrumbs__doc_operator: Category_Breadcrumbs__Doc_Operator;
  Category_breadcrumbs__id_operator: Category_Breadcrumbs__Id_Operator;
  Category_breadcrumbs__label_operator: Category_Breadcrumbs__Label_Operator;
  Category_breadcrumbs__url_operator: Category_Breadcrumbs__Url_Operator;
  Category_createdAt_operator: Category_CreatedAt_Operator;
  Category_id_operator: Category_Id_Operator;
  Category_parent_operator: Category_Parent_Operator;
  Category_title_operator: Category_Title_Operator;
  Category_updatedAt_operator: Category_UpdatedAt_Operator;
  Category_where: Category_Where;
  Category_where_and: Category_Where_And;
  Category_where_or: Category_Where_Or;
  Content: Content;
  Cta: Cta;
  Cta_Links: Cta_Links;
  Cta_Links_Link: Cta_Links_Link;
  Cta_Links_Link_Reference: ResolversUnionTypes<ResolversParentTypes>['Cta_Links_Link_Reference'];
  Cta_Links_Link_Reference_Relationship: Omit<Cta_Links_Link_Reference_Relationship, 'value'> & { value?: Maybe<ResolversParentTypes['Cta_Links_Link_Reference']> };
  DateTime: Scalars['DateTime']['output'];
  EmailAddress: Scalars['EmailAddress']['output'];
  Float: Scalars['Float']['output'];
  Int: Scalars['Int']['output'];
  JSON: Scalars['JSON']['output'];
  JSONObject: Scalars['JSONObject']['output'];
  Media: Media;
  MediaBlock: MediaBlock;
  MediaBlock_Media_alt_operator: MediaBlock_Media_Alt_Operator;
  MediaBlock_Media_caption_operator: MediaBlock_Media_Caption_Operator;
  MediaBlock_Media_createdAt_operator: MediaBlock_Media_CreatedAt_Operator;
  MediaBlock_Media_filename_operator: MediaBlock_Media_Filename_Operator;
  MediaBlock_Media_filesize_operator: MediaBlock_Media_Filesize_Operator;
  MediaBlock_Media_height_operator: MediaBlock_Media_Height_Operator;
  MediaBlock_Media_id_operator: MediaBlock_Media_Id_Operator;
  MediaBlock_Media_mimeType_operator: MediaBlock_Media_MimeType_Operator;
  MediaBlock_Media_updatedAt_operator: MediaBlock_Media_UpdatedAt_Operator;
  MediaBlock_Media_url_operator: MediaBlock_Media_Url_Operator;
  MediaBlock_Media_where: MediaBlock_Media_Where;
  MediaBlock_Media_where_and: MediaBlock_Media_Where_And;
  MediaBlock_Media_where_or: MediaBlock_Media_Where_Or;
  MediaBlock_Media_width_operator: MediaBlock_Media_Width_Operator;
  MediaCreateAccess: MediaCreateAccess;
  MediaCreateDocAccess: MediaCreateDocAccess;
  MediaDeleteAccess: MediaDeleteAccess;
  MediaDeleteDocAccess: MediaDeleteDocAccess;
  MediaDocAccessFields: MediaDocAccessFields;
  MediaDocAccessFields_alt: MediaDocAccessFields_Alt;
  MediaDocAccessFields_alt_Create: MediaDocAccessFields_Alt_Create;
  MediaDocAccessFields_alt_Delete: MediaDocAccessFields_Alt_Delete;
  MediaDocAccessFields_alt_Read: MediaDocAccessFields_Alt_Read;
  MediaDocAccessFields_alt_Update: MediaDocAccessFields_Alt_Update;
  MediaDocAccessFields_caption: MediaDocAccessFields_Caption;
  MediaDocAccessFields_caption_Create: MediaDocAccessFields_Caption_Create;
  MediaDocAccessFields_caption_Delete: MediaDocAccessFields_Caption_Delete;
  MediaDocAccessFields_caption_Read: MediaDocAccessFields_Caption_Read;
  MediaDocAccessFields_caption_Update: MediaDocAccessFields_Caption_Update;
  MediaDocAccessFields_createdAt: MediaDocAccessFields_CreatedAt;
  MediaDocAccessFields_createdAt_Create: MediaDocAccessFields_CreatedAt_Create;
  MediaDocAccessFields_createdAt_Delete: MediaDocAccessFields_CreatedAt_Delete;
  MediaDocAccessFields_createdAt_Read: MediaDocAccessFields_CreatedAt_Read;
  MediaDocAccessFields_createdAt_Update: MediaDocAccessFields_CreatedAt_Update;
  MediaDocAccessFields_filename: MediaDocAccessFields_Filename;
  MediaDocAccessFields_filename_Create: MediaDocAccessFields_Filename_Create;
  MediaDocAccessFields_filename_Delete: MediaDocAccessFields_Filename_Delete;
  MediaDocAccessFields_filename_Read: MediaDocAccessFields_Filename_Read;
  MediaDocAccessFields_filename_Update: MediaDocAccessFields_Filename_Update;
  MediaDocAccessFields_filesize: MediaDocAccessFields_Filesize;
  MediaDocAccessFields_filesize_Create: MediaDocAccessFields_Filesize_Create;
  MediaDocAccessFields_filesize_Delete: MediaDocAccessFields_Filesize_Delete;
  MediaDocAccessFields_filesize_Read: MediaDocAccessFields_Filesize_Read;
  MediaDocAccessFields_filesize_Update: MediaDocAccessFields_Filesize_Update;
  MediaDocAccessFields_height: MediaDocAccessFields_Height;
  MediaDocAccessFields_height_Create: MediaDocAccessFields_Height_Create;
  MediaDocAccessFields_height_Delete: MediaDocAccessFields_Height_Delete;
  MediaDocAccessFields_height_Read: MediaDocAccessFields_Height_Read;
  MediaDocAccessFields_height_Update: MediaDocAccessFields_Height_Update;
  MediaDocAccessFields_mimeType: MediaDocAccessFields_MimeType;
  MediaDocAccessFields_mimeType_Create: MediaDocAccessFields_MimeType_Create;
  MediaDocAccessFields_mimeType_Delete: MediaDocAccessFields_MimeType_Delete;
  MediaDocAccessFields_mimeType_Read: MediaDocAccessFields_MimeType_Read;
  MediaDocAccessFields_mimeType_Update: MediaDocAccessFields_MimeType_Update;
  MediaDocAccessFields_updatedAt: MediaDocAccessFields_UpdatedAt;
  MediaDocAccessFields_updatedAt_Create: MediaDocAccessFields_UpdatedAt_Create;
  MediaDocAccessFields_updatedAt_Delete: MediaDocAccessFields_UpdatedAt_Delete;
  MediaDocAccessFields_updatedAt_Read: MediaDocAccessFields_UpdatedAt_Read;
  MediaDocAccessFields_updatedAt_Update: MediaDocAccessFields_UpdatedAt_Update;
  MediaDocAccessFields_url: MediaDocAccessFields_Url;
  MediaDocAccessFields_url_Create: MediaDocAccessFields_Url_Create;
  MediaDocAccessFields_url_Delete: MediaDocAccessFields_Url_Delete;
  MediaDocAccessFields_url_Read: MediaDocAccessFields_Url_Read;
  MediaDocAccessFields_url_Update: MediaDocAccessFields_Url_Update;
  MediaDocAccessFields_width: MediaDocAccessFields_Width;
  MediaDocAccessFields_width_Create: MediaDocAccessFields_Width_Create;
  MediaDocAccessFields_width_Delete: MediaDocAccessFields_Width_Delete;
  MediaDocAccessFields_width_Read: MediaDocAccessFields_Width_Read;
  MediaDocAccessFields_width_Update: MediaDocAccessFields_Width_Update;
  MediaFields: MediaFields;
  MediaFields_alt: MediaFields_Alt;
  MediaFields_alt_Create: MediaFields_Alt_Create;
  MediaFields_alt_Delete: MediaFields_Alt_Delete;
  MediaFields_alt_Read: MediaFields_Alt_Read;
  MediaFields_alt_Update: MediaFields_Alt_Update;
  MediaFields_caption: MediaFields_Caption;
  MediaFields_caption_Create: MediaFields_Caption_Create;
  MediaFields_caption_Delete: MediaFields_Caption_Delete;
  MediaFields_caption_Read: MediaFields_Caption_Read;
  MediaFields_caption_Update: MediaFields_Caption_Update;
  MediaFields_createdAt: MediaFields_CreatedAt;
  MediaFields_createdAt_Create: MediaFields_CreatedAt_Create;
  MediaFields_createdAt_Delete: MediaFields_CreatedAt_Delete;
  MediaFields_createdAt_Read: MediaFields_CreatedAt_Read;
  MediaFields_createdAt_Update: MediaFields_CreatedAt_Update;
  MediaFields_filename: MediaFields_Filename;
  MediaFields_filename_Create: MediaFields_Filename_Create;
  MediaFields_filename_Delete: MediaFields_Filename_Delete;
  MediaFields_filename_Read: MediaFields_Filename_Read;
  MediaFields_filename_Update: MediaFields_Filename_Update;
  MediaFields_filesize: MediaFields_Filesize;
  MediaFields_filesize_Create: MediaFields_Filesize_Create;
  MediaFields_filesize_Delete: MediaFields_Filesize_Delete;
  MediaFields_filesize_Read: MediaFields_Filesize_Read;
  MediaFields_filesize_Update: MediaFields_Filesize_Update;
  MediaFields_height: MediaFields_Height;
  MediaFields_height_Create: MediaFields_Height_Create;
  MediaFields_height_Delete: MediaFields_Height_Delete;
  MediaFields_height_Read: MediaFields_Height_Read;
  MediaFields_height_Update: MediaFields_Height_Update;
  MediaFields_mimeType: MediaFields_MimeType;
  MediaFields_mimeType_Create: MediaFields_MimeType_Create;
  MediaFields_mimeType_Delete: MediaFields_MimeType_Delete;
  MediaFields_mimeType_Read: MediaFields_MimeType_Read;
  MediaFields_mimeType_Update: MediaFields_MimeType_Update;
  MediaFields_updatedAt: MediaFields_UpdatedAt;
  MediaFields_updatedAt_Create: MediaFields_UpdatedAt_Create;
  MediaFields_updatedAt_Delete: MediaFields_UpdatedAt_Delete;
  MediaFields_updatedAt_Read: MediaFields_UpdatedAt_Read;
  MediaFields_updatedAt_Update: MediaFields_UpdatedAt_Update;
  MediaFields_url: MediaFields_Url;
  MediaFields_url_Create: MediaFields_Url_Create;
  MediaFields_url_Delete: MediaFields_Url_Delete;
  MediaFields_url_Read: MediaFields_Url_Read;
  MediaFields_url_Update: MediaFields_Url_Update;
  MediaFields_width: MediaFields_Width;
  MediaFields_width_Create: MediaFields_Width_Create;
  MediaFields_width_Delete: MediaFields_Width_Delete;
  MediaFields_width_Read: MediaFields_Width_Read;
  MediaFields_width_Update: MediaFields_Width_Update;
  MediaReadAccess: MediaReadAccess;
  MediaReadDocAccess: MediaReadDocAccess;
  MediaUpdateAccess: MediaUpdateAccess;
  MediaUpdateDocAccess: MediaUpdateDocAccess;
  Media_alt_operator: Media_Alt_Operator;
  Media_caption_operator: Media_Caption_Operator;
  Media_createdAt_operator: Media_CreatedAt_Operator;
  Media_filename_operator: Media_Filename_Operator;
  Media_filesize_operator: Media_Filesize_Operator;
  Media_height_operator: Media_Height_Operator;
  Media_id_operator: Media_Id_Operator;
  Media_mimeType_operator: Media_MimeType_Operator;
  Media_updatedAt_operator: Media_UpdatedAt_Operator;
  Media_url_operator: Media_Url_Operator;
  Media_where: Media_Where;
  Media_where_and: Media_Where_And;
  Media_where_or: Media_Where_Or;
  Media_width_operator: Media_Width_Operator;
  Mutation: {};
  PayloadPreference: PayloadPreference;
  PayloadPreferenceUpdate_UserRelationshipInput: PayloadPreferenceUpdate_UserRelationshipInput;
  PayloadPreference_User: ResolversUnionTypes<ResolversParentTypes>['PayloadPreference_User'];
  PayloadPreference_UserRelationshipInput: PayloadPreference_UserRelationshipInput;
  PayloadPreference_User_Relationship: Omit<PayloadPreference_User_Relationship, 'value'> & { value?: Maybe<ResolversParentTypes['PayloadPreference_User']> };
  PayloadPreference_createdAt_operator: PayloadPreference_CreatedAt_Operator;
  PayloadPreference_id_operator: PayloadPreference_Id_Operator;
  PayloadPreference_key_operator: PayloadPreference_Key_Operator;
  PayloadPreference_updatedAt_operator: PayloadPreference_UpdatedAt_Operator;
  PayloadPreference_user_Relation: PayloadPreference_User_Relation;
  PayloadPreference_value_operator: PayloadPreference_Value_Operator;
  PayloadPreference_where: PayloadPreference_Where;
  PayloadPreference_where_and: PayloadPreference_Where_And;
  PayloadPreference_where_or: PayloadPreference_Where_Or;
  PayloadPreferences: PayloadPreferences;
  PayloadPreferencesCreateAccess: PayloadPreferencesCreateAccess;
  PayloadPreferencesCreateDocAccess: PayloadPreferencesCreateDocAccess;
  PayloadPreferencesDeleteAccess: PayloadPreferencesDeleteAccess;
  PayloadPreferencesDeleteDocAccess: PayloadPreferencesDeleteDocAccess;
  PayloadPreferencesDocAccessFields: PayloadPreferencesDocAccessFields;
  PayloadPreferencesDocAccessFields_createdAt: PayloadPreferencesDocAccessFields_CreatedAt;
  PayloadPreferencesDocAccessFields_createdAt_Create: PayloadPreferencesDocAccessFields_CreatedAt_Create;
  PayloadPreferencesDocAccessFields_createdAt_Delete: PayloadPreferencesDocAccessFields_CreatedAt_Delete;
  PayloadPreferencesDocAccessFields_createdAt_Read: PayloadPreferencesDocAccessFields_CreatedAt_Read;
  PayloadPreferencesDocAccessFields_createdAt_Update: PayloadPreferencesDocAccessFields_CreatedAt_Update;
  PayloadPreferencesDocAccessFields_key: PayloadPreferencesDocAccessFields_Key;
  PayloadPreferencesDocAccessFields_key_Create: PayloadPreferencesDocAccessFields_Key_Create;
  PayloadPreferencesDocAccessFields_key_Delete: PayloadPreferencesDocAccessFields_Key_Delete;
  PayloadPreferencesDocAccessFields_key_Read: PayloadPreferencesDocAccessFields_Key_Read;
  PayloadPreferencesDocAccessFields_key_Update: PayloadPreferencesDocAccessFields_Key_Update;
  PayloadPreferencesDocAccessFields_updatedAt: PayloadPreferencesDocAccessFields_UpdatedAt;
  PayloadPreferencesDocAccessFields_updatedAt_Create: PayloadPreferencesDocAccessFields_UpdatedAt_Create;
  PayloadPreferencesDocAccessFields_updatedAt_Delete: PayloadPreferencesDocAccessFields_UpdatedAt_Delete;
  PayloadPreferencesDocAccessFields_updatedAt_Read: PayloadPreferencesDocAccessFields_UpdatedAt_Read;
  PayloadPreferencesDocAccessFields_updatedAt_Update: PayloadPreferencesDocAccessFields_UpdatedAt_Update;
  PayloadPreferencesDocAccessFields_user: PayloadPreferencesDocAccessFields_User;
  PayloadPreferencesDocAccessFields_user_Create: PayloadPreferencesDocAccessFields_User_Create;
  PayloadPreferencesDocAccessFields_user_Delete: PayloadPreferencesDocAccessFields_User_Delete;
  PayloadPreferencesDocAccessFields_user_Read: PayloadPreferencesDocAccessFields_User_Read;
  PayloadPreferencesDocAccessFields_user_Update: PayloadPreferencesDocAccessFields_User_Update;
  PayloadPreferencesDocAccessFields_value: PayloadPreferencesDocAccessFields_Value;
  PayloadPreferencesDocAccessFields_value_Create: PayloadPreferencesDocAccessFields_Value_Create;
  PayloadPreferencesDocAccessFields_value_Delete: PayloadPreferencesDocAccessFields_Value_Delete;
  PayloadPreferencesDocAccessFields_value_Read: PayloadPreferencesDocAccessFields_Value_Read;
  PayloadPreferencesDocAccessFields_value_Update: PayloadPreferencesDocAccessFields_Value_Update;
  PayloadPreferencesFields: PayloadPreferencesFields;
  PayloadPreferencesFields_createdAt: PayloadPreferencesFields_CreatedAt;
  PayloadPreferencesFields_createdAt_Create: PayloadPreferencesFields_CreatedAt_Create;
  PayloadPreferencesFields_createdAt_Delete: PayloadPreferencesFields_CreatedAt_Delete;
  PayloadPreferencesFields_createdAt_Read: PayloadPreferencesFields_CreatedAt_Read;
  PayloadPreferencesFields_createdAt_Update: PayloadPreferencesFields_CreatedAt_Update;
  PayloadPreferencesFields_key: PayloadPreferencesFields_Key;
  PayloadPreferencesFields_key_Create: PayloadPreferencesFields_Key_Create;
  PayloadPreferencesFields_key_Delete: PayloadPreferencesFields_Key_Delete;
  PayloadPreferencesFields_key_Read: PayloadPreferencesFields_Key_Read;
  PayloadPreferencesFields_key_Update: PayloadPreferencesFields_Key_Update;
  PayloadPreferencesFields_updatedAt: PayloadPreferencesFields_UpdatedAt;
  PayloadPreferencesFields_updatedAt_Create: PayloadPreferencesFields_UpdatedAt_Create;
  PayloadPreferencesFields_updatedAt_Delete: PayloadPreferencesFields_UpdatedAt_Delete;
  PayloadPreferencesFields_updatedAt_Read: PayloadPreferencesFields_UpdatedAt_Read;
  PayloadPreferencesFields_updatedAt_Update: PayloadPreferencesFields_UpdatedAt_Update;
  PayloadPreferencesFields_user: PayloadPreferencesFields_User;
  PayloadPreferencesFields_user_Create: PayloadPreferencesFields_User_Create;
  PayloadPreferencesFields_user_Delete: PayloadPreferencesFields_User_Delete;
  PayloadPreferencesFields_user_Read: PayloadPreferencesFields_User_Read;
  PayloadPreferencesFields_user_Update: PayloadPreferencesFields_User_Update;
  PayloadPreferencesFields_value: PayloadPreferencesFields_Value;
  PayloadPreferencesFields_value_Create: PayloadPreferencesFields_Value_Create;
  PayloadPreferencesFields_value_Delete: PayloadPreferencesFields_Value_Delete;
  PayloadPreferencesFields_value_Read: PayloadPreferencesFields_Value_Read;
  PayloadPreferencesFields_value_Update: PayloadPreferencesFields_Value_Update;
  PayloadPreferencesReadAccess: PayloadPreferencesReadAccess;
  PayloadPreferencesReadDocAccess: PayloadPreferencesReadDocAccess;
  PayloadPreferencesUpdateAccess: PayloadPreferencesUpdateAccess;
  PayloadPreferencesUpdateDocAccess: PayloadPreferencesUpdateDocAccess;
  Query: {};
  String: Scalars['String']['output'];
  TaskTemplate: TaskTemplate;
  TaskTemplateVersion: TaskTemplateVersion;
  TaskTemplateVersion_Version: TaskTemplateVersion_Version;
  TaskTemplateVersion_Version_Resources: TaskTemplateVersion_Version_Resources;
  TaskTemplateVersion_Version_Review: TaskTemplateVersion_Version_Review;
  TaskTemplateVersion_Version_Schedule: TaskTemplateVersion_Version_Schedule;
  TaskTemplateVersion_Version_Schedule_Recurrence: TaskTemplateVersion_Version_Schedule_Recurrence;
  TaskTemplateVersion_Version_Thumbnail_alt_operator: TaskTemplateVersion_Version_Thumbnail_Alt_Operator;
  TaskTemplateVersion_Version_Thumbnail_caption_operator: TaskTemplateVersion_Version_Thumbnail_Caption_Operator;
  TaskTemplateVersion_Version_Thumbnail_createdAt_operator: TaskTemplateVersion_Version_Thumbnail_CreatedAt_Operator;
  TaskTemplateVersion_Version_Thumbnail_filename_operator: TaskTemplateVersion_Version_Thumbnail_Filename_Operator;
  TaskTemplateVersion_Version_Thumbnail_filesize_operator: TaskTemplateVersion_Version_Thumbnail_Filesize_Operator;
  TaskTemplateVersion_Version_Thumbnail_height_operator: TaskTemplateVersion_Version_Thumbnail_Height_Operator;
  TaskTemplateVersion_Version_Thumbnail_id_operator: TaskTemplateVersion_Version_Thumbnail_Id_Operator;
  TaskTemplateVersion_Version_Thumbnail_mimeType_operator: TaskTemplateVersion_Version_Thumbnail_MimeType_Operator;
  TaskTemplateVersion_Version_Thumbnail_updatedAt_operator: TaskTemplateVersion_Version_Thumbnail_UpdatedAt_Operator;
  TaskTemplateVersion_Version_Thumbnail_url_operator: TaskTemplateVersion_Version_Thumbnail_Url_Operator;
  TaskTemplateVersion_Version_Thumbnail_where: TaskTemplateVersion_Version_Thumbnail_Where;
  TaskTemplateVersion_Version_Thumbnail_where_and: TaskTemplateVersion_Version_Thumbnail_Where_And;
  TaskTemplateVersion_Version_Thumbnail_where_or: TaskTemplateVersion_Version_Thumbnail_Where_Or;
  TaskTemplateVersion_Version_Thumbnail_width_operator: TaskTemplateVersion_Version_Thumbnail_Width_Operator;
  TaskTemplate_Resources: TaskTemplate_Resources;
  TaskTemplate_Review: TaskTemplate_Review;
  TaskTemplate_Schedule: TaskTemplate_Schedule;
  TaskTemplate_Schedule_Recurrence: TaskTemplate_Schedule_Recurrence;
  TaskTemplate_Thumbnail_alt_operator: TaskTemplate_Thumbnail_Alt_Operator;
  TaskTemplate_Thumbnail_caption_operator: TaskTemplate_Thumbnail_Caption_Operator;
  TaskTemplate_Thumbnail_createdAt_operator: TaskTemplate_Thumbnail_CreatedAt_Operator;
  TaskTemplate_Thumbnail_filename_operator: TaskTemplate_Thumbnail_Filename_Operator;
  TaskTemplate_Thumbnail_filesize_operator: TaskTemplate_Thumbnail_Filesize_Operator;
  TaskTemplate_Thumbnail_height_operator: TaskTemplate_Thumbnail_Height_Operator;
  TaskTemplate_Thumbnail_id_operator: TaskTemplate_Thumbnail_Id_Operator;
  TaskTemplate_Thumbnail_mimeType_operator: TaskTemplate_Thumbnail_MimeType_Operator;
  TaskTemplate_Thumbnail_updatedAt_operator: TaskTemplate_Thumbnail_UpdatedAt_Operator;
  TaskTemplate_Thumbnail_url_operator: TaskTemplate_Thumbnail_Url_Operator;
  TaskTemplate_Thumbnail_where: TaskTemplate_Thumbnail_Where;
  TaskTemplate_Thumbnail_where_and: TaskTemplate_Thumbnail_Where_And;
  TaskTemplate_Thumbnail_where_or: TaskTemplate_Thumbnail_Where_Or;
  TaskTemplate_Thumbnail_width_operator: TaskTemplate_Thumbnail_Width_Operator;
  TaskTemplate__status_operator: TaskTemplate__Status_Operator;
  TaskTemplate_assignment_operator: TaskTemplate_Assignment_Operator;
  TaskTemplate_categories_operator: TaskTemplate_Categories_Operator;
  TaskTemplate_createdAt_operator: TaskTemplate_CreatedAt_Operator;
  TaskTemplate_description_operator: TaskTemplate_Description_Operator;
  TaskTemplate_details_operator: TaskTemplate_Details_Operator;
  TaskTemplate_feature_operator: TaskTemplate_Feature_Operator;
  TaskTemplate_id_operator: TaskTemplate_Id_Operator;
  TaskTemplate_recurrence__freq_operator: TaskTemplate_Recurrence__Freq_Operator;
  TaskTemplate_recurrence__interval_operator: TaskTemplate_Recurrence__Interval_Operator;
  TaskTemplate_resources__articles_operator: TaskTemplate_Resources__Articles_Operator;
  TaskTemplate_resources__videos_operator: TaskTemplate_Resources__Videos_Operator;
  TaskTemplate_review__approver_operator: TaskTemplate_Review__Approver_Operator;
  TaskTemplate_review__evidence_operator: TaskTemplate_Review__Evidence_Operator;
  TaskTemplate_review__required_operator: TaskTemplate_Review__Required_Operator;
  TaskTemplate_schedule__due_operator: TaskTemplate_Schedule__Due_Operator;
  TaskTemplate_schedule__event_operator: TaskTemplate_Schedule__Event_Operator;
  TaskTemplate_schedule__id_operator: TaskTemplate_Schedule__Id_Operator;
  TaskTemplate_thumbnail_operator: TaskTemplate_Thumbnail_Operator;
  TaskTemplate_title_operator: TaskTemplate_Title_Operator;
  TaskTemplate_updatedAt_operator: TaskTemplate_UpdatedAt_Operator;
  TaskTemplate_where: TaskTemplate_Where;
  TaskTemplate_where_and: TaskTemplate_Where_And;
  TaskTemplate_where_or: TaskTemplate_Where_Or;
  TaskTemplates: TaskTemplates;
  TaskTemplatesCreateAccess: TaskTemplatesCreateAccess;
  TaskTemplatesCreateDocAccess: TaskTemplatesCreateDocAccess;
  TaskTemplatesDeleteAccess: TaskTemplatesDeleteAccess;
  TaskTemplatesDeleteDocAccess: TaskTemplatesDeleteDocAccess;
  TaskTemplatesDocAccessFields: TaskTemplatesDocAccessFields;
  TaskTemplatesDocAccessFields__status: TaskTemplatesDocAccessFields__Status;
  TaskTemplatesDocAccessFields__status_Create: TaskTemplatesDocAccessFields__Status_Create;
  TaskTemplatesDocAccessFields__status_Delete: TaskTemplatesDocAccessFields__Status_Delete;
  TaskTemplatesDocAccessFields__status_Read: TaskTemplatesDocAccessFields__Status_Read;
  TaskTemplatesDocAccessFields__status_Update: TaskTemplatesDocAccessFields__Status_Update;
  TaskTemplatesDocAccessFields_assignment: TaskTemplatesDocAccessFields_Assignment;
  TaskTemplatesDocAccessFields_assignment_Create: TaskTemplatesDocAccessFields_Assignment_Create;
  TaskTemplatesDocAccessFields_assignment_Delete: TaskTemplatesDocAccessFields_Assignment_Delete;
  TaskTemplatesDocAccessFields_assignment_Read: TaskTemplatesDocAccessFields_Assignment_Read;
  TaskTemplatesDocAccessFields_assignment_Update: TaskTemplatesDocAccessFields_Assignment_Update;
  TaskTemplatesDocAccessFields_categories: TaskTemplatesDocAccessFields_Categories;
  TaskTemplatesDocAccessFields_categories_Create: TaskTemplatesDocAccessFields_Categories_Create;
  TaskTemplatesDocAccessFields_categories_Delete: TaskTemplatesDocAccessFields_Categories_Delete;
  TaskTemplatesDocAccessFields_categories_Read: TaskTemplatesDocAccessFields_Categories_Read;
  TaskTemplatesDocAccessFields_categories_Update: TaskTemplatesDocAccessFields_Categories_Update;
  TaskTemplatesDocAccessFields_createdAt: TaskTemplatesDocAccessFields_CreatedAt;
  TaskTemplatesDocAccessFields_createdAt_Create: TaskTemplatesDocAccessFields_CreatedAt_Create;
  TaskTemplatesDocAccessFields_createdAt_Delete: TaskTemplatesDocAccessFields_CreatedAt_Delete;
  TaskTemplatesDocAccessFields_createdAt_Read: TaskTemplatesDocAccessFields_CreatedAt_Read;
  TaskTemplatesDocAccessFields_createdAt_Update: TaskTemplatesDocAccessFields_CreatedAt_Update;
  TaskTemplatesDocAccessFields_description: TaskTemplatesDocAccessFields_Description;
  TaskTemplatesDocAccessFields_description_Create: TaskTemplatesDocAccessFields_Description_Create;
  TaskTemplatesDocAccessFields_description_Delete: TaskTemplatesDocAccessFields_Description_Delete;
  TaskTemplatesDocAccessFields_description_Read: TaskTemplatesDocAccessFields_Description_Read;
  TaskTemplatesDocAccessFields_description_Update: TaskTemplatesDocAccessFields_Description_Update;
  TaskTemplatesDocAccessFields_details: TaskTemplatesDocAccessFields_Details;
  TaskTemplatesDocAccessFields_details_Create: TaskTemplatesDocAccessFields_Details_Create;
  TaskTemplatesDocAccessFields_details_Delete: TaskTemplatesDocAccessFields_Details_Delete;
  TaskTemplatesDocAccessFields_details_Read: TaskTemplatesDocAccessFields_Details_Read;
  TaskTemplatesDocAccessFields_details_Update: TaskTemplatesDocAccessFields_Details_Update;
  TaskTemplatesDocAccessFields_feature: TaskTemplatesDocAccessFields_Feature;
  TaskTemplatesDocAccessFields_feature_Create: TaskTemplatesDocAccessFields_Feature_Create;
  TaskTemplatesDocAccessFields_feature_Delete: TaskTemplatesDocAccessFields_Feature_Delete;
  TaskTemplatesDocAccessFields_feature_Read: TaskTemplatesDocAccessFields_Feature_Read;
  TaskTemplatesDocAccessFields_feature_Update: TaskTemplatesDocAccessFields_Feature_Update;
  TaskTemplatesDocAccessFields_resources: TaskTemplatesDocAccessFields_Resources;
  TaskTemplatesDocAccessFields_resources_Create: TaskTemplatesDocAccessFields_Resources_Create;
  TaskTemplatesDocAccessFields_resources_Delete: TaskTemplatesDocAccessFields_Resources_Delete;
  TaskTemplatesDocAccessFields_resources_Fields: TaskTemplatesDocAccessFields_Resources_Fields;
  TaskTemplatesDocAccessFields_resources_Read: TaskTemplatesDocAccessFields_Resources_Read;
  TaskTemplatesDocAccessFields_resources_Update: TaskTemplatesDocAccessFields_Resources_Update;
  TaskTemplatesDocAccessFields_resources_articles: TaskTemplatesDocAccessFields_Resources_Articles;
  TaskTemplatesDocAccessFields_resources_articles_Create: TaskTemplatesDocAccessFields_Resources_Articles_Create;
  TaskTemplatesDocAccessFields_resources_articles_Delete: TaskTemplatesDocAccessFields_Resources_Articles_Delete;
  TaskTemplatesDocAccessFields_resources_articles_Read: TaskTemplatesDocAccessFields_Resources_Articles_Read;
  TaskTemplatesDocAccessFields_resources_articles_Update: TaskTemplatesDocAccessFields_Resources_Articles_Update;
  TaskTemplatesDocAccessFields_resources_videos: TaskTemplatesDocAccessFields_Resources_Videos;
  TaskTemplatesDocAccessFields_resources_videos_Create: TaskTemplatesDocAccessFields_Resources_Videos_Create;
  TaskTemplatesDocAccessFields_resources_videos_Delete: TaskTemplatesDocAccessFields_Resources_Videos_Delete;
  TaskTemplatesDocAccessFields_resources_videos_Read: TaskTemplatesDocAccessFields_Resources_Videos_Read;
  TaskTemplatesDocAccessFields_resources_videos_Update: TaskTemplatesDocAccessFields_Resources_Videos_Update;
  TaskTemplatesDocAccessFields_review: TaskTemplatesDocAccessFields_Review;
  TaskTemplatesDocAccessFields_review_Create: TaskTemplatesDocAccessFields_Review_Create;
  TaskTemplatesDocAccessFields_review_Delete: TaskTemplatesDocAccessFields_Review_Delete;
  TaskTemplatesDocAccessFields_review_Fields: TaskTemplatesDocAccessFields_Review_Fields;
  TaskTemplatesDocAccessFields_review_Read: TaskTemplatesDocAccessFields_Review_Read;
  TaskTemplatesDocAccessFields_review_Update: TaskTemplatesDocAccessFields_Review_Update;
  TaskTemplatesDocAccessFields_review_approver: TaskTemplatesDocAccessFields_Review_Approver;
  TaskTemplatesDocAccessFields_review_approver_Create: TaskTemplatesDocAccessFields_Review_Approver_Create;
  TaskTemplatesDocAccessFields_review_approver_Delete: TaskTemplatesDocAccessFields_Review_Approver_Delete;
  TaskTemplatesDocAccessFields_review_approver_Read: TaskTemplatesDocAccessFields_Review_Approver_Read;
  TaskTemplatesDocAccessFields_review_approver_Update: TaskTemplatesDocAccessFields_Review_Approver_Update;
  TaskTemplatesDocAccessFields_review_evidence: TaskTemplatesDocAccessFields_Review_Evidence;
  TaskTemplatesDocAccessFields_review_evidence_Create: TaskTemplatesDocAccessFields_Review_Evidence_Create;
  TaskTemplatesDocAccessFields_review_evidence_Delete: TaskTemplatesDocAccessFields_Review_Evidence_Delete;
  TaskTemplatesDocAccessFields_review_evidence_Read: TaskTemplatesDocAccessFields_Review_Evidence_Read;
  TaskTemplatesDocAccessFields_review_evidence_Update: TaskTemplatesDocAccessFields_Review_Evidence_Update;
  TaskTemplatesDocAccessFields_review_required: TaskTemplatesDocAccessFields_Review_Required;
  TaskTemplatesDocAccessFields_review_required_Create: TaskTemplatesDocAccessFields_Review_Required_Create;
  TaskTemplatesDocAccessFields_review_required_Delete: TaskTemplatesDocAccessFields_Review_Required_Delete;
  TaskTemplatesDocAccessFields_review_required_Read: TaskTemplatesDocAccessFields_Review_Required_Read;
  TaskTemplatesDocAccessFields_review_required_Update: TaskTemplatesDocAccessFields_Review_Required_Update;
  TaskTemplatesDocAccessFields_schedule: TaskTemplatesDocAccessFields_Schedule;
  TaskTemplatesDocAccessFields_schedule_Create: TaskTemplatesDocAccessFields_Schedule_Create;
  TaskTemplatesDocAccessFields_schedule_Delete: TaskTemplatesDocAccessFields_Schedule_Delete;
  TaskTemplatesDocAccessFields_schedule_Fields: TaskTemplatesDocAccessFields_Schedule_Fields;
  TaskTemplatesDocAccessFields_schedule_Read: TaskTemplatesDocAccessFields_Schedule_Read;
  TaskTemplatesDocAccessFields_schedule_Update: TaskTemplatesDocAccessFields_Schedule_Update;
  TaskTemplatesDocAccessFields_schedule_due: TaskTemplatesDocAccessFields_Schedule_Due;
  TaskTemplatesDocAccessFields_schedule_due_Create: TaskTemplatesDocAccessFields_Schedule_Due_Create;
  TaskTemplatesDocAccessFields_schedule_due_Delete: TaskTemplatesDocAccessFields_Schedule_Due_Delete;
  TaskTemplatesDocAccessFields_schedule_due_Read: TaskTemplatesDocAccessFields_Schedule_Due_Read;
  TaskTemplatesDocAccessFields_schedule_due_Update: TaskTemplatesDocAccessFields_Schedule_Due_Update;
  TaskTemplatesDocAccessFields_schedule_event: TaskTemplatesDocAccessFields_Schedule_Event;
  TaskTemplatesDocAccessFields_schedule_event_Create: TaskTemplatesDocAccessFields_Schedule_Event_Create;
  TaskTemplatesDocAccessFields_schedule_event_Delete: TaskTemplatesDocAccessFields_Schedule_Event_Delete;
  TaskTemplatesDocAccessFields_schedule_event_Read: TaskTemplatesDocAccessFields_Schedule_Event_Read;
  TaskTemplatesDocAccessFields_schedule_event_Update: TaskTemplatesDocAccessFields_Schedule_Event_Update;
  TaskTemplatesDocAccessFields_schedule_freq: TaskTemplatesDocAccessFields_Schedule_Freq;
  TaskTemplatesDocAccessFields_schedule_freq_Create: TaskTemplatesDocAccessFields_Schedule_Freq_Create;
  TaskTemplatesDocAccessFields_schedule_freq_Delete: TaskTemplatesDocAccessFields_Schedule_Freq_Delete;
  TaskTemplatesDocAccessFields_schedule_freq_Read: TaskTemplatesDocAccessFields_Schedule_Freq_Read;
  TaskTemplatesDocAccessFields_schedule_freq_Update: TaskTemplatesDocAccessFields_Schedule_Freq_Update;
  TaskTemplatesDocAccessFields_schedule_id: TaskTemplatesDocAccessFields_Schedule_Id;
  TaskTemplatesDocAccessFields_schedule_id_Create: TaskTemplatesDocAccessFields_Schedule_Id_Create;
  TaskTemplatesDocAccessFields_schedule_id_Delete: TaskTemplatesDocAccessFields_Schedule_Id_Delete;
  TaskTemplatesDocAccessFields_schedule_id_Read: TaskTemplatesDocAccessFields_Schedule_Id_Read;
  TaskTemplatesDocAccessFields_schedule_id_Update: TaskTemplatesDocAccessFields_Schedule_Id_Update;
  TaskTemplatesDocAccessFields_schedule_interval: TaskTemplatesDocAccessFields_Schedule_Interval;
  TaskTemplatesDocAccessFields_schedule_interval_Create: TaskTemplatesDocAccessFields_Schedule_Interval_Create;
  TaskTemplatesDocAccessFields_schedule_interval_Delete: TaskTemplatesDocAccessFields_Schedule_Interval_Delete;
  TaskTemplatesDocAccessFields_schedule_interval_Read: TaskTemplatesDocAccessFields_Schedule_Interval_Read;
  TaskTemplatesDocAccessFields_schedule_interval_Update: TaskTemplatesDocAccessFields_Schedule_Interval_Update;
  TaskTemplatesDocAccessFields_thumbnail: TaskTemplatesDocAccessFields_Thumbnail;
  TaskTemplatesDocAccessFields_thumbnail_Create: TaskTemplatesDocAccessFields_Thumbnail_Create;
  TaskTemplatesDocAccessFields_thumbnail_Delete: TaskTemplatesDocAccessFields_Thumbnail_Delete;
  TaskTemplatesDocAccessFields_thumbnail_Read: TaskTemplatesDocAccessFields_Thumbnail_Read;
  TaskTemplatesDocAccessFields_thumbnail_Update: TaskTemplatesDocAccessFields_Thumbnail_Update;
  TaskTemplatesDocAccessFields_title: TaskTemplatesDocAccessFields_Title;
  TaskTemplatesDocAccessFields_title_Create: TaskTemplatesDocAccessFields_Title_Create;
  TaskTemplatesDocAccessFields_title_Delete: TaskTemplatesDocAccessFields_Title_Delete;
  TaskTemplatesDocAccessFields_title_Read: TaskTemplatesDocAccessFields_Title_Read;
  TaskTemplatesDocAccessFields_title_Update: TaskTemplatesDocAccessFields_Title_Update;
  TaskTemplatesDocAccessFields_updatedAt: TaskTemplatesDocAccessFields_UpdatedAt;
  TaskTemplatesDocAccessFields_updatedAt_Create: TaskTemplatesDocAccessFields_UpdatedAt_Create;
  TaskTemplatesDocAccessFields_updatedAt_Delete: TaskTemplatesDocAccessFields_UpdatedAt_Delete;
  TaskTemplatesDocAccessFields_updatedAt_Read: TaskTemplatesDocAccessFields_UpdatedAt_Read;
  TaskTemplatesDocAccessFields_updatedAt_Update: TaskTemplatesDocAccessFields_UpdatedAt_Update;
  TaskTemplatesFields: TaskTemplatesFields;
  TaskTemplatesFields__status: TaskTemplatesFields__Status;
  TaskTemplatesFields__status_Create: TaskTemplatesFields__Status_Create;
  TaskTemplatesFields__status_Delete: TaskTemplatesFields__Status_Delete;
  TaskTemplatesFields__status_Read: TaskTemplatesFields__Status_Read;
  TaskTemplatesFields__status_Update: TaskTemplatesFields__Status_Update;
  TaskTemplatesFields_assignment: TaskTemplatesFields_Assignment;
  TaskTemplatesFields_assignment_Create: TaskTemplatesFields_Assignment_Create;
  TaskTemplatesFields_assignment_Delete: TaskTemplatesFields_Assignment_Delete;
  TaskTemplatesFields_assignment_Read: TaskTemplatesFields_Assignment_Read;
  TaskTemplatesFields_assignment_Update: TaskTemplatesFields_Assignment_Update;
  TaskTemplatesFields_categories: TaskTemplatesFields_Categories;
  TaskTemplatesFields_categories_Create: TaskTemplatesFields_Categories_Create;
  TaskTemplatesFields_categories_Delete: TaskTemplatesFields_Categories_Delete;
  TaskTemplatesFields_categories_Read: TaskTemplatesFields_Categories_Read;
  TaskTemplatesFields_categories_Update: TaskTemplatesFields_Categories_Update;
  TaskTemplatesFields_createdAt: TaskTemplatesFields_CreatedAt;
  TaskTemplatesFields_createdAt_Create: TaskTemplatesFields_CreatedAt_Create;
  TaskTemplatesFields_createdAt_Delete: TaskTemplatesFields_CreatedAt_Delete;
  TaskTemplatesFields_createdAt_Read: TaskTemplatesFields_CreatedAt_Read;
  TaskTemplatesFields_createdAt_Update: TaskTemplatesFields_CreatedAt_Update;
  TaskTemplatesFields_description: TaskTemplatesFields_Description;
  TaskTemplatesFields_description_Create: TaskTemplatesFields_Description_Create;
  TaskTemplatesFields_description_Delete: TaskTemplatesFields_Description_Delete;
  TaskTemplatesFields_description_Read: TaskTemplatesFields_Description_Read;
  TaskTemplatesFields_description_Update: TaskTemplatesFields_Description_Update;
  TaskTemplatesFields_details: TaskTemplatesFields_Details;
  TaskTemplatesFields_details_Create: TaskTemplatesFields_Details_Create;
  TaskTemplatesFields_details_Delete: TaskTemplatesFields_Details_Delete;
  TaskTemplatesFields_details_Read: TaskTemplatesFields_Details_Read;
  TaskTemplatesFields_details_Update: TaskTemplatesFields_Details_Update;
  TaskTemplatesFields_feature: TaskTemplatesFields_Feature;
  TaskTemplatesFields_feature_Create: TaskTemplatesFields_Feature_Create;
  TaskTemplatesFields_feature_Delete: TaskTemplatesFields_Feature_Delete;
  TaskTemplatesFields_feature_Read: TaskTemplatesFields_Feature_Read;
  TaskTemplatesFields_feature_Update: TaskTemplatesFields_Feature_Update;
  TaskTemplatesFields_resources: TaskTemplatesFields_Resources;
  TaskTemplatesFields_resources_Create: TaskTemplatesFields_Resources_Create;
  TaskTemplatesFields_resources_Delete: TaskTemplatesFields_Resources_Delete;
  TaskTemplatesFields_resources_Fields: TaskTemplatesFields_Resources_Fields;
  TaskTemplatesFields_resources_Read: TaskTemplatesFields_Resources_Read;
  TaskTemplatesFields_resources_Update: TaskTemplatesFields_Resources_Update;
  TaskTemplatesFields_resources_articles: TaskTemplatesFields_Resources_Articles;
  TaskTemplatesFields_resources_articles_Create: TaskTemplatesFields_Resources_Articles_Create;
  TaskTemplatesFields_resources_articles_Delete: TaskTemplatesFields_Resources_Articles_Delete;
  TaskTemplatesFields_resources_articles_Read: TaskTemplatesFields_Resources_Articles_Read;
  TaskTemplatesFields_resources_articles_Update: TaskTemplatesFields_Resources_Articles_Update;
  TaskTemplatesFields_resources_videos: TaskTemplatesFields_Resources_Videos;
  TaskTemplatesFields_resources_videos_Create: TaskTemplatesFields_Resources_Videos_Create;
  TaskTemplatesFields_resources_videos_Delete: TaskTemplatesFields_Resources_Videos_Delete;
  TaskTemplatesFields_resources_videos_Read: TaskTemplatesFields_Resources_Videos_Read;
  TaskTemplatesFields_resources_videos_Update: TaskTemplatesFields_Resources_Videos_Update;
  TaskTemplatesFields_review: TaskTemplatesFields_Review;
  TaskTemplatesFields_review_Create: TaskTemplatesFields_Review_Create;
  TaskTemplatesFields_review_Delete: TaskTemplatesFields_Review_Delete;
  TaskTemplatesFields_review_Fields: TaskTemplatesFields_Review_Fields;
  TaskTemplatesFields_review_Read: TaskTemplatesFields_Review_Read;
  TaskTemplatesFields_review_Update: TaskTemplatesFields_Review_Update;
  TaskTemplatesFields_review_approver: TaskTemplatesFields_Review_Approver;
  TaskTemplatesFields_review_approver_Create: TaskTemplatesFields_Review_Approver_Create;
  TaskTemplatesFields_review_approver_Delete: TaskTemplatesFields_Review_Approver_Delete;
  TaskTemplatesFields_review_approver_Read: TaskTemplatesFields_Review_Approver_Read;
  TaskTemplatesFields_review_approver_Update: TaskTemplatesFields_Review_Approver_Update;
  TaskTemplatesFields_review_evidence: TaskTemplatesFields_Review_Evidence;
  TaskTemplatesFields_review_evidence_Create: TaskTemplatesFields_Review_Evidence_Create;
  TaskTemplatesFields_review_evidence_Delete: TaskTemplatesFields_Review_Evidence_Delete;
  TaskTemplatesFields_review_evidence_Read: TaskTemplatesFields_Review_Evidence_Read;
  TaskTemplatesFields_review_evidence_Update: TaskTemplatesFields_Review_Evidence_Update;
  TaskTemplatesFields_review_required: TaskTemplatesFields_Review_Required;
  TaskTemplatesFields_review_required_Create: TaskTemplatesFields_Review_Required_Create;
  TaskTemplatesFields_review_required_Delete: TaskTemplatesFields_Review_Required_Delete;
  TaskTemplatesFields_review_required_Read: TaskTemplatesFields_Review_Required_Read;
  TaskTemplatesFields_review_required_Update: TaskTemplatesFields_Review_Required_Update;
  TaskTemplatesFields_schedule: TaskTemplatesFields_Schedule;
  TaskTemplatesFields_schedule_Create: TaskTemplatesFields_Schedule_Create;
  TaskTemplatesFields_schedule_Delete: TaskTemplatesFields_Schedule_Delete;
  TaskTemplatesFields_schedule_Fields: TaskTemplatesFields_Schedule_Fields;
  TaskTemplatesFields_schedule_Read: TaskTemplatesFields_Schedule_Read;
  TaskTemplatesFields_schedule_Update: TaskTemplatesFields_Schedule_Update;
  TaskTemplatesFields_schedule_due: TaskTemplatesFields_Schedule_Due;
  TaskTemplatesFields_schedule_due_Create: TaskTemplatesFields_Schedule_Due_Create;
  TaskTemplatesFields_schedule_due_Delete: TaskTemplatesFields_Schedule_Due_Delete;
  TaskTemplatesFields_schedule_due_Read: TaskTemplatesFields_Schedule_Due_Read;
  TaskTemplatesFields_schedule_due_Update: TaskTemplatesFields_Schedule_Due_Update;
  TaskTemplatesFields_schedule_event: TaskTemplatesFields_Schedule_Event;
  TaskTemplatesFields_schedule_event_Create: TaskTemplatesFields_Schedule_Event_Create;
  TaskTemplatesFields_schedule_event_Delete: TaskTemplatesFields_Schedule_Event_Delete;
  TaskTemplatesFields_schedule_event_Read: TaskTemplatesFields_Schedule_Event_Read;
  TaskTemplatesFields_schedule_event_Update: TaskTemplatesFields_Schedule_Event_Update;
  TaskTemplatesFields_schedule_freq: TaskTemplatesFields_Schedule_Freq;
  TaskTemplatesFields_schedule_freq_Create: TaskTemplatesFields_Schedule_Freq_Create;
  TaskTemplatesFields_schedule_freq_Delete: TaskTemplatesFields_Schedule_Freq_Delete;
  TaskTemplatesFields_schedule_freq_Read: TaskTemplatesFields_Schedule_Freq_Read;
  TaskTemplatesFields_schedule_freq_Update: TaskTemplatesFields_Schedule_Freq_Update;
  TaskTemplatesFields_schedule_id: TaskTemplatesFields_Schedule_Id;
  TaskTemplatesFields_schedule_id_Create: TaskTemplatesFields_Schedule_Id_Create;
  TaskTemplatesFields_schedule_id_Delete: TaskTemplatesFields_Schedule_Id_Delete;
  TaskTemplatesFields_schedule_id_Read: TaskTemplatesFields_Schedule_Id_Read;
  TaskTemplatesFields_schedule_id_Update: TaskTemplatesFields_Schedule_Id_Update;
  TaskTemplatesFields_schedule_interval: TaskTemplatesFields_Schedule_Interval;
  TaskTemplatesFields_schedule_interval_Create: TaskTemplatesFields_Schedule_Interval_Create;
  TaskTemplatesFields_schedule_interval_Delete: TaskTemplatesFields_Schedule_Interval_Delete;
  TaskTemplatesFields_schedule_interval_Read: TaskTemplatesFields_Schedule_Interval_Read;
  TaskTemplatesFields_schedule_interval_Update: TaskTemplatesFields_Schedule_Interval_Update;
  TaskTemplatesFields_thumbnail: TaskTemplatesFields_Thumbnail;
  TaskTemplatesFields_thumbnail_Create: TaskTemplatesFields_Thumbnail_Create;
  TaskTemplatesFields_thumbnail_Delete: TaskTemplatesFields_Thumbnail_Delete;
  TaskTemplatesFields_thumbnail_Read: TaskTemplatesFields_Thumbnail_Read;
  TaskTemplatesFields_thumbnail_Update: TaskTemplatesFields_Thumbnail_Update;
  TaskTemplatesFields_title: TaskTemplatesFields_Title;
  TaskTemplatesFields_title_Create: TaskTemplatesFields_Title_Create;
  TaskTemplatesFields_title_Delete: TaskTemplatesFields_Title_Delete;
  TaskTemplatesFields_title_Read: TaskTemplatesFields_Title_Read;
  TaskTemplatesFields_title_Update: TaskTemplatesFields_Title_Update;
  TaskTemplatesFields_updatedAt: TaskTemplatesFields_UpdatedAt;
  TaskTemplatesFields_updatedAt_Create: TaskTemplatesFields_UpdatedAt_Create;
  TaskTemplatesFields_updatedAt_Delete: TaskTemplatesFields_UpdatedAt_Delete;
  TaskTemplatesFields_updatedAt_Read: TaskTemplatesFields_UpdatedAt_Read;
  TaskTemplatesFields_updatedAt_Update: TaskTemplatesFields_UpdatedAt_Update;
  TaskTemplatesReadAccess: TaskTemplatesReadAccess;
  TaskTemplatesReadDocAccess: TaskTemplatesReadDocAccess;
  TaskTemplatesReadVersionsAccess: TaskTemplatesReadVersionsAccess;
  TaskTemplatesReadVersionsDocAccess: TaskTemplatesReadVersionsDocAccess;
  TaskTemplatesUpdateAccess: TaskTemplatesUpdateAccess;
  TaskTemplatesUpdateDocAccess: TaskTemplatesUpdateDocAccess;
  User: User;
  User_createdAt_operator: User_CreatedAt_Operator;
  User_email_operator: User_Email_Operator;
  User_id_operator: User_Id_Operator;
  User_name_operator: User_Name_Operator;
  User_roles_operator: User_Roles_Operator;
  User_updatedAt_operator: User_UpdatedAt_Operator;
  User_where: User_Where;
  User_where_and: User_Where_And;
  User_where_or: User_Where_Or;
  Users: Users;
  UsersCreateAccess: UsersCreateAccess;
  UsersCreateDocAccess: UsersCreateDocAccess;
  UsersDeleteAccess: UsersDeleteAccess;
  UsersDeleteDocAccess: UsersDeleteDocAccess;
  UsersDocAccessFields: UsersDocAccessFields;
  UsersDocAccessFields_createdAt: UsersDocAccessFields_CreatedAt;
  UsersDocAccessFields_createdAt_Create: UsersDocAccessFields_CreatedAt_Create;
  UsersDocAccessFields_createdAt_Delete: UsersDocAccessFields_CreatedAt_Delete;
  UsersDocAccessFields_createdAt_Read: UsersDocAccessFields_CreatedAt_Read;
  UsersDocAccessFields_createdAt_Update: UsersDocAccessFields_CreatedAt_Update;
  UsersDocAccessFields_email: UsersDocAccessFields_Email;
  UsersDocAccessFields_email_Create: UsersDocAccessFields_Email_Create;
  UsersDocAccessFields_email_Delete: UsersDocAccessFields_Email_Delete;
  UsersDocAccessFields_email_Read: UsersDocAccessFields_Email_Read;
  UsersDocAccessFields_email_Update: UsersDocAccessFields_Email_Update;
  UsersDocAccessFields_name: UsersDocAccessFields_Name;
  UsersDocAccessFields_name_Create: UsersDocAccessFields_Name_Create;
  UsersDocAccessFields_name_Delete: UsersDocAccessFields_Name_Delete;
  UsersDocAccessFields_name_Read: UsersDocAccessFields_Name_Read;
  UsersDocAccessFields_name_Update: UsersDocAccessFields_Name_Update;
  UsersDocAccessFields_password: UsersDocAccessFields_Password;
  UsersDocAccessFields_password_Create: UsersDocAccessFields_Password_Create;
  UsersDocAccessFields_password_Delete: UsersDocAccessFields_Password_Delete;
  UsersDocAccessFields_password_Read: UsersDocAccessFields_Password_Read;
  UsersDocAccessFields_password_Update: UsersDocAccessFields_Password_Update;
  UsersDocAccessFields_roles: UsersDocAccessFields_Roles;
  UsersDocAccessFields_roles_Create: UsersDocAccessFields_Roles_Create;
  UsersDocAccessFields_roles_Delete: UsersDocAccessFields_Roles_Delete;
  UsersDocAccessFields_roles_Read: UsersDocAccessFields_Roles_Read;
  UsersDocAccessFields_roles_Update: UsersDocAccessFields_Roles_Update;
  UsersDocAccessFields_updatedAt: UsersDocAccessFields_UpdatedAt;
  UsersDocAccessFields_updatedAt_Create: UsersDocAccessFields_UpdatedAt_Create;
  UsersDocAccessFields_updatedAt_Delete: UsersDocAccessFields_UpdatedAt_Delete;
  UsersDocAccessFields_updatedAt_Read: UsersDocAccessFields_UpdatedAt_Read;
  UsersDocAccessFields_updatedAt_Update: UsersDocAccessFields_UpdatedAt_Update;
  UsersFields: UsersFields;
  UsersFields_createdAt: UsersFields_CreatedAt;
  UsersFields_createdAt_Create: UsersFields_CreatedAt_Create;
  UsersFields_createdAt_Delete: UsersFields_CreatedAt_Delete;
  UsersFields_createdAt_Read: UsersFields_CreatedAt_Read;
  UsersFields_createdAt_Update: UsersFields_CreatedAt_Update;
  UsersFields_email: UsersFields_Email;
  UsersFields_email_Create: UsersFields_Email_Create;
  UsersFields_email_Delete: UsersFields_Email_Delete;
  UsersFields_email_Read: UsersFields_Email_Read;
  UsersFields_email_Update: UsersFields_Email_Update;
  UsersFields_name: UsersFields_Name;
  UsersFields_name_Create: UsersFields_Name_Create;
  UsersFields_name_Delete: UsersFields_Name_Delete;
  UsersFields_name_Read: UsersFields_Name_Read;
  UsersFields_name_Update: UsersFields_Name_Update;
  UsersFields_password: UsersFields_Password;
  UsersFields_password_Create: UsersFields_Password_Create;
  UsersFields_password_Delete: UsersFields_Password_Delete;
  UsersFields_password_Read: UsersFields_Password_Read;
  UsersFields_password_Update: UsersFields_Password_Update;
  UsersFields_roles: UsersFields_Roles;
  UsersFields_roles_Create: UsersFields_Roles_Create;
  UsersFields_roles_Delete: UsersFields_Roles_Delete;
  UsersFields_roles_Read: UsersFields_Roles_Read;
  UsersFields_roles_Update: UsersFields_Roles_Update;
  UsersFields_updatedAt: UsersFields_UpdatedAt;
  UsersFields_updatedAt_Create: UsersFields_UpdatedAt_Create;
  UsersFields_updatedAt_Delete: UsersFields_UpdatedAt_Delete;
  UsersFields_updatedAt_Read: UsersFields_UpdatedAt_Read;
  UsersFields_updatedAt_Update: UsersFields_UpdatedAt_Update;
  UsersReadAccess: UsersReadAccess;
  UsersReadDocAccess: UsersReadDocAccess;
  UsersUnlockAccess: UsersUnlockAccess;
  UsersUnlockDocAccess: UsersUnlockDocAccess;
  UsersUpdateAccess: UsersUpdateAccess;
  UsersUpdateDocAccess: UsersUpdateDocAccess;
  allMedia: AllMedia;
  articlesAccess: ArticlesAccess;
  articlesDocAccess: ArticlesDocAccess;
  categoriesAccess: CategoriesAccess;
  categoriesDocAccess: CategoriesDocAccess;
  mediaAccess: MediaAccess;
  mediaDocAccess: MediaDocAccess;
  mutationArticleInput: MutationArticleInput;
  mutationArticleUpdateInput: MutationArticleUpdateInput;
  mutationArticleUpdate_HeroInput: MutationArticleUpdate_HeroInput;
  mutationArticleUpdate_MetaInput: MutationArticleUpdate_MetaInput;
  mutationArticleUpdate_PopulatedAuthorInput: MutationArticleUpdate_PopulatedAuthorInput;
  mutationArticle_HeroInput: MutationArticle_HeroInput;
  mutationArticle_MetaInput: MutationArticle_MetaInput;
  mutationArticle_PopulatedAuthorInput: MutationArticle_PopulatedAuthorInput;
  mutationCategoryInput: MutationCategoryInput;
  mutationCategoryUpdateInput: MutationCategoryUpdateInput;
  mutationCategoryUpdate_BreadcrumbsInput: MutationCategoryUpdate_BreadcrumbsInput;
  mutationCategory_BreadcrumbsInput: MutationCategory_BreadcrumbsInput;
  mutationMediaInput: MutationMediaInput;
  mutationMediaUpdateInput: MutationMediaUpdateInput;
  mutationPayloadPreferenceInput: MutationPayloadPreferenceInput;
  mutationPayloadPreferenceUpdateInput: MutationPayloadPreferenceUpdateInput;
  mutationTaskTemplateInput: MutationTaskTemplateInput;
  mutationTaskTemplateUpdateInput: MutationTaskTemplateUpdateInput;
  mutationTaskTemplateUpdate_ResourcesInput: MutationTaskTemplateUpdate_ResourcesInput;
  mutationTaskTemplateUpdate_ReviewInput: MutationTaskTemplateUpdate_ReviewInput;
  mutationTaskTemplateUpdate_ScheduleInput: MutationTaskTemplateUpdate_ScheduleInput;
  mutationTaskTemplateUpdate_Schedule_RecurrenceInput: MutationTaskTemplateUpdate_Schedule_RecurrenceInput;
  mutationTaskTemplate_ResourcesInput: MutationTaskTemplate_ResourcesInput;
  mutationTaskTemplate_ReviewInput: MutationTaskTemplate_ReviewInput;
  mutationTaskTemplate_ScheduleInput: MutationTaskTemplate_ScheduleInput;
  mutationTaskTemplate_Schedule_RecurrenceInput: MutationTaskTemplate_Schedule_RecurrenceInput;
  mutationUserInput: MutationUserInput;
  mutationUserUpdateInput: MutationUserUpdateInput;
  payload_preferencesAccess: Payload_PreferencesAccess;
  payload_preferencesDocAccess: Payload_PreferencesDocAccess;
  task_templatesAccess: Task_TemplatesAccess;
  task_templatesDocAccess: Task_TemplatesDocAccess;
  usersAccess: UsersAccess;
  usersDocAccess: UsersDocAccess;
  usersJWT: UsersJwt;
  usersLoginResult: UsersLoginResult;
  usersMe: UsersMe;
  usersRefreshedUser: UsersRefreshedUser;
  usersResetPassword: UsersResetPassword;
  versionsArticle_createdAt_operator: VersionsArticle_CreatedAt_Operator;
  versionsArticle_id_operator: VersionsArticle_Id_Operator;
  versionsArticle_latest_operator: VersionsArticle_Latest_Operator;
  versionsArticle_parent_operator: VersionsArticle_Parent_Operator;
  versionsArticle_updatedAt_operator: VersionsArticle_UpdatedAt_Operator;
  versionsArticle_version___status_operator: VersionsArticle_Version___Status_Operator;
  versionsArticle_version__author_operator: VersionsArticle_Version__Author_Operator;
  versionsArticle_version__categories_operator: VersionsArticle_Version__Categories_Operator;
  versionsArticle_version__createdAt_operator: VersionsArticle_Version__CreatedAt_Operator;
  versionsArticle_version__hero__media_operator: VersionsArticle_Version__Hero__Media_Operator;
  versionsArticle_version__hero__richText_operator: VersionsArticle_Version__Hero__RichText_Operator;
  versionsArticle_version__meta__description_operator: VersionsArticle_Version__Meta__Description_Operator;
  versionsArticle_version__meta__image_operator: VersionsArticle_Version__Meta__Image_Operator;
  versionsArticle_version__meta__title_operator: VersionsArticle_Version__Meta__Title_Operator;
  versionsArticle_version__populatedAuthor__id_operator: VersionsArticle_Version__PopulatedAuthor__Id_Operator;
  versionsArticle_version__populatedAuthor__name_operator: VersionsArticle_Version__PopulatedAuthor__Name_Operator;
  versionsArticle_version__publishedAt_operator: VersionsArticle_Version__PublishedAt_Operator;
  versionsArticle_version__relatedArticles_operator: VersionsArticle_Version__RelatedArticles_Operator;
  versionsArticle_version__slug_operator: VersionsArticle_Version__Slug_Operator;
  versionsArticle_version__title_operator: VersionsArticle_Version__Title_Operator;
  versionsArticle_version__updatedAt_operator: VersionsArticle_Version__UpdatedAt_Operator;
  versionsArticle_where: VersionsArticle_Where;
  versionsArticle_where_and: VersionsArticle_Where_And;
  versionsArticle_where_or: VersionsArticle_Where_Or;
  versionsArticles: VersionsArticles;
  versionsTaskTemplate_createdAt_operator: VersionsTaskTemplate_CreatedAt_Operator;
  versionsTaskTemplate_id_operator: VersionsTaskTemplate_Id_Operator;
  versionsTaskTemplate_latest_operator: VersionsTaskTemplate_Latest_Operator;
  versionsTaskTemplate_parent_operator: VersionsTaskTemplate_Parent_Operator;
  versionsTaskTemplate_recurrence__freq_operator: VersionsTaskTemplate_Recurrence__Freq_Operator;
  versionsTaskTemplate_recurrence__interval_operator: VersionsTaskTemplate_Recurrence__Interval_Operator;
  versionsTaskTemplate_updatedAt_operator: VersionsTaskTemplate_UpdatedAt_Operator;
  versionsTaskTemplate_version___status_operator: VersionsTaskTemplate_Version___Status_Operator;
  versionsTaskTemplate_version__assignment_operator: VersionsTaskTemplate_Version__Assignment_Operator;
  versionsTaskTemplate_version__categories_operator: VersionsTaskTemplate_Version__Categories_Operator;
  versionsTaskTemplate_version__createdAt_operator: VersionsTaskTemplate_Version__CreatedAt_Operator;
  versionsTaskTemplate_version__description_operator: VersionsTaskTemplate_Version__Description_Operator;
  versionsTaskTemplate_version__details_operator: VersionsTaskTemplate_Version__Details_Operator;
  versionsTaskTemplate_version__feature_operator: VersionsTaskTemplate_Version__Feature_Operator;
  versionsTaskTemplate_version__resources__articles_operator: VersionsTaskTemplate_Version__Resources__Articles_Operator;
  versionsTaskTemplate_version__resources__videos_operator: VersionsTaskTemplate_Version__Resources__Videos_Operator;
  versionsTaskTemplate_version__review__approver_operator: VersionsTaskTemplate_Version__Review__Approver_Operator;
  versionsTaskTemplate_version__review__evidence_operator: VersionsTaskTemplate_Version__Review__Evidence_Operator;
  versionsTaskTemplate_version__review__required_operator: VersionsTaskTemplate_Version__Review__Required_Operator;
  versionsTaskTemplate_version__schedule__due_operator: VersionsTaskTemplate_Version__Schedule__Due_Operator;
  versionsTaskTemplate_version__schedule__event_operator: VersionsTaskTemplate_Version__Schedule__Event_Operator;
  versionsTaskTemplate_version__schedule__id_operator: VersionsTaskTemplate_Version__Schedule__Id_Operator;
  versionsTaskTemplate_version__thumbnail_operator: VersionsTaskTemplate_Version__Thumbnail_Operator;
  versionsTaskTemplate_version__title_operator: VersionsTaskTemplate_Version__Title_Operator;
  versionsTaskTemplate_version__updatedAt_operator: VersionsTaskTemplate_Version__UpdatedAt_Operator;
  versionsTaskTemplate_where: VersionsTaskTemplate_Where;
  versionsTaskTemplate_where_and: VersionsTaskTemplate_Where_And;
  versionsTaskTemplate_where_or: VersionsTaskTemplate_Where_Or;
  versionsTaskTemplates: VersionsTaskTemplates;
};

export type AccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['Access'] = ResolversParentTypes['Access']> = {
  articles?: Resolver<Maybe<ResolversTypes['articlesAccess']>, ParentType, ContextType>;
  canAccessAdmin?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  categories?: Resolver<Maybe<ResolversTypes['categoriesAccess']>, ParentType, ContextType>;
  media?: Resolver<Maybe<ResolversTypes['mediaAccess']>, ParentType, ContextType>;
  payload_preferences?: Resolver<Maybe<ResolversTypes['payload_preferencesAccess']>, ParentType, ContextType>;
  task_templates?: Resolver<Maybe<ResolversTypes['task_templatesAccess']>, ParentType, ContextType>;
  users?: Resolver<Maybe<ResolversTypes['usersAccess']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticleResolvers<ContextType = any, ParentType extends ResolversParentTypes['Article'] = ResolversParentTypes['Article']> = {
  _status?: Resolver<Maybe<ResolversTypes['Article__status']>, ParentType, ContextType>;
  author?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  categories?: Resolver<Maybe<Array<ResolversTypes['Category']>>, ParentType, ContextType>;
  content?: Resolver<Array<ResolversTypes['Article_Content']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  hero?: Resolver<Maybe<ResolversTypes['Article_Hero']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  meta?: Resolver<Maybe<ResolversTypes['Article_Meta']>, ParentType, ContextType>;
  populatedAuthor?: Resolver<Maybe<ResolversTypes['Article_PopulatedAuthor']>, ParentType, ContextType>;
  publishedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  relatedArticles?: Resolver<Maybe<Array<ResolversTypes['Article']>>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticleVersionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticleVersion'] = ResolversParentTypes['ArticleVersion']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  latest?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  parent?: Resolver<Maybe<ResolversTypes['Article']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['ArticleVersion_Version']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticleVersion_VersionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticleVersion_Version'] = ResolversParentTypes['ArticleVersion_Version']> = {
  _status?: Resolver<Maybe<ResolversTypes['ArticleVersion_Version__status']>, ParentType, ContextType>;
  author?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  categories?: Resolver<Maybe<Array<ResolversTypes['Category']>>, ParentType, ContextType>;
  content?: Resolver<Array<ResolversTypes['ArticleVersion_Version_Content']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  hero?: Resolver<Maybe<ResolversTypes['ArticleVersion_Version_Hero']>, ParentType, ContextType>;
  meta?: Resolver<Maybe<ResolversTypes['ArticleVersion_Version_Meta']>, ParentType, ContextType>;
  populatedAuthor?: Resolver<Maybe<ResolversTypes['ArticleVersion_Version_PopulatedAuthor']>, ParentType, ContextType>;
  publishedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  relatedArticles?: Resolver<Maybe<Array<ResolversTypes['Article']>>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticleVersion_Version_ContentResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticleVersion_Version_Content'] = ResolversParentTypes['ArticleVersion_Version_Content']> = {
  __resolveType: TypeResolveFn<'Content' | 'Cta' | 'MediaBlock', ParentType, ContextType>;
};

export type ArticleVersion_Version_HeroResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticleVersion_Version_Hero'] = ResolversParentTypes['ArticleVersion_Version_Hero']> = {
  media?: Resolver<Maybe<ResolversTypes['Media']>, ParentType, ContextType, Partial<ArticleVersion_Version_HeroMediaArgs>>;
  richText?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType, Partial<ArticleVersion_Version_HeroRichTextArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticleVersion_Version_MetaResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticleVersion_Version_Meta'] = ResolversParentTypes['ArticleVersion_Version_Meta']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['Media']>, ParentType, ContextType, Partial<ArticleVersion_Version_MetaImageArgs>>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticleVersion_Version_PopulatedAuthorResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticleVersion_Version_PopulatedAuthor'] = ResolversParentTypes['ArticleVersion_Version_PopulatedAuthor']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Article_ContentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Article_Content'] = ResolversParentTypes['Article_Content']> = {
  __resolveType: TypeResolveFn<'Content' | 'Cta' | 'MediaBlock', ParentType, ContextType>;
};

export type Article_HeroResolvers<ContextType = any, ParentType extends ResolversParentTypes['Article_Hero'] = ResolversParentTypes['Article_Hero']> = {
  media?: Resolver<Maybe<ResolversTypes['Media']>, ParentType, ContextType, Partial<Article_HeroMediaArgs>>;
  richText?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType, Partial<Article_HeroRichTextArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Article_MetaResolvers<ContextType = any, ParentType extends ResolversParentTypes['Article_Meta'] = ResolversParentTypes['Article_Meta']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['Media']>, ParentType, ContextType, Partial<Article_MetaImageArgs>>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Article_PopulatedAuthorResolvers<ContextType = any, ParentType extends ResolversParentTypes['Article_PopulatedAuthor'] = ResolversParentTypes['Article_PopulatedAuthor']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesResolvers<ContextType = any, ParentType extends ResolversParentTypes['Articles'] = ResolversParentTypes['Articles']> = {
  docs?: Resolver<Maybe<Array<Maybe<ResolversTypes['Article']>>>, ParentType, ContextType>;
  hasNextPage?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasPrevPage?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  limit?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  nextPage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  offset?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  page?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pagingCounter?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  prevPage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalDocs?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalPages?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesCreateAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesCreateAccess'] = ResolversParentTypes['ArticlesCreateAccess']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  where?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesCreateDocAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesCreateDocAccess'] = ResolversParentTypes['ArticlesCreateDocAccess']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  where?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDeleteAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDeleteAccess'] = ResolversParentTypes['ArticlesDeleteAccess']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  where?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDeleteDocAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDeleteDocAccess'] = ResolversParentTypes['ArticlesDeleteDocAccess']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  where?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields'] = ResolversParentTypes['ArticlesDocAccessFields']> = {
  _status?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields__status']>, ParentType, ContextType>;
  author?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_author']>, ParentType, ContextType>;
  categories?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_categories']>, ParentType, ContextType>;
  content?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_content']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_createdAt']>, ParentType, ContextType>;
  hero?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_hero']>, ParentType, ContextType>;
  meta?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_meta']>, ParentType, ContextType>;
  populatedAuthor?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_populatedAuthor']>, ParentType, ContextType>;
  publishedAt?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_publishedAt']>, ParentType, ContextType>;
  relatedArticles?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_relatedArticles']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_slug']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_title']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_updatedAt']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields__StatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields__status'] = ResolversParentTypes['ArticlesDocAccessFields__status']> = {
  create?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields__status_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields__status_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields__status_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields__status_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields__Status_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields__status_Create'] = ResolversParentTypes['ArticlesDocAccessFields__status_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields__Status_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields__status_Delete'] = ResolversParentTypes['ArticlesDocAccessFields__status_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields__Status_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields__status_Read'] = ResolversParentTypes['ArticlesDocAccessFields__status_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields__Status_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields__status_Update'] = ResolversParentTypes['ArticlesDocAccessFields__status_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_AuthorResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_author'] = ResolversParentTypes['ArticlesDocAccessFields_author']> = {
  create?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_author_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_author_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_author_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_author_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_Author_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_author_Create'] = ResolversParentTypes['ArticlesDocAccessFields_author_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_Author_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_author_Delete'] = ResolversParentTypes['ArticlesDocAccessFields_author_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_Author_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_author_Read'] = ResolversParentTypes['ArticlesDocAccessFields_author_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_Author_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_author_Update'] = ResolversParentTypes['ArticlesDocAccessFields_author_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_CategoriesResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_categories'] = ResolversParentTypes['ArticlesDocAccessFields_categories']> = {
  create?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_categories_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_categories_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_categories_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_categories_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_Categories_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_categories_Create'] = ResolversParentTypes['ArticlesDocAccessFields_categories_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_Categories_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_categories_Delete'] = ResolversParentTypes['ArticlesDocAccessFields_categories_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_Categories_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_categories_Read'] = ResolversParentTypes['ArticlesDocAccessFields_categories_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_Categories_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_categories_Update'] = ResolversParentTypes['ArticlesDocAccessFields_categories_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_ContentResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_content'] = ResolversParentTypes['ArticlesDocAccessFields_content']> = {
  create?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_content_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_content_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_content_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_content_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_Content_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_content_Create'] = ResolversParentTypes['ArticlesDocAccessFields_content_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_Content_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_content_Delete'] = ResolversParentTypes['ArticlesDocAccessFields_content_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_Content_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_content_Read'] = ResolversParentTypes['ArticlesDocAccessFields_content_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_Content_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_content_Update'] = ResolversParentTypes['ArticlesDocAccessFields_content_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_CreatedAtResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_createdAt'] = ResolversParentTypes['ArticlesDocAccessFields_createdAt']> = {
  create?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_createdAt_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_createdAt_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_createdAt_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_createdAt_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_CreatedAt_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_createdAt_Create'] = ResolversParentTypes['ArticlesDocAccessFields_createdAt_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_CreatedAt_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_createdAt_Delete'] = ResolversParentTypes['ArticlesDocAccessFields_createdAt_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_CreatedAt_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_createdAt_Read'] = ResolversParentTypes['ArticlesDocAccessFields_createdAt_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_CreatedAt_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_createdAt_Update'] = ResolversParentTypes['ArticlesDocAccessFields_createdAt_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_HeroResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_hero'] = ResolversParentTypes['ArticlesDocAccessFields_hero']> = {
  create?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_hero_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_hero_Delete']>, ParentType, ContextType>;
  fields?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_hero_Fields']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_hero_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_hero_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_Hero_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_hero_Create'] = ResolversParentTypes['ArticlesDocAccessFields_hero_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_Hero_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_hero_Delete'] = ResolversParentTypes['ArticlesDocAccessFields_hero_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_Hero_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_hero_Fields'] = ResolversParentTypes['ArticlesDocAccessFields_hero_Fields']> = {
  media?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_hero_media']>, ParentType, ContextType>;
  richText?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_hero_richText']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_Hero_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_hero_Read'] = ResolversParentTypes['ArticlesDocAccessFields_hero_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_Hero_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_hero_Update'] = ResolversParentTypes['ArticlesDocAccessFields_hero_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_Hero_MediaResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_hero_media'] = ResolversParentTypes['ArticlesDocAccessFields_hero_media']> = {
  create?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_hero_media_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_hero_media_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_hero_media_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_hero_media_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_Hero_Media_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_hero_media_Create'] = ResolversParentTypes['ArticlesDocAccessFields_hero_media_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_Hero_Media_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_hero_media_Delete'] = ResolversParentTypes['ArticlesDocAccessFields_hero_media_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_Hero_Media_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_hero_media_Read'] = ResolversParentTypes['ArticlesDocAccessFields_hero_media_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_Hero_Media_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_hero_media_Update'] = ResolversParentTypes['ArticlesDocAccessFields_hero_media_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_Hero_RichTextResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_hero_richText'] = ResolversParentTypes['ArticlesDocAccessFields_hero_richText']> = {
  create?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_hero_richText_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_hero_richText_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_hero_richText_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_hero_richText_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_Hero_RichText_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_hero_richText_Create'] = ResolversParentTypes['ArticlesDocAccessFields_hero_richText_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_Hero_RichText_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_hero_richText_Delete'] = ResolversParentTypes['ArticlesDocAccessFields_hero_richText_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_Hero_RichText_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_hero_richText_Read'] = ResolversParentTypes['ArticlesDocAccessFields_hero_richText_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_Hero_RichText_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_hero_richText_Update'] = ResolversParentTypes['ArticlesDocAccessFields_hero_richText_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_MetaResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_meta'] = ResolversParentTypes['ArticlesDocAccessFields_meta']> = {
  create?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_meta_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_meta_Delete']>, ParentType, ContextType>;
  fields?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_meta_Fields']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_meta_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_meta_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_Meta_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_meta_Create'] = ResolversParentTypes['ArticlesDocAccessFields_meta_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_Meta_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_meta_Delete'] = ResolversParentTypes['ArticlesDocAccessFields_meta_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_Meta_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_meta_Fields'] = ResolversParentTypes['ArticlesDocAccessFields_meta_Fields']> = {
  description?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_meta_description']>, ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_meta_image']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_meta_title']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_Meta_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_meta_Read'] = ResolversParentTypes['ArticlesDocAccessFields_meta_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_Meta_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_meta_Update'] = ResolversParentTypes['ArticlesDocAccessFields_meta_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_Meta_DescriptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_meta_description'] = ResolversParentTypes['ArticlesDocAccessFields_meta_description']> = {
  create?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_meta_description_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_meta_description_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_meta_description_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_meta_description_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_Meta_Description_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_meta_description_Create'] = ResolversParentTypes['ArticlesDocAccessFields_meta_description_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_Meta_Description_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_meta_description_Delete'] = ResolversParentTypes['ArticlesDocAccessFields_meta_description_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_Meta_Description_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_meta_description_Read'] = ResolversParentTypes['ArticlesDocAccessFields_meta_description_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_Meta_Description_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_meta_description_Update'] = ResolversParentTypes['ArticlesDocAccessFields_meta_description_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_Meta_ImageResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_meta_image'] = ResolversParentTypes['ArticlesDocAccessFields_meta_image']> = {
  create?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_meta_image_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_meta_image_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_meta_image_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_meta_image_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_Meta_Image_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_meta_image_Create'] = ResolversParentTypes['ArticlesDocAccessFields_meta_image_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_Meta_Image_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_meta_image_Delete'] = ResolversParentTypes['ArticlesDocAccessFields_meta_image_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_Meta_Image_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_meta_image_Read'] = ResolversParentTypes['ArticlesDocAccessFields_meta_image_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_Meta_Image_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_meta_image_Update'] = ResolversParentTypes['ArticlesDocAccessFields_meta_image_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_Meta_TitleResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_meta_title'] = ResolversParentTypes['ArticlesDocAccessFields_meta_title']> = {
  create?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_meta_title_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_meta_title_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_meta_title_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_meta_title_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_Meta_Title_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_meta_title_Create'] = ResolversParentTypes['ArticlesDocAccessFields_meta_title_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_Meta_Title_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_meta_title_Delete'] = ResolversParentTypes['ArticlesDocAccessFields_meta_title_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_Meta_Title_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_meta_title_Read'] = ResolversParentTypes['ArticlesDocAccessFields_meta_title_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_Meta_Title_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_meta_title_Update'] = ResolversParentTypes['ArticlesDocAccessFields_meta_title_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_PopulatedAuthorResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_populatedAuthor'] = ResolversParentTypes['ArticlesDocAccessFields_populatedAuthor']> = {
  create?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_populatedAuthor_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_populatedAuthor_Delete']>, ParentType, ContextType>;
  fields?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_populatedAuthor_Fields']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_populatedAuthor_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_populatedAuthor_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_PopulatedAuthor_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_populatedAuthor_Create'] = ResolversParentTypes['ArticlesDocAccessFields_populatedAuthor_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_PopulatedAuthor_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_populatedAuthor_Delete'] = ResolversParentTypes['ArticlesDocAccessFields_populatedAuthor_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_PopulatedAuthor_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_populatedAuthor_Fields'] = ResolversParentTypes['ArticlesDocAccessFields_populatedAuthor_Fields']> = {
  id?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_populatedAuthor_id']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_populatedAuthor_name']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_PopulatedAuthor_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_populatedAuthor_Read'] = ResolversParentTypes['ArticlesDocAccessFields_populatedAuthor_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_PopulatedAuthor_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_populatedAuthor_Update'] = ResolversParentTypes['ArticlesDocAccessFields_populatedAuthor_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_PopulatedAuthor_IdResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_populatedAuthor_id'] = ResolversParentTypes['ArticlesDocAccessFields_populatedAuthor_id']> = {
  create?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_populatedAuthor_id_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_populatedAuthor_id_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_populatedAuthor_id_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_populatedAuthor_id_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_PopulatedAuthor_Id_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_populatedAuthor_id_Create'] = ResolversParentTypes['ArticlesDocAccessFields_populatedAuthor_id_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_PopulatedAuthor_Id_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_populatedAuthor_id_Delete'] = ResolversParentTypes['ArticlesDocAccessFields_populatedAuthor_id_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_PopulatedAuthor_Id_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_populatedAuthor_id_Read'] = ResolversParentTypes['ArticlesDocAccessFields_populatedAuthor_id_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_PopulatedAuthor_Id_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_populatedAuthor_id_Update'] = ResolversParentTypes['ArticlesDocAccessFields_populatedAuthor_id_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_PopulatedAuthor_NameResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_populatedAuthor_name'] = ResolversParentTypes['ArticlesDocAccessFields_populatedAuthor_name']> = {
  create?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_populatedAuthor_name_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_populatedAuthor_name_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_populatedAuthor_name_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_populatedAuthor_name_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_PopulatedAuthor_Name_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_populatedAuthor_name_Create'] = ResolversParentTypes['ArticlesDocAccessFields_populatedAuthor_name_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_PopulatedAuthor_Name_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_populatedAuthor_name_Delete'] = ResolversParentTypes['ArticlesDocAccessFields_populatedAuthor_name_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_PopulatedAuthor_Name_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_populatedAuthor_name_Read'] = ResolversParentTypes['ArticlesDocAccessFields_populatedAuthor_name_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_PopulatedAuthor_Name_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_populatedAuthor_name_Update'] = ResolversParentTypes['ArticlesDocAccessFields_populatedAuthor_name_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_PublishedAtResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_publishedAt'] = ResolversParentTypes['ArticlesDocAccessFields_publishedAt']> = {
  create?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_publishedAt_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_publishedAt_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_publishedAt_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_publishedAt_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_PublishedAt_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_publishedAt_Create'] = ResolversParentTypes['ArticlesDocAccessFields_publishedAt_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_PublishedAt_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_publishedAt_Delete'] = ResolversParentTypes['ArticlesDocAccessFields_publishedAt_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_PublishedAt_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_publishedAt_Read'] = ResolversParentTypes['ArticlesDocAccessFields_publishedAt_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_PublishedAt_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_publishedAt_Update'] = ResolversParentTypes['ArticlesDocAccessFields_publishedAt_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_RelatedArticlesResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_relatedArticles'] = ResolversParentTypes['ArticlesDocAccessFields_relatedArticles']> = {
  create?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_relatedArticles_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_relatedArticles_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_relatedArticles_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_relatedArticles_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_RelatedArticles_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_relatedArticles_Create'] = ResolversParentTypes['ArticlesDocAccessFields_relatedArticles_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_RelatedArticles_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_relatedArticles_Delete'] = ResolversParentTypes['ArticlesDocAccessFields_relatedArticles_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_RelatedArticles_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_relatedArticles_Read'] = ResolversParentTypes['ArticlesDocAccessFields_relatedArticles_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_RelatedArticles_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_relatedArticles_Update'] = ResolversParentTypes['ArticlesDocAccessFields_relatedArticles_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_SlugResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_slug'] = ResolversParentTypes['ArticlesDocAccessFields_slug']> = {
  create?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_slug_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_slug_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_slug_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_slug_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_Slug_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_slug_Create'] = ResolversParentTypes['ArticlesDocAccessFields_slug_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_Slug_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_slug_Delete'] = ResolversParentTypes['ArticlesDocAccessFields_slug_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_Slug_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_slug_Read'] = ResolversParentTypes['ArticlesDocAccessFields_slug_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_Slug_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_slug_Update'] = ResolversParentTypes['ArticlesDocAccessFields_slug_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_TitleResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_title'] = ResolversParentTypes['ArticlesDocAccessFields_title']> = {
  create?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_title_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_title_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_title_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_title_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_Title_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_title_Create'] = ResolversParentTypes['ArticlesDocAccessFields_title_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_Title_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_title_Delete'] = ResolversParentTypes['ArticlesDocAccessFields_title_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_Title_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_title_Read'] = ResolversParentTypes['ArticlesDocAccessFields_title_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_Title_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_title_Update'] = ResolversParentTypes['ArticlesDocAccessFields_title_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_UpdatedAtResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_updatedAt'] = ResolversParentTypes['ArticlesDocAccessFields_updatedAt']> = {
  create?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_updatedAt_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_updatedAt_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_updatedAt_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields_updatedAt_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_UpdatedAt_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_updatedAt_Create'] = ResolversParentTypes['ArticlesDocAccessFields_updatedAt_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_UpdatedAt_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_updatedAt_Delete'] = ResolversParentTypes['ArticlesDocAccessFields_updatedAt_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_UpdatedAt_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_updatedAt_Read'] = ResolversParentTypes['ArticlesDocAccessFields_updatedAt_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessFields_UpdatedAt_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesDocAccessFields_updatedAt_Update'] = ResolversParentTypes['ArticlesDocAccessFields_updatedAt_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields'] = ResolversParentTypes['ArticlesFields']> = {
  _status?: Resolver<Maybe<ResolversTypes['ArticlesFields__status']>, ParentType, ContextType>;
  author?: Resolver<Maybe<ResolversTypes['ArticlesFields_author']>, ParentType, ContextType>;
  categories?: Resolver<Maybe<ResolversTypes['ArticlesFields_categories']>, ParentType, ContextType>;
  content?: Resolver<Maybe<ResolversTypes['ArticlesFields_content']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['ArticlesFields_createdAt']>, ParentType, ContextType>;
  hero?: Resolver<Maybe<ResolversTypes['ArticlesFields_hero']>, ParentType, ContextType>;
  meta?: Resolver<Maybe<ResolversTypes['ArticlesFields_meta']>, ParentType, ContextType>;
  populatedAuthor?: Resolver<Maybe<ResolversTypes['ArticlesFields_populatedAuthor']>, ParentType, ContextType>;
  publishedAt?: Resolver<Maybe<ResolversTypes['ArticlesFields_publishedAt']>, ParentType, ContextType>;
  relatedArticles?: Resolver<Maybe<ResolversTypes['ArticlesFields_relatedArticles']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['ArticlesFields_slug']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['ArticlesFields_title']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['ArticlesFields_updatedAt']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields__StatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields__status'] = ResolversParentTypes['ArticlesFields__status']> = {
  create?: Resolver<Maybe<ResolversTypes['ArticlesFields__status_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['ArticlesFields__status_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['ArticlesFields__status_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['ArticlesFields__status_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields__Status_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields__status_Create'] = ResolversParentTypes['ArticlesFields__status_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields__Status_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields__status_Delete'] = ResolversParentTypes['ArticlesFields__status_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields__Status_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields__status_Read'] = ResolversParentTypes['ArticlesFields__status_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields__Status_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields__status_Update'] = ResolversParentTypes['ArticlesFields__status_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_AuthorResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_author'] = ResolversParentTypes['ArticlesFields_author']> = {
  create?: Resolver<Maybe<ResolversTypes['ArticlesFields_author_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['ArticlesFields_author_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['ArticlesFields_author_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['ArticlesFields_author_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_Author_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_author_Create'] = ResolversParentTypes['ArticlesFields_author_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_Author_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_author_Delete'] = ResolversParentTypes['ArticlesFields_author_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_Author_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_author_Read'] = ResolversParentTypes['ArticlesFields_author_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_Author_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_author_Update'] = ResolversParentTypes['ArticlesFields_author_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_CategoriesResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_categories'] = ResolversParentTypes['ArticlesFields_categories']> = {
  create?: Resolver<Maybe<ResolversTypes['ArticlesFields_categories_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['ArticlesFields_categories_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['ArticlesFields_categories_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['ArticlesFields_categories_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_Categories_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_categories_Create'] = ResolversParentTypes['ArticlesFields_categories_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_Categories_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_categories_Delete'] = ResolversParentTypes['ArticlesFields_categories_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_Categories_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_categories_Read'] = ResolversParentTypes['ArticlesFields_categories_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_Categories_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_categories_Update'] = ResolversParentTypes['ArticlesFields_categories_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_ContentResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_content'] = ResolversParentTypes['ArticlesFields_content']> = {
  create?: Resolver<Maybe<ResolversTypes['ArticlesFields_content_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['ArticlesFields_content_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['ArticlesFields_content_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['ArticlesFields_content_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_Content_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_content_Create'] = ResolversParentTypes['ArticlesFields_content_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_Content_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_content_Delete'] = ResolversParentTypes['ArticlesFields_content_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_Content_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_content_Read'] = ResolversParentTypes['ArticlesFields_content_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_Content_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_content_Update'] = ResolversParentTypes['ArticlesFields_content_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_CreatedAtResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_createdAt'] = ResolversParentTypes['ArticlesFields_createdAt']> = {
  create?: Resolver<Maybe<ResolversTypes['ArticlesFields_createdAt_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['ArticlesFields_createdAt_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['ArticlesFields_createdAt_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['ArticlesFields_createdAt_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_CreatedAt_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_createdAt_Create'] = ResolversParentTypes['ArticlesFields_createdAt_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_CreatedAt_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_createdAt_Delete'] = ResolversParentTypes['ArticlesFields_createdAt_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_CreatedAt_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_createdAt_Read'] = ResolversParentTypes['ArticlesFields_createdAt_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_CreatedAt_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_createdAt_Update'] = ResolversParentTypes['ArticlesFields_createdAt_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_HeroResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_hero'] = ResolversParentTypes['ArticlesFields_hero']> = {
  create?: Resolver<Maybe<ResolversTypes['ArticlesFields_hero_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['ArticlesFields_hero_Delete']>, ParentType, ContextType>;
  fields?: Resolver<Maybe<ResolversTypes['ArticlesFields_hero_Fields']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['ArticlesFields_hero_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['ArticlesFields_hero_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_Hero_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_hero_Create'] = ResolversParentTypes['ArticlesFields_hero_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_Hero_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_hero_Delete'] = ResolversParentTypes['ArticlesFields_hero_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_Hero_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_hero_Fields'] = ResolversParentTypes['ArticlesFields_hero_Fields']> = {
  media?: Resolver<Maybe<ResolversTypes['ArticlesFields_hero_media']>, ParentType, ContextType>;
  richText?: Resolver<Maybe<ResolversTypes['ArticlesFields_hero_richText']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_Hero_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_hero_Read'] = ResolversParentTypes['ArticlesFields_hero_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_Hero_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_hero_Update'] = ResolversParentTypes['ArticlesFields_hero_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_Hero_MediaResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_hero_media'] = ResolversParentTypes['ArticlesFields_hero_media']> = {
  create?: Resolver<Maybe<ResolversTypes['ArticlesFields_hero_media_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['ArticlesFields_hero_media_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['ArticlesFields_hero_media_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['ArticlesFields_hero_media_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_Hero_Media_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_hero_media_Create'] = ResolversParentTypes['ArticlesFields_hero_media_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_Hero_Media_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_hero_media_Delete'] = ResolversParentTypes['ArticlesFields_hero_media_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_Hero_Media_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_hero_media_Read'] = ResolversParentTypes['ArticlesFields_hero_media_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_Hero_Media_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_hero_media_Update'] = ResolversParentTypes['ArticlesFields_hero_media_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_Hero_RichTextResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_hero_richText'] = ResolversParentTypes['ArticlesFields_hero_richText']> = {
  create?: Resolver<Maybe<ResolversTypes['ArticlesFields_hero_richText_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['ArticlesFields_hero_richText_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['ArticlesFields_hero_richText_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['ArticlesFields_hero_richText_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_Hero_RichText_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_hero_richText_Create'] = ResolversParentTypes['ArticlesFields_hero_richText_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_Hero_RichText_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_hero_richText_Delete'] = ResolversParentTypes['ArticlesFields_hero_richText_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_Hero_RichText_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_hero_richText_Read'] = ResolversParentTypes['ArticlesFields_hero_richText_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_Hero_RichText_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_hero_richText_Update'] = ResolversParentTypes['ArticlesFields_hero_richText_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_MetaResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_meta'] = ResolversParentTypes['ArticlesFields_meta']> = {
  create?: Resolver<Maybe<ResolversTypes['ArticlesFields_meta_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['ArticlesFields_meta_Delete']>, ParentType, ContextType>;
  fields?: Resolver<Maybe<ResolversTypes['ArticlesFields_meta_Fields']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['ArticlesFields_meta_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['ArticlesFields_meta_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_Meta_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_meta_Create'] = ResolversParentTypes['ArticlesFields_meta_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_Meta_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_meta_Delete'] = ResolversParentTypes['ArticlesFields_meta_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_Meta_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_meta_Fields'] = ResolversParentTypes['ArticlesFields_meta_Fields']> = {
  description?: Resolver<Maybe<ResolversTypes['ArticlesFields_meta_description']>, ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['ArticlesFields_meta_image']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['ArticlesFields_meta_title']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_Meta_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_meta_Read'] = ResolversParentTypes['ArticlesFields_meta_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_Meta_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_meta_Update'] = ResolversParentTypes['ArticlesFields_meta_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_Meta_DescriptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_meta_description'] = ResolversParentTypes['ArticlesFields_meta_description']> = {
  create?: Resolver<Maybe<ResolversTypes['ArticlesFields_meta_description_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['ArticlesFields_meta_description_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['ArticlesFields_meta_description_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['ArticlesFields_meta_description_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_Meta_Description_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_meta_description_Create'] = ResolversParentTypes['ArticlesFields_meta_description_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_Meta_Description_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_meta_description_Delete'] = ResolversParentTypes['ArticlesFields_meta_description_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_Meta_Description_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_meta_description_Read'] = ResolversParentTypes['ArticlesFields_meta_description_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_Meta_Description_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_meta_description_Update'] = ResolversParentTypes['ArticlesFields_meta_description_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_Meta_ImageResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_meta_image'] = ResolversParentTypes['ArticlesFields_meta_image']> = {
  create?: Resolver<Maybe<ResolversTypes['ArticlesFields_meta_image_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['ArticlesFields_meta_image_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['ArticlesFields_meta_image_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['ArticlesFields_meta_image_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_Meta_Image_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_meta_image_Create'] = ResolversParentTypes['ArticlesFields_meta_image_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_Meta_Image_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_meta_image_Delete'] = ResolversParentTypes['ArticlesFields_meta_image_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_Meta_Image_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_meta_image_Read'] = ResolversParentTypes['ArticlesFields_meta_image_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_Meta_Image_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_meta_image_Update'] = ResolversParentTypes['ArticlesFields_meta_image_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_Meta_TitleResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_meta_title'] = ResolversParentTypes['ArticlesFields_meta_title']> = {
  create?: Resolver<Maybe<ResolversTypes['ArticlesFields_meta_title_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['ArticlesFields_meta_title_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['ArticlesFields_meta_title_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['ArticlesFields_meta_title_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_Meta_Title_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_meta_title_Create'] = ResolversParentTypes['ArticlesFields_meta_title_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_Meta_Title_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_meta_title_Delete'] = ResolversParentTypes['ArticlesFields_meta_title_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_Meta_Title_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_meta_title_Read'] = ResolversParentTypes['ArticlesFields_meta_title_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_Meta_Title_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_meta_title_Update'] = ResolversParentTypes['ArticlesFields_meta_title_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_PopulatedAuthorResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_populatedAuthor'] = ResolversParentTypes['ArticlesFields_populatedAuthor']> = {
  create?: Resolver<Maybe<ResolversTypes['ArticlesFields_populatedAuthor_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['ArticlesFields_populatedAuthor_Delete']>, ParentType, ContextType>;
  fields?: Resolver<Maybe<ResolversTypes['ArticlesFields_populatedAuthor_Fields']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['ArticlesFields_populatedAuthor_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['ArticlesFields_populatedAuthor_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_PopulatedAuthor_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_populatedAuthor_Create'] = ResolversParentTypes['ArticlesFields_populatedAuthor_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_PopulatedAuthor_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_populatedAuthor_Delete'] = ResolversParentTypes['ArticlesFields_populatedAuthor_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_PopulatedAuthor_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_populatedAuthor_Fields'] = ResolversParentTypes['ArticlesFields_populatedAuthor_Fields']> = {
  id?: Resolver<Maybe<ResolversTypes['ArticlesFields_populatedAuthor_id']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['ArticlesFields_populatedAuthor_name']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_PopulatedAuthor_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_populatedAuthor_Read'] = ResolversParentTypes['ArticlesFields_populatedAuthor_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_PopulatedAuthor_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_populatedAuthor_Update'] = ResolversParentTypes['ArticlesFields_populatedAuthor_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_PopulatedAuthor_IdResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_populatedAuthor_id'] = ResolversParentTypes['ArticlesFields_populatedAuthor_id']> = {
  create?: Resolver<Maybe<ResolversTypes['ArticlesFields_populatedAuthor_id_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['ArticlesFields_populatedAuthor_id_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['ArticlesFields_populatedAuthor_id_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['ArticlesFields_populatedAuthor_id_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_PopulatedAuthor_Id_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_populatedAuthor_id_Create'] = ResolversParentTypes['ArticlesFields_populatedAuthor_id_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_PopulatedAuthor_Id_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_populatedAuthor_id_Delete'] = ResolversParentTypes['ArticlesFields_populatedAuthor_id_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_PopulatedAuthor_Id_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_populatedAuthor_id_Read'] = ResolversParentTypes['ArticlesFields_populatedAuthor_id_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_PopulatedAuthor_Id_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_populatedAuthor_id_Update'] = ResolversParentTypes['ArticlesFields_populatedAuthor_id_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_PopulatedAuthor_NameResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_populatedAuthor_name'] = ResolversParentTypes['ArticlesFields_populatedAuthor_name']> = {
  create?: Resolver<Maybe<ResolversTypes['ArticlesFields_populatedAuthor_name_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['ArticlesFields_populatedAuthor_name_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['ArticlesFields_populatedAuthor_name_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['ArticlesFields_populatedAuthor_name_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_PopulatedAuthor_Name_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_populatedAuthor_name_Create'] = ResolversParentTypes['ArticlesFields_populatedAuthor_name_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_PopulatedAuthor_Name_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_populatedAuthor_name_Delete'] = ResolversParentTypes['ArticlesFields_populatedAuthor_name_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_PopulatedAuthor_Name_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_populatedAuthor_name_Read'] = ResolversParentTypes['ArticlesFields_populatedAuthor_name_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_PopulatedAuthor_Name_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_populatedAuthor_name_Update'] = ResolversParentTypes['ArticlesFields_populatedAuthor_name_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_PublishedAtResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_publishedAt'] = ResolversParentTypes['ArticlesFields_publishedAt']> = {
  create?: Resolver<Maybe<ResolversTypes['ArticlesFields_publishedAt_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['ArticlesFields_publishedAt_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['ArticlesFields_publishedAt_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['ArticlesFields_publishedAt_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_PublishedAt_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_publishedAt_Create'] = ResolversParentTypes['ArticlesFields_publishedAt_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_PublishedAt_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_publishedAt_Delete'] = ResolversParentTypes['ArticlesFields_publishedAt_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_PublishedAt_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_publishedAt_Read'] = ResolversParentTypes['ArticlesFields_publishedAt_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_PublishedAt_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_publishedAt_Update'] = ResolversParentTypes['ArticlesFields_publishedAt_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_RelatedArticlesResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_relatedArticles'] = ResolversParentTypes['ArticlesFields_relatedArticles']> = {
  create?: Resolver<Maybe<ResolversTypes['ArticlesFields_relatedArticles_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['ArticlesFields_relatedArticles_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['ArticlesFields_relatedArticles_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['ArticlesFields_relatedArticles_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_RelatedArticles_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_relatedArticles_Create'] = ResolversParentTypes['ArticlesFields_relatedArticles_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_RelatedArticles_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_relatedArticles_Delete'] = ResolversParentTypes['ArticlesFields_relatedArticles_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_RelatedArticles_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_relatedArticles_Read'] = ResolversParentTypes['ArticlesFields_relatedArticles_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_RelatedArticles_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_relatedArticles_Update'] = ResolversParentTypes['ArticlesFields_relatedArticles_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_SlugResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_slug'] = ResolversParentTypes['ArticlesFields_slug']> = {
  create?: Resolver<Maybe<ResolversTypes['ArticlesFields_slug_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['ArticlesFields_slug_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['ArticlesFields_slug_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['ArticlesFields_slug_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_Slug_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_slug_Create'] = ResolversParentTypes['ArticlesFields_slug_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_Slug_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_slug_Delete'] = ResolversParentTypes['ArticlesFields_slug_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_Slug_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_slug_Read'] = ResolversParentTypes['ArticlesFields_slug_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_Slug_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_slug_Update'] = ResolversParentTypes['ArticlesFields_slug_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_TitleResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_title'] = ResolversParentTypes['ArticlesFields_title']> = {
  create?: Resolver<Maybe<ResolversTypes['ArticlesFields_title_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['ArticlesFields_title_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['ArticlesFields_title_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['ArticlesFields_title_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_Title_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_title_Create'] = ResolversParentTypes['ArticlesFields_title_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_Title_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_title_Delete'] = ResolversParentTypes['ArticlesFields_title_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_Title_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_title_Read'] = ResolversParentTypes['ArticlesFields_title_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_Title_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_title_Update'] = ResolversParentTypes['ArticlesFields_title_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_UpdatedAtResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_updatedAt'] = ResolversParentTypes['ArticlesFields_updatedAt']> = {
  create?: Resolver<Maybe<ResolversTypes['ArticlesFields_updatedAt_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['ArticlesFields_updatedAt_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['ArticlesFields_updatedAt_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['ArticlesFields_updatedAt_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_UpdatedAt_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_updatedAt_Create'] = ResolversParentTypes['ArticlesFields_updatedAt_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_UpdatedAt_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_updatedAt_Delete'] = ResolversParentTypes['ArticlesFields_updatedAt_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_UpdatedAt_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_updatedAt_Read'] = ResolversParentTypes['ArticlesFields_updatedAt_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesFields_UpdatedAt_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesFields_updatedAt_Update'] = ResolversParentTypes['ArticlesFields_updatedAt_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesReadAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesReadAccess'] = ResolversParentTypes['ArticlesReadAccess']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  where?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesReadDocAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesReadDocAccess'] = ResolversParentTypes['ArticlesReadDocAccess']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  where?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesReadVersionsAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesReadVersionsAccess'] = ResolversParentTypes['ArticlesReadVersionsAccess']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  where?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesReadVersionsDocAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesReadVersionsDocAccess'] = ResolversParentTypes['ArticlesReadVersionsDocAccess']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  where?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesUpdateAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesUpdateAccess'] = ResolversParentTypes['ArticlesUpdateAccess']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  where?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesUpdateDocAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArticlesUpdateDocAccess'] = ResolversParentTypes['ArticlesUpdateDocAccess']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  where?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesResolvers<ContextType = any, ParentType extends ResolversParentTypes['Categories'] = ResolversParentTypes['Categories']> = {
  docs?: Resolver<Maybe<Array<Maybe<ResolversTypes['Category']>>>, ParentType, ContextType>;
  hasNextPage?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasPrevPage?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  limit?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  nextPage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  offset?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  page?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pagingCounter?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  prevPage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalDocs?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalPages?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesCreateAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesCreateAccess'] = ResolversParentTypes['CategoriesCreateAccess']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  where?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesCreateDocAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesCreateDocAccess'] = ResolversParentTypes['CategoriesCreateDocAccess']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  where?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesDeleteAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesDeleteAccess'] = ResolversParentTypes['CategoriesDeleteAccess']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  where?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesDeleteDocAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesDeleteDocAccess'] = ResolversParentTypes['CategoriesDeleteDocAccess']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  where?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesDocAccessFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesDocAccessFields'] = ResolversParentTypes['CategoriesDocAccessFields']> = {
  breadcrumbs?: Resolver<Maybe<ResolversTypes['CategoriesDocAccessFields_breadcrumbs']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['CategoriesDocAccessFields_createdAt']>, ParentType, ContextType>;
  parent?: Resolver<Maybe<ResolversTypes['CategoriesDocAccessFields_parent']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['CategoriesDocAccessFields_title']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['CategoriesDocAccessFields_updatedAt']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesDocAccessFields_BreadcrumbsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesDocAccessFields_breadcrumbs'] = ResolversParentTypes['CategoriesDocAccessFields_breadcrumbs']> = {
  create?: Resolver<Maybe<ResolversTypes['CategoriesDocAccessFields_breadcrumbs_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['CategoriesDocAccessFields_breadcrumbs_Delete']>, ParentType, ContextType>;
  fields?: Resolver<Maybe<ResolversTypes['CategoriesDocAccessFields_breadcrumbs_Fields']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['CategoriesDocAccessFields_breadcrumbs_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['CategoriesDocAccessFields_breadcrumbs_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesDocAccessFields_Breadcrumbs_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesDocAccessFields_breadcrumbs_Create'] = ResolversParentTypes['CategoriesDocAccessFields_breadcrumbs_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesDocAccessFields_Breadcrumbs_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesDocAccessFields_breadcrumbs_Delete'] = ResolversParentTypes['CategoriesDocAccessFields_breadcrumbs_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesDocAccessFields_Breadcrumbs_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesDocAccessFields_breadcrumbs_Fields'] = ResolversParentTypes['CategoriesDocAccessFields_breadcrumbs_Fields']> = {
  doc?: Resolver<Maybe<ResolversTypes['CategoriesDocAccessFields_breadcrumbs_doc']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['CategoriesDocAccessFields_breadcrumbs_id']>, ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['CategoriesDocAccessFields_breadcrumbs_label']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['CategoriesDocAccessFields_breadcrumbs_url']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesDocAccessFields_Breadcrumbs_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesDocAccessFields_breadcrumbs_Read'] = ResolversParentTypes['CategoriesDocAccessFields_breadcrumbs_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesDocAccessFields_Breadcrumbs_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesDocAccessFields_breadcrumbs_Update'] = ResolversParentTypes['CategoriesDocAccessFields_breadcrumbs_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesDocAccessFields_Breadcrumbs_DocResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesDocAccessFields_breadcrumbs_doc'] = ResolversParentTypes['CategoriesDocAccessFields_breadcrumbs_doc']> = {
  create?: Resolver<Maybe<ResolversTypes['CategoriesDocAccessFields_breadcrumbs_doc_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['CategoriesDocAccessFields_breadcrumbs_doc_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['CategoriesDocAccessFields_breadcrumbs_doc_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['CategoriesDocAccessFields_breadcrumbs_doc_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesDocAccessFields_Breadcrumbs_Doc_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesDocAccessFields_breadcrumbs_doc_Create'] = ResolversParentTypes['CategoriesDocAccessFields_breadcrumbs_doc_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesDocAccessFields_Breadcrumbs_Doc_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesDocAccessFields_breadcrumbs_doc_Delete'] = ResolversParentTypes['CategoriesDocAccessFields_breadcrumbs_doc_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesDocAccessFields_Breadcrumbs_Doc_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesDocAccessFields_breadcrumbs_doc_Read'] = ResolversParentTypes['CategoriesDocAccessFields_breadcrumbs_doc_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesDocAccessFields_Breadcrumbs_Doc_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesDocAccessFields_breadcrumbs_doc_Update'] = ResolversParentTypes['CategoriesDocAccessFields_breadcrumbs_doc_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesDocAccessFields_Breadcrumbs_IdResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesDocAccessFields_breadcrumbs_id'] = ResolversParentTypes['CategoriesDocAccessFields_breadcrumbs_id']> = {
  create?: Resolver<Maybe<ResolversTypes['CategoriesDocAccessFields_breadcrumbs_id_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['CategoriesDocAccessFields_breadcrumbs_id_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['CategoriesDocAccessFields_breadcrumbs_id_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['CategoriesDocAccessFields_breadcrumbs_id_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesDocAccessFields_Breadcrumbs_Id_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesDocAccessFields_breadcrumbs_id_Create'] = ResolversParentTypes['CategoriesDocAccessFields_breadcrumbs_id_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesDocAccessFields_Breadcrumbs_Id_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesDocAccessFields_breadcrumbs_id_Delete'] = ResolversParentTypes['CategoriesDocAccessFields_breadcrumbs_id_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesDocAccessFields_Breadcrumbs_Id_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesDocAccessFields_breadcrumbs_id_Read'] = ResolversParentTypes['CategoriesDocAccessFields_breadcrumbs_id_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesDocAccessFields_Breadcrumbs_Id_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesDocAccessFields_breadcrumbs_id_Update'] = ResolversParentTypes['CategoriesDocAccessFields_breadcrumbs_id_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesDocAccessFields_Breadcrumbs_LabelResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesDocAccessFields_breadcrumbs_label'] = ResolversParentTypes['CategoriesDocAccessFields_breadcrumbs_label']> = {
  create?: Resolver<Maybe<ResolversTypes['CategoriesDocAccessFields_breadcrumbs_label_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['CategoriesDocAccessFields_breadcrumbs_label_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['CategoriesDocAccessFields_breadcrumbs_label_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['CategoriesDocAccessFields_breadcrumbs_label_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesDocAccessFields_Breadcrumbs_Label_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesDocAccessFields_breadcrumbs_label_Create'] = ResolversParentTypes['CategoriesDocAccessFields_breadcrumbs_label_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesDocAccessFields_Breadcrumbs_Label_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesDocAccessFields_breadcrumbs_label_Delete'] = ResolversParentTypes['CategoriesDocAccessFields_breadcrumbs_label_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesDocAccessFields_Breadcrumbs_Label_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesDocAccessFields_breadcrumbs_label_Read'] = ResolversParentTypes['CategoriesDocAccessFields_breadcrumbs_label_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesDocAccessFields_Breadcrumbs_Label_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesDocAccessFields_breadcrumbs_label_Update'] = ResolversParentTypes['CategoriesDocAccessFields_breadcrumbs_label_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesDocAccessFields_Breadcrumbs_UrlResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesDocAccessFields_breadcrumbs_url'] = ResolversParentTypes['CategoriesDocAccessFields_breadcrumbs_url']> = {
  create?: Resolver<Maybe<ResolversTypes['CategoriesDocAccessFields_breadcrumbs_url_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['CategoriesDocAccessFields_breadcrumbs_url_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['CategoriesDocAccessFields_breadcrumbs_url_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['CategoriesDocAccessFields_breadcrumbs_url_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesDocAccessFields_Breadcrumbs_Url_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesDocAccessFields_breadcrumbs_url_Create'] = ResolversParentTypes['CategoriesDocAccessFields_breadcrumbs_url_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesDocAccessFields_Breadcrumbs_Url_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesDocAccessFields_breadcrumbs_url_Delete'] = ResolversParentTypes['CategoriesDocAccessFields_breadcrumbs_url_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesDocAccessFields_Breadcrumbs_Url_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesDocAccessFields_breadcrumbs_url_Read'] = ResolversParentTypes['CategoriesDocAccessFields_breadcrumbs_url_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesDocAccessFields_Breadcrumbs_Url_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesDocAccessFields_breadcrumbs_url_Update'] = ResolversParentTypes['CategoriesDocAccessFields_breadcrumbs_url_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesDocAccessFields_CreatedAtResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesDocAccessFields_createdAt'] = ResolversParentTypes['CategoriesDocAccessFields_createdAt']> = {
  create?: Resolver<Maybe<ResolversTypes['CategoriesDocAccessFields_createdAt_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['CategoriesDocAccessFields_createdAt_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['CategoriesDocAccessFields_createdAt_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['CategoriesDocAccessFields_createdAt_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesDocAccessFields_CreatedAt_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesDocAccessFields_createdAt_Create'] = ResolversParentTypes['CategoriesDocAccessFields_createdAt_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesDocAccessFields_CreatedAt_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesDocAccessFields_createdAt_Delete'] = ResolversParentTypes['CategoriesDocAccessFields_createdAt_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesDocAccessFields_CreatedAt_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesDocAccessFields_createdAt_Read'] = ResolversParentTypes['CategoriesDocAccessFields_createdAt_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesDocAccessFields_CreatedAt_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesDocAccessFields_createdAt_Update'] = ResolversParentTypes['CategoriesDocAccessFields_createdAt_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesDocAccessFields_ParentResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesDocAccessFields_parent'] = ResolversParentTypes['CategoriesDocAccessFields_parent']> = {
  create?: Resolver<Maybe<ResolversTypes['CategoriesDocAccessFields_parent_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['CategoriesDocAccessFields_parent_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['CategoriesDocAccessFields_parent_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['CategoriesDocAccessFields_parent_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesDocAccessFields_Parent_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesDocAccessFields_parent_Create'] = ResolversParentTypes['CategoriesDocAccessFields_parent_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesDocAccessFields_Parent_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesDocAccessFields_parent_Delete'] = ResolversParentTypes['CategoriesDocAccessFields_parent_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesDocAccessFields_Parent_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesDocAccessFields_parent_Read'] = ResolversParentTypes['CategoriesDocAccessFields_parent_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesDocAccessFields_Parent_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesDocAccessFields_parent_Update'] = ResolversParentTypes['CategoriesDocAccessFields_parent_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesDocAccessFields_TitleResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesDocAccessFields_title'] = ResolversParentTypes['CategoriesDocAccessFields_title']> = {
  create?: Resolver<Maybe<ResolversTypes['CategoriesDocAccessFields_title_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['CategoriesDocAccessFields_title_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['CategoriesDocAccessFields_title_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['CategoriesDocAccessFields_title_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesDocAccessFields_Title_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesDocAccessFields_title_Create'] = ResolversParentTypes['CategoriesDocAccessFields_title_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesDocAccessFields_Title_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesDocAccessFields_title_Delete'] = ResolversParentTypes['CategoriesDocAccessFields_title_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesDocAccessFields_Title_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesDocAccessFields_title_Read'] = ResolversParentTypes['CategoriesDocAccessFields_title_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesDocAccessFields_Title_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesDocAccessFields_title_Update'] = ResolversParentTypes['CategoriesDocAccessFields_title_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesDocAccessFields_UpdatedAtResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesDocAccessFields_updatedAt'] = ResolversParentTypes['CategoriesDocAccessFields_updatedAt']> = {
  create?: Resolver<Maybe<ResolversTypes['CategoriesDocAccessFields_updatedAt_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['CategoriesDocAccessFields_updatedAt_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['CategoriesDocAccessFields_updatedAt_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['CategoriesDocAccessFields_updatedAt_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesDocAccessFields_UpdatedAt_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesDocAccessFields_updatedAt_Create'] = ResolversParentTypes['CategoriesDocAccessFields_updatedAt_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesDocAccessFields_UpdatedAt_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesDocAccessFields_updatedAt_Delete'] = ResolversParentTypes['CategoriesDocAccessFields_updatedAt_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesDocAccessFields_UpdatedAt_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesDocAccessFields_updatedAt_Read'] = ResolversParentTypes['CategoriesDocAccessFields_updatedAt_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesDocAccessFields_UpdatedAt_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesDocAccessFields_updatedAt_Update'] = ResolversParentTypes['CategoriesDocAccessFields_updatedAt_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesFields'] = ResolversParentTypes['CategoriesFields']> = {
  breadcrumbs?: Resolver<Maybe<ResolversTypes['CategoriesFields_breadcrumbs']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['CategoriesFields_createdAt']>, ParentType, ContextType>;
  parent?: Resolver<Maybe<ResolversTypes['CategoriesFields_parent']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['CategoriesFields_title']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['CategoriesFields_updatedAt']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesFields_BreadcrumbsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesFields_breadcrumbs'] = ResolversParentTypes['CategoriesFields_breadcrumbs']> = {
  create?: Resolver<Maybe<ResolversTypes['CategoriesFields_breadcrumbs_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['CategoriesFields_breadcrumbs_Delete']>, ParentType, ContextType>;
  fields?: Resolver<Maybe<ResolversTypes['CategoriesFields_breadcrumbs_Fields']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['CategoriesFields_breadcrumbs_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['CategoriesFields_breadcrumbs_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesFields_Breadcrumbs_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesFields_breadcrumbs_Create'] = ResolversParentTypes['CategoriesFields_breadcrumbs_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesFields_Breadcrumbs_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesFields_breadcrumbs_Delete'] = ResolversParentTypes['CategoriesFields_breadcrumbs_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesFields_Breadcrumbs_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesFields_breadcrumbs_Fields'] = ResolversParentTypes['CategoriesFields_breadcrumbs_Fields']> = {
  doc?: Resolver<Maybe<ResolversTypes['CategoriesFields_breadcrumbs_doc']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['CategoriesFields_breadcrumbs_id']>, ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['CategoriesFields_breadcrumbs_label']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['CategoriesFields_breadcrumbs_url']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesFields_Breadcrumbs_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesFields_breadcrumbs_Read'] = ResolversParentTypes['CategoriesFields_breadcrumbs_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesFields_Breadcrumbs_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesFields_breadcrumbs_Update'] = ResolversParentTypes['CategoriesFields_breadcrumbs_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesFields_Breadcrumbs_DocResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesFields_breadcrumbs_doc'] = ResolversParentTypes['CategoriesFields_breadcrumbs_doc']> = {
  create?: Resolver<Maybe<ResolversTypes['CategoriesFields_breadcrumbs_doc_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['CategoriesFields_breadcrumbs_doc_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['CategoriesFields_breadcrumbs_doc_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['CategoriesFields_breadcrumbs_doc_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesFields_Breadcrumbs_Doc_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesFields_breadcrumbs_doc_Create'] = ResolversParentTypes['CategoriesFields_breadcrumbs_doc_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesFields_Breadcrumbs_Doc_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesFields_breadcrumbs_doc_Delete'] = ResolversParentTypes['CategoriesFields_breadcrumbs_doc_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesFields_Breadcrumbs_Doc_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesFields_breadcrumbs_doc_Read'] = ResolversParentTypes['CategoriesFields_breadcrumbs_doc_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesFields_Breadcrumbs_Doc_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesFields_breadcrumbs_doc_Update'] = ResolversParentTypes['CategoriesFields_breadcrumbs_doc_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesFields_Breadcrumbs_IdResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesFields_breadcrumbs_id'] = ResolversParentTypes['CategoriesFields_breadcrumbs_id']> = {
  create?: Resolver<Maybe<ResolversTypes['CategoriesFields_breadcrumbs_id_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['CategoriesFields_breadcrumbs_id_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['CategoriesFields_breadcrumbs_id_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['CategoriesFields_breadcrumbs_id_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesFields_Breadcrumbs_Id_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesFields_breadcrumbs_id_Create'] = ResolversParentTypes['CategoriesFields_breadcrumbs_id_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesFields_Breadcrumbs_Id_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesFields_breadcrumbs_id_Delete'] = ResolversParentTypes['CategoriesFields_breadcrumbs_id_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesFields_Breadcrumbs_Id_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesFields_breadcrumbs_id_Read'] = ResolversParentTypes['CategoriesFields_breadcrumbs_id_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesFields_Breadcrumbs_Id_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesFields_breadcrumbs_id_Update'] = ResolversParentTypes['CategoriesFields_breadcrumbs_id_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesFields_Breadcrumbs_LabelResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesFields_breadcrumbs_label'] = ResolversParentTypes['CategoriesFields_breadcrumbs_label']> = {
  create?: Resolver<Maybe<ResolversTypes['CategoriesFields_breadcrumbs_label_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['CategoriesFields_breadcrumbs_label_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['CategoriesFields_breadcrumbs_label_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['CategoriesFields_breadcrumbs_label_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesFields_Breadcrumbs_Label_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesFields_breadcrumbs_label_Create'] = ResolversParentTypes['CategoriesFields_breadcrumbs_label_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesFields_Breadcrumbs_Label_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesFields_breadcrumbs_label_Delete'] = ResolversParentTypes['CategoriesFields_breadcrumbs_label_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesFields_Breadcrumbs_Label_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesFields_breadcrumbs_label_Read'] = ResolversParentTypes['CategoriesFields_breadcrumbs_label_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesFields_Breadcrumbs_Label_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesFields_breadcrumbs_label_Update'] = ResolversParentTypes['CategoriesFields_breadcrumbs_label_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesFields_Breadcrumbs_UrlResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesFields_breadcrumbs_url'] = ResolversParentTypes['CategoriesFields_breadcrumbs_url']> = {
  create?: Resolver<Maybe<ResolversTypes['CategoriesFields_breadcrumbs_url_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['CategoriesFields_breadcrumbs_url_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['CategoriesFields_breadcrumbs_url_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['CategoriesFields_breadcrumbs_url_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesFields_Breadcrumbs_Url_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesFields_breadcrumbs_url_Create'] = ResolversParentTypes['CategoriesFields_breadcrumbs_url_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesFields_Breadcrumbs_Url_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesFields_breadcrumbs_url_Delete'] = ResolversParentTypes['CategoriesFields_breadcrumbs_url_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesFields_Breadcrumbs_Url_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesFields_breadcrumbs_url_Read'] = ResolversParentTypes['CategoriesFields_breadcrumbs_url_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesFields_Breadcrumbs_Url_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesFields_breadcrumbs_url_Update'] = ResolversParentTypes['CategoriesFields_breadcrumbs_url_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesFields_CreatedAtResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesFields_createdAt'] = ResolversParentTypes['CategoriesFields_createdAt']> = {
  create?: Resolver<Maybe<ResolversTypes['CategoriesFields_createdAt_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['CategoriesFields_createdAt_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['CategoriesFields_createdAt_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['CategoriesFields_createdAt_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesFields_CreatedAt_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesFields_createdAt_Create'] = ResolversParentTypes['CategoriesFields_createdAt_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesFields_CreatedAt_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesFields_createdAt_Delete'] = ResolversParentTypes['CategoriesFields_createdAt_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesFields_CreatedAt_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesFields_createdAt_Read'] = ResolversParentTypes['CategoriesFields_createdAt_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesFields_CreatedAt_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesFields_createdAt_Update'] = ResolversParentTypes['CategoriesFields_createdAt_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesFields_ParentResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesFields_parent'] = ResolversParentTypes['CategoriesFields_parent']> = {
  create?: Resolver<Maybe<ResolversTypes['CategoriesFields_parent_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['CategoriesFields_parent_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['CategoriesFields_parent_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['CategoriesFields_parent_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesFields_Parent_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesFields_parent_Create'] = ResolversParentTypes['CategoriesFields_parent_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesFields_Parent_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesFields_parent_Delete'] = ResolversParentTypes['CategoriesFields_parent_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesFields_Parent_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesFields_parent_Read'] = ResolversParentTypes['CategoriesFields_parent_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesFields_Parent_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesFields_parent_Update'] = ResolversParentTypes['CategoriesFields_parent_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesFields_TitleResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesFields_title'] = ResolversParentTypes['CategoriesFields_title']> = {
  create?: Resolver<Maybe<ResolversTypes['CategoriesFields_title_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['CategoriesFields_title_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['CategoriesFields_title_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['CategoriesFields_title_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesFields_Title_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesFields_title_Create'] = ResolversParentTypes['CategoriesFields_title_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesFields_Title_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesFields_title_Delete'] = ResolversParentTypes['CategoriesFields_title_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesFields_Title_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesFields_title_Read'] = ResolversParentTypes['CategoriesFields_title_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesFields_Title_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesFields_title_Update'] = ResolversParentTypes['CategoriesFields_title_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesFields_UpdatedAtResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesFields_updatedAt'] = ResolversParentTypes['CategoriesFields_updatedAt']> = {
  create?: Resolver<Maybe<ResolversTypes['CategoriesFields_updatedAt_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['CategoriesFields_updatedAt_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['CategoriesFields_updatedAt_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['CategoriesFields_updatedAt_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesFields_UpdatedAt_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesFields_updatedAt_Create'] = ResolversParentTypes['CategoriesFields_updatedAt_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesFields_UpdatedAt_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesFields_updatedAt_Delete'] = ResolversParentTypes['CategoriesFields_updatedAt_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesFields_UpdatedAt_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesFields_updatedAt_Read'] = ResolversParentTypes['CategoriesFields_updatedAt_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesFields_UpdatedAt_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesFields_updatedAt_Update'] = ResolversParentTypes['CategoriesFields_updatedAt_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesReadAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesReadAccess'] = ResolversParentTypes['CategoriesReadAccess']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  where?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesReadDocAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesReadDocAccess'] = ResolversParentTypes['CategoriesReadDocAccess']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  where?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesUpdateAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesUpdateAccess'] = ResolversParentTypes['CategoriesUpdateAccess']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  where?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesUpdateDocAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoriesUpdateDocAccess'] = ResolversParentTypes['CategoriesUpdateDocAccess']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  where?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Category'] = ResolversParentTypes['Category']> = {
  breadcrumbs?: Resolver<Maybe<Array<ResolversTypes['Category_Breadcrumbs']>>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  parent?: Resolver<Maybe<ResolversTypes['Category']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Category_BreadcrumbsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Category_Breadcrumbs'] = ResolversParentTypes['Category_Breadcrumbs']> = {
  doc?: Resolver<Maybe<ResolversTypes['Category']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Content'] = ResolversParentTypes['Content']> = {
  blockName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  blockType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  richText?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType, Partial<ContentRichTextArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CtaResolvers<ContextType = any, ParentType extends ResolversParentTypes['Cta'] = ResolversParentTypes['Cta']> = {
  blockName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  blockType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  links?: Resolver<Maybe<Array<ResolversTypes['Cta_Links']>>, ParentType, ContextType>;
  richText?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType, Partial<CtaRichTextArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Cta_LinksResolvers<ContextType = any, ParentType extends ResolversParentTypes['Cta_Links'] = ResolversParentTypes['Cta_Links']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  link?: Resolver<Maybe<ResolversTypes['Cta_Links_Link']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Cta_Links_LinkResolvers<ContextType = any, ParentType extends ResolversParentTypes['Cta_Links_Link'] = ResolversParentTypes['Cta_Links_Link']> = {
  appearance?: Resolver<Maybe<ResolversTypes['Cta_Links_Link_appearance']>, ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  newTab?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  reference?: Resolver<Maybe<ResolversTypes['Cta_Links_Link_Reference_Relationship']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['Cta_Links_Link_type']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Cta_Links_Link_ReferenceResolvers<ContextType = any, ParentType extends ResolversParentTypes['Cta_Links_Link_Reference'] = ResolversParentTypes['Cta_Links_Link_Reference']> = {
  __resolveType: TypeResolveFn<'Article', ParentType, ContextType>;
};

export type Cta_Links_Link_Reference_RelationshipResolvers<ContextType = any, ParentType extends ResolversParentTypes['Cta_Links_Link_Reference_Relationship'] = ResolversParentTypes['Cta_Links_Link_Reference_Relationship']> = {
  relationTo?: Resolver<Maybe<ResolversTypes['Cta_Links_Link_Reference_RelationTo']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Cta_Links_Link_Reference']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export interface EmailAddressScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['EmailAddress'], any> {
  name: 'EmailAddress';
}

export interface JsonScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['JSON'], any> {
  name: 'JSON';
}

export interface JsonObjectScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['JSONObject'], any> {
  name: 'JSONObject';
}

export type MediaResolvers<ContextType = any, ParentType extends ResolversParentTypes['Media'] = ResolversParentTypes['Media']> = {
  alt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  caption?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType, Partial<MediaCaptionArgs>>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  filename?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  filesize?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  height?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mimeType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  width?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaBlockResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaBlock'] = ResolversParentTypes['MediaBlock']> = {
  blockName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  blockType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  media?: Resolver<Maybe<ResolversTypes['Media']>, ParentType, ContextType, Partial<MediaBlockMediaArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaCreateAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaCreateAccess'] = ResolversParentTypes['MediaCreateAccess']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  where?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaCreateDocAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaCreateDocAccess'] = ResolversParentTypes['MediaCreateDocAccess']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  where?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaDeleteAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaDeleteAccess'] = ResolversParentTypes['MediaDeleteAccess']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  where?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaDeleteDocAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaDeleteDocAccess'] = ResolversParentTypes['MediaDeleteDocAccess']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  where?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaDocAccessFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaDocAccessFields'] = ResolversParentTypes['MediaDocAccessFields']> = {
  alt?: Resolver<Maybe<ResolversTypes['MediaDocAccessFields_alt']>, ParentType, ContextType>;
  caption?: Resolver<Maybe<ResolversTypes['MediaDocAccessFields_caption']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['MediaDocAccessFields_createdAt']>, ParentType, ContextType>;
  filename?: Resolver<Maybe<ResolversTypes['MediaDocAccessFields_filename']>, ParentType, ContextType>;
  filesize?: Resolver<Maybe<ResolversTypes['MediaDocAccessFields_filesize']>, ParentType, ContextType>;
  height?: Resolver<Maybe<ResolversTypes['MediaDocAccessFields_height']>, ParentType, ContextType>;
  mimeType?: Resolver<Maybe<ResolversTypes['MediaDocAccessFields_mimeType']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['MediaDocAccessFields_updatedAt']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['MediaDocAccessFields_url']>, ParentType, ContextType>;
  width?: Resolver<Maybe<ResolversTypes['MediaDocAccessFields_width']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaDocAccessFields_AltResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaDocAccessFields_alt'] = ResolversParentTypes['MediaDocAccessFields_alt']> = {
  create?: Resolver<Maybe<ResolversTypes['MediaDocAccessFields_alt_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['MediaDocAccessFields_alt_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['MediaDocAccessFields_alt_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['MediaDocAccessFields_alt_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaDocAccessFields_Alt_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaDocAccessFields_alt_Create'] = ResolversParentTypes['MediaDocAccessFields_alt_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaDocAccessFields_Alt_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaDocAccessFields_alt_Delete'] = ResolversParentTypes['MediaDocAccessFields_alt_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaDocAccessFields_Alt_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaDocAccessFields_alt_Read'] = ResolversParentTypes['MediaDocAccessFields_alt_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaDocAccessFields_Alt_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaDocAccessFields_alt_Update'] = ResolversParentTypes['MediaDocAccessFields_alt_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaDocAccessFields_CaptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaDocAccessFields_caption'] = ResolversParentTypes['MediaDocAccessFields_caption']> = {
  create?: Resolver<Maybe<ResolversTypes['MediaDocAccessFields_caption_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['MediaDocAccessFields_caption_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['MediaDocAccessFields_caption_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['MediaDocAccessFields_caption_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaDocAccessFields_Caption_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaDocAccessFields_caption_Create'] = ResolversParentTypes['MediaDocAccessFields_caption_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaDocAccessFields_Caption_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaDocAccessFields_caption_Delete'] = ResolversParentTypes['MediaDocAccessFields_caption_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaDocAccessFields_Caption_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaDocAccessFields_caption_Read'] = ResolversParentTypes['MediaDocAccessFields_caption_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaDocAccessFields_Caption_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaDocAccessFields_caption_Update'] = ResolversParentTypes['MediaDocAccessFields_caption_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaDocAccessFields_CreatedAtResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaDocAccessFields_createdAt'] = ResolversParentTypes['MediaDocAccessFields_createdAt']> = {
  create?: Resolver<Maybe<ResolversTypes['MediaDocAccessFields_createdAt_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['MediaDocAccessFields_createdAt_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['MediaDocAccessFields_createdAt_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['MediaDocAccessFields_createdAt_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaDocAccessFields_CreatedAt_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaDocAccessFields_createdAt_Create'] = ResolversParentTypes['MediaDocAccessFields_createdAt_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaDocAccessFields_CreatedAt_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaDocAccessFields_createdAt_Delete'] = ResolversParentTypes['MediaDocAccessFields_createdAt_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaDocAccessFields_CreatedAt_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaDocAccessFields_createdAt_Read'] = ResolversParentTypes['MediaDocAccessFields_createdAt_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaDocAccessFields_CreatedAt_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaDocAccessFields_createdAt_Update'] = ResolversParentTypes['MediaDocAccessFields_createdAt_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaDocAccessFields_FilenameResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaDocAccessFields_filename'] = ResolversParentTypes['MediaDocAccessFields_filename']> = {
  create?: Resolver<Maybe<ResolversTypes['MediaDocAccessFields_filename_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['MediaDocAccessFields_filename_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['MediaDocAccessFields_filename_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['MediaDocAccessFields_filename_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaDocAccessFields_Filename_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaDocAccessFields_filename_Create'] = ResolversParentTypes['MediaDocAccessFields_filename_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaDocAccessFields_Filename_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaDocAccessFields_filename_Delete'] = ResolversParentTypes['MediaDocAccessFields_filename_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaDocAccessFields_Filename_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaDocAccessFields_filename_Read'] = ResolversParentTypes['MediaDocAccessFields_filename_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaDocAccessFields_Filename_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaDocAccessFields_filename_Update'] = ResolversParentTypes['MediaDocAccessFields_filename_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaDocAccessFields_FilesizeResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaDocAccessFields_filesize'] = ResolversParentTypes['MediaDocAccessFields_filesize']> = {
  create?: Resolver<Maybe<ResolversTypes['MediaDocAccessFields_filesize_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['MediaDocAccessFields_filesize_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['MediaDocAccessFields_filesize_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['MediaDocAccessFields_filesize_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaDocAccessFields_Filesize_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaDocAccessFields_filesize_Create'] = ResolversParentTypes['MediaDocAccessFields_filesize_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaDocAccessFields_Filesize_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaDocAccessFields_filesize_Delete'] = ResolversParentTypes['MediaDocAccessFields_filesize_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaDocAccessFields_Filesize_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaDocAccessFields_filesize_Read'] = ResolversParentTypes['MediaDocAccessFields_filesize_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaDocAccessFields_Filesize_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaDocAccessFields_filesize_Update'] = ResolversParentTypes['MediaDocAccessFields_filesize_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaDocAccessFields_HeightResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaDocAccessFields_height'] = ResolversParentTypes['MediaDocAccessFields_height']> = {
  create?: Resolver<Maybe<ResolversTypes['MediaDocAccessFields_height_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['MediaDocAccessFields_height_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['MediaDocAccessFields_height_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['MediaDocAccessFields_height_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaDocAccessFields_Height_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaDocAccessFields_height_Create'] = ResolversParentTypes['MediaDocAccessFields_height_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaDocAccessFields_Height_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaDocAccessFields_height_Delete'] = ResolversParentTypes['MediaDocAccessFields_height_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaDocAccessFields_Height_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaDocAccessFields_height_Read'] = ResolversParentTypes['MediaDocAccessFields_height_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaDocAccessFields_Height_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaDocAccessFields_height_Update'] = ResolversParentTypes['MediaDocAccessFields_height_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaDocAccessFields_MimeTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaDocAccessFields_mimeType'] = ResolversParentTypes['MediaDocAccessFields_mimeType']> = {
  create?: Resolver<Maybe<ResolversTypes['MediaDocAccessFields_mimeType_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['MediaDocAccessFields_mimeType_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['MediaDocAccessFields_mimeType_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['MediaDocAccessFields_mimeType_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaDocAccessFields_MimeType_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaDocAccessFields_mimeType_Create'] = ResolversParentTypes['MediaDocAccessFields_mimeType_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaDocAccessFields_MimeType_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaDocAccessFields_mimeType_Delete'] = ResolversParentTypes['MediaDocAccessFields_mimeType_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaDocAccessFields_MimeType_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaDocAccessFields_mimeType_Read'] = ResolversParentTypes['MediaDocAccessFields_mimeType_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaDocAccessFields_MimeType_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaDocAccessFields_mimeType_Update'] = ResolversParentTypes['MediaDocAccessFields_mimeType_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaDocAccessFields_UpdatedAtResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaDocAccessFields_updatedAt'] = ResolversParentTypes['MediaDocAccessFields_updatedAt']> = {
  create?: Resolver<Maybe<ResolversTypes['MediaDocAccessFields_updatedAt_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['MediaDocAccessFields_updatedAt_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['MediaDocAccessFields_updatedAt_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['MediaDocAccessFields_updatedAt_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaDocAccessFields_UpdatedAt_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaDocAccessFields_updatedAt_Create'] = ResolversParentTypes['MediaDocAccessFields_updatedAt_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaDocAccessFields_UpdatedAt_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaDocAccessFields_updatedAt_Delete'] = ResolversParentTypes['MediaDocAccessFields_updatedAt_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaDocAccessFields_UpdatedAt_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaDocAccessFields_updatedAt_Read'] = ResolversParentTypes['MediaDocAccessFields_updatedAt_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaDocAccessFields_UpdatedAt_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaDocAccessFields_updatedAt_Update'] = ResolversParentTypes['MediaDocAccessFields_updatedAt_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaDocAccessFields_UrlResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaDocAccessFields_url'] = ResolversParentTypes['MediaDocAccessFields_url']> = {
  create?: Resolver<Maybe<ResolversTypes['MediaDocAccessFields_url_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['MediaDocAccessFields_url_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['MediaDocAccessFields_url_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['MediaDocAccessFields_url_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaDocAccessFields_Url_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaDocAccessFields_url_Create'] = ResolversParentTypes['MediaDocAccessFields_url_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaDocAccessFields_Url_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaDocAccessFields_url_Delete'] = ResolversParentTypes['MediaDocAccessFields_url_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaDocAccessFields_Url_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaDocAccessFields_url_Read'] = ResolversParentTypes['MediaDocAccessFields_url_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaDocAccessFields_Url_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaDocAccessFields_url_Update'] = ResolversParentTypes['MediaDocAccessFields_url_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaDocAccessFields_WidthResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaDocAccessFields_width'] = ResolversParentTypes['MediaDocAccessFields_width']> = {
  create?: Resolver<Maybe<ResolversTypes['MediaDocAccessFields_width_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['MediaDocAccessFields_width_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['MediaDocAccessFields_width_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['MediaDocAccessFields_width_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaDocAccessFields_Width_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaDocAccessFields_width_Create'] = ResolversParentTypes['MediaDocAccessFields_width_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaDocAccessFields_Width_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaDocAccessFields_width_Delete'] = ResolversParentTypes['MediaDocAccessFields_width_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaDocAccessFields_Width_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaDocAccessFields_width_Read'] = ResolversParentTypes['MediaDocAccessFields_width_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaDocAccessFields_Width_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaDocAccessFields_width_Update'] = ResolversParentTypes['MediaDocAccessFields_width_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaFields'] = ResolversParentTypes['MediaFields']> = {
  alt?: Resolver<Maybe<ResolversTypes['MediaFields_alt']>, ParentType, ContextType>;
  caption?: Resolver<Maybe<ResolversTypes['MediaFields_caption']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['MediaFields_createdAt']>, ParentType, ContextType>;
  filename?: Resolver<Maybe<ResolversTypes['MediaFields_filename']>, ParentType, ContextType>;
  filesize?: Resolver<Maybe<ResolversTypes['MediaFields_filesize']>, ParentType, ContextType>;
  height?: Resolver<Maybe<ResolversTypes['MediaFields_height']>, ParentType, ContextType>;
  mimeType?: Resolver<Maybe<ResolversTypes['MediaFields_mimeType']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['MediaFields_updatedAt']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['MediaFields_url']>, ParentType, ContextType>;
  width?: Resolver<Maybe<ResolversTypes['MediaFields_width']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaFields_AltResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaFields_alt'] = ResolversParentTypes['MediaFields_alt']> = {
  create?: Resolver<Maybe<ResolversTypes['MediaFields_alt_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['MediaFields_alt_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['MediaFields_alt_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['MediaFields_alt_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaFields_Alt_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaFields_alt_Create'] = ResolversParentTypes['MediaFields_alt_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaFields_Alt_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaFields_alt_Delete'] = ResolversParentTypes['MediaFields_alt_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaFields_Alt_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaFields_alt_Read'] = ResolversParentTypes['MediaFields_alt_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaFields_Alt_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaFields_alt_Update'] = ResolversParentTypes['MediaFields_alt_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaFields_CaptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaFields_caption'] = ResolversParentTypes['MediaFields_caption']> = {
  create?: Resolver<Maybe<ResolversTypes['MediaFields_caption_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['MediaFields_caption_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['MediaFields_caption_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['MediaFields_caption_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaFields_Caption_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaFields_caption_Create'] = ResolversParentTypes['MediaFields_caption_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaFields_Caption_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaFields_caption_Delete'] = ResolversParentTypes['MediaFields_caption_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaFields_Caption_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaFields_caption_Read'] = ResolversParentTypes['MediaFields_caption_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaFields_Caption_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaFields_caption_Update'] = ResolversParentTypes['MediaFields_caption_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaFields_CreatedAtResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaFields_createdAt'] = ResolversParentTypes['MediaFields_createdAt']> = {
  create?: Resolver<Maybe<ResolversTypes['MediaFields_createdAt_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['MediaFields_createdAt_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['MediaFields_createdAt_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['MediaFields_createdAt_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaFields_CreatedAt_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaFields_createdAt_Create'] = ResolversParentTypes['MediaFields_createdAt_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaFields_CreatedAt_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaFields_createdAt_Delete'] = ResolversParentTypes['MediaFields_createdAt_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaFields_CreatedAt_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaFields_createdAt_Read'] = ResolversParentTypes['MediaFields_createdAt_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaFields_CreatedAt_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaFields_createdAt_Update'] = ResolversParentTypes['MediaFields_createdAt_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaFields_FilenameResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaFields_filename'] = ResolversParentTypes['MediaFields_filename']> = {
  create?: Resolver<Maybe<ResolversTypes['MediaFields_filename_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['MediaFields_filename_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['MediaFields_filename_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['MediaFields_filename_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaFields_Filename_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaFields_filename_Create'] = ResolversParentTypes['MediaFields_filename_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaFields_Filename_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaFields_filename_Delete'] = ResolversParentTypes['MediaFields_filename_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaFields_Filename_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaFields_filename_Read'] = ResolversParentTypes['MediaFields_filename_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaFields_Filename_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaFields_filename_Update'] = ResolversParentTypes['MediaFields_filename_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaFields_FilesizeResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaFields_filesize'] = ResolversParentTypes['MediaFields_filesize']> = {
  create?: Resolver<Maybe<ResolversTypes['MediaFields_filesize_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['MediaFields_filesize_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['MediaFields_filesize_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['MediaFields_filesize_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaFields_Filesize_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaFields_filesize_Create'] = ResolversParentTypes['MediaFields_filesize_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaFields_Filesize_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaFields_filesize_Delete'] = ResolversParentTypes['MediaFields_filesize_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaFields_Filesize_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaFields_filesize_Read'] = ResolversParentTypes['MediaFields_filesize_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaFields_Filesize_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaFields_filesize_Update'] = ResolversParentTypes['MediaFields_filesize_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaFields_HeightResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaFields_height'] = ResolversParentTypes['MediaFields_height']> = {
  create?: Resolver<Maybe<ResolversTypes['MediaFields_height_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['MediaFields_height_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['MediaFields_height_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['MediaFields_height_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaFields_Height_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaFields_height_Create'] = ResolversParentTypes['MediaFields_height_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaFields_Height_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaFields_height_Delete'] = ResolversParentTypes['MediaFields_height_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaFields_Height_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaFields_height_Read'] = ResolversParentTypes['MediaFields_height_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaFields_Height_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaFields_height_Update'] = ResolversParentTypes['MediaFields_height_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaFields_MimeTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaFields_mimeType'] = ResolversParentTypes['MediaFields_mimeType']> = {
  create?: Resolver<Maybe<ResolversTypes['MediaFields_mimeType_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['MediaFields_mimeType_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['MediaFields_mimeType_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['MediaFields_mimeType_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaFields_MimeType_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaFields_mimeType_Create'] = ResolversParentTypes['MediaFields_mimeType_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaFields_MimeType_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaFields_mimeType_Delete'] = ResolversParentTypes['MediaFields_mimeType_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaFields_MimeType_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaFields_mimeType_Read'] = ResolversParentTypes['MediaFields_mimeType_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaFields_MimeType_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaFields_mimeType_Update'] = ResolversParentTypes['MediaFields_mimeType_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaFields_UpdatedAtResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaFields_updatedAt'] = ResolversParentTypes['MediaFields_updatedAt']> = {
  create?: Resolver<Maybe<ResolversTypes['MediaFields_updatedAt_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['MediaFields_updatedAt_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['MediaFields_updatedAt_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['MediaFields_updatedAt_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaFields_UpdatedAt_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaFields_updatedAt_Create'] = ResolversParentTypes['MediaFields_updatedAt_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaFields_UpdatedAt_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaFields_updatedAt_Delete'] = ResolversParentTypes['MediaFields_updatedAt_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaFields_UpdatedAt_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaFields_updatedAt_Read'] = ResolversParentTypes['MediaFields_updatedAt_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaFields_UpdatedAt_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaFields_updatedAt_Update'] = ResolversParentTypes['MediaFields_updatedAt_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaFields_UrlResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaFields_url'] = ResolversParentTypes['MediaFields_url']> = {
  create?: Resolver<Maybe<ResolversTypes['MediaFields_url_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['MediaFields_url_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['MediaFields_url_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['MediaFields_url_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaFields_Url_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaFields_url_Create'] = ResolversParentTypes['MediaFields_url_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaFields_Url_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaFields_url_Delete'] = ResolversParentTypes['MediaFields_url_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaFields_Url_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaFields_url_Read'] = ResolversParentTypes['MediaFields_url_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaFields_Url_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaFields_url_Update'] = ResolversParentTypes['MediaFields_url_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaFields_WidthResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaFields_width'] = ResolversParentTypes['MediaFields_width']> = {
  create?: Resolver<Maybe<ResolversTypes['MediaFields_width_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['MediaFields_width_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['MediaFields_width_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['MediaFields_width_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaFields_Width_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaFields_width_Create'] = ResolversParentTypes['MediaFields_width_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaFields_Width_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaFields_width_Delete'] = ResolversParentTypes['MediaFields_width_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaFields_Width_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaFields_width_Read'] = ResolversParentTypes['MediaFields_width_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaFields_Width_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaFields_width_Update'] = ResolversParentTypes['MediaFields_width_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaReadAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaReadAccess'] = ResolversParentTypes['MediaReadAccess']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  where?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaReadDocAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaReadDocAccess'] = ResolversParentTypes['MediaReadDocAccess']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  where?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaUpdateAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaUpdateAccess'] = ResolversParentTypes['MediaUpdateAccess']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  where?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaUpdateDocAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaUpdateDocAccess'] = ResolversParentTypes['MediaUpdateDocAccess']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  where?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  createArticle?: Resolver<Maybe<ResolversTypes['Article']>, ParentType, ContextType, RequireFields<MutationCreateArticleArgs, 'data'>>;
  createCategory?: Resolver<Maybe<ResolversTypes['Category']>, ParentType, ContextType, RequireFields<MutationCreateCategoryArgs, 'data'>>;
  createMedia?: Resolver<Maybe<ResolversTypes['Media']>, ParentType, ContextType, RequireFields<MutationCreateMediaArgs, 'data'>>;
  createPayloadPreference?: Resolver<Maybe<ResolversTypes['PayloadPreference']>, ParentType, ContextType, RequireFields<MutationCreatePayloadPreferenceArgs, 'data'>>;
  createTaskTemplate?: Resolver<Maybe<ResolversTypes['TaskTemplate']>, ParentType, ContextType, RequireFields<MutationCreateTaskTemplateArgs, 'data'>>;
  createUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<MutationCreateUserArgs, 'data'>>;
  deleteArticle?: Resolver<Maybe<ResolversTypes['Article']>, ParentType, ContextType, RequireFields<MutationDeleteArticleArgs, 'id'>>;
  deleteCategory?: Resolver<Maybe<ResolversTypes['Category']>, ParentType, ContextType, RequireFields<MutationDeleteCategoryArgs, 'id'>>;
  deleteMedia?: Resolver<Maybe<ResolversTypes['Media']>, ParentType, ContextType, RequireFields<MutationDeleteMediaArgs, 'id'>>;
  deletePayloadPreference?: Resolver<Maybe<ResolversTypes['PayloadPreference']>, ParentType, ContextType, RequireFields<MutationDeletePayloadPreferenceArgs, 'id'>>;
  deleteTaskTemplate?: Resolver<Maybe<ResolversTypes['TaskTemplate']>, ParentType, ContextType, RequireFields<MutationDeleteTaskTemplateArgs, 'id'>>;
  deleteUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<MutationDeleteUserArgs, 'id'>>;
  forgotPasswordUser?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationForgotPasswordUserArgs, 'email'>>;
  loginUser?: Resolver<Maybe<ResolversTypes['usersLoginResult']>, ParentType, ContextType, Partial<MutationLoginUserArgs>>;
  logoutUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  refreshTokenUser?: Resolver<Maybe<ResolversTypes['usersRefreshedUser']>, ParentType, ContextType, Partial<MutationRefreshTokenUserArgs>>;
  resetPasswordUser?: Resolver<Maybe<ResolversTypes['usersResetPassword']>, ParentType, ContextType, Partial<MutationResetPasswordUserArgs>>;
  restoreVersionArticle?: Resolver<Maybe<ResolversTypes['Article']>, ParentType, ContextType, Partial<MutationRestoreVersionArticleArgs>>;
  restoreVersionTaskTemplate?: Resolver<Maybe<ResolversTypes['TaskTemplate']>, ParentType, ContextType, Partial<MutationRestoreVersionTaskTemplateArgs>>;
  unlockUser?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationUnlockUserArgs, 'email'>>;
  updateArticle?: Resolver<Maybe<ResolversTypes['Article']>, ParentType, ContextType, RequireFields<MutationUpdateArticleArgs, 'data' | 'id'>>;
  updateCategory?: Resolver<Maybe<ResolversTypes['Category']>, ParentType, ContextType, RequireFields<MutationUpdateCategoryArgs, 'data' | 'id'>>;
  updateMedia?: Resolver<Maybe<ResolversTypes['Media']>, ParentType, ContextType, RequireFields<MutationUpdateMediaArgs, 'data' | 'id'>>;
  updatePayloadPreference?: Resolver<Maybe<ResolversTypes['PayloadPreference']>, ParentType, ContextType, RequireFields<MutationUpdatePayloadPreferenceArgs, 'data' | 'id'>>;
  updateTaskTemplate?: Resolver<Maybe<ResolversTypes['TaskTemplate']>, ParentType, ContextType, RequireFields<MutationUpdateTaskTemplateArgs, 'data' | 'id'>>;
  updateUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<MutationUpdateUserArgs, 'data' | 'id'>>;
  verifyEmailUser?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, Partial<MutationVerifyEmailUserArgs>>;
};

export type PayloadPreferenceResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreference'] = ResolversParentTypes['PayloadPreference']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  user?: Resolver<ResolversTypes['PayloadPreference_User_Relationship'], ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreference_UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreference_User'] = ResolversParentTypes['PayloadPreference_User']> = {
  __resolveType: TypeResolveFn<'User', ParentType, ContextType>;
};

export type PayloadPreference_User_RelationshipResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreference_User_Relationship'] = ResolversParentTypes['PayloadPreference_User_Relationship']> = {
  relationTo?: Resolver<Maybe<ResolversTypes['PayloadPreference_User_RelationTo']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['PayloadPreference_User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreferencesResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreferences'] = ResolversParentTypes['PayloadPreferences']> = {
  docs?: Resolver<Maybe<Array<Maybe<ResolversTypes['PayloadPreference']>>>, ParentType, ContextType>;
  hasNextPage?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasPrevPage?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  limit?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  nextPage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  offset?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  page?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pagingCounter?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  prevPage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalDocs?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalPages?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreferencesCreateAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreferencesCreateAccess'] = ResolversParentTypes['PayloadPreferencesCreateAccess']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  where?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreferencesCreateDocAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreferencesCreateDocAccess'] = ResolversParentTypes['PayloadPreferencesCreateDocAccess']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  where?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreferencesDeleteAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreferencesDeleteAccess'] = ResolversParentTypes['PayloadPreferencesDeleteAccess']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  where?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreferencesDeleteDocAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreferencesDeleteDocAccess'] = ResolversParentTypes['PayloadPreferencesDeleteDocAccess']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  where?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreferencesDocAccessFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreferencesDocAccessFields'] = ResolversParentTypes['PayloadPreferencesDocAccessFields']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['PayloadPreferencesDocAccessFields_createdAt']>, ParentType, ContextType>;
  key?: Resolver<Maybe<ResolversTypes['PayloadPreferencesDocAccessFields_key']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['PayloadPreferencesDocAccessFields_updatedAt']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['PayloadPreferencesDocAccessFields_user']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['PayloadPreferencesDocAccessFields_value']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreferencesDocAccessFields_CreatedAtResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreferencesDocAccessFields_createdAt'] = ResolversParentTypes['PayloadPreferencesDocAccessFields_createdAt']> = {
  create?: Resolver<Maybe<ResolversTypes['PayloadPreferencesDocAccessFields_createdAt_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['PayloadPreferencesDocAccessFields_createdAt_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['PayloadPreferencesDocAccessFields_createdAt_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['PayloadPreferencesDocAccessFields_createdAt_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreferencesDocAccessFields_CreatedAt_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreferencesDocAccessFields_createdAt_Create'] = ResolversParentTypes['PayloadPreferencesDocAccessFields_createdAt_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreferencesDocAccessFields_CreatedAt_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreferencesDocAccessFields_createdAt_Delete'] = ResolversParentTypes['PayloadPreferencesDocAccessFields_createdAt_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreferencesDocAccessFields_CreatedAt_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreferencesDocAccessFields_createdAt_Read'] = ResolversParentTypes['PayloadPreferencesDocAccessFields_createdAt_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreferencesDocAccessFields_CreatedAt_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreferencesDocAccessFields_createdAt_Update'] = ResolversParentTypes['PayloadPreferencesDocAccessFields_createdAt_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreferencesDocAccessFields_KeyResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreferencesDocAccessFields_key'] = ResolversParentTypes['PayloadPreferencesDocAccessFields_key']> = {
  create?: Resolver<Maybe<ResolversTypes['PayloadPreferencesDocAccessFields_key_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['PayloadPreferencesDocAccessFields_key_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['PayloadPreferencesDocAccessFields_key_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['PayloadPreferencesDocAccessFields_key_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreferencesDocAccessFields_Key_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreferencesDocAccessFields_key_Create'] = ResolversParentTypes['PayloadPreferencesDocAccessFields_key_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreferencesDocAccessFields_Key_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreferencesDocAccessFields_key_Delete'] = ResolversParentTypes['PayloadPreferencesDocAccessFields_key_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreferencesDocAccessFields_Key_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreferencesDocAccessFields_key_Read'] = ResolversParentTypes['PayloadPreferencesDocAccessFields_key_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreferencesDocAccessFields_Key_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreferencesDocAccessFields_key_Update'] = ResolversParentTypes['PayloadPreferencesDocAccessFields_key_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreferencesDocAccessFields_UpdatedAtResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreferencesDocAccessFields_updatedAt'] = ResolversParentTypes['PayloadPreferencesDocAccessFields_updatedAt']> = {
  create?: Resolver<Maybe<ResolversTypes['PayloadPreferencesDocAccessFields_updatedAt_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['PayloadPreferencesDocAccessFields_updatedAt_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['PayloadPreferencesDocAccessFields_updatedAt_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['PayloadPreferencesDocAccessFields_updatedAt_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreferencesDocAccessFields_UpdatedAt_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreferencesDocAccessFields_updatedAt_Create'] = ResolversParentTypes['PayloadPreferencesDocAccessFields_updatedAt_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreferencesDocAccessFields_UpdatedAt_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreferencesDocAccessFields_updatedAt_Delete'] = ResolversParentTypes['PayloadPreferencesDocAccessFields_updatedAt_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreferencesDocAccessFields_UpdatedAt_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreferencesDocAccessFields_updatedAt_Read'] = ResolversParentTypes['PayloadPreferencesDocAccessFields_updatedAt_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreferencesDocAccessFields_UpdatedAt_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreferencesDocAccessFields_updatedAt_Update'] = ResolversParentTypes['PayloadPreferencesDocAccessFields_updatedAt_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreferencesDocAccessFields_UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreferencesDocAccessFields_user'] = ResolversParentTypes['PayloadPreferencesDocAccessFields_user']> = {
  create?: Resolver<Maybe<ResolversTypes['PayloadPreferencesDocAccessFields_user_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['PayloadPreferencesDocAccessFields_user_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['PayloadPreferencesDocAccessFields_user_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['PayloadPreferencesDocAccessFields_user_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreferencesDocAccessFields_User_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreferencesDocAccessFields_user_Create'] = ResolversParentTypes['PayloadPreferencesDocAccessFields_user_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreferencesDocAccessFields_User_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreferencesDocAccessFields_user_Delete'] = ResolversParentTypes['PayloadPreferencesDocAccessFields_user_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreferencesDocAccessFields_User_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreferencesDocAccessFields_user_Read'] = ResolversParentTypes['PayloadPreferencesDocAccessFields_user_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreferencesDocAccessFields_User_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreferencesDocAccessFields_user_Update'] = ResolversParentTypes['PayloadPreferencesDocAccessFields_user_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreferencesDocAccessFields_ValueResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreferencesDocAccessFields_value'] = ResolversParentTypes['PayloadPreferencesDocAccessFields_value']> = {
  create?: Resolver<Maybe<ResolversTypes['PayloadPreferencesDocAccessFields_value_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['PayloadPreferencesDocAccessFields_value_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['PayloadPreferencesDocAccessFields_value_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['PayloadPreferencesDocAccessFields_value_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreferencesDocAccessFields_Value_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreferencesDocAccessFields_value_Create'] = ResolversParentTypes['PayloadPreferencesDocAccessFields_value_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreferencesDocAccessFields_Value_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreferencesDocAccessFields_value_Delete'] = ResolversParentTypes['PayloadPreferencesDocAccessFields_value_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreferencesDocAccessFields_Value_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreferencesDocAccessFields_value_Read'] = ResolversParentTypes['PayloadPreferencesDocAccessFields_value_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreferencesDocAccessFields_Value_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreferencesDocAccessFields_value_Update'] = ResolversParentTypes['PayloadPreferencesDocAccessFields_value_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreferencesFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreferencesFields'] = ResolversParentTypes['PayloadPreferencesFields']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['PayloadPreferencesFields_createdAt']>, ParentType, ContextType>;
  key?: Resolver<Maybe<ResolversTypes['PayloadPreferencesFields_key']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['PayloadPreferencesFields_updatedAt']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['PayloadPreferencesFields_user']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['PayloadPreferencesFields_value']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreferencesFields_CreatedAtResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreferencesFields_createdAt'] = ResolversParentTypes['PayloadPreferencesFields_createdAt']> = {
  create?: Resolver<Maybe<ResolversTypes['PayloadPreferencesFields_createdAt_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['PayloadPreferencesFields_createdAt_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['PayloadPreferencesFields_createdAt_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['PayloadPreferencesFields_createdAt_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreferencesFields_CreatedAt_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreferencesFields_createdAt_Create'] = ResolversParentTypes['PayloadPreferencesFields_createdAt_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreferencesFields_CreatedAt_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreferencesFields_createdAt_Delete'] = ResolversParentTypes['PayloadPreferencesFields_createdAt_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreferencesFields_CreatedAt_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreferencesFields_createdAt_Read'] = ResolversParentTypes['PayloadPreferencesFields_createdAt_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreferencesFields_CreatedAt_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreferencesFields_createdAt_Update'] = ResolversParentTypes['PayloadPreferencesFields_createdAt_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreferencesFields_KeyResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreferencesFields_key'] = ResolversParentTypes['PayloadPreferencesFields_key']> = {
  create?: Resolver<Maybe<ResolversTypes['PayloadPreferencesFields_key_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['PayloadPreferencesFields_key_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['PayloadPreferencesFields_key_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['PayloadPreferencesFields_key_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreferencesFields_Key_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreferencesFields_key_Create'] = ResolversParentTypes['PayloadPreferencesFields_key_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreferencesFields_Key_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreferencesFields_key_Delete'] = ResolversParentTypes['PayloadPreferencesFields_key_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreferencesFields_Key_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreferencesFields_key_Read'] = ResolversParentTypes['PayloadPreferencesFields_key_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreferencesFields_Key_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreferencesFields_key_Update'] = ResolversParentTypes['PayloadPreferencesFields_key_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreferencesFields_UpdatedAtResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreferencesFields_updatedAt'] = ResolversParentTypes['PayloadPreferencesFields_updatedAt']> = {
  create?: Resolver<Maybe<ResolversTypes['PayloadPreferencesFields_updatedAt_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['PayloadPreferencesFields_updatedAt_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['PayloadPreferencesFields_updatedAt_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['PayloadPreferencesFields_updatedAt_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreferencesFields_UpdatedAt_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreferencesFields_updatedAt_Create'] = ResolversParentTypes['PayloadPreferencesFields_updatedAt_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreferencesFields_UpdatedAt_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreferencesFields_updatedAt_Delete'] = ResolversParentTypes['PayloadPreferencesFields_updatedAt_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreferencesFields_UpdatedAt_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreferencesFields_updatedAt_Read'] = ResolversParentTypes['PayloadPreferencesFields_updatedAt_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreferencesFields_UpdatedAt_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreferencesFields_updatedAt_Update'] = ResolversParentTypes['PayloadPreferencesFields_updatedAt_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreferencesFields_UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreferencesFields_user'] = ResolversParentTypes['PayloadPreferencesFields_user']> = {
  create?: Resolver<Maybe<ResolversTypes['PayloadPreferencesFields_user_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['PayloadPreferencesFields_user_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['PayloadPreferencesFields_user_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['PayloadPreferencesFields_user_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreferencesFields_User_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreferencesFields_user_Create'] = ResolversParentTypes['PayloadPreferencesFields_user_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreferencesFields_User_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreferencesFields_user_Delete'] = ResolversParentTypes['PayloadPreferencesFields_user_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreferencesFields_User_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreferencesFields_user_Read'] = ResolversParentTypes['PayloadPreferencesFields_user_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreferencesFields_User_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreferencesFields_user_Update'] = ResolversParentTypes['PayloadPreferencesFields_user_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreferencesFields_ValueResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreferencesFields_value'] = ResolversParentTypes['PayloadPreferencesFields_value']> = {
  create?: Resolver<Maybe<ResolversTypes['PayloadPreferencesFields_value_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['PayloadPreferencesFields_value_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['PayloadPreferencesFields_value_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['PayloadPreferencesFields_value_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreferencesFields_Value_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreferencesFields_value_Create'] = ResolversParentTypes['PayloadPreferencesFields_value_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreferencesFields_Value_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreferencesFields_value_Delete'] = ResolversParentTypes['PayloadPreferencesFields_value_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreferencesFields_Value_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreferencesFields_value_Read'] = ResolversParentTypes['PayloadPreferencesFields_value_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreferencesFields_Value_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreferencesFields_value_Update'] = ResolversParentTypes['PayloadPreferencesFields_value_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreferencesReadAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreferencesReadAccess'] = ResolversParentTypes['PayloadPreferencesReadAccess']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  where?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreferencesReadDocAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreferencesReadDocAccess'] = ResolversParentTypes['PayloadPreferencesReadDocAccess']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  where?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreferencesUpdateAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreferencesUpdateAccess'] = ResolversParentTypes['PayloadPreferencesUpdateAccess']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  where?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadPreferencesUpdateDocAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadPreferencesUpdateDocAccess'] = ResolversParentTypes['PayloadPreferencesUpdateDocAccess']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  where?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  Access?: Resolver<Maybe<ResolversTypes['Access']>, ParentType, ContextType>;
  Article?: Resolver<Maybe<ResolversTypes['Article']>, ParentType, ContextType, RequireFields<QueryArticleArgs, 'id'>>;
  Articles?: Resolver<Maybe<ResolversTypes['Articles']>, ParentType, ContextType, Partial<QueryArticlesArgs>>;
  Categories?: Resolver<Maybe<ResolversTypes['Categories']>, ParentType, ContextType, Partial<QueryCategoriesArgs>>;
  Category?: Resolver<Maybe<ResolversTypes['Category']>, ParentType, ContextType, RequireFields<QueryCategoryArgs, 'id'>>;
  Media?: Resolver<Maybe<ResolversTypes['Media']>, ParentType, ContextType, RequireFields<QueryMediaArgs, 'id'>>;
  PayloadPreference?: Resolver<Maybe<ResolversTypes['PayloadPreference']>, ParentType, ContextType, RequireFields<QueryPayloadPreferenceArgs, 'id'>>;
  PayloadPreferences?: Resolver<Maybe<ResolversTypes['PayloadPreferences']>, ParentType, ContextType, Partial<QueryPayloadPreferencesArgs>>;
  TaskTemplate?: Resolver<Maybe<ResolversTypes['TaskTemplate']>, ParentType, ContextType, RequireFields<QueryTaskTemplateArgs, 'id'>>;
  TaskTemplates?: Resolver<Maybe<ResolversTypes['TaskTemplates']>, ParentType, ContextType, Partial<QueryTaskTemplatesArgs>>;
  User?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<QueryUserArgs, 'id'>>;
  Users?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType, Partial<QueryUsersArgs>>;
  allMedia?: Resolver<Maybe<ResolversTypes['allMedia']>, ParentType, ContextType, Partial<QueryAllMediaArgs>>;
  docAccessArticle?: Resolver<Maybe<ResolversTypes['articlesDocAccess']>, ParentType, ContextType, RequireFields<QueryDocAccessArticleArgs, 'id'>>;
  docAccessCategory?: Resolver<Maybe<ResolversTypes['categoriesDocAccess']>, ParentType, ContextType, RequireFields<QueryDocAccessCategoryArgs, 'id'>>;
  docAccessMedia?: Resolver<Maybe<ResolversTypes['mediaDocAccess']>, ParentType, ContextType, RequireFields<QueryDocAccessMediaArgs, 'id'>>;
  docAccessPayloadPreference?: Resolver<Maybe<ResolversTypes['payload_preferencesDocAccess']>, ParentType, ContextType, RequireFields<QueryDocAccessPayloadPreferenceArgs, 'id'>>;
  docAccessTaskTemplate?: Resolver<Maybe<ResolversTypes['task_templatesDocAccess']>, ParentType, ContextType, RequireFields<QueryDocAccessTaskTemplateArgs, 'id'>>;
  docAccessUser?: Resolver<Maybe<ResolversTypes['usersDocAccess']>, ParentType, ContextType, RequireFields<QueryDocAccessUserArgs, 'id'>>;
  initializedUser?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  meUser?: Resolver<Maybe<ResolversTypes['usersMe']>, ParentType, ContextType>;
  versionArticle?: Resolver<Maybe<ResolversTypes['ArticleVersion']>, ParentType, ContextType, Partial<QueryVersionArticleArgs>>;
  versionTaskTemplate?: Resolver<Maybe<ResolversTypes['TaskTemplateVersion']>, ParentType, ContextType, Partial<QueryVersionTaskTemplateArgs>>;
  versionsArticles?: Resolver<Maybe<ResolversTypes['versionsArticles']>, ParentType, ContextType, Partial<QueryVersionsArticlesArgs>>;
  versionsTaskTemplates?: Resolver<Maybe<ResolversTypes['versionsTaskTemplates']>, ParentType, ContextType, Partial<QueryVersionsTaskTemplatesArgs>>;
};

export type TaskTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplate'] = ResolversParentTypes['TaskTemplate']> = {
  _status?: Resolver<Maybe<ResolversTypes['TaskTemplate__status']>, ParentType, ContextType>;
  assignment?: Resolver<Maybe<ResolversTypes['TaskTemplate_assignment']>, ParentType, ContextType>;
  categories?: Resolver<Maybe<Array<ResolversTypes['TaskTemplate_categories']>>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  details?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType, Partial<TaskTemplateDetailsArgs>>;
  feature?: Resolver<Maybe<ResolversTypes['TaskTemplate_feature']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  resources?: Resolver<Maybe<ResolversTypes['TaskTemplate_Resources']>, ParentType, ContextType>;
  review?: Resolver<Maybe<ResolversTypes['TaskTemplate_Review']>, ParentType, ContextType>;
  schedule?: Resolver<Maybe<Array<ResolversTypes['TaskTemplate_Schedule']>>, ParentType, ContextType>;
  thumbnail?: Resolver<Maybe<ResolversTypes['Media']>, ParentType, ContextType, Partial<TaskTemplateThumbnailArgs>>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplateVersionResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplateVersion'] = ResolversParentTypes['TaskTemplateVersion']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  latest?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  parent?: Resolver<Maybe<ResolversTypes['TaskTemplate']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['TaskTemplateVersion_Version']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplateVersion_VersionResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplateVersion_Version'] = ResolversParentTypes['TaskTemplateVersion_Version']> = {
  _status?: Resolver<Maybe<ResolversTypes['TaskTemplateVersion_Version__status']>, ParentType, ContextType>;
  assignment?: Resolver<Maybe<ResolversTypes['TaskTemplateVersion_Version_assignment']>, ParentType, ContextType>;
  categories?: Resolver<Maybe<Array<ResolversTypes['TaskTemplateVersion_Version_categories']>>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  details?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType, Partial<TaskTemplateVersion_VersionDetailsArgs>>;
  feature?: Resolver<Maybe<ResolversTypes['TaskTemplateVersion_Version_feature']>, ParentType, ContextType>;
  resources?: Resolver<Maybe<ResolversTypes['TaskTemplateVersion_Version_Resources']>, ParentType, ContextType>;
  review?: Resolver<Maybe<ResolversTypes['TaskTemplateVersion_Version_Review']>, ParentType, ContextType>;
  schedule?: Resolver<Maybe<Array<ResolversTypes['TaskTemplateVersion_Version_Schedule']>>, ParentType, ContextType>;
  thumbnail?: Resolver<Maybe<ResolversTypes['Media']>, ParentType, ContextType, Partial<TaskTemplateVersion_VersionThumbnailArgs>>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplateVersion_Version_ResourcesResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplateVersion_Version_Resources'] = ResolversParentTypes['TaskTemplateVersion_Version_Resources']> = {
  articles?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  videos?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplateVersion_Version_ReviewResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplateVersion_Version_Review'] = ResolversParentTypes['TaskTemplateVersion_Version_Review']> = {
  approver?: Resolver<Maybe<ResolversTypes['TaskTemplateVersion_Version_Review_approver']>, ParentType, ContextType>;
  evidence?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  required?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplateVersion_Version_ScheduleResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplateVersion_Version_Schedule'] = ResolversParentTypes['TaskTemplateVersion_Version_Schedule']> = {
  due?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  event?: Resolver<Maybe<ResolversTypes['TaskTemplateVersion_Version_Schedule_event']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  recurrence?: Resolver<Maybe<ResolversTypes['TaskTemplateVersion_Version_Schedule_Recurrence']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplateVersion_Version_Schedule_RecurrenceResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplateVersion_Version_Schedule_Recurrence'] = ResolversParentTypes['TaskTemplateVersion_Version_Schedule_Recurrence']> = {
  freq?: Resolver<Maybe<ResolversTypes['TaskTemplateVersion_Version_Schedule_Recurrence_freq']>, ParentType, ContextType>;
  interval?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplate_ResourcesResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplate_Resources'] = ResolversParentTypes['TaskTemplate_Resources']> = {
  articles?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  videos?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplate_ReviewResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplate_Review'] = ResolversParentTypes['TaskTemplate_Review']> = {
  approver?: Resolver<Maybe<ResolversTypes['TaskTemplate_Review_approver']>, ParentType, ContextType>;
  evidence?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  required?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplate_ScheduleResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplate_Schedule'] = ResolversParentTypes['TaskTemplate_Schedule']> = {
  due?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  event?: Resolver<Maybe<ResolversTypes['TaskTemplate_Schedule_event']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  recurrence?: Resolver<Maybe<ResolversTypes['TaskTemplate_Schedule_Recurrence']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplate_Schedule_RecurrenceResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplate_Schedule_Recurrence'] = ResolversParentTypes['TaskTemplate_Schedule_Recurrence']> = {
  freq?: Resolver<Maybe<ResolversTypes['TaskTemplate_Schedule_Recurrence_freq']>, ParentType, ContextType>;
  interval?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplates'] = ResolversParentTypes['TaskTemplates']> = {
  docs?: Resolver<Maybe<Array<Maybe<ResolversTypes['TaskTemplate']>>>, ParentType, ContextType>;
  hasNextPage?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasPrevPage?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  limit?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  nextPage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  offset?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  page?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pagingCounter?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  prevPage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalDocs?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalPages?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesCreateAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesCreateAccess'] = ResolversParentTypes['TaskTemplatesCreateAccess']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  where?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesCreateDocAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesCreateDocAccess'] = ResolversParentTypes['TaskTemplatesCreateDocAccess']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  where?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDeleteAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDeleteAccess'] = ResolversParentTypes['TaskTemplatesDeleteAccess']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  where?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDeleteDocAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDeleteDocAccess'] = ResolversParentTypes['TaskTemplatesDeleteDocAccess']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  where?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields'] = ResolversParentTypes['TaskTemplatesDocAccessFields']> = {
  _status?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields__status']>, ParentType, ContextType>;
  assignment?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_assignment']>, ParentType, ContextType>;
  categories?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_categories']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_createdAt']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_description']>, ParentType, ContextType>;
  details?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_details']>, ParentType, ContextType>;
  feature?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_feature']>, ParentType, ContextType>;
  resources?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_resources']>, ParentType, ContextType>;
  review?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_review']>, ParentType, ContextType>;
  schedule?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_schedule']>, ParentType, ContextType>;
  thumbnail?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_thumbnail']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_title']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_updatedAt']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields__StatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields__status'] = ResolversParentTypes['TaskTemplatesDocAccessFields__status']> = {
  create?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields__status_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields__status_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields__status_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields__status_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields__Status_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields__status_Create'] = ResolversParentTypes['TaskTemplatesDocAccessFields__status_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields__Status_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields__status_Delete'] = ResolversParentTypes['TaskTemplatesDocAccessFields__status_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields__Status_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields__status_Read'] = ResolversParentTypes['TaskTemplatesDocAccessFields__status_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields__Status_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields__status_Update'] = ResolversParentTypes['TaskTemplatesDocAccessFields__status_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_AssignmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_assignment'] = ResolversParentTypes['TaskTemplatesDocAccessFields_assignment']> = {
  create?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_assignment_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_assignment_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_assignment_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_assignment_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Assignment_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_assignment_Create'] = ResolversParentTypes['TaskTemplatesDocAccessFields_assignment_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Assignment_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_assignment_Delete'] = ResolversParentTypes['TaskTemplatesDocAccessFields_assignment_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Assignment_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_assignment_Read'] = ResolversParentTypes['TaskTemplatesDocAccessFields_assignment_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Assignment_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_assignment_Update'] = ResolversParentTypes['TaskTemplatesDocAccessFields_assignment_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_CategoriesResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_categories'] = ResolversParentTypes['TaskTemplatesDocAccessFields_categories']> = {
  create?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_categories_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_categories_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_categories_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_categories_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Categories_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_categories_Create'] = ResolversParentTypes['TaskTemplatesDocAccessFields_categories_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Categories_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_categories_Delete'] = ResolversParentTypes['TaskTemplatesDocAccessFields_categories_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Categories_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_categories_Read'] = ResolversParentTypes['TaskTemplatesDocAccessFields_categories_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Categories_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_categories_Update'] = ResolversParentTypes['TaskTemplatesDocAccessFields_categories_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_CreatedAtResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_createdAt'] = ResolversParentTypes['TaskTemplatesDocAccessFields_createdAt']> = {
  create?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_createdAt_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_createdAt_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_createdAt_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_createdAt_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_CreatedAt_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_createdAt_Create'] = ResolversParentTypes['TaskTemplatesDocAccessFields_createdAt_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_CreatedAt_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_createdAt_Delete'] = ResolversParentTypes['TaskTemplatesDocAccessFields_createdAt_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_CreatedAt_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_createdAt_Read'] = ResolversParentTypes['TaskTemplatesDocAccessFields_createdAt_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_CreatedAt_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_createdAt_Update'] = ResolversParentTypes['TaskTemplatesDocAccessFields_createdAt_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_DescriptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_description'] = ResolversParentTypes['TaskTemplatesDocAccessFields_description']> = {
  create?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_description_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_description_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_description_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_description_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Description_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_description_Create'] = ResolversParentTypes['TaskTemplatesDocAccessFields_description_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Description_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_description_Delete'] = ResolversParentTypes['TaskTemplatesDocAccessFields_description_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Description_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_description_Read'] = ResolversParentTypes['TaskTemplatesDocAccessFields_description_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Description_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_description_Update'] = ResolversParentTypes['TaskTemplatesDocAccessFields_description_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_DetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_details'] = ResolversParentTypes['TaskTemplatesDocAccessFields_details']> = {
  create?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_details_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_details_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_details_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_details_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Details_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_details_Create'] = ResolversParentTypes['TaskTemplatesDocAccessFields_details_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Details_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_details_Delete'] = ResolversParentTypes['TaskTemplatesDocAccessFields_details_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Details_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_details_Read'] = ResolversParentTypes['TaskTemplatesDocAccessFields_details_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Details_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_details_Update'] = ResolversParentTypes['TaskTemplatesDocAccessFields_details_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_FeatureResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_feature'] = ResolversParentTypes['TaskTemplatesDocAccessFields_feature']> = {
  create?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_feature_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_feature_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_feature_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_feature_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Feature_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_feature_Create'] = ResolversParentTypes['TaskTemplatesDocAccessFields_feature_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Feature_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_feature_Delete'] = ResolversParentTypes['TaskTemplatesDocAccessFields_feature_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Feature_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_feature_Read'] = ResolversParentTypes['TaskTemplatesDocAccessFields_feature_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Feature_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_feature_Update'] = ResolversParentTypes['TaskTemplatesDocAccessFields_feature_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_ResourcesResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_resources'] = ResolversParentTypes['TaskTemplatesDocAccessFields_resources']> = {
  create?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_resources_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_resources_Delete']>, ParentType, ContextType>;
  fields?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_resources_Fields']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_resources_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_resources_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Resources_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_resources_Create'] = ResolversParentTypes['TaskTemplatesDocAccessFields_resources_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Resources_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_resources_Delete'] = ResolversParentTypes['TaskTemplatesDocAccessFields_resources_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Resources_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_resources_Fields'] = ResolversParentTypes['TaskTemplatesDocAccessFields_resources_Fields']> = {
  articles?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_resources_articles']>, ParentType, ContextType>;
  videos?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_resources_videos']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Resources_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_resources_Read'] = ResolversParentTypes['TaskTemplatesDocAccessFields_resources_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Resources_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_resources_Update'] = ResolversParentTypes['TaskTemplatesDocAccessFields_resources_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Resources_ArticlesResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_resources_articles'] = ResolversParentTypes['TaskTemplatesDocAccessFields_resources_articles']> = {
  create?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_resources_articles_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_resources_articles_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_resources_articles_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_resources_articles_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Resources_Articles_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_resources_articles_Create'] = ResolversParentTypes['TaskTemplatesDocAccessFields_resources_articles_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Resources_Articles_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_resources_articles_Delete'] = ResolversParentTypes['TaskTemplatesDocAccessFields_resources_articles_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Resources_Articles_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_resources_articles_Read'] = ResolversParentTypes['TaskTemplatesDocAccessFields_resources_articles_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Resources_Articles_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_resources_articles_Update'] = ResolversParentTypes['TaskTemplatesDocAccessFields_resources_articles_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Resources_VideosResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_resources_videos'] = ResolversParentTypes['TaskTemplatesDocAccessFields_resources_videos']> = {
  create?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_resources_videos_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_resources_videos_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_resources_videos_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_resources_videos_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Resources_Videos_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_resources_videos_Create'] = ResolversParentTypes['TaskTemplatesDocAccessFields_resources_videos_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Resources_Videos_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_resources_videos_Delete'] = ResolversParentTypes['TaskTemplatesDocAccessFields_resources_videos_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Resources_Videos_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_resources_videos_Read'] = ResolversParentTypes['TaskTemplatesDocAccessFields_resources_videos_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Resources_Videos_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_resources_videos_Update'] = ResolversParentTypes['TaskTemplatesDocAccessFields_resources_videos_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_ReviewResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_review'] = ResolversParentTypes['TaskTemplatesDocAccessFields_review']> = {
  create?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_review_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_review_Delete']>, ParentType, ContextType>;
  fields?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_review_Fields']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_review_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_review_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Review_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_review_Create'] = ResolversParentTypes['TaskTemplatesDocAccessFields_review_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Review_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_review_Delete'] = ResolversParentTypes['TaskTemplatesDocAccessFields_review_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Review_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_review_Fields'] = ResolversParentTypes['TaskTemplatesDocAccessFields_review_Fields']> = {
  approver?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_review_approver']>, ParentType, ContextType>;
  evidence?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_review_evidence']>, ParentType, ContextType>;
  required?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_review_required']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Review_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_review_Read'] = ResolversParentTypes['TaskTemplatesDocAccessFields_review_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Review_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_review_Update'] = ResolversParentTypes['TaskTemplatesDocAccessFields_review_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Review_ApproverResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_review_approver'] = ResolversParentTypes['TaskTemplatesDocAccessFields_review_approver']> = {
  create?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_review_approver_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_review_approver_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_review_approver_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_review_approver_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Review_Approver_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_review_approver_Create'] = ResolversParentTypes['TaskTemplatesDocAccessFields_review_approver_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Review_Approver_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_review_approver_Delete'] = ResolversParentTypes['TaskTemplatesDocAccessFields_review_approver_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Review_Approver_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_review_approver_Read'] = ResolversParentTypes['TaskTemplatesDocAccessFields_review_approver_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Review_Approver_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_review_approver_Update'] = ResolversParentTypes['TaskTemplatesDocAccessFields_review_approver_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Review_EvidenceResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_review_evidence'] = ResolversParentTypes['TaskTemplatesDocAccessFields_review_evidence']> = {
  create?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_review_evidence_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_review_evidence_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_review_evidence_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_review_evidence_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Review_Evidence_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_review_evidence_Create'] = ResolversParentTypes['TaskTemplatesDocAccessFields_review_evidence_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Review_Evidence_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_review_evidence_Delete'] = ResolversParentTypes['TaskTemplatesDocAccessFields_review_evidence_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Review_Evidence_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_review_evidence_Read'] = ResolversParentTypes['TaskTemplatesDocAccessFields_review_evidence_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Review_Evidence_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_review_evidence_Update'] = ResolversParentTypes['TaskTemplatesDocAccessFields_review_evidence_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Review_RequiredResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_review_required'] = ResolversParentTypes['TaskTemplatesDocAccessFields_review_required']> = {
  create?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_review_required_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_review_required_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_review_required_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_review_required_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Review_Required_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_review_required_Create'] = ResolversParentTypes['TaskTemplatesDocAccessFields_review_required_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Review_Required_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_review_required_Delete'] = ResolversParentTypes['TaskTemplatesDocAccessFields_review_required_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Review_Required_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_review_required_Read'] = ResolversParentTypes['TaskTemplatesDocAccessFields_review_required_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Review_Required_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_review_required_Update'] = ResolversParentTypes['TaskTemplatesDocAccessFields_review_required_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_ScheduleResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_schedule'] = ResolversParentTypes['TaskTemplatesDocAccessFields_schedule']> = {
  create?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_schedule_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_schedule_Delete']>, ParentType, ContextType>;
  fields?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_schedule_Fields']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_schedule_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_schedule_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Schedule_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_schedule_Create'] = ResolversParentTypes['TaskTemplatesDocAccessFields_schedule_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Schedule_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_schedule_Delete'] = ResolversParentTypes['TaskTemplatesDocAccessFields_schedule_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Schedule_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_schedule_Fields'] = ResolversParentTypes['TaskTemplatesDocAccessFields_schedule_Fields']> = {
  due?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_schedule_due']>, ParentType, ContextType>;
  event?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_schedule_event']>, ParentType, ContextType>;
  freq?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_schedule_freq']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_schedule_id']>, ParentType, ContextType>;
  interval?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_schedule_interval']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Schedule_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_schedule_Read'] = ResolversParentTypes['TaskTemplatesDocAccessFields_schedule_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Schedule_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_schedule_Update'] = ResolversParentTypes['TaskTemplatesDocAccessFields_schedule_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Schedule_DueResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_schedule_due'] = ResolversParentTypes['TaskTemplatesDocAccessFields_schedule_due']> = {
  create?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_schedule_due_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_schedule_due_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_schedule_due_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_schedule_due_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Schedule_Due_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_schedule_due_Create'] = ResolversParentTypes['TaskTemplatesDocAccessFields_schedule_due_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Schedule_Due_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_schedule_due_Delete'] = ResolversParentTypes['TaskTemplatesDocAccessFields_schedule_due_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Schedule_Due_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_schedule_due_Read'] = ResolversParentTypes['TaskTemplatesDocAccessFields_schedule_due_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Schedule_Due_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_schedule_due_Update'] = ResolversParentTypes['TaskTemplatesDocAccessFields_schedule_due_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Schedule_EventResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_schedule_event'] = ResolversParentTypes['TaskTemplatesDocAccessFields_schedule_event']> = {
  create?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_schedule_event_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_schedule_event_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_schedule_event_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_schedule_event_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Schedule_Event_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_schedule_event_Create'] = ResolversParentTypes['TaskTemplatesDocAccessFields_schedule_event_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Schedule_Event_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_schedule_event_Delete'] = ResolversParentTypes['TaskTemplatesDocAccessFields_schedule_event_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Schedule_Event_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_schedule_event_Read'] = ResolversParentTypes['TaskTemplatesDocAccessFields_schedule_event_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Schedule_Event_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_schedule_event_Update'] = ResolversParentTypes['TaskTemplatesDocAccessFields_schedule_event_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Schedule_FreqResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_schedule_freq'] = ResolversParentTypes['TaskTemplatesDocAccessFields_schedule_freq']> = {
  create?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_schedule_freq_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_schedule_freq_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_schedule_freq_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_schedule_freq_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Schedule_Freq_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_schedule_freq_Create'] = ResolversParentTypes['TaskTemplatesDocAccessFields_schedule_freq_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Schedule_Freq_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_schedule_freq_Delete'] = ResolversParentTypes['TaskTemplatesDocAccessFields_schedule_freq_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Schedule_Freq_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_schedule_freq_Read'] = ResolversParentTypes['TaskTemplatesDocAccessFields_schedule_freq_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Schedule_Freq_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_schedule_freq_Update'] = ResolversParentTypes['TaskTemplatesDocAccessFields_schedule_freq_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Schedule_IdResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_schedule_id'] = ResolversParentTypes['TaskTemplatesDocAccessFields_schedule_id']> = {
  create?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_schedule_id_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_schedule_id_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_schedule_id_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_schedule_id_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Schedule_Id_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_schedule_id_Create'] = ResolversParentTypes['TaskTemplatesDocAccessFields_schedule_id_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Schedule_Id_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_schedule_id_Delete'] = ResolversParentTypes['TaskTemplatesDocAccessFields_schedule_id_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Schedule_Id_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_schedule_id_Read'] = ResolversParentTypes['TaskTemplatesDocAccessFields_schedule_id_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Schedule_Id_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_schedule_id_Update'] = ResolversParentTypes['TaskTemplatesDocAccessFields_schedule_id_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Schedule_IntervalResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_schedule_interval'] = ResolversParentTypes['TaskTemplatesDocAccessFields_schedule_interval']> = {
  create?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_schedule_interval_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_schedule_interval_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_schedule_interval_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_schedule_interval_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Schedule_Interval_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_schedule_interval_Create'] = ResolversParentTypes['TaskTemplatesDocAccessFields_schedule_interval_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Schedule_Interval_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_schedule_interval_Delete'] = ResolversParentTypes['TaskTemplatesDocAccessFields_schedule_interval_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Schedule_Interval_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_schedule_interval_Read'] = ResolversParentTypes['TaskTemplatesDocAccessFields_schedule_interval_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Schedule_Interval_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_schedule_interval_Update'] = ResolversParentTypes['TaskTemplatesDocAccessFields_schedule_interval_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_ThumbnailResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_thumbnail'] = ResolversParentTypes['TaskTemplatesDocAccessFields_thumbnail']> = {
  create?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_thumbnail_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_thumbnail_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_thumbnail_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_thumbnail_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Thumbnail_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_thumbnail_Create'] = ResolversParentTypes['TaskTemplatesDocAccessFields_thumbnail_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Thumbnail_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_thumbnail_Delete'] = ResolversParentTypes['TaskTemplatesDocAccessFields_thumbnail_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Thumbnail_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_thumbnail_Read'] = ResolversParentTypes['TaskTemplatesDocAccessFields_thumbnail_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Thumbnail_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_thumbnail_Update'] = ResolversParentTypes['TaskTemplatesDocAccessFields_thumbnail_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_TitleResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_title'] = ResolversParentTypes['TaskTemplatesDocAccessFields_title']> = {
  create?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_title_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_title_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_title_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_title_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Title_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_title_Create'] = ResolversParentTypes['TaskTemplatesDocAccessFields_title_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Title_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_title_Delete'] = ResolversParentTypes['TaskTemplatesDocAccessFields_title_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Title_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_title_Read'] = ResolversParentTypes['TaskTemplatesDocAccessFields_title_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_Title_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_title_Update'] = ResolversParentTypes['TaskTemplatesDocAccessFields_title_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_UpdatedAtResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_updatedAt'] = ResolversParentTypes['TaskTemplatesDocAccessFields_updatedAt']> = {
  create?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_updatedAt_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_updatedAt_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_updatedAt_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields_updatedAt_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_UpdatedAt_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_updatedAt_Create'] = ResolversParentTypes['TaskTemplatesDocAccessFields_updatedAt_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_UpdatedAt_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_updatedAt_Delete'] = ResolversParentTypes['TaskTemplatesDocAccessFields_updatedAt_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_UpdatedAt_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_updatedAt_Read'] = ResolversParentTypes['TaskTemplatesDocAccessFields_updatedAt_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesDocAccessFields_UpdatedAt_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesDocAccessFields_updatedAt_Update'] = ResolversParentTypes['TaskTemplatesDocAccessFields_updatedAt_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields'] = ResolversParentTypes['TaskTemplatesFields']> = {
  _status?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields__status']>, ParentType, ContextType>;
  assignment?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_assignment']>, ParentType, ContextType>;
  categories?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_categories']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_createdAt']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_description']>, ParentType, ContextType>;
  details?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_details']>, ParentType, ContextType>;
  feature?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_feature']>, ParentType, ContextType>;
  resources?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_resources']>, ParentType, ContextType>;
  review?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_review']>, ParentType, ContextType>;
  schedule?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_schedule']>, ParentType, ContextType>;
  thumbnail?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_thumbnail']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_title']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_updatedAt']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields__StatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields__status'] = ResolversParentTypes['TaskTemplatesFields__status']> = {
  create?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields__status_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields__status_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields__status_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields__status_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields__Status_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields__status_Create'] = ResolversParentTypes['TaskTemplatesFields__status_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields__Status_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields__status_Delete'] = ResolversParentTypes['TaskTemplatesFields__status_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields__Status_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields__status_Read'] = ResolversParentTypes['TaskTemplatesFields__status_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields__Status_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields__status_Update'] = ResolversParentTypes['TaskTemplatesFields__status_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_AssignmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_assignment'] = ResolversParentTypes['TaskTemplatesFields_assignment']> = {
  create?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_assignment_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_assignment_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_assignment_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_assignment_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Assignment_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_assignment_Create'] = ResolversParentTypes['TaskTemplatesFields_assignment_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Assignment_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_assignment_Delete'] = ResolversParentTypes['TaskTemplatesFields_assignment_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Assignment_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_assignment_Read'] = ResolversParentTypes['TaskTemplatesFields_assignment_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Assignment_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_assignment_Update'] = ResolversParentTypes['TaskTemplatesFields_assignment_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_CategoriesResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_categories'] = ResolversParentTypes['TaskTemplatesFields_categories']> = {
  create?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_categories_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_categories_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_categories_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_categories_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Categories_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_categories_Create'] = ResolversParentTypes['TaskTemplatesFields_categories_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Categories_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_categories_Delete'] = ResolversParentTypes['TaskTemplatesFields_categories_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Categories_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_categories_Read'] = ResolversParentTypes['TaskTemplatesFields_categories_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Categories_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_categories_Update'] = ResolversParentTypes['TaskTemplatesFields_categories_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_CreatedAtResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_createdAt'] = ResolversParentTypes['TaskTemplatesFields_createdAt']> = {
  create?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_createdAt_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_createdAt_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_createdAt_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_createdAt_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_CreatedAt_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_createdAt_Create'] = ResolversParentTypes['TaskTemplatesFields_createdAt_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_CreatedAt_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_createdAt_Delete'] = ResolversParentTypes['TaskTemplatesFields_createdAt_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_CreatedAt_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_createdAt_Read'] = ResolversParentTypes['TaskTemplatesFields_createdAt_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_CreatedAt_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_createdAt_Update'] = ResolversParentTypes['TaskTemplatesFields_createdAt_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_DescriptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_description'] = ResolversParentTypes['TaskTemplatesFields_description']> = {
  create?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_description_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_description_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_description_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_description_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Description_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_description_Create'] = ResolversParentTypes['TaskTemplatesFields_description_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Description_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_description_Delete'] = ResolversParentTypes['TaskTemplatesFields_description_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Description_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_description_Read'] = ResolversParentTypes['TaskTemplatesFields_description_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Description_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_description_Update'] = ResolversParentTypes['TaskTemplatesFields_description_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_DetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_details'] = ResolversParentTypes['TaskTemplatesFields_details']> = {
  create?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_details_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_details_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_details_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_details_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Details_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_details_Create'] = ResolversParentTypes['TaskTemplatesFields_details_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Details_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_details_Delete'] = ResolversParentTypes['TaskTemplatesFields_details_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Details_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_details_Read'] = ResolversParentTypes['TaskTemplatesFields_details_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Details_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_details_Update'] = ResolversParentTypes['TaskTemplatesFields_details_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_FeatureResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_feature'] = ResolversParentTypes['TaskTemplatesFields_feature']> = {
  create?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_feature_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_feature_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_feature_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_feature_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Feature_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_feature_Create'] = ResolversParentTypes['TaskTemplatesFields_feature_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Feature_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_feature_Delete'] = ResolversParentTypes['TaskTemplatesFields_feature_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Feature_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_feature_Read'] = ResolversParentTypes['TaskTemplatesFields_feature_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Feature_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_feature_Update'] = ResolversParentTypes['TaskTemplatesFields_feature_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_ResourcesResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_resources'] = ResolversParentTypes['TaskTemplatesFields_resources']> = {
  create?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_resources_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_resources_Delete']>, ParentType, ContextType>;
  fields?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_resources_Fields']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_resources_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_resources_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Resources_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_resources_Create'] = ResolversParentTypes['TaskTemplatesFields_resources_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Resources_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_resources_Delete'] = ResolversParentTypes['TaskTemplatesFields_resources_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Resources_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_resources_Fields'] = ResolversParentTypes['TaskTemplatesFields_resources_Fields']> = {
  articles?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_resources_articles']>, ParentType, ContextType>;
  videos?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_resources_videos']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Resources_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_resources_Read'] = ResolversParentTypes['TaskTemplatesFields_resources_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Resources_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_resources_Update'] = ResolversParentTypes['TaskTemplatesFields_resources_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Resources_ArticlesResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_resources_articles'] = ResolversParentTypes['TaskTemplatesFields_resources_articles']> = {
  create?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_resources_articles_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_resources_articles_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_resources_articles_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_resources_articles_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Resources_Articles_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_resources_articles_Create'] = ResolversParentTypes['TaskTemplatesFields_resources_articles_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Resources_Articles_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_resources_articles_Delete'] = ResolversParentTypes['TaskTemplatesFields_resources_articles_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Resources_Articles_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_resources_articles_Read'] = ResolversParentTypes['TaskTemplatesFields_resources_articles_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Resources_Articles_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_resources_articles_Update'] = ResolversParentTypes['TaskTemplatesFields_resources_articles_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Resources_VideosResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_resources_videos'] = ResolversParentTypes['TaskTemplatesFields_resources_videos']> = {
  create?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_resources_videos_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_resources_videos_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_resources_videos_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_resources_videos_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Resources_Videos_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_resources_videos_Create'] = ResolversParentTypes['TaskTemplatesFields_resources_videos_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Resources_Videos_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_resources_videos_Delete'] = ResolversParentTypes['TaskTemplatesFields_resources_videos_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Resources_Videos_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_resources_videos_Read'] = ResolversParentTypes['TaskTemplatesFields_resources_videos_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Resources_Videos_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_resources_videos_Update'] = ResolversParentTypes['TaskTemplatesFields_resources_videos_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_ReviewResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_review'] = ResolversParentTypes['TaskTemplatesFields_review']> = {
  create?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_review_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_review_Delete']>, ParentType, ContextType>;
  fields?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_review_Fields']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_review_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_review_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Review_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_review_Create'] = ResolversParentTypes['TaskTemplatesFields_review_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Review_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_review_Delete'] = ResolversParentTypes['TaskTemplatesFields_review_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Review_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_review_Fields'] = ResolversParentTypes['TaskTemplatesFields_review_Fields']> = {
  approver?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_review_approver']>, ParentType, ContextType>;
  evidence?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_review_evidence']>, ParentType, ContextType>;
  required?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_review_required']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Review_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_review_Read'] = ResolversParentTypes['TaskTemplatesFields_review_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Review_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_review_Update'] = ResolversParentTypes['TaskTemplatesFields_review_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Review_ApproverResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_review_approver'] = ResolversParentTypes['TaskTemplatesFields_review_approver']> = {
  create?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_review_approver_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_review_approver_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_review_approver_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_review_approver_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Review_Approver_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_review_approver_Create'] = ResolversParentTypes['TaskTemplatesFields_review_approver_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Review_Approver_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_review_approver_Delete'] = ResolversParentTypes['TaskTemplatesFields_review_approver_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Review_Approver_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_review_approver_Read'] = ResolversParentTypes['TaskTemplatesFields_review_approver_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Review_Approver_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_review_approver_Update'] = ResolversParentTypes['TaskTemplatesFields_review_approver_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Review_EvidenceResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_review_evidence'] = ResolversParentTypes['TaskTemplatesFields_review_evidence']> = {
  create?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_review_evidence_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_review_evidence_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_review_evidence_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_review_evidence_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Review_Evidence_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_review_evidence_Create'] = ResolversParentTypes['TaskTemplatesFields_review_evidence_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Review_Evidence_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_review_evidence_Delete'] = ResolversParentTypes['TaskTemplatesFields_review_evidence_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Review_Evidence_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_review_evidence_Read'] = ResolversParentTypes['TaskTemplatesFields_review_evidence_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Review_Evidence_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_review_evidence_Update'] = ResolversParentTypes['TaskTemplatesFields_review_evidence_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Review_RequiredResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_review_required'] = ResolversParentTypes['TaskTemplatesFields_review_required']> = {
  create?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_review_required_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_review_required_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_review_required_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_review_required_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Review_Required_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_review_required_Create'] = ResolversParentTypes['TaskTemplatesFields_review_required_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Review_Required_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_review_required_Delete'] = ResolversParentTypes['TaskTemplatesFields_review_required_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Review_Required_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_review_required_Read'] = ResolversParentTypes['TaskTemplatesFields_review_required_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Review_Required_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_review_required_Update'] = ResolversParentTypes['TaskTemplatesFields_review_required_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_ScheduleResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_schedule'] = ResolversParentTypes['TaskTemplatesFields_schedule']> = {
  create?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_schedule_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_schedule_Delete']>, ParentType, ContextType>;
  fields?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_schedule_Fields']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_schedule_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_schedule_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Schedule_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_schedule_Create'] = ResolversParentTypes['TaskTemplatesFields_schedule_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Schedule_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_schedule_Delete'] = ResolversParentTypes['TaskTemplatesFields_schedule_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Schedule_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_schedule_Fields'] = ResolversParentTypes['TaskTemplatesFields_schedule_Fields']> = {
  due?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_schedule_due']>, ParentType, ContextType>;
  event?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_schedule_event']>, ParentType, ContextType>;
  freq?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_schedule_freq']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_schedule_id']>, ParentType, ContextType>;
  interval?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_schedule_interval']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Schedule_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_schedule_Read'] = ResolversParentTypes['TaskTemplatesFields_schedule_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Schedule_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_schedule_Update'] = ResolversParentTypes['TaskTemplatesFields_schedule_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Schedule_DueResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_schedule_due'] = ResolversParentTypes['TaskTemplatesFields_schedule_due']> = {
  create?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_schedule_due_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_schedule_due_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_schedule_due_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_schedule_due_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Schedule_Due_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_schedule_due_Create'] = ResolversParentTypes['TaskTemplatesFields_schedule_due_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Schedule_Due_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_schedule_due_Delete'] = ResolversParentTypes['TaskTemplatesFields_schedule_due_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Schedule_Due_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_schedule_due_Read'] = ResolversParentTypes['TaskTemplatesFields_schedule_due_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Schedule_Due_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_schedule_due_Update'] = ResolversParentTypes['TaskTemplatesFields_schedule_due_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Schedule_EventResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_schedule_event'] = ResolversParentTypes['TaskTemplatesFields_schedule_event']> = {
  create?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_schedule_event_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_schedule_event_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_schedule_event_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_schedule_event_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Schedule_Event_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_schedule_event_Create'] = ResolversParentTypes['TaskTemplatesFields_schedule_event_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Schedule_Event_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_schedule_event_Delete'] = ResolversParentTypes['TaskTemplatesFields_schedule_event_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Schedule_Event_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_schedule_event_Read'] = ResolversParentTypes['TaskTemplatesFields_schedule_event_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Schedule_Event_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_schedule_event_Update'] = ResolversParentTypes['TaskTemplatesFields_schedule_event_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Schedule_FreqResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_schedule_freq'] = ResolversParentTypes['TaskTemplatesFields_schedule_freq']> = {
  create?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_schedule_freq_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_schedule_freq_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_schedule_freq_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_schedule_freq_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Schedule_Freq_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_schedule_freq_Create'] = ResolversParentTypes['TaskTemplatesFields_schedule_freq_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Schedule_Freq_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_schedule_freq_Delete'] = ResolversParentTypes['TaskTemplatesFields_schedule_freq_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Schedule_Freq_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_schedule_freq_Read'] = ResolversParentTypes['TaskTemplatesFields_schedule_freq_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Schedule_Freq_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_schedule_freq_Update'] = ResolversParentTypes['TaskTemplatesFields_schedule_freq_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Schedule_IdResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_schedule_id'] = ResolversParentTypes['TaskTemplatesFields_schedule_id']> = {
  create?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_schedule_id_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_schedule_id_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_schedule_id_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_schedule_id_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Schedule_Id_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_schedule_id_Create'] = ResolversParentTypes['TaskTemplatesFields_schedule_id_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Schedule_Id_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_schedule_id_Delete'] = ResolversParentTypes['TaskTemplatesFields_schedule_id_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Schedule_Id_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_schedule_id_Read'] = ResolversParentTypes['TaskTemplatesFields_schedule_id_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Schedule_Id_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_schedule_id_Update'] = ResolversParentTypes['TaskTemplatesFields_schedule_id_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Schedule_IntervalResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_schedule_interval'] = ResolversParentTypes['TaskTemplatesFields_schedule_interval']> = {
  create?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_schedule_interval_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_schedule_interval_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_schedule_interval_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_schedule_interval_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Schedule_Interval_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_schedule_interval_Create'] = ResolversParentTypes['TaskTemplatesFields_schedule_interval_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Schedule_Interval_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_schedule_interval_Delete'] = ResolversParentTypes['TaskTemplatesFields_schedule_interval_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Schedule_Interval_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_schedule_interval_Read'] = ResolversParentTypes['TaskTemplatesFields_schedule_interval_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Schedule_Interval_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_schedule_interval_Update'] = ResolversParentTypes['TaskTemplatesFields_schedule_interval_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_ThumbnailResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_thumbnail'] = ResolversParentTypes['TaskTemplatesFields_thumbnail']> = {
  create?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_thumbnail_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_thumbnail_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_thumbnail_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_thumbnail_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Thumbnail_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_thumbnail_Create'] = ResolversParentTypes['TaskTemplatesFields_thumbnail_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Thumbnail_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_thumbnail_Delete'] = ResolversParentTypes['TaskTemplatesFields_thumbnail_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Thumbnail_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_thumbnail_Read'] = ResolversParentTypes['TaskTemplatesFields_thumbnail_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Thumbnail_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_thumbnail_Update'] = ResolversParentTypes['TaskTemplatesFields_thumbnail_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_TitleResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_title'] = ResolversParentTypes['TaskTemplatesFields_title']> = {
  create?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_title_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_title_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_title_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_title_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Title_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_title_Create'] = ResolversParentTypes['TaskTemplatesFields_title_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Title_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_title_Delete'] = ResolversParentTypes['TaskTemplatesFields_title_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Title_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_title_Read'] = ResolversParentTypes['TaskTemplatesFields_title_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_Title_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_title_Update'] = ResolversParentTypes['TaskTemplatesFields_title_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_UpdatedAtResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_updatedAt'] = ResolversParentTypes['TaskTemplatesFields_updatedAt']> = {
  create?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_updatedAt_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_updatedAt_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_updatedAt_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields_updatedAt_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_UpdatedAt_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_updatedAt_Create'] = ResolversParentTypes['TaskTemplatesFields_updatedAt_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_UpdatedAt_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_updatedAt_Delete'] = ResolversParentTypes['TaskTemplatesFields_updatedAt_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_UpdatedAt_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_updatedAt_Read'] = ResolversParentTypes['TaskTemplatesFields_updatedAt_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesFields_UpdatedAt_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesFields_updatedAt_Update'] = ResolversParentTypes['TaskTemplatesFields_updatedAt_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesReadAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesReadAccess'] = ResolversParentTypes['TaskTemplatesReadAccess']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  where?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesReadDocAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesReadDocAccess'] = ResolversParentTypes['TaskTemplatesReadDocAccess']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  where?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesReadVersionsAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesReadVersionsAccess'] = ResolversParentTypes['TaskTemplatesReadVersionsAccess']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  where?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesReadVersionsDocAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesReadVersionsDocAccess'] = ResolversParentTypes['TaskTemplatesReadVersionsDocAccess']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  where?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesUpdateAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesUpdateAccess'] = ResolversParentTypes['TaskTemplatesUpdateAccess']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  where?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTemplatesUpdateDocAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskTemplatesUpdateDocAccess'] = ResolversParentTypes['TaskTemplatesUpdateDocAccess']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  where?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['EmailAddress'], ParentType, ContextType>;
  hash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lockUntil?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  loginAttempts?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  password?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  resetPasswordExpiration?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  resetPasswordToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  roles?: Resolver<Maybe<Array<ResolversTypes['User_roles']>>, ParentType, ContextType>;
  salt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersResolvers<ContextType = any, ParentType extends ResolversParentTypes['Users'] = ResolversParentTypes['Users']> = {
  docs?: Resolver<Maybe<Array<Maybe<ResolversTypes['User']>>>, ParentType, ContextType>;
  hasNextPage?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasPrevPage?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  limit?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  nextPage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  offset?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  page?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pagingCounter?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  prevPage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalDocs?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalPages?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersCreateAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersCreateAccess'] = ResolversParentTypes['UsersCreateAccess']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  where?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersCreateDocAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersCreateDocAccess'] = ResolversParentTypes['UsersCreateDocAccess']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  where?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersDeleteAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersDeleteAccess'] = ResolversParentTypes['UsersDeleteAccess']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  where?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersDeleteDocAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersDeleteDocAccess'] = ResolversParentTypes['UsersDeleteDocAccess']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  where?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersDocAccessFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersDocAccessFields'] = ResolversParentTypes['UsersDocAccessFields']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['UsersDocAccessFields_createdAt']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['UsersDocAccessFields_email']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['UsersDocAccessFields_name']>, ParentType, ContextType>;
  password?: Resolver<Maybe<ResolversTypes['UsersDocAccessFields_password']>, ParentType, ContextType>;
  roles?: Resolver<Maybe<ResolversTypes['UsersDocAccessFields_roles']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['UsersDocAccessFields_updatedAt']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersDocAccessFields_CreatedAtResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersDocAccessFields_createdAt'] = ResolversParentTypes['UsersDocAccessFields_createdAt']> = {
  create?: Resolver<Maybe<ResolversTypes['UsersDocAccessFields_createdAt_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['UsersDocAccessFields_createdAt_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['UsersDocAccessFields_createdAt_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['UsersDocAccessFields_createdAt_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersDocAccessFields_CreatedAt_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersDocAccessFields_createdAt_Create'] = ResolversParentTypes['UsersDocAccessFields_createdAt_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersDocAccessFields_CreatedAt_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersDocAccessFields_createdAt_Delete'] = ResolversParentTypes['UsersDocAccessFields_createdAt_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersDocAccessFields_CreatedAt_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersDocAccessFields_createdAt_Read'] = ResolversParentTypes['UsersDocAccessFields_createdAt_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersDocAccessFields_CreatedAt_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersDocAccessFields_createdAt_Update'] = ResolversParentTypes['UsersDocAccessFields_createdAt_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersDocAccessFields_EmailResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersDocAccessFields_email'] = ResolversParentTypes['UsersDocAccessFields_email']> = {
  create?: Resolver<Maybe<ResolversTypes['UsersDocAccessFields_email_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['UsersDocAccessFields_email_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['UsersDocAccessFields_email_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['UsersDocAccessFields_email_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersDocAccessFields_Email_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersDocAccessFields_email_Create'] = ResolversParentTypes['UsersDocAccessFields_email_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersDocAccessFields_Email_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersDocAccessFields_email_Delete'] = ResolversParentTypes['UsersDocAccessFields_email_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersDocAccessFields_Email_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersDocAccessFields_email_Read'] = ResolversParentTypes['UsersDocAccessFields_email_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersDocAccessFields_Email_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersDocAccessFields_email_Update'] = ResolversParentTypes['UsersDocAccessFields_email_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersDocAccessFields_NameResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersDocAccessFields_name'] = ResolversParentTypes['UsersDocAccessFields_name']> = {
  create?: Resolver<Maybe<ResolversTypes['UsersDocAccessFields_name_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['UsersDocAccessFields_name_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['UsersDocAccessFields_name_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['UsersDocAccessFields_name_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersDocAccessFields_Name_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersDocAccessFields_name_Create'] = ResolversParentTypes['UsersDocAccessFields_name_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersDocAccessFields_Name_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersDocAccessFields_name_Delete'] = ResolversParentTypes['UsersDocAccessFields_name_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersDocAccessFields_Name_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersDocAccessFields_name_Read'] = ResolversParentTypes['UsersDocAccessFields_name_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersDocAccessFields_Name_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersDocAccessFields_name_Update'] = ResolversParentTypes['UsersDocAccessFields_name_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersDocAccessFields_PasswordResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersDocAccessFields_password'] = ResolversParentTypes['UsersDocAccessFields_password']> = {
  create?: Resolver<Maybe<ResolversTypes['UsersDocAccessFields_password_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['UsersDocAccessFields_password_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['UsersDocAccessFields_password_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['UsersDocAccessFields_password_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersDocAccessFields_Password_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersDocAccessFields_password_Create'] = ResolversParentTypes['UsersDocAccessFields_password_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersDocAccessFields_Password_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersDocAccessFields_password_Delete'] = ResolversParentTypes['UsersDocAccessFields_password_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersDocAccessFields_Password_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersDocAccessFields_password_Read'] = ResolversParentTypes['UsersDocAccessFields_password_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersDocAccessFields_Password_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersDocAccessFields_password_Update'] = ResolversParentTypes['UsersDocAccessFields_password_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersDocAccessFields_RolesResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersDocAccessFields_roles'] = ResolversParentTypes['UsersDocAccessFields_roles']> = {
  create?: Resolver<Maybe<ResolversTypes['UsersDocAccessFields_roles_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['UsersDocAccessFields_roles_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['UsersDocAccessFields_roles_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['UsersDocAccessFields_roles_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersDocAccessFields_Roles_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersDocAccessFields_roles_Create'] = ResolversParentTypes['UsersDocAccessFields_roles_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersDocAccessFields_Roles_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersDocAccessFields_roles_Delete'] = ResolversParentTypes['UsersDocAccessFields_roles_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersDocAccessFields_Roles_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersDocAccessFields_roles_Read'] = ResolversParentTypes['UsersDocAccessFields_roles_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersDocAccessFields_Roles_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersDocAccessFields_roles_Update'] = ResolversParentTypes['UsersDocAccessFields_roles_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersDocAccessFields_UpdatedAtResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersDocAccessFields_updatedAt'] = ResolversParentTypes['UsersDocAccessFields_updatedAt']> = {
  create?: Resolver<Maybe<ResolversTypes['UsersDocAccessFields_updatedAt_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['UsersDocAccessFields_updatedAt_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['UsersDocAccessFields_updatedAt_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['UsersDocAccessFields_updatedAt_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersDocAccessFields_UpdatedAt_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersDocAccessFields_updatedAt_Create'] = ResolversParentTypes['UsersDocAccessFields_updatedAt_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersDocAccessFields_UpdatedAt_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersDocAccessFields_updatedAt_Delete'] = ResolversParentTypes['UsersDocAccessFields_updatedAt_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersDocAccessFields_UpdatedAt_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersDocAccessFields_updatedAt_Read'] = ResolversParentTypes['UsersDocAccessFields_updatedAt_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersDocAccessFields_UpdatedAt_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersDocAccessFields_updatedAt_Update'] = ResolversParentTypes['UsersDocAccessFields_updatedAt_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersFields'] = ResolversParentTypes['UsersFields']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['UsersFields_createdAt']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['UsersFields_email']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['UsersFields_name']>, ParentType, ContextType>;
  password?: Resolver<Maybe<ResolversTypes['UsersFields_password']>, ParentType, ContextType>;
  roles?: Resolver<Maybe<ResolversTypes['UsersFields_roles']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['UsersFields_updatedAt']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersFields_CreatedAtResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersFields_createdAt'] = ResolversParentTypes['UsersFields_createdAt']> = {
  create?: Resolver<Maybe<ResolversTypes['UsersFields_createdAt_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['UsersFields_createdAt_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['UsersFields_createdAt_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['UsersFields_createdAt_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersFields_CreatedAt_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersFields_createdAt_Create'] = ResolversParentTypes['UsersFields_createdAt_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersFields_CreatedAt_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersFields_createdAt_Delete'] = ResolversParentTypes['UsersFields_createdAt_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersFields_CreatedAt_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersFields_createdAt_Read'] = ResolversParentTypes['UsersFields_createdAt_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersFields_CreatedAt_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersFields_createdAt_Update'] = ResolversParentTypes['UsersFields_createdAt_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersFields_EmailResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersFields_email'] = ResolversParentTypes['UsersFields_email']> = {
  create?: Resolver<Maybe<ResolversTypes['UsersFields_email_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['UsersFields_email_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['UsersFields_email_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['UsersFields_email_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersFields_Email_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersFields_email_Create'] = ResolversParentTypes['UsersFields_email_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersFields_Email_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersFields_email_Delete'] = ResolversParentTypes['UsersFields_email_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersFields_Email_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersFields_email_Read'] = ResolversParentTypes['UsersFields_email_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersFields_Email_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersFields_email_Update'] = ResolversParentTypes['UsersFields_email_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersFields_NameResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersFields_name'] = ResolversParentTypes['UsersFields_name']> = {
  create?: Resolver<Maybe<ResolversTypes['UsersFields_name_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['UsersFields_name_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['UsersFields_name_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['UsersFields_name_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersFields_Name_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersFields_name_Create'] = ResolversParentTypes['UsersFields_name_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersFields_Name_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersFields_name_Delete'] = ResolversParentTypes['UsersFields_name_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersFields_Name_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersFields_name_Read'] = ResolversParentTypes['UsersFields_name_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersFields_Name_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersFields_name_Update'] = ResolversParentTypes['UsersFields_name_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersFields_PasswordResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersFields_password'] = ResolversParentTypes['UsersFields_password']> = {
  create?: Resolver<Maybe<ResolversTypes['UsersFields_password_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['UsersFields_password_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['UsersFields_password_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['UsersFields_password_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersFields_Password_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersFields_password_Create'] = ResolversParentTypes['UsersFields_password_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersFields_Password_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersFields_password_Delete'] = ResolversParentTypes['UsersFields_password_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersFields_Password_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersFields_password_Read'] = ResolversParentTypes['UsersFields_password_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersFields_Password_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersFields_password_Update'] = ResolversParentTypes['UsersFields_password_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersFields_RolesResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersFields_roles'] = ResolversParentTypes['UsersFields_roles']> = {
  create?: Resolver<Maybe<ResolversTypes['UsersFields_roles_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['UsersFields_roles_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['UsersFields_roles_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['UsersFields_roles_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersFields_Roles_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersFields_roles_Create'] = ResolversParentTypes['UsersFields_roles_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersFields_Roles_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersFields_roles_Delete'] = ResolversParentTypes['UsersFields_roles_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersFields_Roles_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersFields_roles_Read'] = ResolversParentTypes['UsersFields_roles_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersFields_Roles_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersFields_roles_Update'] = ResolversParentTypes['UsersFields_roles_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersFields_UpdatedAtResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersFields_updatedAt'] = ResolversParentTypes['UsersFields_updatedAt']> = {
  create?: Resolver<Maybe<ResolversTypes['UsersFields_updatedAt_Create']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['UsersFields_updatedAt_Delete']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['UsersFields_updatedAt_Read']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['UsersFields_updatedAt_Update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersFields_UpdatedAt_CreateResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersFields_updatedAt_Create'] = ResolversParentTypes['UsersFields_updatedAt_Create']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersFields_UpdatedAt_DeleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersFields_updatedAt_Delete'] = ResolversParentTypes['UsersFields_updatedAt_Delete']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersFields_UpdatedAt_ReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersFields_updatedAt_Read'] = ResolversParentTypes['UsersFields_updatedAt_Read']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersFields_UpdatedAt_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersFields_updatedAt_Update'] = ResolversParentTypes['UsersFields_updatedAt_Update']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersReadAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersReadAccess'] = ResolversParentTypes['UsersReadAccess']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  where?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersReadDocAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersReadDocAccess'] = ResolversParentTypes['UsersReadDocAccess']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  where?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersUnlockAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersUnlockAccess'] = ResolversParentTypes['UsersUnlockAccess']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  where?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersUnlockDocAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersUnlockDocAccess'] = ResolversParentTypes['UsersUnlockDocAccess']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  where?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersUpdateAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersUpdateAccess'] = ResolversParentTypes['UsersUpdateAccess']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  where?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersUpdateDocAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersUpdateDocAccess'] = ResolversParentTypes['UsersUpdateDocAccess']> = {
  permission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  where?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AllMediaResolvers<ContextType = any, ParentType extends ResolversParentTypes['allMedia'] = ResolversParentTypes['allMedia']> = {
  docs?: Resolver<Maybe<Array<Maybe<ResolversTypes['Media']>>>, ParentType, ContextType>;
  hasNextPage?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasPrevPage?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  limit?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  nextPage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  offset?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  page?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pagingCounter?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  prevPage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalDocs?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalPages?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['articlesAccess'] = ResolversParentTypes['articlesAccess']> = {
  create?: Resolver<Maybe<ResolversTypes['ArticlesCreateAccess']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['ArticlesDeleteAccess']>, ParentType, ContextType>;
  fields?: Resolver<Maybe<ResolversTypes['ArticlesFields']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['ArticlesReadAccess']>, ParentType, ContextType>;
  readVersions?: Resolver<Maybe<ResolversTypes['ArticlesReadVersionsAccess']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['ArticlesUpdateAccess']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticlesDocAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['articlesDocAccess'] = ResolversParentTypes['articlesDocAccess']> = {
  create?: Resolver<Maybe<ResolversTypes['ArticlesCreateDocAccess']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['ArticlesDeleteDocAccess']>, ParentType, ContextType>;
  fields?: Resolver<Maybe<ResolversTypes['ArticlesDocAccessFields']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['ArticlesReadDocAccess']>, ParentType, ContextType>;
  readVersions?: Resolver<Maybe<ResolversTypes['ArticlesReadVersionsDocAccess']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['ArticlesUpdateDocAccess']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['categoriesAccess'] = ResolversParentTypes['categoriesAccess']> = {
  create?: Resolver<Maybe<ResolversTypes['CategoriesCreateAccess']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['CategoriesDeleteAccess']>, ParentType, ContextType>;
  fields?: Resolver<Maybe<ResolversTypes['CategoriesFields']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['CategoriesReadAccess']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['CategoriesUpdateAccess']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoriesDocAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['categoriesDocAccess'] = ResolversParentTypes['categoriesDocAccess']> = {
  create?: Resolver<Maybe<ResolversTypes['CategoriesCreateDocAccess']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['CategoriesDeleteDocAccess']>, ParentType, ContextType>;
  fields?: Resolver<Maybe<ResolversTypes['CategoriesDocAccessFields']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['CategoriesReadDocAccess']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['CategoriesUpdateDocAccess']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['mediaAccess'] = ResolversParentTypes['mediaAccess']> = {
  create?: Resolver<Maybe<ResolversTypes['MediaCreateAccess']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['MediaDeleteAccess']>, ParentType, ContextType>;
  fields?: Resolver<Maybe<ResolversTypes['MediaFields']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['MediaReadAccess']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['MediaUpdateAccess']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaDocAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['mediaDocAccess'] = ResolversParentTypes['mediaDocAccess']> = {
  create?: Resolver<Maybe<ResolversTypes['MediaCreateDocAccess']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['MediaDeleteDocAccess']>, ParentType, ContextType>;
  fields?: Resolver<Maybe<ResolversTypes['MediaDocAccessFields']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['MediaReadDocAccess']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['MediaUpdateDocAccess']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Payload_PreferencesAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['payload_preferencesAccess'] = ResolversParentTypes['payload_preferencesAccess']> = {
  create?: Resolver<Maybe<ResolversTypes['PayloadPreferencesCreateAccess']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['PayloadPreferencesDeleteAccess']>, ParentType, ContextType>;
  fields?: Resolver<Maybe<ResolversTypes['PayloadPreferencesFields']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['PayloadPreferencesReadAccess']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['PayloadPreferencesUpdateAccess']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Payload_PreferencesDocAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['payload_preferencesDocAccess'] = ResolversParentTypes['payload_preferencesDocAccess']> = {
  create?: Resolver<Maybe<ResolversTypes['PayloadPreferencesCreateDocAccess']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['PayloadPreferencesDeleteDocAccess']>, ParentType, ContextType>;
  fields?: Resolver<Maybe<ResolversTypes['PayloadPreferencesDocAccessFields']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['PayloadPreferencesReadDocAccess']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['PayloadPreferencesUpdateDocAccess']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_TemplatesAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_templatesAccess'] = ResolversParentTypes['task_templatesAccess']> = {
  create?: Resolver<Maybe<ResolversTypes['TaskTemplatesCreateAccess']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['TaskTemplatesDeleteAccess']>, ParentType, ContextType>;
  fields?: Resolver<Maybe<ResolversTypes['TaskTemplatesFields']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['TaskTemplatesReadAccess']>, ParentType, ContextType>;
  readVersions?: Resolver<Maybe<ResolversTypes['TaskTemplatesReadVersionsAccess']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['TaskTemplatesUpdateAccess']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Task_TemplatesDocAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['task_templatesDocAccess'] = ResolversParentTypes['task_templatesDocAccess']> = {
  create?: Resolver<Maybe<ResolversTypes['TaskTemplatesCreateDocAccess']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['TaskTemplatesDeleteDocAccess']>, ParentType, ContextType>;
  fields?: Resolver<Maybe<ResolversTypes['TaskTemplatesDocAccessFields']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['TaskTemplatesReadDocAccess']>, ParentType, ContextType>;
  readVersions?: Resolver<Maybe<ResolversTypes['TaskTemplatesReadVersionsDocAccess']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['TaskTemplatesUpdateDocAccess']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['usersAccess'] = ResolversParentTypes['usersAccess']> = {
  create?: Resolver<Maybe<ResolversTypes['UsersCreateAccess']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['UsersDeleteAccess']>, ParentType, ContextType>;
  fields?: Resolver<Maybe<ResolversTypes['UsersFields']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['UsersReadAccess']>, ParentType, ContextType>;
  unlock?: Resolver<Maybe<ResolversTypes['UsersUnlockAccess']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['UsersUpdateAccess']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersDocAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['usersDocAccess'] = ResolversParentTypes['usersDocAccess']> = {
  create?: Resolver<Maybe<ResolversTypes['UsersCreateDocAccess']>, ParentType, ContextType>;
  delete?: Resolver<Maybe<ResolversTypes['UsersDeleteDocAccess']>, ParentType, ContextType>;
  fields?: Resolver<Maybe<ResolversTypes['UsersDocAccessFields']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['UsersReadDocAccess']>, ParentType, ContextType>;
  unlock?: Resolver<Maybe<ResolversTypes['UsersUnlockDocAccess']>, ParentType, ContextType>;
  update?: Resolver<Maybe<ResolversTypes['UsersUpdateDocAccess']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersJwtResolvers<ContextType = any, ParentType extends ResolversParentTypes['usersJWT'] = ResolversParentTypes['usersJWT']> = {
  collection?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['EmailAddress'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersLoginResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['usersLoginResult'] = ResolversParentTypes['usersLoginResult']> = {
  exp?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersMeResolvers<ContextType = any, ParentType extends ResolversParentTypes['usersMe'] = ResolversParentTypes['usersMe']> = {
  collection?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  exp?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersRefreshedUserResolvers<ContextType = any, ParentType extends ResolversParentTypes['usersRefreshedUser'] = ResolversParentTypes['usersRefreshedUser']> = {
  exp?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  refreshedToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['usersJWT']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersResetPasswordResolvers<ContextType = any, ParentType extends ResolversParentTypes['usersResetPassword'] = ResolversParentTypes['usersResetPassword']> = {
  token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VersionsArticlesResolvers<ContextType = any, ParentType extends ResolversParentTypes['versionsArticles'] = ResolversParentTypes['versionsArticles']> = {
  docs?: Resolver<Maybe<Array<Maybe<ResolversTypes['ArticleVersion']>>>, ParentType, ContextType>;
  hasNextPage?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasPrevPage?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  limit?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  nextPage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  offset?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  page?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pagingCounter?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  prevPage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalDocs?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalPages?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VersionsTaskTemplatesResolvers<ContextType = any, ParentType extends ResolversParentTypes['versionsTaskTemplates'] = ResolversParentTypes['versionsTaskTemplates']> = {
  docs?: Resolver<Maybe<Array<Maybe<ResolversTypes['TaskTemplateVersion']>>>, ParentType, ContextType>;
  hasNextPage?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasPrevPage?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  limit?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  nextPage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  offset?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  page?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pagingCounter?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  prevPage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalDocs?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalPages?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  Access?: AccessResolvers<ContextType>;
  Article?: ArticleResolvers<ContextType>;
  ArticleVersion?: ArticleVersionResolvers<ContextType>;
  ArticleVersion_Version?: ArticleVersion_VersionResolvers<ContextType>;
  ArticleVersion_Version_Content?: ArticleVersion_Version_ContentResolvers<ContextType>;
  ArticleVersion_Version_Hero?: ArticleVersion_Version_HeroResolvers<ContextType>;
  ArticleVersion_Version_Meta?: ArticleVersion_Version_MetaResolvers<ContextType>;
  ArticleVersion_Version_PopulatedAuthor?: ArticleVersion_Version_PopulatedAuthorResolvers<ContextType>;
  Article_Content?: Article_ContentResolvers<ContextType>;
  Article_Hero?: Article_HeroResolvers<ContextType>;
  Article_Meta?: Article_MetaResolvers<ContextType>;
  Article_PopulatedAuthor?: Article_PopulatedAuthorResolvers<ContextType>;
  Articles?: ArticlesResolvers<ContextType>;
  ArticlesCreateAccess?: ArticlesCreateAccessResolvers<ContextType>;
  ArticlesCreateDocAccess?: ArticlesCreateDocAccessResolvers<ContextType>;
  ArticlesDeleteAccess?: ArticlesDeleteAccessResolvers<ContextType>;
  ArticlesDeleteDocAccess?: ArticlesDeleteDocAccessResolvers<ContextType>;
  ArticlesDocAccessFields?: ArticlesDocAccessFieldsResolvers<ContextType>;
  ArticlesDocAccessFields__status?: ArticlesDocAccessFields__StatusResolvers<ContextType>;
  ArticlesDocAccessFields__status_Create?: ArticlesDocAccessFields__Status_CreateResolvers<ContextType>;
  ArticlesDocAccessFields__status_Delete?: ArticlesDocAccessFields__Status_DeleteResolvers<ContextType>;
  ArticlesDocAccessFields__status_Read?: ArticlesDocAccessFields__Status_ReadResolvers<ContextType>;
  ArticlesDocAccessFields__status_Update?: ArticlesDocAccessFields__Status_UpdateResolvers<ContextType>;
  ArticlesDocAccessFields_author?: ArticlesDocAccessFields_AuthorResolvers<ContextType>;
  ArticlesDocAccessFields_author_Create?: ArticlesDocAccessFields_Author_CreateResolvers<ContextType>;
  ArticlesDocAccessFields_author_Delete?: ArticlesDocAccessFields_Author_DeleteResolvers<ContextType>;
  ArticlesDocAccessFields_author_Read?: ArticlesDocAccessFields_Author_ReadResolvers<ContextType>;
  ArticlesDocAccessFields_author_Update?: ArticlesDocAccessFields_Author_UpdateResolvers<ContextType>;
  ArticlesDocAccessFields_categories?: ArticlesDocAccessFields_CategoriesResolvers<ContextType>;
  ArticlesDocAccessFields_categories_Create?: ArticlesDocAccessFields_Categories_CreateResolvers<ContextType>;
  ArticlesDocAccessFields_categories_Delete?: ArticlesDocAccessFields_Categories_DeleteResolvers<ContextType>;
  ArticlesDocAccessFields_categories_Read?: ArticlesDocAccessFields_Categories_ReadResolvers<ContextType>;
  ArticlesDocAccessFields_categories_Update?: ArticlesDocAccessFields_Categories_UpdateResolvers<ContextType>;
  ArticlesDocAccessFields_content?: ArticlesDocAccessFields_ContentResolvers<ContextType>;
  ArticlesDocAccessFields_content_Create?: ArticlesDocAccessFields_Content_CreateResolvers<ContextType>;
  ArticlesDocAccessFields_content_Delete?: ArticlesDocAccessFields_Content_DeleteResolvers<ContextType>;
  ArticlesDocAccessFields_content_Read?: ArticlesDocAccessFields_Content_ReadResolvers<ContextType>;
  ArticlesDocAccessFields_content_Update?: ArticlesDocAccessFields_Content_UpdateResolvers<ContextType>;
  ArticlesDocAccessFields_createdAt?: ArticlesDocAccessFields_CreatedAtResolvers<ContextType>;
  ArticlesDocAccessFields_createdAt_Create?: ArticlesDocAccessFields_CreatedAt_CreateResolvers<ContextType>;
  ArticlesDocAccessFields_createdAt_Delete?: ArticlesDocAccessFields_CreatedAt_DeleteResolvers<ContextType>;
  ArticlesDocAccessFields_createdAt_Read?: ArticlesDocAccessFields_CreatedAt_ReadResolvers<ContextType>;
  ArticlesDocAccessFields_createdAt_Update?: ArticlesDocAccessFields_CreatedAt_UpdateResolvers<ContextType>;
  ArticlesDocAccessFields_hero?: ArticlesDocAccessFields_HeroResolvers<ContextType>;
  ArticlesDocAccessFields_hero_Create?: ArticlesDocAccessFields_Hero_CreateResolvers<ContextType>;
  ArticlesDocAccessFields_hero_Delete?: ArticlesDocAccessFields_Hero_DeleteResolvers<ContextType>;
  ArticlesDocAccessFields_hero_Fields?: ArticlesDocAccessFields_Hero_FieldsResolvers<ContextType>;
  ArticlesDocAccessFields_hero_Read?: ArticlesDocAccessFields_Hero_ReadResolvers<ContextType>;
  ArticlesDocAccessFields_hero_Update?: ArticlesDocAccessFields_Hero_UpdateResolvers<ContextType>;
  ArticlesDocAccessFields_hero_media?: ArticlesDocAccessFields_Hero_MediaResolvers<ContextType>;
  ArticlesDocAccessFields_hero_media_Create?: ArticlesDocAccessFields_Hero_Media_CreateResolvers<ContextType>;
  ArticlesDocAccessFields_hero_media_Delete?: ArticlesDocAccessFields_Hero_Media_DeleteResolvers<ContextType>;
  ArticlesDocAccessFields_hero_media_Read?: ArticlesDocAccessFields_Hero_Media_ReadResolvers<ContextType>;
  ArticlesDocAccessFields_hero_media_Update?: ArticlesDocAccessFields_Hero_Media_UpdateResolvers<ContextType>;
  ArticlesDocAccessFields_hero_richText?: ArticlesDocAccessFields_Hero_RichTextResolvers<ContextType>;
  ArticlesDocAccessFields_hero_richText_Create?: ArticlesDocAccessFields_Hero_RichText_CreateResolvers<ContextType>;
  ArticlesDocAccessFields_hero_richText_Delete?: ArticlesDocAccessFields_Hero_RichText_DeleteResolvers<ContextType>;
  ArticlesDocAccessFields_hero_richText_Read?: ArticlesDocAccessFields_Hero_RichText_ReadResolvers<ContextType>;
  ArticlesDocAccessFields_hero_richText_Update?: ArticlesDocAccessFields_Hero_RichText_UpdateResolvers<ContextType>;
  ArticlesDocAccessFields_meta?: ArticlesDocAccessFields_MetaResolvers<ContextType>;
  ArticlesDocAccessFields_meta_Create?: ArticlesDocAccessFields_Meta_CreateResolvers<ContextType>;
  ArticlesDocAccessFields_meta_Delete?: ArticlesDocAccessFields_Meta_DeleteResolvers<ContextType>;
  ArticlesDocAccessFields_meta_Fields?: ArticlesDocAccessFields_Meta_FieldsResolvers<ContextType>;
  ArticlesDocAccessFields_meta_Read?: ArticlesDocAccessFields_Meta_ReadResolvers<ContextType>;
  ArticlesDocAccessFields_meta_Update?: ArticlesDocAccessFields_Meta_UpdateResolvers<ContextType>;
  ArticlesDocAccessFields_meta_description?: ArticlesDocAccessFields_Meta_DescriptionResolvers<ContextType>;
  ArticlesDocAccessFields_meta_description_Create?: ArticlesDocAccessFields_Meta_Description_CreateResolvers<ContextType>;
  ArticlesDocAccessFields_meta_description_Delete?: ArticlesDocAccessFields_Meta_Description_DeleteResolvers<ContextType>;
  ArticlesDocAccessFields_meta_description_Read?: ArticlesDocAccessFields_Meta_Description_ReadResolvers<ContextType>;
  ArticlesDocAccessFields_meta_description_Update?: ArticlesDocAccessFields_Meta_Description_UpdateResolvers<ContextType>;
  ArticlesDocAccessFields_meta_image?: ArticlesDocAccessFields_Meta_ImageResolvers<ContextType>;
  ArticlesDocAccessFields_meta_image_Create?: ArticlesDocAccessFields_Meta_Image_CreateResolvers<ContextType>;
  ArticlesDocAccessFields_meta_image_Delete?: ArticlesDocAccessFields_Meta_Image_DeleteResolvers<ContextType>;
  ArticlesDocAccessFields_meta_image_Read?: ArticlesDocAccessFields_Meta_Image_ReadResolvers<ContextType>;
  ArticlesDocAccessFields_meta_image_Update?: ArticlesDocAccessFields_Meta_Image_UpdateResolvers<ContextType>;
  ArticlesDocAccessFields_meta_title?: ArticlesDocAccessFields_Meta_TitleResolvers<ContextType>;
  ArticlesDocAccessFields_meta_title_Create?: ArticlesDocAccessFields_Meta_Title_CreateResolvers<ContextType>;
  ArticlesDocAccessFields_meta_title_Delete?: ArticlesDocAccessFields_Meta_Title_DeleteResolvers<ContextType>;
  ArticlesDocAccessFields_meta_title_Read?: ArticlesDocAccessFields_Meta_Title_ReadResolvers<ContextType>;
  ArticlesDocAccessFields_meta_title_Update?: ArticlesDocAccessFields_Meta_Title_UpdateResolvers<ContextType>;
  ArticlesDocAccessFields_populatedAuthor?: ArticlesDocAccessFields_PopulatedAuthorResolvers<ContextType>;
  ArticlesDocAccessFields_populatedAuthor_Create?: ArticlesDocAccessFields_PopulatedAuthor_CreateResolvers<ContextType>;
  ArticlesDocAccessFields_populatedAuthor_Delete?: ArticlesDocAccessFields_PopulatedAuthor_DeleteResolvers<ContextType>;
  ArticlesDocAccessFields_populatedAuthor_Fields?: ArticlesDocAccessFields_PopulatedAuthor_FieldsResolvers<ContextType>;
  ArticlesDocAccessFields_populatedAuthor_Read?: ArticlesDocAccessFields_PopulatedAuthor_ReadResolvers<ContextType>;
  ArticlesDocAccessFields_populatedAuthor_Update?: ArticlesDocAccessFields_PopulatedAuthor_UpdateResolvers<ContextType>;
  ArticlesDocAccessFields_populatedAuthor_id?: ArticlesDocAccessFields_PopulatedAuthor_IdResolvers<ContextType>;
  ArticlesDocAccessFields_populatedAuthor_id_Create?: ArticlesDocAccessFields_PopulatedAuthor_Id_CreateResolvers<ContextType>;
  ArticlesDocAccessFields_populatedAuthor_id_Delete?: ArticlesDocAccessFields_PopulatedAuthor_Id_DeleteResolvers<ContextType>;
  ArticlesDocAccessFields_populatedAuthor_id_Read?: ArticlesDocAccessFields_PopulatedAuthor_Id_ReadResolvers<ContextType>;
  ArticlesDocAccessFields_populatedAuthor_id_Update?: ArticlesDocAccessFields_PopulatedAuthor_Id_UpdateResolvers<ContextType>;
  ArticlesDocAccessFields_populatedAuthor_name?: ArticlesDocAccessFields_PopulatedAuthor_NameResolvers<ContextType>;
  ArticlesDocAccessFields_populatedAuthor_name_Create?: ArticlesDocAccessFields_PopulatedAuthor_Name_CreateResolvers<ContextType>;
  ArticlesDocAccessFields_populatedAuthor_name_Delete?: ArticlesDocAccessFields_PopulatedAuthor_Name_DeleteResolvers<ContextType>;
  ArticlesDocAccessFields_populatedAuthor_name_Read?: ArticlesDocAccessFields_PopulatedAuthor_Name_ReadResolvers<ContextType>;
  ArticlesDocAccessFields_populatedAuthor_name_Update?: ArticlesDocAccessFields_PopulatedAuthor_Name_UpdateResolvers<ContextType>;
  ArticlesDocAccessFields_publishedAt?: ArticlesDocAccessFields_PublishedAtResolvers<ContextType>;
  ArticlesDocAccessFields_publishedAt_Create?: ArticlesDocAccessFields_PublishedAt_CreateResolvers<ContextType>;
  ArticlesDocAccessFields_publishedAt_Delete?: ArticlesDocAccessFields_PublishedAt_DeleteResolvers<ContextType>;
  ArticlesDocAccessFields_publishedAt_Read?: ArticlesDocAccessFields_PublishedAt_ReadResolvers<ContextType>;
  ArticlesDocAccessFields_publishedAt_Update?: ArticlesDocAccessFields_PublishedAt_UpdateResolvers<ContextType>;
  ArticlesDocAccessFields_relatedArticles?: ArticlesDocAccessFields_RelatedArticlesResolvers<ContextType>;
  ArticlesDocAccessFields_relatedArticles_Create?: ArticlesDocAccessFields_RelatedArticles_CreateResolvers<ContextType>;
  ArticlesDocAccessFields_relatedArticles_Delete?: ArticlesDocAccessFields_RelatedArticles_DeleteResolvers<ContextType>;
  ArticlesDocAccessFields_relatedArticles_Read?: ArticlesDocAccessFields_RelatedArticles_ReadResolvers<ContextType>;
  ArticlesDocAccessFields_relatedArticles_Update?: ArticlesDocAccessFields_RelatedArticles_UpdateResolvers<ContextType>;
  ArticlesDocAccessFields_slug?: ArticlesDocAccessFields_SlugResolvers<ContextType>;
  ArticlesDocAccessFields_slug_Create?: ArticlesDocAccessFields_Slug_CreateResolvers<ContextType>;
  ArticlesDocAccessFields_slug_Delete?: ArticlesDocAccessFields_Slug_DeleteResolvers<ContextType>;
  ArticlesDocAccessFields_slug_Read?: ArticlesDocAccessFields_Slug_ReadResolvers<ContextType>;
  ArticlesDocAccessFields_slug_Update?: ArticlesDocAccessFields_Slug_UpdateResolvers<ContextType>;
  ArticlesDocAccessFields_title?: ArticlesDocAccessFields_TitleResolvers<ContextType>;
  ArticlesDocAccessFields_title_Create?: ArticlesDocAccessFields_Title_CreateResolvers<ContextType>;
  ArticlesDocAccessFields_title_Delete?: ArticlesDocAccessFields_Title_DeleteResolvers<ContextType>;
  ArticlesDocAccessFields_title_Read?: ArticlesDocAccessFields_Title_ReadResolvers<ContextType>;
  ArticlesDocAccessFields_title_Update?: ArticlesDocAccessFields_Title_UpdateResolvers<ContextType>;
  ArticlesDocAccessFields_updatedAt?: ArticlesDocAccessFields_UpdatedAtResolvers<ContextType>;
  ArticlesDocAccessFields_updatedAt_Create?: ArticlesDocAccessFields_UpdatedAt_CreateResolvers<ContextType>;
  ArticlesDocAccessFields_updatedAt_Delete?: ArticlesDocAccessFields_UpdatedAt_DeleteResolvers<ContextType>;
  ArticlesDocAccessFields_updatedAt_Read?: ArticlesDocAccessFields_UpdatedAt_ReadResolvers<ContextType>;
  ArticlesDocAccessFields_updatedAt_Update?: ArticlesDocAccessFields_UpdatedAt_UpdateResolvers<ContextType>;
  ArticlesFields?: ArticlesFieldsResolvers<ContextType>;
  ArticlesFields__status?: ArticlesFields__StatusResolvers<ContextType>;
  ArticlesFields__status_Create?: ArticlesFields__Status_CreateResolvers<ContextType>;
  ArticlesFields__status_Delete?: ArticlesFields__Status_DeleteResolvers<ContextType>;
  ArticlesFields__status_Read?: ArticlesFields__Status_ReadResolvers<ContextType>;
  ArticlesFields__status_Update?: ArticlesFields__Status_UpdateResolvers<ContextType>;
  ArticlesFields_author?: ArticlesFields_AuthorResolvers<ContextType>;
  ArticlesFields_author_Create?: ArticlesFields_Author_CreateResolvers<ContextType>;
  ArticlesFields_author_Delete?: ArticlesFields_Author_DeleteResolvers<ContextType>;
  ArticlesFields_author_Read?: ArticlesFields_Author_ReadResolvers<ContextType>;
  ArticlesFields_author_Update?: ArticlesFields_Author_UpdateResolvers<ContextType>;
  ArticlesFields_categories?: ArticlesFields_CategoriesResolvers<ContextType>;
  ArticlesFields_categories_Create?: ArticlesFields_Categories_CreateResolvers<ContextType>;
  ArticlesFields_categories_Delete?: ArticlesFields_Categories_DeleteResolvers<ContextType>;
  ArticlesFields_categories_Read?: ArticlesFields_Categories_ReadResolvers<ContextType>;
  ArticlesFields_categories_Update?: ArticlesFields_Categories_UpdateResolvers<ContextType>;
  ArticlesFields_content?: ArticlesFields_ContentResolvers<ContextType>;
  ArticlesFields_content_Create?: ArticlesFields_Content_CreateResolvers<ContextType>;
  ArticlesFields_content_Delete?: ArticlesFields_Content_DeleteResolvers<ContextType>;
  ArticlesFields_content_Read?: ArticlesFields_Content_ReadResolvers<ContextType>;
  ArticlesFields_content_Update?: ArticlesFields_Content_UpdateResolvers<ContextType>;
  ArticlesFields_createdAt?: ArticlesFields_CreatedAtResolvers<ContextType>;
  ArticlesFields_createdAt_Create?: ArticlesFields_CreatedAt_CreateResolvers<ContextType>;
  ArticlesFields_createdAt_Delete?: ArticlesFields_CreatedAt_DeleteResolvers<ContextType>;
  ArticlesFields_createdAt_Read?: ArticlesFields_CreatedAt_ReadResolvers<ContextType>;
  ArticlesFields_createdAt_Update?: ArticlesFields_CreatedAt_UpdateResolvers<ContextType>;
  ArticlesFields_hero?: ArticlesFields_HeroResolvers<ContextType>;
  ArticlesFields_hero_Create?: ArticlesFields_Hero_CreateResolvers<ContextType>;
  ArticlesFields_hero_Delete?: ArticlesFields_Hero_DeleteResolvers<ContextType>;
  ArticlesFields_hero_Fields?: ArticlesFields_Hero_FieldsResolvers<ContextType>;
  ArticlesFields_hero_Read?: ArticlesFields_Hero_ReadResolvers<ContextType>;
  ArticlesFields_hero_Update?: ArticlesFields_Hero_UpdateResolvers<ContextType>;
  ArticlesFields_hero_media?: ArticlesFields_Hero_MediaResolvers<ContextType>;
  ArticlesFields_hero_media_Create?: ArticlesFields_Hero_Media_CreateResolvers<ContextType>;
  ArticlesFields_hero_media_Delete?: ArticlesFields_Hero_Media_DeleteResolvers<ContextType>;
  ArticlesFields_hero_media_Read?: ArticlesFields_Hero_Media_ReadResolvers<ContextType>;
  ArticlesFields_hero_media_Update?: ArticlesFields_Hero_Media_UpdateResolvers<ContextType>;
  ArticlesFields_hero_richText?: ArticlesFields_Hero_RichTextResolvers<ContextType>;
  ArticlesFields_hero_richText_Create?: ArticlesFields_Hero_RichText_CreateResolvers<ContextType>;
  ArticlesFields_hero_richText_Delete?: ArticlesFields_Hero_RichText_DeleteResolvers<ContextType>;
  ArticlesFields_hero_richText_Read?: ArticlesFields_Hero_RichText_ReadResolvers<ContextType>;
  ArticlesFields_hero_richText_Update?: ArticlesFields_Hero_RichText_UpdateResolvers<ContextType>;
  ArticlesFields_meta?: ArticlesFields_MetaResolvers<ContextType>;
  ArticlesFields_meta_Create?: ArticlesFields_Meta_CreateResolvers<ContextType>;
  ArticlesFields_meta_Delete?: ArticlesFields_Meta_DeleteResolvers<ContextType>;
  ArticlesFields_meta_Fields?: ArticlesFields_Meta_FieldsResolvers<ContextType>;
  ArticlesFields_meta_Read?: ArticlesFields_Meta_ReadResolvers<ContextType>;
  ArticlesFields_meta_Update?: ArticlesFields_Meta_UpdateResolvers<ContextType>;
  ArticlesFields_meta_description?: ArticlesFields_Meta_DescriptionResolvers<ContextType>;
  ArticlesFields_meta_description_Create?: ArticlesFields_Meta_Description_CreateResolvers<ContextType>;
  ArticlesFields_meta_description_Delete?: ArticlesFields_Meta_Description_DeleteResolvers<ContextType>;
  ArticlesFields_meta_description_Read?: ArticlesFields_Meta_Description_ReadResolvers<ContextType>;
  ArticlesFields_meta_description_Update?: ArticlesFields_Meta_Description_UpdateResolvers<ContextType>;
  ArticlesFields_meta_image?: ArticlesFields_Meta_ImageResolvers<ContextType>;
  ArticlesFields_meta_image_Create?: ArticlesFields_Meta_Image_CreateResolvers<ContextType>;
  ArticlesFields_meta_image_Delete?: ArticlesFields_Meta_Image_DeleteResolvers<ContextType>;
  ArticlesFields_meta_image_Read?: ArticlesFields_Meta_Image_ReadResolvers<ContextType>;
  ArticlesFields_meta_image_Update?: ArticlesFields_Meta_Image_UpdateResolvers<ContextType>;
  ArticlesFields_meta_title?: ArticlesFields_Meta_TitleResolvers<ContextType>;
  ArticlesFields_meta_title_Create?: ArticlesFields_Meta_Title_CreateResolvers<ContextType>;
  ArticlesFields_meta_title_Delete?: ArticlesFields_Meta_Title_DeleteResolvers<ContextType>;
  ArticlesFields_meta_title_Read?: ArticlesFields_Meta_Title_ReadResolvers<ContextType>;
  ArticlesFields_meta_title_Update?: ArticlesFields_Meta_Title_UpdateResolvers<ContextType>;
  ArticlesFields_populatedAuthor?: ArticlesFields_PopulatedAuthorResolvers<ContextType>;
  ArticlesFields_populatedAuthor_Create?: ArticlesFields_PopulatedAuthor_CreateResolvers<ContextType>;
  ArticlesFields_populatedAuthor_Delete?: ArticlesFields_PopulatedAuthor_DeleteResolvers<ContextType>;
  ArticlesFields_populatedAuthor_Fields?: ArticlesFields_PopulatedAuthor_FieldsResolvers<ContextType>;
  ArticlesFields_populatedAuthor_Read?: ArticlesFields_PopulatedAuthor_ReadResolvers<ContextType>;
  ArticlesFields_populatedAuthor_Update?: ArticlesFields_PopulatedAuthor_UpdateResolvers<ContextType>;
  ArticlesFields_populatedAuthor_id?: ArticlesFields_PopulatedAuthor_IdResolvers<ContextType>;
  ArticlesFields_populatedAuthor_id_Create?: ArticlesFields_PopulatedAuthor_Id_CreateResolvers<ContextType>;
  ArticlesFields_populatedAuthor_id_Delete?: ArticlesFields_PopulatedAuthor_Id_DeleteResolvers<ContextType>;
  ArticlesFields_populatedAuthor_id_Read?: ArticlesFields_PopulatedAuthor_Id_ReadResolvers<ContextType>;
  ArticlesFields_populatedAuthor_id_Update?: ArticlesFields_PopulatedAuthor_Id_UpdateResolvers<ContextType>;
  ArticlesFields_populatedAuthor_name?: ArticlesFields_PopulatedAuthor_NameResolvers<ContextType>;
  ArticlesFields_populatedAuthor_name_Create?: ArticlesFields_PopulatedAuthor_Name_CreateResolvers<ContextType>;
  ArticlesFields_populatedAuthor_name_Delete?: ArticlesFields_PopulatedAuthor_Name_DeleteResolvers<ContextType>;
  ArticlesFields_populatedAuthor_name_Read?: ArticlesFields_PopulatedAuthor_Name_ReadResolvers<ContextType>;
  ArticlesFields_populatedAuthor_name_Update?: ArticlesFields_PopulatedAuthor_Name_UpdateResolvers<ContextType>;
  ArticlesFields_publishedAt?: ArticlesFields_PublishedAtResolvers<ContextType>;
  ArticlesFields_publishedAt_Create?: ArticlesFields_PublishedAt_CreateResolvers<ContextType>;
  ArticlesFields_publishedAt_Delete?: ArticlesFields_PublishedAt_DeleteResolvers<ContextType>;
  ArticlesFields_publishedAt_Read?: ArticlesFields_PublishedAt_ReadResolvers<ContextType>;
  ArticlesFields_publishedAt_Update?: ArticlesFields_PublishedAt_UpdateResolvers<ContextType>;
  ArticlesFields_relatedArticles?: ArticlesFields_RelatedArticlesResolvers<ContextType>;
  ArticlesFields_relatedArticles_Create?: ArticlesFields_RelatedArticles_CreateResolvers<ContextType>;
  ArticlesFields_relatedArticles_Delete?: ArticlesFields_RelatedArticles_DeleteResolvers<ContextType>;
  ArticlesFields_relatedArticles_Read?: ArticlesFields_RelatedArticles_ReadResolvers<ContextType>;
  ArticlesFields_relatedArticles_Update?: ArticlesFields_RelatedArticles_UpdateResolvers<ContextType>;
  ArticlesFields_slug?: ArticlesFields_SlugResolvers<ContextType>;
  ArticlesFields_slug_Create?: ArticlesFields_Slug_CreateResolvers<ContextType>;
  ArticlesFields_slug_Delete?: ArticlesFields_Slug_DeleteResolvers<ContextType>;
  ArticlesFields_slug_Read?: ArticlesFields_Slug_ReadResolvers<ContextType>;
  ArticlesFields_slug_Update?: ArticlesFields_Slug_UpdateResolvers<ContextType>;
  ArticlesFields_title?: ArticlesFields_TitleResolvers<ContextType>;
  ArticlesFields_title_Create?: ArticlesFields_Title_CreateResolvers<ContextType>;
  ArticlesFields_title_Delete?: ArticlesFields_Title_DeleteResolvers<ContextType>;
  ArticlesFields_title_Read?: ArticlesFields_Title_ReadResolvers<ContextType>;
  ArticlesFields_title_Update?: ArticlesFields_Title_UpdateResolvers<ContextType>;
  ArticlesFields_updatedAt?: ArticlesFields_UpdatedAtResolvers<ContextType>;
  ArticlesFields_updatedAt_Create?: ArticlesFields_UpdatedAt_CreateResolvers<ContextType>;
  ArticlesFields_updatedAt_Delete?: ArticlesFields_UpdatedAt_DeleteResolvers<ContextType>;
  ArticlesFields_updatedAt_Read?: ArticlesFields_UpdatedAt_ReadResolvers<ContextType>;
  ArticlesFields_updatedAt_Update?: ArticlesFields_UpdatedAt_UpdateResolvers<ContextType>;
  ArticlesReadAccess?: ArticlesReadAccessResolvers<ContextType>;
  ArticlesReadDocAccess?: ArticlesReadDocAccessResolvers<ContextType>;
  ArticlesReadVersionsAccess?: ArticlesReadVersionsAccessResolvers<ContextType>;
  ArticlesReadVersionsDocAccess?: ArticlesReadVersionsDocAccessResolvers<ContextType>;
  ArticlesUpdateAccess?: ArticlesUpdateAccessResolvers<ContextType>;
  ArticlesUpdateDocAccess?: ArticlesUpdateDocAccessResolvers<ContextType>;
  Categories?: CategoriesResolvers<ContextType>;
  CategoriesCreateAccess?: CategoriesCreateAccessResolvers<ContextType>;
  CategoriesCreateDocAccess?: CategoriesCreateDocAccessResolvers<ContextType>;
  CategoriesDeleteAccess?: CategoriesDeleteAccessResolvers<ContextType>;
  CategoriesDeleteDocAccess?: CategoriesDeleteDocAccessResolvers<ContextType>;
  CategoriesDocAccessFields?: CategoriesDocAccessFieldsResolvers<ContextType>;
  CategoriesDocAccessFields_breadcrumbs?: CategoriesDocAccessFields_BreadcrumbsResolvers<ContextType>;
  CategoriesDocAccessFields_breadcrumbs_Create?: CategoriesDocAccessFields_Breadcrumbs_CreateResolvers<ContextType>;
  CategoriesDocAccessFields_breadcrumbs_Delete?: CategoriesDocAccessFields_Breadcrumbs_DeleteResolvers<ContextType>;
  CategoriesDocAccessFields_breadcrumbs_Fields?: CategoriesDocAccessFields_Breadcrumbs_FieldsResolvers<ContextType>;
  CategoriesDocAccessFields_breadcrumbs_Read?: CategoriesDocAccessFields_Breadcrumbs_ReadResolvers<ContextType>;
  CategoriesDocAccessFields_breadcrumbs_Update?: CategoriesDocAccessFields_Breadcrumbs_UpdateResolvers<ContextType>;
  CategoriesDocAccessFields_breadcrumbs_doc?: CategoriesDocAccessFields_Breadcrumbs_DocResolvers<ContextType>;
  CategoriesDocAccessFields_breadcrumbs_doc_Create?: CategoriesDocAccessFields_Breadcrumbs_Doc_CreateResolvers<ContextType>;
  CategoriesDocAccessFields_breadcrumbs_doc_Delete?: CategoriesDocAccessFields_Breadcrumbs_Doc_DeleteResolvers<ContextType>;
  CategoriesDocAccessFields_breadcrumbs_doc_Read?: CategoriesDocAccessFields_Breadcrumbs_Doc_ReadResolvers<ContextType>;
  CategoriesDocAccessFields_breadcrumbs_doc_Update?: CategoriesDocAccessFields_Breadcrumbs_Doc_UpdateResolvers<ContextType>;
  CategoriesDocAccessFields_breadcrumbs_id?: CategoriesDocAccessFields_Breadcrumbs_IdResolvers<ContextType>;
  CategoriesDocAccessFields_breadcrumbs_id_Create?: CategoriesDocAccessFields_Breadcrumbs_Id_CreateResolvers<ContextType>;
  CategoriesDocAccessFields_breadcrumbs_id_Delete?: CategoriesDocAccessFields_Breadcrumbs_Id_DeleteResolvers<ContextType>;
  CategoriesDocAccessFields_breadcrumbs_id_Read?: CategoriesDocAccessFields_Breadcrumbs_Id_ReadResolvers<ContextType>;
  CategoriesDocAccessFields_breadcrumbs_id_Update?: CategoriesDocAccessFields_Breadcrumbs_Id_UpdateResolvers<ContextType>;
  CategoriesDocAccessFields_breadcrumbs_label?: CategoriesDocAccessFields_Breadcrumbs_LabelResolvers<ContextType>;
  CategoriesDocAccessFields_breadcrumbs_label_Create?: CategoriesDocAccessFields_Breadcrumbs_Label_CreateResolvers<ContextType>;
  CategoriesDocAccessFields_breadcrumbs_label_Delete?: CategoriesDocAccessFields_Breadcrumbs_Label_DeleteResolvers<ContextType>;
  CategoriesDocAccessFields_breadcrumbs_label_Read?: CategoriesDocAccessFields_Breadcrumbs_Label_ReadResolvers<ContextType>;
  CategoriesDocAccessFields_breadcrumbs_label_Update?: CategoriesDocAccessFields_Breadcrumbs_Label_UpdateResolvers<ContextType>;
  CategoriesDocAccessFields_breadcrumbs_url?: CategoriesDocAccessFields_Breadcrumbs_UrlResolvers<ContextType>;
  CategoriesDocAccessFields_breadcrumbs_url_Create?: CategoriesDocAccessFields_Breadcrumbs_Url_CreateResolvers<ContextType>;
  CategoriesDocAccessFields_breadcrumbs_url_Delete?: CategoriesDocAccessFields_Breadcrumbs_Url_DeleteResolvers<ContextType>;
  CategoriesDocAccessFields_breadcrumbs_url_Read?: CategoriesDocAccessFields_Breadcrumbs_Url_ReadResolvers<ContextType>;
  CategoriesDocAccessFields_breadcrumbs_url_Update?: CategoriesDocAccessFields_Breadcrumbs_Url_UpdateResolvers<ContextType>;
  CategoriesDocAccessFields_createdAt?: CategoriesDocAccessFields_CreatedAtResolvers<ContextType>;
  CategoriesDocAccessFields_createdAt_Create?: CategoriesDocAccessFields_CreatedAt_CreateResolvers<ContextType>;
  CategoriesDocAccessFields_createdAt_Delete?: CategoriesDocAccessFields_CreatedAt_DeleteResolvers<ContextType>;
  CategoriesDocAccessFields_createdAt_Read?: CategoriesDocAccessFields_CreatedAt_ReadResolvers<ContextType>;
  CategoriesDocAccessFields_createdAt_Update?: CategoriesDocAccessFields_CreatedAt_UpdateResolvers<ContextType>;
  CategoriesDocAccessFields_parent?: CategoriesDocAccessFields_ParentResolvers<ContextType>;
  CategoriesDocAccessFields_parent_Create?: CategoriesDocAccessFields_Parent_CreateResolvers<ContextType>;
  CategoriesDocAccessFields_parent_Delete?: CategoriesDocAccessFields_Parent_DeleteResolvers<ContextType>;
  CategoriesDocAccessFields_parent_Read?: CategoriesDocAccessFields_Parent_ReadResolvers<ContextType>;
  CategoriesDocAccessFields_parent_Update?: CategoriesDocAccessFields_Parent_UpdateResolvers<ContextType>;
  CategoriesDocAccessFields_title?: CategoriesDocAccessFields_TitleResolvers<ContextType>;
  CategoriesDocAccessFields_title_Create?: CategoriesDocAccessFields_Title_CreateResolvers<ContextType>;
  CategoriesDocAccessFields_title_Delete?: CategoriesDocAccessFields_Title_DeleteResolvers<ContextType>;
  CategoriesDocAccessFields_title_Read?: CategoriesDocAccessFields_Title_ReadResolvers<ContextType>;
  CategoriesDocAccessFields_title_Update?: CategoriesDocAccessFields_Title_UpdateResolvers<ContextType>;
  CategoriesDocAccessFields_updatedAt?: CategoriesDocAccessFields_UpdatedAtResolvers<ContextType>;
  CategoriesDocAccessFields_updatedAt_Create?: CategoriesDocAccessFields_UpdatedAt_CreateResolvers<ContextType>;
  CategoriesDocAccessFields_updatedAt_Delete?: CategoriesDocAccessFields_UpdatedAt_DeleteResolvers<ContextType>;
  CategoriesDocAccessFields_updatedAt_Read?: CategoriesDocAccessFields_UpdatedAt_ReadResolvers<ContextType>;
  CategoriesDocAccessFields_updatedAt_Update?: CategoriesDocAccessFields_UpdatedAt_UpdateResolvers<ContextType>;
  CategoriesFields?: CategoriesFieldsResolvers<ContextType>;
  CategoriesFields_breadcrumbs?: CategoriesFields_BreadcrumbsResolvers<ContextType>;
  CategoriesFields_breadcrumbs_Create?: CategoriesFields_Breadcrumbs_CreateResolvers<ContextType>;
  CategoriesFields_breadcrumbs_Delete?: CategoriesFields_Breadcrumbs_DeleteResolvers<ContextType>;
  CategoriesFields_breadcrumbs_Fields?: CategoriesFields_Breadcrumbs_FieldsResolvers<ContextType>;
  CategoriesFields_breadcrumbs_Read?: CategoriesFields_Breadcrumbs_ReadResolvers<ContextType>;
  CategoriesFields_breadcrumbs_Update?: CategoriesFields_Breadcrumbs_UpdateResolvers<ContextType>;
  CategoriesFields_breadcrumbs_doc?: CategoriesFields_Breadcrumbs_DocResolvers<ContextType>;
  CategoriesFields_breadcrumbs_doc_Create?: CategoriesFields_Breadcrumbs_Doc_CreateResolvers<ContextType>;
  CategoriesFields_breadcrumbs_doc_Delete?: CategoriesFields_Breadcrumbs_Doc_DeleteResolvers<ContextType>;
  CategoriesFields_breadcrumbs_doc_Read?: CategoriesFields_Breadcrumbs_Doc_ReadResolvers<ContextType>;
  CategoriesFields_breadcrumbs_doc_Update?: CategoriesFields_Breadcrumbs_Doc_UpdateResolvers<ContextType>;
  CategoriesFields_breadcrumbs_id?: CategoriesFields_Breadcrumbs_IdResolvers<ContextType>;
  CategoriesFields_breadcrumbs_id_Create?: CategoriesFields_Breadcrumbs_Id_CreateResolvers<ContextType>;
  CategoriesFields_breadcrumbs_id_Delete?: CategoriesFields_Breadcrumbs_Id_DeleteResolvers<ContextType>;
  CategoriesFields_breadcrumbs_id_Read?: CategoriesFields_Breadcrumbs_Id_ReadResolvers<ContextType>;
  CategoriesFields_breadcrumbs_id_Update?: CategoriesFields_Breadcrumbs_Id_UpdateResolvers<ContextType>;
  CategoriesFields_breadcrumbs_label?: CategoriesFields_Breadcrumbs_LabelResolvers<ContextType>;
  CategoriesFields_breadcrumbs_label_Create?: CategoriesFields_Breadcrumbs_Label_CreateResolvers<ContextType>;
  CategoriesFields_breadcrumbs_label_Delete?: CategoriesFields_Breadcrumbs_Label_DeleteResolvers<ContextType>;
  CategoriesFields_breadcrumbs_label_Read?: CategoriesFields_Breadcrumbs_Label_ReadResolvers<ContextType>;
  CategoriesFields_breadcrumbs_label_Update?: CategoriesFields_Breadcrumbs_Label_UpdateResolvers<ContextType>;
  CategoriesFields_breadcrumbs_url?: CategoriesFields_Breadcrumbs_UrlResolvers<ContextType>;
  CategoriesFields_breadcrumbs_url_Create?: CategoriesFields_Breadcrumbs_Url_CreateResolvers<ContextType>;
  CategoriesFields_breadcrumbs_url_Delete?: CategoriesFields_Breadcrumbs_Url_DeleteResolvers<ContextType>;
  CategoriesFields_breadcrumbs_url_Read?: CategoriesFields_Breadcrumbs_Url_ReadResolvers<ContextType>;
  CategoriesFields_breadcrumbs_url_Update?: CategoriesFields_Breadcrumbs_Url_UpdateResolvers<ContextType>;
  CategoriesFields_createdAt?: CategoriesFields_CreatedAtResolvers<ContextType>;
  CategoriesFields_createdAt_Create?: CategoriesFields_CreatedAt_CreateResolvers<ContextType>;
  CategoriesFields_createdAt_Delete?: CategoriesFields_CreatedAt_DeleteResolvers<ContextType>;
  CategoriesFields_createdAt_Read?: CategoriesFields_CreatedAt_ReadResolvers<ContextType>;
  CategoriesFields_createdAt_Update?: CategoriesFields_CreatedAt_UpdateResolvers<ContextType>;
  CategoriesFields_parent?: CategoriesFields_ParentResolvers<ContextType>;
  CategoriesFields_parent_Create?: CategoriesFields_Parent_CreateResolvers<ContextType>;
  CategoriesFields_parent_Delete?: CategoriesFields_Parent_DeleteResolvers<ContextType>;
  CategoriesFields_parent_Read?: CategoriesFields_Parent_ReadResolvers<ContextType>;
  CategoriesFields_parent_Update?: CategoriesFields_Parent_UpdateResolvers<ContextType>;
  CategoriesFields_title?: CategoriesFields_TitleResolvers<ContextType>;
  CategoriesFields_title_Create?: CategoriesFields_Title_CreateResolvers<ContextType>;
  CategoriesFields_title_Delete?: CategoriesFields_Title_DeleteResolvers<ContextType>;
  CategoriesFields_title_Read?: CategoriesFields_Title_ReadResolvers<ContextType>;
  CategoriesFields_title_Update?: CategoriesFields_Title_UpdateResolvers<ContextType>;
  CategoriesFields_updatedAt?: CategoriesFields_UpdatedAtResolvers<ContextType>;
  CategoriesFields_updatedAt_Create?: CategoriesFields_UpdatedAt_CreateResolvers<ContextType>;
  CategoriesFields_updatedAt_Delete?: CategoriesFields_UpdatedAt_DeleteResolvers<ContextType>;
  CategoriesFields_updatedAt_Read?: CategoriesFields_UpdatedAt_ReadResolvers<ContextType>;
  CategoriesFields_updatedAt_Update?: CategoriesFields_UpdatedAt_UpdateResolvers<ContextType>;
  CategoriesReadAccess?: CategoriesReadAccessResolvers<ContextType>;
  CategoriesReadDocAccess?: CategoriesReadDocAccessResolvers<ContextType>;
  CategoriesUpdateAccess?: CategoriesUpdateAccessResolvers<ContextType>;
  CategoriesUpdateDocAccess?: CategoriesUpdateDocAccessResolvers<ContextType>;
  Category?: CategoryResolvers<ContextType>;
  Category_Breadcrumbs?: Category_BreadcrumbsResolvers<ContextType>;
  Content?: ContentResolvers<ContextType>;
  Cta?: CtaResolvers<ContextType>;
  Cta_Links?: Cta_LinksResolvers<ContextType>;
  Cta_Links_Link?: Cta_Links_LinkResolvers<ContextType>;
  Cta_Links_Link_Reference?: Cta_Links_Link_ReferenceResolvers<ContextType>;
  Cta_Links_Link_Reference_Relationship?: Cta_Links_Link_Reference_RelationshipResolvers<ContextType>;
  DateTime?: GraphQLScalarType;
  EmailAddress?: GraphQLScalarType;
  JSON?: GraphQLScalarType;
  JSONObject?: GraphQLScalarType;
  Media?: MediaResolvers<ContextType>;
  MediaBlock?: MediaBlockResolvers<ContextType>;
  MediaCreateAccess?: MediaCreateAccessResolvers<ContextType>;
  MediaCreateDocAccess?: MediaCreateDocAccessResolvers<ContextType>;
  MediaDeleteAccess?: MediaDeleteAccessResolvers<ContextType>;
  MediaDeleteDocAccess?: MediaDeleteDocAccessResolvers<ContextType>;
  MediaDocAccessFields?: MediaDocAccessFieldsResolvers<ContextType>;
  MediaDocAccessFields_alt?: MediaDocAccessFields_AltResolvers<ContextType>;
  MediaDocAccessFields_alt_Create?: MediaDocAccessFields_Alt_CreateResolvers<ContextType>;
  MediaDocAccessFields_alt_Delete?: MediaDocAccessFields_Alt_DeleteResolvers<ContextType>;
  MediaDocAccessFields_alt_Read?: MediaDocAccessFields_Alt_ReadResolvers<ContextType>;
  MediaDocAccessFields_alt_Update?: MediaDocAccessFields_Alt_UpdateResolvers<ContextType>;
  MediaDocAccessFields_caption?: MediaDocAccessFields_CaptionResolvers<ContextType>;
  MediaDocAccessFields_caption_Create?: MediaDocAccessFields_Caption_CreateResolvers<ContextType>;
  MediaDocAccessFields_caption_Delete?: MediaDocAccessFields_Caption_DeleteResolvers<ContextType>;
  MediaDocAccessFields_caption_Read?: MediaDocAccessFields_Caption_ReadResolvers<ContextType>;
  MediaDocAccessFields_caption_Update?: MediaDocAccessFields_Caption_UpdateResolvers<ContextType>;
  MediaDocAccessFields_createdAt?: MediaDocAccessFields_CreatedAtResolvers<ContextType>;
  MediaDocAccessFields_createdAt_Create?: MediaDocAccessFields_CreatedAt_CreateResolvers<ContextType>;
  MediaDocAccessFields_createdAt_Delete?: MediaDocAccessFields_CreatedAt_DeleteResolvers<ContextType>;
  MediaDocAccessFields_createdAt_Read?: MediaDocAccessFields_CreatedAt_ReadResolvers<ContextType>;
  MediaDocAccessFields_createdAt_Update?: MediaDocAccessFields_CreatedAt_UpdateResolvers<ContextType>;
  MediaDocAccessFields_filename?: MediaDocAccessFields_FilenameResolvers<ContextType>;
  MediaDocAccessFields_filename_Create?: MediaDocAccessFields_Filename_CreateResolvers<ContextType>;
  MediaDocAccessFields_filename_Delete?: MediaDocAccessFields_Filename_DeleteResolvers<ContextType>;
  MediaDocAccessFields_filename_Read?: MediaDocAccessFields_Filename_ReadResolvers<ContextType>;
  MediaDocAccessFields_filename_Update?: MediaDocAccessFields_Filename_UpdateResolvers<ContextType>;
  MediaDocAccessFields_filesize?: MediaDocAccessFields_FilesizeResolvers<ContextType>;
  MediaDocAccessFields_filesize_Create?: MediaDocAccessFields_Filesize_CreateResolvers<ContextType>;
  MediaDocAccessFields_filesize_Delete?: MediaDocAccessFields_Filesize_DeleteResolvers<ContextType>;
  MediaDocAccessFields_filesize_Read?: MediaDocAccessFields_Filesize_ReadResolvers<ContextType>;
  MediaDocAccessFields_filesize_Update?: MediaDocAccessFields_Filesize_UpdateResolvers<ContextType>;
  MediaDocAccessFields_height?: MediaDocAccessFields_HeightResolvers<ContextType>;
  MediaDocAccessFields_height_Create?: MediaDocAccessFields_Height_CreateResolvers<ContextType>;
  MediaDocAccessFields_height_Delete?: MediaDocAccessFields_Height_DeleteResolvers<ContextType>;
  MediaDocAccessFields_height_Read?: MediaDocAccessFields_Height_ReadResolvers<ContextType>;
  MediaDocAccessFields_height_Update?: MediaDocAccessFields_Height_UpdateResolvers<ContextType>;
  MediaDocAccessFields_mimeType?: MediaDocAccessFields_MimeTypeResolvers<ContextType>;
  MediaDocAccessFields_mimeType_Create?: MediaDocAccessFields_MimeType_CreateResolvers<ContextType>;
  MediaDocAccessFields_mimeType_Delete?: MediaDocAccessFields_MimeType_DeleteResolvers<ContextType>;
  MediaDocAccessFields_mimeType_Read?: MediaDocAccessFields_MimeType_ReadResolvers<ContextType>;
  MediaDocAccessFields_mimeType_Update?: MediaDocAccessFields_MimeType_UpdateResolvers<ContextType>;
  MediaDocAccessFields_updatedAt?: MediaDocAccessFields_UpdatedAtResolvers<ContextType>;
  MediaDocAccessFields_updatedAt_Create?: MediaDocAccessFields_UpdatedAt_CreateResolvers<ContextType>;
  MediaDocAccessFields_updatedAt_Delete?: MediaDocAccessFields_UpdatedAt_DeleteResolvers<ContextType>;
  MediaDocAccessFields_updatedAt_Read?: MediaDocAccessFields_UpdatedAt_ReadResolvers<ContextType>;
  MediaDocAccessFields_updatedAt_Update?: MediaDocAccessFields_UpdatedAt_UpdateResolvers<ContextType>;
  MediaDocAccessFields_url?: MediaDocAccessFields_UrlResolvers<ContextType>;
  MediaDocAccessFields_url_Create?: MediaDocAccessFields_Url_CreateResolvers<ContextType>;
  MediaDocAccessFields_url_Delete?: MediaDocAccessFields_Url_DeleteResolvers<ContextType>;
  MediaDocAccessFields_url_Read?: MediaDocAccessFields_Url_ReadResolvers<ContextType>;
  MediaDocAccessFields_url_Update?: MediaDocAccessFields_Url_UpdateResolvers<ContextType>;
  MediaDocAccessFields_width?: MediaDocAccessFields_WidthResolvers<ContextType>;
  MediaDocAccessFields_width_Create?: MediaDocAccessFields_Width_CreateResolvers<ContextType>;
  MediaDocAccessFields_width_Delete?: MediaDocAccessFields_Width_DeleteResolvers<ContextType>;
  MediaDocAccessFields_width_Read?: MediaDocAccessFields_Width_ReadResolvers<ContextType>;
  MediaDocAccessFields_width_Update?: MediaDocAccessFields_Width_UpdateResolvers<ContextType>;
  MediaFields?: MediaFieldsResolvers<ContextType>;
  MediaFields_alt?: MediaFields_AltResolvers<ContextType>;
  MediaFields_alt_Create?: MediaFields_Alt_CreateResolvers<ContextType>;
  MediaFields_alt_Delete?: MediaFields_Alt_DeleteResolvers<ContextType>;
  MediaFields_alt_Read?: MediaFields_Alt_ReadResolvers<ContextType>;
  MediaFields_alt_Update?: MediaFields_Alt_UpdateResolvers<ContextType>;
  MediaFields_caption?: MediaFields_CaptionResolvers<ContextType>;
  MediaFields_caption_Create?: MediaFields_Caption_CreateResolvers<ContextType>;
  MediaFields_caption_Delete?: MediaFields_Caption_DeleteResolvers<ContextType>;
  MediaFields_caption_Read?: MediaFields_Caption_ReadResolvers<ContextType>;
  MediaFields_caption_Update?: MediaFields_Caption_UpdateResolvers<ContextType>;
  MediaFields_createdAt?: MediaFields_CreatedAtResolvers<ContextType>;
  MediaFields_createdAt_Create?: MediaFields_CreatedAt_CreateResolvers<ContextType>;
  MediaFields_createdAt_Delete?: MediaFields_CreatedAt_DeleteResolvers<ContextType>;
  MediaFields_createdAt_Read?: MediaFields_CreatedAt_ReadResolvers<ContextType>;
  MediaFields_createdAt_Update?: MediaFields_CreatedAt_UpdateResolvers<ContextType>;
  MediaFields_filename?: MediaFields_FilenameResolvers<ContextType>;
  MediaFields_filename_Create?: MediaFields_Filename_CreateResolvers<ContextType>;
  MediaFields_filename_Delete?: MediaFields_Filename_DeleteResolvers<ContextType>;
  MediaFields_filename_Read?: MediaFields_Filename_ReadResolvers<ContextType>;
  MediaFields_filename_Update?: MediaFields_Filename_UpdateResolvers<ContextType>;
  MediaFields_filesize?: MediaFields_FilesizeResolvers<ContextType>;
  MediaFields_filesize_Create?: MediaFields_Filesize_CreateResolvers<ContextType>;
  MediaFields_filesize_Delete?: MediaFields_Filesize_DeleteResolvers<ContextType>;
  MediaFields_filesize_Read?: MediaFields_Filesize_ReadResolvers<ContextType>;
  MediaFields_filesize_Update?: MediaFields_Filesize_UpdateResolvers<ContextType>;
  MediaFields_height?: MediaFields_HeightResolvers<ContextType>;
  MediaFields_height_Create?: MediaFields_Height_CreateResolvers<ContextType>;
  MediaFields_height_Delete?: MediaFields_Height_DeleteResolvers<ContextType>;
  MediaFields_height_Read?: MediaFields_Height_ReadResolvers<ContextType>;
  MediaFields_height_Update?: MediaFields_Height_UpdateResolvers<ContextType>;
  MediaFields_mimeType?: MediaFields_MimeTypeResolvers<ContextType>;
  MediaFields_mimeType_Create?: MediaFields_MimeType_CreateResolvers<ContextType>;
  MediaFields_mimeType_Delete?: MediaFields_MimeType_DeleteResolvers<ContextType>;
  MediaFields_mimeType_Read?: MediaFields_MimeType_ReadResolvers<ContextType>;
  MediaFields_mimeType_Update?: MediaFields_MimeType_UpdateResolvers<ContextType>;
  MediaFields_updatedAt?: MediaFields_UpdatedAtResolvers<ContextType>;
  MediaFields_updatedAt_Create?: MediaFields_UpdatedAt_CreateResolvers<ContextType>;
  MediaFields_updatedAt_Delete?: MediaFields_UpdatedAt_DeleteResolvers<ContextType>;
  MediaFields_updatedAt_Read?: MediaFields_UpdatedAt_ReadResolvers<ContextType>;
  MediaFields_updatedAt_Update?: MediaFields_UpdatedAt_UpdateResolvers<ContextType>;
  MediaFields_url?: MediaFields_UrlResolvers<ContextType>;
  MediaFields_url_Create?: MediaFields_Url_CreateResolvers<ContextType>;
  MediaFields_url_Delete?: MediaFields_Url_DeleteResolvers<ContextType>;
  MediaFields_url_Read?: MediaFields_Url_ReadResolvers<ContextType>;
  MediaFields_url_Update?: MediaFields_Url_UpdateResolvers<ContextType>;
  MediaFields_width?: MediaFields_WidthResolvers<ContextType>;
  MediaFields_width_Create?: MediaFields_Width_CreateResolvers<ContextType>;
  MediaFields_width_Delete?: MediaFields_Width_DeleteResolvers<ContextType>;
  MediaFields_width_Read?: MediaFields_Width_ReadResolvers<ContextType>;
  MediaFields_width_Update?: MediaFields_Width_UpdateResolvers<ContextType>;
  MediaReadAccess?: MediaReadAccessResolvers<ContextType>;
  MediaReadDocAccess?: MediaReadDocAccessResolvers<ContextType>;
  MediaUpdateAccess?: MediaUpdateAccessResolvers<ContextType>;
  MediaUpdateDocAccess?: MediaUpdateDocAccessResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  PayloadPreference?: PayloadPreferenceResolvers<ContextType>;
  PayloadPreference_User?: PayloadPreference_UserResolvers<ContextType>;
  PayloadPreference_User_Relationship?: PayloadPreference_User_RelationshipResolvers<ContextType>;
  PayloadPreferences?: PayloadPreferencesResolvers<ContextType>;
  PayloadPreferencesCreateAccess?: PayloadPreferencesCreateAccessResolvers<ContextType>;
  PayloadPreferencesCreateDocAccess?: PayloadPreferencesCreateDocAccessResolvers<ContextType>;
  PayloadPreferencesDeleteAccess?: PayloadPreferencesDeleteAccessResolvers<ContextType>;
  PayloadPreferencesDeleteDocAccess?: PayloadPreferencesDeleteDocAccessResolvers<ContextType>;
  PayloadPreferencesDocAccessFields?: PayloadPreferencesDocAccessFieldsResolvers<ContextType>;
  PayloadPreferencesDocAccessFields_createdAt?: PayloadPreferencesDocAccessFields_CreatedAtResolvers<ContextType>;
  PayloadPreferencesDocAccessFields_createdAt_Create?: PayloadPreferencesDocAccessFields_CreatedAt_CreateResolvers<ContextType>;
  PayloadPreferencesDocAccessFields_createdAt_Delete?: PayloadPreferencesDocAccessFields_CreatedAt_DeleteResolvers<ContextType>;
  PayloadPreferencesDocAccessFields_createdAt_Read?: PayloadPreferencesDocAccessFields_CreatedAt_ReadResolvers<ContextType>;
  PayloadPreferencesDocAccessFields_createdAt_Update?: PayloadPreferencesDocAccessFields_CreatedAt_UpdateResolvers<ContextType>;
  PayloadPreferencesDocAccessFields_key?: PayloadPreferencesDocAccessFields_KeyResolvers<ContextType>;
  PayloadPreferencesDocAccessFields_key_Create?: PayloadPreferencesDocAccessFields_Key_CreateResolvers<ContextType>;
  PayloadPreferencesDocAccessFields_key_Delete?: PayloadPreferencesDocAccessFields_Key_DeleteResolvers<ContextType>;
  PayloadPreferencesDocAccessFields_key_Read?: PayloadPreferencesDocAccessFields_Key_ReadResolvers<ContextType>;
  PayloadPreferencesDocAccessFields_key_Update?: PayloadPreferencesDocAccessFields_Key_UpdateResolvers<ContextType>;
  PayloadPreferencesDocAccessFields_updatedAt?: PayloadPreferencesDocAccessFields_UpdatedAtResolvers<ContextType>;
  PayloadPreferencesDocAccessFields_updatedAt_Create?: PayloadPreferencesDocAccessFields_UpdatedAt_CreateResolvers<ContextType>;
  PayloadPreferencesDocAccessFields_updatedAt_Delete?: PayloadPreferencesDocAccessFields_UpdatedAt_DeleteResolvers<ContextType>;
  PayloadPreferencesDocAccessFields_updatedAt_Read?: PayloadPreferencesDocAccessFields_UpdatedAt_ReadResolvers<ContextType>;
  PayloadPreferencesDocAccessFields_updatedAt_Update?: PayloadPreferencesDocAccessFields_UpdatedAt_UpdateResolvers<ContextType>;
  PayloadPreferencesDocAccessFields_user?: PayloadPreferencesDocAccessFields_UserResolvers<ContextType>;
  PayloadPreferencesDocAccessFields_user_Create?: PayloadPreferencesDocAccessFields_User_CreateResolvers<ContextType>;
  PayloadPreferencesDocAccessFields_user_Delete?: PayloadPreferencesDocAccessFields_User_DeleteResolvers<ContextType>;
  PayloadPreferencesDocAccessFields_user_Read?: PayloadPreferencesDocAccessFields_User_ReadResolvers<ContextType>;
  PayloadPreferencesDocAccessFields_user_Update?: PayloadPreferencesDocAccessFields_User_UpdateResolvers<ContextType>;
  PayloadPreferencesDocAccessFields_value?: PayloadPreferencesDocAccessFields_ValueResolvers<ContextType>;
  PayloadPreferencesDocAccessFields_value_Create?: PayloadPreferencesDocAccessFields_Value_CreateResolvers<ContextType>;
  PayloadPreferencesDocAccessFields_value_Delete?: PayloadPreferencesDocAccessFields_Value_DeleteResolvers<ContextType>;
  PayloadPreferencesDocAccessFields_value_Read?: PayloadPreferencesDocAccessFields_Value_ReadResolvers<ContextType>;
  PayloadPreferencesDocAccessFields_value_Update?: PayloadPreferencesDocAccessFields_Value_UpdateResolvers<ContextType>;
  PayloadPreferencesFields?: PayloadPreferencesFieldsResolvers<ContextType>;
  PayloadPreferencesFields_createdAt?: PayloadPreferencesFields_CreatedAtResolvers<ContextType>;
  PayloadPreferencesFields_createdAt_Create?: PayloadPreferencesFields_CreatedAt_CreateResolvers<ContextType>;
  PayloadPreferencesFields_createdAt_Delete?: PayloadPreferencesFields_CreatedAt_DeleteResolvers<ContextType>;
  PayloadPreferencesFields_createdAt_Read?: PayloadPreferencesFields_CreatedAt_ReadResolvers<ContextType>;
  PayloadPreferencesFields_createdAt_Update?: PayloadPreferencesFields_CreatedAt_UpdateResolvers<ContextType>;
  PayloadPreferencesFields_key?: PayloadPreferencesFields_KeyResolvers<ContextType>;
  PayloadPreferencesFields_key_Create?: PayloadPreferencesFields_Key_CreateResolvers<ContextType>;
  PayloadPreferencesFields_key_Delete?: PayloadPreferencesFields_Key_DeleteResolvers<ContextType>;
  PayloadPreferencesFields_key_Read?: PayloadPreferencesFields_Key_ReadResolvers<ContextType>;
  PayloadPreferencesFields_key_Update?: PayloadPreferencesFields_Key_UpdateResolvers<ContextType>;
  PayloadPreferencesFields_updatedAt?: PayloadPreferencesFields_UpdatedAtResolvers<ContextType>;
  PayloadPreferencesFields_updatedAt_Create?: PayloadPreferencesFields_UpdatedAt_CreateResolvers<ContextType>;
  PayloadPreferencesFields_updatedAt_Delete?: PayloadPreferencesFields_UpdatedAt_DeleteResolvers<ContextType>;
  PayloadPreferencesFields_updatedAt_Read?: PayloadPreferencesFields_UpdatedAt_ReadResolvers<ContextType>;
  PayloadPreferencesFields_updatedAt_Update?: PayloadPreferencesFields_UpdatedAt_UpdateResolvers<ContextType>;
  PayloadPreferencesFields_user?: PayloadPreferencesFields_UserResolvers<ContextType>;
  PayloadPreferencesFields_user_Create?: PayloadPreferencesFields_User_CreateResolvers<ContextType>;
  PayloadPreferencesFields_user_Delete?: PayloadPreferencesFields_User_DeleteResolvers<ContextType>;
  PayloadPreferencesFields_user_Read?: PayloadPreferencesFields_User_ReadResolvers<ContextType>;
  PayloadPreferencesFields_user_Update?: PayloadPreferencesFields_User_UpdateResolvers<ContextType>;
  PayloadPreferencesFields_value?: PayloadPreferencesFields_ValueResolvers<ContextType>;
  PayloadPreferencesFields_value_Create?: PayloadPreferencesFields_Value_CreateResolvers<ContextType>;
  PayloadPreferencesFields_value_Delete?: PayloadPreferencesFields_Value_DeleteResolvers<ContextType>;
  PayloadPreferencesFields_value_Read?: PayloadPreferencesFields_Value_ReadResolvers<ContextType>;
  PayloadPreferencesFields_value_Update?: PayloadPreferencesFields_Value_UpdateResolvers<ContextType>;
  PayloadPreferencesReadAccess?: PayloadPreferencesReadAccessResolvers<ContextType>;
  PayloadPreferencesReadDocAccess?: PayloadPreferencesReadDocAccessResolvers<ContextType>;
  PayloadPreferencesUpdateAccess?: PayloadPreferencesUpdateAccessResolvers<ContextType>;
  PayloadPreferencesUpdateDocAccess?: PayloadPreferencesUpdateDocAccessResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  TaskTemplate?: TaskTemplateResolvers<ContextType>;
  TaskTemplateVersion?: TaskTemplateVersionResolvers<ContextType>;
  TaskTemplateVersion_Version?: TaskTemplateVersion_VersionResolvers<ContextType>;
  TaskTemplateVersion_Version_Resources?: TaskTemplateVersion_Version_ResourcesResolvers<ContextType>;
  TaskTemplateVersion_Version_Review?: TaskTemplateVersion_Version_ReviewResolvers<ContextType>;
  TaskTemplateVersion_Version_Schedule?: TaskTemplateVersion_Version_ScheduleResolvers<ContextType>;
  TaskTemplateVersion_Version_Schedule_Recurrence?: TaskTemplateVersion_Version_Schedule_RecurrenceResolvers<ContextType>;
  TaskTemplate_Resources?: TaskTemplate_ResourcesResolvers<ContextType>;
  TaskTemplate_Review?: TaskTemplate_ReviewResolvers<ContextType>;
  TaskTemplate_Schedule?: TaskTemplate_ScheduleResolvers<ContextType>;
  TaskTemplate_Schedule_Recurrence?: TaskTemplate_Schedule_RecurrenceResolvers<ContextType>;
  TaskTemplates?: TaskTemplatesResolvers<ContextType>;
  TaskTemplatesCreateAccess?: TaskTemplatesCreateAccessResolvers<ContextType>;
  TaskTemplatesCreateDocAccess?: TaskTemplatesCreateDocAccessResolvers<ContextType>;
  TaskTemplatesDeleteAccess?: TaskTemplatesDeleteAccessResolvers<ContextType>;
  TaskTemplatesDeleteDocAccess?: TaskTemplatesDeleteDocAccessResolvers<ContextType>;
  TaskTemplatesDocAccessFields?: TaskTemplatesDocAccessFieldsResolvers<ContextType>;
  TaskTemplatesDocAccessFields__status?: TaskTemplatesDocAccessFields__StatusResolvers<ContextType>;
  TaskTemplatesDocAccessFields__status_Create?: TaskTemplatesDocAccessFields__Status_CreateResolvers<ContextType>;
  TaskTemplatesDocAccessFields__status_Delete?: TaskTemplatesDocAccessFields__Status_DeleteResolvers<ContextType>;
  TaskTemplatesDocAccessFields__status_Read?: TaskTemplatesDocAccessFields__Status_ReadResolvers<ContextType>;
  TaskTemplatesDocAccessFields__status_Update?: TaskTemplatesDocAccessFields__Status_UpdateResolvers<ContextType>;
  TaskTemplatesDocAccessFields_assignment?: TaskTemplatesDocAccessFields_AssignmentResolvers<ContextType>;
  TaskTemplatesDocAccessFields_assignment_Create?: TaskTemplatesDocAccessFields_Assignment_CreateResolvers<ContextType>;
  TaskTemplatesDocAccessFields_assignment_Delete?: TaskTemplatesDocAccessFields_Assignment_DeleteResolvers<ContextType>;
  TaskTemplatesDocAccessFields_assignment_Read?: TaskTemplatesDocAccessFields_Assignment_ReadResolvers<ContextType>;
  TaskTemplatesDocAccessFields_assignment_Update?: TaskTemplatesDocAccessFields_Assignment_UpdateResolvers<ContextType>;
  TaskTemplatesDocAccessFields_categories?: TaskTemplatesDocAccessFields_CategoriesResolvers<ContextType>;
  TaskTemplatesDocAccessFields_categories_Create?: TaskTemplatesDocAccessFields_Categories_CreateResolvers<ContextType>;
  TaskTemplatesDocAccessFields_categories_Delete?: TaskTemplatesDocAccessFields_Categories_DeleteResolvers<ContextType>;
  TaskTemplatesDocAccessFields_categories_Read?: TaskTemplatesDocAccessFields_Categories_ReadResolvers<ContextType>;
  TaskTemplatesDocAccessFields_categories_Update?: TaskTemplatesDocAccessFields_Categories_UpdateResolvers<ContextType>;
  TaskTemplatesDocAccessFields_createdAt?: TaskTemplatesDocAccessFields_CreatedAtResolvers<ContextType>;
  TaskTemplatesDocAccessFields_createdAt_Create?: TaskTemplatesDocAccessFields_CreatedAt_CreateResolvers<ContextType>;
  TaskTemplatesDocAccessFields_createdAt_Delete?: TaskTemplatesDocAccessFields_CreatedAt_DeleteResolvers<ContextType>;
  TaskTemplatesDocAccessFields_createdAt_Read?: TaskTemplatesDocAccessFields_CreatedAt_ReadResolvers<ContextType>;
  TaskTemplatesDocAccessFields_createdAt_Update?: TaskTemplatesDocAccessFields_CreatedAt_UpdateResolvers<ContextType>;
  TaskTemplatesDocAccessFields_description?: TaskTemplatesDocAccessFields_DescriptionResolvers<ContextType>;
  TaskTemplatesDocAccessFields_description_Create?: TaskTemplatesDocAccessFields_Description_CreateResolvers<ContextType>;
  TaskTemplatesDocAccessFields_description_Delete?: TaskTemplatesDocAccessFields_Description_DeleteResolvers<ContextType>;
  TaskTemplatesDocAccessFields_description_Read?: TaskTemplatesDocAccessFields_Description_ReadResolvers<ContextType>;
  TaskTemplatesDocAccessFields_description_Update?: TaskTemplatesDocAccessFields_Description_UpdateResolvers<ContextType>;
  TaskTemplatesDocAccessFields_details?: TaskTemplatesDocAccessFields_DetailsResolvers<ContextType>;
  TaskTemplatesDocAccessFields_details_Create?: TaskTemplatesDocAccessFields_Details_CreateResolvers<ContextType>;
  TaskTemplatesDocAccessFields_details_Delete?: TaskTemplatesDocAccessFields_Details_DeleteResolvers<ContextType>;
  TaskTemplatesDocAccessFields_details_Read?: TaskTemplatesDocAccessFields_Details_ReadResolvers<ContextType>;
  TaskTemplatesDocAccessFields_details_Update?: TaskTemplatesDocAccessFields_Details_UpdateResolvers<ContextType>;
  TaskTemplatesDocAccessFields_feature?: TaskTemplatesDocAccessFields_FeatureResolvers<ContextType>;
  TaskTemplatesDocAccessFields_feature_Create?: TaskTemplatesDocAccessFields_Feature_CreateResolvers<ContextType>;
  TaskTemplatesDocAccessFields_feature_Delete?: TaskTemplatesDocAccessFields_Feature_DeleteResolvers<ContextType>;
  TaskTemplatesDocAccessFields_feature_Read?: TaskTemplatesDocAccessFields_Feature_ReadResolvers<ContextType>;
  TaskTemplatesDocAccessFields_feature_Update?: TaskTemplatesDocAccessFields_Feature_UpdateResolvers<ContextType>;
  TaskTemplatesDocAccessFields_resources?: TaskTemplatesDocAccessFields_ResourcesResolvers<ContextType>;
  TaskTemplatesDocAccessFields_resources_Create?: TaskTemplatesDocAccessFields_Resources_CreateResolvers<ContextType>;
  TaskTemplatesDocAccessFields_resources_Delete?: TaskTemplatesDocAccessFields_Resources_DeleteResolvers<ContextType>;
  TaskTemplatesDocAccessFields_resources_Fields?: TaskTemplatesDocAccessFields_Resources_FieldsResolvers<ContextType>;
  TaskTemplatesDocAccessFields_resources_Read?: TaskTemplatesDocAccessFields_Resources_ReadResolvers<ContextType>;
  TaskTemplatesDocAccessFields_resources_Update?: TaskTemplatesDocAccessFields_Resources_UpdateResolvers<ContextType>;
  TaskTemplatesDocAccessFields_resources_articles?: TaskTemplatesDocAccessFields_Resources_ArticlesResolvers<ContextType>;
  TaskTemplatesDocAccessFields_resources_articles_Create?: TaskTemplatesDocAccessFields_Resources_Articles_CreateResolvers<ContextType>;
  TaskTemplatesDocAccessFields_resources_articles_Delete?: TaskTemplatesDocAccessFields_Resources_Articles_DeleteResolvers<ContextType>;
  TaskTemplatesDocAccessFields_resources_articles_Read?: TaskTemplatesDocAccessFields_Resources_Articles_ReadResolvers<ContextType>;
  TaskTemplatesDocAccessFields_resources_articles_Update?: TaskTemplatesDocAccessFields_Resources_Articles_UpdateResolvers<ContextType>;
  TaskTemplatesDocAccessFields_resources_videos?: TaskTemplatesDocAccessFields_Resources_VideosResolvers<ContextType>;
  TaskTemplatesDocAccessFields_resources_videos_Create?: TaskTemplatesDocAccessFields_Resources_Videos_CreateResolvers<ContextType>;
  TaskTemplatesDocAccessFields_resources_videos_Delete?: TaskTemplatesDocAccessFields_Resources_Videos_DeleteResolvers<ContextType>;
  TaskTemplatesDocAccessFields_resources_videos_Read?: TaskTemplatesDocAccessFields_Resources_Videos_ReadResolvers<ContextType>;
  TaskTemplatesDocAccessFields_resources_videos_Update?: TaskTemplatesDocAccessFields_Resources_Videos_UpdateResolvers<ContextType>;
  TaskTemplatesDocAccessFields_review?: TaskTemplatesDocAccessFields_ReviewResolvers<ContextType>;
  TaskTemplatesDocAccessFields_review_Create?: TaskTemplatesDocAccessFields_Review_CreateResolvers<ContextType>;
  TaskTemplatesDocAccessFields_review_Delete?: TaskTemplatesDocAccessFields_Review_DeleteResolvers<ContextType>;
  TaskTemplatesDocAccessFields_review_Fields?: TaskTemplatesDocAccessFields_Review_FieldsResolvers<ContextType>;
  TaskTemplatesDocAccessFields_review_Read?: TaskTemplatesDocAccessFields_Review_ReadResolvers<ContextType>;
  TaskTemplatesDocAccessFields_review_Update?: TaskTemplatesDocAccessFields_Review_UpdateResolvers<ContextType>;
  TaskTemplatesDocAccessFields_review_approver?: TaskTemplatesDocAccessFields_Review_ApproverResolvers<ContextType>;
  TaskTemplatesDocAccessFields_review_approver_Create?: TaskTemplatesDocAccessFields_Review_Approver_CreateResolvers<ContextType>;
  TaskTemplatesDocAccessFields_review_approver_Delete?: TaskTemplatesDocAccessFields_Review_Approver_DeleteResolvers<ContextType>;
  TaskTemplatesDocAccessFields_review_approver_Read?: TaskTemplatesDocAccessFields_Review_Approver_ReadResolvers<ContextType>;
  TaskTemplatesDocAccessFields_review_approver_Update?: TaskTemplatesDocAccessFields_Review_Approver_UpdateResolvers<ContextType>;
  TaskTemplatesDocAccessFields_review_evidence?: TaskTemplatesDocAccessFields_Review_EvidenceResolvers<ContextType>;
  TaskTemplatesDocAccessFields_review_evidence_Create?: TaskTemplatesDocAccessFields_Review_Evidence_CreateResolvers<ContextType>;
  TaskTemplatesDocAccessFields_review_evidence_Delete?: TaskTemplatesDocAccessFields_Review_Evidence_DeleteResolvers<ContextType>;
  TaskTemplatesDocAccessFields_review_evidence_Read?: TaskTemplatesDocAccessFields_Review_Evidence_ReadResolvers<ContextType>;
  TaskTemplatesDocAccessFields_review_evidence_Update?: TaskTemplatesDocAccessFields_Review_Evidence_UpdateResolvers<ContextType>;
  TaskTemplatesDocAccessFields_review_required?: TaskTemplatesDocAccessFields_Review_RequiredResolvers<ContextType>;
  TaskTemplatesDocAccessFields_review_required_Create?: TaskTemplatesDocAccessFields_Review_Required_CreateResolvers<ContextType>;
  TaskTemplatesDocAccessFields_review_required_Delete?: TaskTemplatesDocAccessFields_Review_Required_DeleteResolvers<ContextType>;
  TaskTemplatesDocAccessFields_review_required_Read?: TaskTemplatesDocAccessFields_Review_Required_ReadResolvers<ContextType>;
  TaskTemplatesDocAccessFields_review_required_Update?: TaskTemplatesDocAccessFields_Review_Required_UpdateResolvers<ContextType>;
  TaskTemplatesDocAccessFields_schedule?: TaskTemplatesDocAccessFields_ScheduleResolvers<ContextType>;
  TaskTemplatesDocAccessFields_schedule_Create?: TaskTemplatesDocAccessFields_Schedule_CreateResolvers<ContextType>;
  TaskTemplatesDocAccessFields_schedule_Delete?: TaskTemplatesDocAccessFields_Schedule_DeleteResolvers<ContextType>;
  TaskTemplatesDocAccessFields_schedule_Fields?: TaskTemplatesDocAccessFields_Schedule_FieldsResolvers<ContextType>;
  TaskTemplatesDocAccessFields_schedule_Read?: TaskTemplatesDocAccessFields_Schedule_ReadResolvers<ContextType>;
  TaskTemplatesDocAccessFields_schedule_Update?: TaskTemplatesDocAccessFields_Schedule_UpdateResolvers<ContextType>;
  TaskTemplatesDocAccessFields_schedule_due?: TaskTemplatesDocAccessFields_Schedule_DueResolvers<ContextType>;
  TaskTemplatesDocAccessFields_schedule_due_Create?: TaskTemplatesDocAccessFields_Schedule_Due_CreateResolvers<ContextType>;
  TaskTemplatesDocAccessFields_schedule_due_Delete?: TaskTemplatesDocAccessFields_Schedule_Due_DeleteResolvers<ContextType>;
  TaskTemplatesDocAccessFields_schedule_due_Read?: TaskTemplatesDocAccessFields_Schedule_Due_ReadResolvers<ContextType>;
  TaskTemplatesDocAccessFields_schedule_due_Update?: TaskTemplatesDocAccessFields_Schedule_Due_UpdateResolvers<ContextType>;
  TaskTemplatesDocAccessFields_schedule_event?: TaskTemplatesDocAccessFields_Schedule_EventResolvers<ContextType>;
  TaskTemplatesDocAccessFields_schedule_event_Create?: TaskTemplatesDocAccessFields_Schedule_Event_CreateResolvers<ContextType>;
  TaskTemplatesDocAccessFields_schedule_event_Delete?: TaskTemplatesDocAccessFields_Schedule_Event_DeleteResolvers<ContextType>;
  TaskTemplatesDocAccessFields_schedule_event_Read?: TaskTemplatesDocAccessFields_Schedule_Event_ReadResolvers<ContextType>;
  TaskTemplatesDocAccessFields_schedule_event_Update?: TaskTemplatesDocAccessFields_Schedule_Event_UpdateResolvers<ContextType>;
  TaskTemplatesDocAccessFields_schedule_freq?: TaskTemplatesDocAccessFields_Schedule_FreqResolvers<ContextType>;
  TaskTemplatesDocAccessFields_schedule_freq_Create?: TaskTemplatesDocAccessFields_Schedule_Freq_CreateResolvers<ContextType>;
  TaskTemplatesDocAccessFields_schedule_freq_Delete?: TaskTemplatesDocAccessFields_Schedule_Freq_DeleteResolvers<ContextType>;
  TaskTemplatesDocAccessFields_schedule_freq_Read?: TaskTemplatesDocAccessFields_Schedule_Freq_ReadResolvers<ContextType>;
  TaskTemplatesDocAccessFields_schedule_freq_Update?: TaskTemplatesDocAccessFields_Schedule_Freq_UpdateResolvers<ContextType>;
  TaskTemplatesDocAccessFields_schedule_id?: TaskTemplatesDocAccessFields_Schedule_IdResolvers<ContextType>;
  TaskTemplatesDocAccessFields_schedule_id_Create?: TaskTemplatesDocAccessFields_Schedule_Id_CreateResolvers<ContextType>;
  TaskTemplatesDocAccessFields_schedule_id_Delete?: TaskTemplatesDocAccessFields_Schedule_Id_DeleteResolvers<ContextType>;
  TaskTemplatesDocAccessFields_schedule_id_Read?: TaskTemplatesDocAccessFields_Schedule_Id_ReadResolvers<ContextType>;
  TaskTemplatesDocAccessFields_schedule_id_Update?: TaskTemplatesDocAccessFields_Schedule_Id_UpdateResolvers<ContextType>;
  TaskTemplatesDocAccessFields_schedule_interval?: TaskTemplatesDocAccessFields_Schedule_IntervalResolvers<ContextType>;
  TaskTemplatesDocAccessFields_schedule_interval_Create?: TaskTemplatesDocAccessFields_Schedule_Interval_CreateResolvers<ContextType>;
  TaskTemplatesDocAccessFields_schedule_interval_Delete?: TaskTemplatesDocAccessFields_Schedule_Interval_DeleteResolvers<ContextType>;
  TaskTemplatesDocAccessFields_schedule_interval_Read?: TaskTemplatesDocAccessFields_Schedule_Interval_ReadResolvers<ContextType>;
  TaskTemplatesDocAccessFields_schedule_interval_Update?: TaskTemplatesDocAccessFields_Schedule_Interval_UpdateResolvers<ContextType>;
  TaskTemplatesDocAccessFields_thumbnail?: TaskTemplatesDocAccessFields_ThumbnailResolvers<ContextType>;
  TaskTemplatesDocAccessFields_thumbnail_Create?: TaskTemplatesDocAccessFields_Thumbnail_CreateResolvers<ContextType>;
  TaskTemplatesDocAccessFields_thumbnail_Delete?: TaskTemplatesDocAccessFields_Thumbnail_DeleteResolvers<ContextType>;
  TaskTemplatesDocAccessFields_thumbnail_Read?: TaskTemplatesDocAccessFields_Thumbnail_ReadResolvers<ContextType>;
  TaskTemplatesDocAccessFields_thumbnail_Update?: TaskTemplatesDocAccessFields_Thumbnail_UpdateResolvers<ContextType>;
  TaskTemplatesDocAccessFields_title?: TaskTemplatesDocAccessFields_TitleResolvers<ContextType>;
  TaskTemplatesDocAccessFields_title_Create?: TaskTemplatesDocAccessFields_Title_CreateResolvers<ContextType>;
  TaskTemplatesDocAccessFields_title_Delete?: TaskTemplatesDocAccessFields_Title_DeleteResolvers<ContextType>;
  TaskTemplatesDocAccessFields_title_Read?: TaskTemplatesDocAccessFields_Title_ReadResolvers<ContextType>;
  TaskTemplatesDocAccessFields_title_Update?: TaskTemplatesDocAccessFields_Title_UpdateResolvers<ContextType>;
  TaskTemplatesDocAccessFields_updatedAt?: TaskTemplatesDocAccessFields_UpdatedAtResolvers<ContextType>;
  TaskTemplatesDocAccessFields_updatedAt_Create?: TaskTemplatesDocAccessFields_UpdatedAt_CreateResolvers<ContextType>;
  TaskTemplatesDocAccessFields_updatedAt_Delete?: TaskTemplatesDocAccessFields_UpdatedAt_DeleteResolvers<ContextType>;
  TaskTemplatesDocAccessFields_updatedAt_Read?: TaskTemplatesDocAccessFields_UpdatedAt_ReadResolvers<ContextType>;
  TaskTemplatesDocAccessFields_updatedAt_Update?: TaskTemplatesDocAccessFields_UpdatedAt_UpdateResolvers<ContextType>;
  TaskTemplatesFields?: TaskTemplatesFieldsResolvers<ContextType>;
  TaskTemplatesFields__status?: TaskTemplatesFields__StatusResolvers<ContextType>;
  TaskTemplatesFields__status_Create?: TaskTemplatesFields__Status_CreateResolvers<ContextType>;
  TaskTemplatesFields__status_Delete?: TaskTemplatesFields__Status_DeleteResolvers<ContextType>;
  TaskTemplatesFields__status_Read?: TaskTemplatesFields__Status_ReadResolvers<ContextType>;
  TaskTemplatesFields__status_Update?: TaskTemplatesFields__Status_UpdateResolvers<ContextType>;
  TaskTemplatesFields_assignment?: TaskTemplatesFields_AssignmentResolvers<ContextType>;
  TaskTemplatesFields_assignment_Create?: TaskTemplatesFields_Assignment_CreateResolvers<ContextType>;
  TaskTemplatesFields_assignment_Delete?: TaskTemplatesFields_Assignment_DeleteResolvers<ContextType>;
  TaskTemplatesFields_assignment_Read?: TaskTemplatesFields_Assignment_ReadResolvers<ContextType>;
  TaskTemplatesFields_assignment_Update?: TaskTemplatesFields_Assignment_UpdateResolvers<ContextType>;
  TaskTemplatesFields_categories?: TaskTemplatesFields_CategoriesResolvers<ContextType>;
  TaskTemplatesFields_categories_Create?: TaskTemplatesFields_Categories_CreateResolvers<ContextType>;
  TaskTemplatesFields_categories_Delete?: TaskTemplatesFields_Categories_DeleteResolvers<ContextType>;
  TaskTemplatesFields_categories_Read?: TaskTemplatesFields_Categories_ReadResolvers<ContextType>;
  TaskTemplatesFields_categories_Update?: TaskTemplatesFields_Categories_UpdateResolvers<ContextType>;
  TaskTemplatesFields_createdAt?: TaskTemplatesFields_CreatedAtResolvers<ContextType>;
  TaskTemplatesFields_createdAt_Create?: TaskTemplatesFields_CreatedAt_CreateResolvers<ContextType>;
  TaskTemplatesFields_createdAt_Delete?: TaskTemplatesFields_CreatedAt_DeleteResolvers<ContextType>;
  TaskTemplatesFields_createdAt_Read?: TaskTemplatesFields_CreatedAt_ReadResolvers<ContextType>;
  TaskTemplatesFields_createdAt_Update?: TaskTemplatesFields_CreatedAt_UpdateResolvers<ContextType>;
  TaskTemplatesFields_description?: TaskTemplatesFields_DescriptionResolvers<ContextType>;
  TaskTemplatesFields_description_Create?: TaskTemplatesFields_Description_CreateResolvers<ContextType>;
  TaskTemplatesFields_description_Delete?: TaskTemplatesFields_Description_DeleteResolvers<ContextType>;
  TaskTemplatesFields_description_Read?: TaskTemplatesFields_Description_ReadResolvers<ContextType>;
  TaskTemplatesFields_description_Update?: TaskTemplatesFields_Description_UpdateResolvers<ContextType>;
  TaskTemplatesFields_details?: TaskTemplatesFields_DetailsResolvers<ContextType>;
  TaskTemplatesFields_details_Create?: TaskTemplatesFields_Details_CreateResolvers<ContextType>;
  TaskTemplatesFields_details_Delete?: TaskTemplatesFields_Details_DeleteResolvers<ContextType>;
  TaskTemplatesFields_details_Read?: TaskTemplatesFields_Details_ReadResolvers<ContextType>;
  TaskTemplatesFields_details_Update?: TaskTemplatesFields_Details_UpdateResolvers<ContextType>;
  TaskTemplatesFields_feature?: TaskTemplatesFields_FeatureResolvers<ContextType>;
  TaskTemplatesFields_feature_Create?: TaskTemplatesFields_Feature_CreateResolvers<ContextType>;
  TaskTemplatesFields_feature_Delete?: TaskTemplatesFields_Feature_DeleteResolvers<ContextType>;
  TaskTemplatesFields_feature_Read?: TaskTemplatesFields_Feature_ReadResolvers<ContextType>;
  TaskTemplatesFields_feature_Update?: TaskTemplatesFields_Feature_UpdateResolvers<ContextType>;
  TaskTemplatesFields_resources?: TaskTemplatesFields_ResourcesResolvers<ContextType>;
  TaskTemplatesFields_resources_Create?: TaskTemplatesFields_Resources_CreateResolvers<ContextType>;
  TaskTemplatesFields_resources_Delete?: TaskTemplatesFields_Resources_DeleteResolvers<ContextType>;
  TaskTemplatesFields_resources_Fields?: TaskTemplatesFields_Resources_FieldsResolvers<ContextType>;
  TaskTemplatesFields_resources_Read?: TaskTemplatesFields_Resources_ReadResolvers<ContextType>;
  TaskTemplatesFields_resources_Update?: TaskTemplatesFields_Resources_UpdateResolvers<ContextType>;
  TaskTemplatesFields_resources_articles?: TaskTemplatesFields_Resources_ArticlesResolvers<ContextType>;
  TaskTemplatesFields_resources_articles_Create?: TaskTemplatesFields_Resources_Articles_CreateResolvers<ContextType>;
  TaskTemplatesFields_resources_articles_Delete?: TaskTemplatesFields_Resources_Articles_DeleteResolvers<ContextType>;
  TaskTemplatesFields_resources_articles_Read?: TaskTemplatesFields_Resources_Articles_ReadResolvers<ContextType>;
  TaskTemplatesFields_resources_articles_Update?: TaskTemplatesFields_Resources_Articles_UpdateResolvers<ContextType>;
  TaskTemplatesFields_resources_videos?: TaskTemplatesFields_Resources_VideosResolvers<ContextType>;
  TaskTemplatesFields_resources_videos_Create?: TaskTemplatesFields_Resources_Videos_CreateResolvers<ContextType>;
  TaskTemplatesFields_resources_videos_Delete?: TaskTemplatesFields_Resources_Videos_DeleteResolvers<ContextType>;
  TaskTemplatesFields_resources_videos_Read?: TaskTemplatesFields_Resources_Videos_ReadResolvers<ContextType>;
  TaskTemplatesFields_resources_videos_Update?: TaskTemplatesFields_Resources_Videos_UpdateResolvers<ContextType>;
  TaskTemplatesFields_review?: TaskTemplatesFields_ReviewResolvers<ContextType>;
  TaskTemplatesFields_review_Create?: TaskTemplatesFields_Review_CreateResolvers<ContextType>;
  TaskTemplatesFields_review_Delete?: TaskTemplatesFields_Review_DeleteResolvers<ContextType>;
  TaskTemplatesFields_review_Fields?: TaskTemplatesFields_Review_FieldsResolvers<ContextType>;
  TaskTemplatesFields_review_Read?: TaskTemplatesFields_Review_ReadResolvers<ContextType>;
  TaskTemplatesFields_review_Update?: TaskTemplatesFields_Review_UpdateResolvers<ContextType>;
  TaskTemplatesFields_review_approver?: TaskTemplatesFields_Review_ApproverResolvers<ContextType>;
  TaskTemplatesFields_review_approver_Create?: TaskTemplatesFields_Review_Approver_CreateResolvers<ContextType>;
  TaskTemplatesFields_review_approver_Delete?: TaskTemplatesFields_Review_Approver_DeleteResolvers<ContextType>;
  TaskTemplatesFields_review_approver_Read?: TaskTemplatesFields_Review_Approver_ReadResolvers<ContextType>;
  TaskTemplatesFields_review_approver_Update?: TaskTemplatesFields_Review_Approver_UpdateResolvers<ContextType>;
  TaskTemplatesFields_review_evidence?: TaskTemplatesFields_Review_EvidenceResolvers<ContextType>;
  TaskTemplatesFields_review_evidence_Create?: TaskTemplatesFields_Review_Evidence_CreateResolvers<ContextType>;
  TaskTemplatesFields_review_evidence_Delete?: TaskTemplatesFields_Review_Evidence_DeleteResolvers<ContextType>;
  TaskTemplatesFields_review_evidence_Read?: TaskTemplatesFields_Review_Evidence_ReadResolvers<ContextType>;
  TaskTemplatesFields_review_evidence_Update?: TaskTemplatesFields_Review_Evidence_UpdateResolvers<ContextType>;
  TaskTemplatesFields_review_required?: TaskTemplatesFields_Review_RequiredResolvers<ContextType>;
  TaskTemplatesFields_review_required_Create?: TaskTemplatesFields_Review_Required_CreateResolvers<ContextType>;
  TaskTemplatesFields_review_required_Delete?: TaskTemplatesFields_Review_Required_DeleteResolvers<ContextType>;
  TaskTemplatesFields_review_required_Read?: TaskTemplatesFields_Review_Required_ReadResolvers<ContextType>;
  TaskTemplatesFields_review_required_Update?: TaskTemplatesFields_Review_Required_UpdateResolvers<ContextType>;
  TaskTemplatesFields_schedule?: TaskTemplatesFields_ScheduleResolvers<ContextType>;
  TaskTemplatesFields_schedule_Create?: TaskTemplatesFields_Schedule_CreateResolvers<ContextType>;
  TaskTemplatesFields_schedule_Delete?: TaskTemplatesFields_Schedule_DeleteResolvers<ContextType>;
  TaskTemplatesFields_schedule_Fields?: TaskTemplatesFields_Schedule_FieldsResolvers<ContextType>;
  TaskTemplatesFields_schedule_Read?: TaskTemplatesFields_Schedule_ReadResolvers<ContextType>;
  TaskTemplatesFields_schedule_Update?: TaskTemplatesFields_Schedule_UpdateResolvers<ContextType>;
  TaskTemplatesFields_schedule_due?: TaskTemplatesFields_Schedule_DueResolvers<ContextType>;
  TaskTemplatesFields_schedule_due_Create?: TaskTemplatesFields_Schedule_Due_CreateResolvers<ContextType>;
  TaskTemplatesFields_schedule_due_Delete?: TaskTemplatesFields_Schedule_Due_DeleteResolvers<ContextType>;
  TaskTemplatesFields_schedule_due_Read?: TaskTemplatesFields_Schedule_Due_ReadResolvers<ContextType>;
  TaskTemplatesFields_schedule_due_Update?: TaskTemplatesFields_Schedule_Due_UpdateResolvers<ContextType>;
  TaskTemplatesFields_schedule_event?: TaskTemplatesFields_Schedule_EventResolvers<ContextType>;
  TaskTemplatesFields_schedule_event_Create?: TaskTemplatesFields_Schedule_Event_CreateResolvers<ContextType>;
  TaskTemplatesFields_schedule_event_Delete?: TaskTemplatesFields_Schedule_Event_DeleteResolvers<ContextType>;
  TaskTemplatesFields_schedule_event_Read?: TaskTemplatesFields_Schedule_Event_ReadResolvers<ContextType>;
  TaskTemplatesFields_schedule_event_Update?: TaskTemplatesFields_Schedule_Event_UpdateResolvers<ContextType>;
  TaskTemplatesFields_schedule_freq?: TaskTemplatesFields_Schedule_FreqResolvers<ContextType>;
  TaskTemplatesFields_schedule_freq_Create?: TaskTemplatesFields_Schedule_Freq_CreateResolvers<ContextType>;
  TaskTemplatesFields_schedule_freq_Delete?: TaskTemplatesFields_Schedule_Freq_DeleteResolvers<ContextType>;
  TaskTemplatesFields_schedule_freq_Read?: TaskTemplatesFields_Schedule_Freq_ReadResolvers<ContextType>;
  TaskTemplatesFields_schedule_freq_Update?: TaskTemplatesFields_Schedule_Freq_UpdateResolvers<ContextType>;
  TaskTemplatesFields_schedule_id?: TaskTemplatesFields_Schedule_IdResolvers<ContextType>;
  TaskTemplatesFields_schedule_id_Create?: TaskTemplatesFields_Schedule_Id_CreateResolvers<ContextType>;
  TaskTemplatesFields_schedule_id_Delete?: TaskTemplatesFields_Schedule_Id_DeleteResolvers<ContextType>;
  TaskTemplatesFields_schedule_id_Read?: TaskTemplatesFields_Schedule_Id_ReadResolvers<ContextType>;
  TaskTemplatesFields_schedule_id_Update?: TaskTemplatesFields_Schedule_Id_UpdateResolvers<ContextType>;
  TaskTemplatesFields_schedule_interval?: TaskTemplatesFields_Schedule_IntervalResolvers<ContextType>;
  TaskTemplatesFields_schedule_interval_Create?: TaskTemplatesFields_Schedule_Interval_CreateResolvers<ContextType>;
  TaskTemplatesFields_schedule_interval_Delete?: TaskTemplatesFields_Schedule_Interval_DeleteResolvers<ContextType>;
  TaskTemplatesFields_schedule_interval_Read?: TaskTemplatesFields_Schedule_Interval_ReadResolvers<ContextType>;
  TaskTemplatesFields_schedule_interval_Update?: TaskTemplatesFields_Schedule_Interval_UpdateResolvers<ContextType>;
  TaskTemplatesFields_thumbnail?: TaskTemplatesFields_ThumbnailResolvers<ContextType>;
  TaskTemplatesFields_thumbnail_Create?: TaskTemplatesFields_Thumbnail_CreateResolvers<ContextType>;
  TaskTemplatesFields_thumbnail_Delete?: TaskTemplatesFields_Thumbnail_DeleteResolvers<ContextType>;
  TaskTemplatesFields_thumbnail_Read?: TaskTemplatesFields_Thumbnail_ReadResolvers<ContextType>;
  TaskTemplatesFields_thumbnail_Update?: TaskTemplatesFields_Thumbnail_UpdateResolvers<ContextType>;
  TaskTemplatesFields_title?: TaskTemplatesFields_TitleResolvers<ContextType>;
  TaskTemplatesFields_title_Create?: TaskTemplatesFields_Title_CreateResolvers<ContextType>;
  TaskTemplatesFields_title_Delete?: TaskTemplatesFields_Title_DeleteResolvers<ContextType>;
  TaskTemplatesFields_title_Read?: TaskTemplatesFields_Title_ReadResolvers<ContextType>;
  TaskTemplatesFields_title_Update?: TaskTemplatesFields_Title_UpdateResolvers<ContextType>;
  TaskTemplatesFields_updatedAt?: TaskTemplatesFields_UpdatedAtResolvers<ContextType>;
  TaskTemplatesFields_updatedAt_Create?: TaskTemplatesFields_UpdatedAt_CreateResolvers<ContextType>;
  TaskTemplatesFields_updatedAt_Delete?: TaskTemplatesFields_UpdatedAt_DeleteResolvers<ContextType>;
  TaskTemplatesFields_updatedAt_Read?: TaskTemplatesFields_UpdatedAt_ReadResolvers<ContextType>;
  TaskTemplatesFields_updatedAt_Update?: TaskTemplatesFields_UpdatedAt_UpdateResolvers<ContextType>;
  TaskTemplatesReadAccess?: TaskTemplatesReadAccessResolvers<ContextType>;
  TaskTemplatesReadDocAccess?: TaskTemplatesReadDocAccessResolvers<ContextType>;
  TaskTemplatesReadVersionsAccess?: TaskTemplatesReadVersionsAccessResolvers<ContextType>;
  TaskTemplatesReadVersionsDocAccess?: TaskTemplatesReadVersionsDocAccessResolvers<ContextType>;
  TaskTemplatesUpdateAccess?: TaskTemplatesUpdateAccessResolvers<ContextType>;
  TaskTemplatesUpdateDocAccess?: TaskTemplatesUpdateDocAccessResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  Users?: UsersResolvers<ContextType>;
  UsersCreateAccess?: UsersCreateAccessResolvers<ContextType>;
  UsersCreateDocAccess?: UsersCreateDocAccessResolvers<ContextType>;
  UsersDeleteAccess?: UsersDeleteAccessResolvers<ContextType>;
  UsersDeleteDocAccess?: UsersDeleteDocAccessResolvers<ContextType>;
  UsersDocAccessFields?: UsersDocAccessFieldsResolvers<ContextType>;
  UsersDocAccessFields_createdAt?: UsersDocAccessFields_CreatedAtResolvers<ContextType>;
  UsersDocAccessFields_createdAt_Create?: UsersDocAccessFields_CreatedAt_CreateResolvers<ContextType>;
  UsersDocAccessFields_createdAt_Delete?: UsersDocAccessFields_CreatedAt_DeleteResolvers<ContextType>;
  UsersDocAccessFields_createdAt_Read?: UsersDocAccessFields_CreatedAt_ReadResolvers<ContextType>;
  UsersDocAccessFields_createdAt_Update?: UsersDocAccessFields_CreatedAt_UpdateResolvers<ContextType>;
  UsersDocAccessFields_email?: UsersDocAccessFields_EmailResolvers<ContextType>;
  UsersDocAccessFields_email_Create?: UsersDocAccessFields_Email_CreateResolvers<ContextType>;
  UsersDocAccessFields_email_Delete?: UsersDocAccessFields_Email_DeleteResolvers<ContextType>;
  UsersDocAccessFields_email_Read?: UsersDocAccessFields_Email_ReadResolvers<ContextType>;
  UsersDocAccessFields_email_Update?: UsersDocAccessFields_Email_UpdateResolvers<ContextType>;
  UsersDocAccessFields_name?: UsersDocAccessFields_NameResolvers<ContextType>;
  UsersDocAccessFields_name_Create?: UsersDocAccessFields_Name_CreateResolvers<ContextType>;
  UsersDocAccessFields_name_Delete?: UsersDocAccessFields_Name_DeleteResolvers<ContextType>;
  UsersDocAccessFields_name_Read?: UsersDocAccessFields_Name_ReadResolvers<ContextType>;
  UsersDocAccessFields_name_Update?: UsersDocAccessFields_Name_UpdateResolvers<ContextType>;
  UsersDocAccessFields_password?: UsersDocAccessFields_PasswordResolvers<ContextType>;
  UsersDocAccessFields_password_Create?: UsersDocAccessFields_Password_CreateResolvers<ContextType>;
  UsersDocAccessFields_password_Delete?: UsersDocAccessFields_Password_DeleteResolvers<ContextType>;
  UsersDocAccessFields_password_Read?: UsersDocAccessFields_Password_ReadResolvers<ContextType>;
  UsersDocAccessFields_password_Update?: UsersDocAccessFields_Password_UpdateResolvers<ContextType>;
  UsersDocAccessFields_roles?: UsersDocAccessFields_RolesResolvers<ContextType>;
  UsersDocAccessFields_roles_Create?: UsersDocAccessFields_Roles_CreateResolvers<ContextType>;
  UsersDocAccessFields_roles_Delete?: UsersDocAccessFields_Roles_DeleteResolvers<ContextType>;
  UsersDocAccessFields_roles_Read?: UsersDocAccessFields_Roles_ReadResolvers<ContextType>;
  UsersDocAccessFields_roles_Update?: UsersDocAccessFields_Roles_UpdateResolvers<ContextType>;
  UsersDocAccessFields_updatedAt?: UsersDocAccessFields_UpdatedAtResolvers<ContextType>;
  UsersDocAccessFields_updatedAt_Create?: UsersDocAccessFields_UpdatedAt_CreateResolvers<ContextType>;
  UsersDocAccessFields_updatedAt_Delete?: UsersDocAccessFields_UpdatedAt_DeleteResolvers<ContextType>;
  UsersDocAccessFields_updatedAt_Read?: UsersDocAccessFields_UpdatedAt_ReadResolvers<ContextType>;
  UsersDocAccessFields_updatedAt_Update?: UsersDocAccessFields_UpdatedAt_UpdateResolvers<ContextType>;
  UsersFields?: UsersFieldsResolvers<ContextType>;
  UsersFields_createdAt?: UsersFields_CreatedAtResolvers<ContextType>;
  UsersFields_createdAt_Create?: UsersFields_CreatedAt_CreateResolvers<ContextType>;
  UsersFields_createdAt_Delete?: UsersFields_CreatedAt_DeleteResolvers<ContextType>;
  UsersFields_createdAt_Read?: UsersFields_CreatedAt_ReadResolvers<ContextType>;
  UsersFields_createdAt_Update?: UsersFields_CreatedAt_UpdateResolvers<ContextType>;
  UsersFields_email?: UsersFields_EmailResolvers<ContextType>;
  UsersFields_email_Create?: UsersFields_Email_CreateResolvers<ContextType>;
  UsersFields_email_Delete?: UsersFields_Email_DeleteResolvers<ContextType>;
  UsersFields_email_Read?: UsersFields_Email_ReadResolvers<ContextType>;
  UsersFields_email_Update?: UsersFields_Email_UpdateResolvers<ContextType>;
  UsersFields_name?: UsersFields_NameResolvers<ContextType>;
  UsersFields_name_Create?: UsersFields_Name_CreateResolvers<ContextType>;
  UsersFields_name_Delete?: UsersFields_Name_DeleteResolvers<ContextType>;
  UsersFields_name_Read?: UsersFields_Name_ReadResolvers<ContextType>;
  UsersFields_name_Update?: UsersFields_Name_UpdateResolvers<ContextType>;
  UsersFields_password?: UsersFields_PasswordResolvers<ContextType>;
  UsersFields_password_Create?: UsersFields_Password_CreateResolvers<ContextType>;
  UsersFields_password_Delete?: UsersFields_Password_DeleteResolvers<ContextType>;
  UsersFields_password_Read?: UsersFields_Password_ReadResolvers<ContextType>;
  UsersFields_password_Update?: UsersFields_Password_UpdateResolvers<ContextType>;
  UsersFields_roles?: UsersFields_RolesResolvers<ContextType>;
  UsersFields_roles_Create?: UsersFields_Roles_CreateResolvers<ContextType>;
  UsersFields_roles_Delete?: UsersFields_Roles_DeleteResolvers<ContextType>;
  UsersFields_roles_Read?: UsersFields_Roles_ReadResolvers<ContextType>;
  UsersFields_roles_Update?: UsersFields_Roles_UpdateResolvers<ContextType>;
  UsersFields_updatedAt?: UsersFields_UpdatedAtResolvers<ContextType>;
  UsersFields_updatedAt_Create?: UsersFields_UpdatedAt_CreateResolvers<ContextType>;
  UsersFields_updatedAt_Delete?: UsersFields_UpdatedAt_DeleteResolvers<ContextType>;
  UsersFields_updatedAt_Read?: UsersFields_UpdatedAt_ReadResolvers<ContextType>;
  UsersFields_updatedAt_Update?: UsersFields_UpdatedAt_UpdateResolvers<ContextType>;
  UsersReadAccess?: UsersReadAccessResolvers<ContextType>;
  UsersReadDocAccess?: UsersReadDocAccessResolvers<ContextType>;
  UsersUnlockAccess?: UsersUnlockAccessResolvers<ContextType>;
  UsersUnlockDocAccess?: UsersUnlockDocAccessResolvers<ContextType>;
  UsersUpdateAccess?: UsersUpdateAccessResolvers<ContextType>;
  UsersUpdateDocAccess?: UsersUpdateDocAccessResolvers<ContextType>;
  allMedia?: AllMediaResolvers<ContextType>;
  articlesAccess?: ArticlesAccessResolvers<ContextType>;
  articlesDocAccess?: ArticlesDocAccessResolvers<ContextType>;
  categoriesAccess?: CategoriesAccessResolvers<ContextType>;
  categoriesDocAccess?: CategoriesDocAccessResolvers<ContextType>;
  mediaAccess?: MediaAccessResolvers<ContextType>;
  mediaDocAccess?: MediaDocAccessResolvers<ContextType>;
  payload_preferencesAccess?: Payload_PreferencesAccessResolvers<ContextType>;
  payload_preferencesDocAccess?: Payload_PreferencesDocAccessResolvers<ContextType>;
  task_templatesAccess?: Task_TemplatesAccessResolvers<ContextType>;
  task_templatesDocAccess?: Task_TemplatesDocAccessResolvers<ContextType>;
  usersAccess?: UsersAccessResolvers<ContextType>;
  usersDocAccess?: UsersDocAccessResolvers<ContextType>;
  usersJWT?: UsersJwtResolvers<ContextType>;
  usersLoginResult?: UsersLoginResultResolvers<ContextType>;
  usersMe?: UsersMeResolvers<ContextType>;
  usersRefreshedUser?: UsersRefreshedUserResolvers<ContextType>;
  usersResetPassword?: UsersResetPasswordResolvers<ContextType>;
  versionsArticles?: VersionsArticlesResolvers<ContextType>;
  versionsTaskTemplates?: VersionsTaskTemplatesResolvers<ContextType>;
};

